<template>
    <div
        :class="['favourite', { solid: isFavourite }]"
        v-if="tooltip"
        v-tooltip="isFavourite ? $t('Remove from favourites') : $t('Add to favourites')"
        @click.prevent.stop="toggleFavourite(folder.shortname)"
    >
        <fa-icon class="icon" :icon="isFavourite ? 'fa-solid fa-star' : 'fa-regular fa-star'" />
    </div>
    <div v-else @click.prevent.stop="toggleFavourite(folder.shortname)" :class="['favourite', { solid: isFavourite }]">
        <fa-icon class="icon" :icon="isFavourite ? 'fa-solid fa-star' : 'fa-regular fa-star'" />
    </div>
</template>

<script setup>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import FolderDTO from '@/js/dto/FolderDTO.js';
import { useFolders } from '@/js/composables/useFolders.js';
import UsesTooltip from '@/js/mixins/UsesTooltip.js';

const props = defineProps({
    folder: {
        type: FolderDTO,
        required: true
    },
    tooltip: {
        type: Boolean,
        default: true
    }
});

const vTooltip = UsesTooltip.directives.tooltip;
const { addFavourite, removeFavourite } = useFolders();
const store = useStore();

const isFavourite = computed(() => store.getters['favourites/isFavourite'](props.folder.shortname));

onMounted(() => {
    const folderUuid = props.folder.shortname;

    if (props.folder.isFavourite && !store.getters['favourites/isFavourite'](folderUuid)) {
        store.commit('favourites/addFavourite', folderUuid);
    }
});

const toggleFavourite = (folderUuid) => {
    if (isFavourite.value) {
        removeFavourite(folderUuid);
        store.commit('favourites/removeFavourite', folderUuid);
    } else {
        addFavourite(folderUuid);
        store.commit('favourites/addFavourite', folderUuid);
    }
};
</script>
