<template>
    <div class="captions-editing-container" :class="classes" @mousedown="startEditing" :style="style">
        <slot />
    </div>
</template>

<script setup lang="ts">
import { ComponentInternalInstance, computed, ComputedRef, getCurrentInstance, onMounted } from 'vue';
import { useStore } from 'vuex';
import { ComponentPosition, ComponentSize, EditableElement } from '@/js/video-studio/types.ts';
import { useCaptions } from '@/js/videos/composables/caption/useCaptions.ts';
import { Edition } from '@/js/video-studio/constants';
import { useCaptionsEditor } from '@/js/videos/composables/caption/useCaptionsEditor.ts';

const { storeModulePath, type, parentStoreModulePath, sequenceId } = defineProps<{
    storeModulePath: string;
    type: string;
    parentStoreModulePath: string;
    sequenceId?: string;
}>();

const store = useStore();
const { position, size, fontSize, mediaId } = useCaptions(storeModulePath, type, parentStoreModulePath);
const { openEditor } = useCaptionsEditor();

let instance: ComponentInternalInstance | null;

const classes: ComputedRef = computed(() => {
    return {
        [Edition.EDITABLE_CONTAINER_CLASS]: true
    };
});

const style: ComputedRef = computed(() => {
    return {
        top: position.value.top || '75%',
        left: position.value.left || '5%',
        width: size.value.width || '90%',
        height: size.value.height || '20%'
    };
});

const startEditing = (event: Event): void => {
    store.commit('edition/setEditingElement', instance);
    store.commit('edition/sendMovementEvent', event);
    if (!!event) document.addEventListener('click', captureClickEvent, { once: true, capture: true });
    if (sequenceId) {
        store.commit('ui/setCurrentEditedItem', sequenceId);
    }
    openEditor(mediaId.value, storeModulePath, type, parentStoreModulePath, sequenceId, true);
};

const captureClickEvent = (event: Event): void => {
    event.stopPropagation();
};

const editPosition = (param: ComponentPosition): void => {
    position.value = param;
};

const editSize = (param: ComponentSize, scale: number): void => {
    size.value = param;
    fontSize.value = fontSize.value * (scale || 1);
};

defineExpose<EditableElement>({
    state: {
        rotation: 0,
        scale: {
            value: 1
        }
    },
    editingData: {
        corners: Edition.CORNERS,
        borders: Edition.BORDERS,
        disableActionsFrame: true,
        disableSizePreview: true,
        clearnessPreview: true,
        homotheticMinimalSize: false,
        movement: true,
        initFrameBeforeTransform: false,
        homotheticOnCorners: true,
        clearBeforePreview: false,
        previewAttribute: null,
        disableFinalState: false
    },
    editPosition,
    editSize
});

onMounted(() => {
    instance = getCurrentInstance();
});
</script>
