const state = () => ({
    active: false,
    mediaId: null,
    storeModulePath: null,
    type: null,
    parentStoreModulePath: null,
    currentCaptionIndex: {},
    sequenceId: null,
    closeWhenClickOutside: false
});

const getters = {};

const mutations = {
    setCurrentCaptionIndex(state, { storeModulePath, index }) {
        state.currentCaptionIndex = {
            ...state.currentCaptionIndex,
            [storeModulePath]: index
        };
    }
};

const actions = {
    openEditor(
        { state },
        { mediaId, storeModulePath, type, parentStoreModulePath, sequenceId, closeWhenClickOutside }
    ) {
        state.mediaId = mediaId;
        state.storeModulePath = storeModulePath;
        state.type = type;
        state.parentStoreModulePath = parentStoreModulePath;
        state.sequenceId = sequenceId;
        state.active = true;
        state.closeWhenClickOutside = closeWhenClickOutside;
    },

    closeEditor({ state }) {
        state.active = false;
        state.mediaId = null;
        state.storeModulePath = null;
        state.type = null;
        state.parentStoreModulePath = null;
        state.sequenceId = null;
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
