<template>
    <div class="video-template-themes ui-card-row">
        <label v-if="showLabel">{{ $t('Themes') }}</label>
        <ui-dropdown
            id="video-template-themes"
            :select="true"
            :multiple="true"
            :model-value="modelValue"
            menu-strategy="fixed"
            :disabled="disabled"
            @update:model-value="handleThemeChange"
        >
            <template #dropdown-toggle="{ instance }">
                {{
                    modelValue.length > 1
                        ? $t('Several')
                        : modelValue.length === 0
                          ? $t('Choose a theme')
                          : instance.selectedLabel
                }}
            </template>
            <ui-dropdown-item
                v-for="theme in availableThemes"
                :key="theme.id"
                :value="theme.id"
                :label="theme.name"
                :custom-icon="theme.icon"
            />
        </ui-dropdown>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import type { PropType } from 'vue';
import type { Theme } from '@/js/videos/types/theme';
import UiDropdown from '@/js/components/UiDropdown.vue';
import UiDropdownItem from '@/js/components/UiDropdownItem.vue';

const props = defineProps({
    modelValue: {
        type: Array as PropType<string[]>,
        required: true
    },
    disabled: {
        type: Boolean,
        default: false
    },
    showLabel: {
        type: Boolean,
        default: true
    }
});

const emit = defineEmits<{
    'update:modelValue': [value: string[]];
}>();

const store = useStore();

const availableThemes = computed<Theme[]>(() => store.state.ui.availableThemes);

function handleThemeChange(themes: string[]) {
    const availableThemeIds = availableThemes.value.map((theme) => theme.id);
    const validThemes = themes.filter((theme) => availableThemeIds.includes(theme));
    emit('update:modelValue', validThemes);
}
</script>

<style lang="scss">
body.edit .ui-text-input:not(.no-style) input,
body.edit .ui-text-input:not(.no-style) textarea {
    width: 100%;
}
</style>
