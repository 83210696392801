<template>
    <dropdown
        class="hide-border hide-chevron disable-padding"
        auto-close="outside"
        ref="dropdownRef"
        :disabled="isCaptioning || !cgsEnabled"
    >
        <template #dropdown-selected-value>
            <button
                class="ui-caption-button ui-caption-button-icon-only"
                :class="buttonClasses"
                :style="buttonStyle"
                :disabled="disabled"
                @click="handleCaptioning"
                v-tooltip="tooltip"
            >
                <fa-icon class="icon ui-caption-button-icon" icon="fa-regular fa-comment-dots" />
                <svg-icon icon="circle-loader" class="loader" />
            </button>
        </template>
        <ui-captions-generator-form
            v-if="cgsEnabled"
            ref="generatorFormRef"
            :media-id="mediaId"
            :store-module-path="storeModulePath"
            :type="type"
            :parent-store-module-path="parentStoreModulePath"
        />
    </dropdown>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import UsesTooltip from '@/js/mixins/UsesTooltip';
import { useCaptions } from '@/js/videos/composables/caption/useCaptions.ts';
import { useModal } from '@/js/composables/useModal';
import Dropdown from '@/js/components/dropdown/Dropdown.vue';
import UiCaptionsGeneratorForm from '@/js/videos/components/ui/body/selectors/caption/UiCaptionsGeneratorForm.vue';

const emit = defineEmits<{
    (e: 'openEditor'): void;
}>();

const props = defineProps<{
    storeModulePath: string;
    type: string;
    parentStoreModulePath: string;
    mediaId: string;
    mediaSrc: string;
    hasOldCaptions: boolean;
    readonly: boolean;
}>();

const store = useStore();
const { t } = useI18n();
const { openModal } = useModal();
const { baseCaptions, isCaptioning, resetCaptions } = useCaptions(
    props.storeModulePath,
    props.type,
    props.parentStoreModulePath
);

const dropdownRef = ref<typeof Dropdown | null>(null);
const generatorFormRef = ref<typeof UiCaptionsGeneratorForm | null>(null);

const vTooltip = UsesTooltip.directives.tooltip;

const cgsEnabled = computed(() => {
    return store.state.ui.restrictions.enableTTS; // TTS has actually the same CGS as STT
});

const isMediaConverting = computed<boolean>(
    () => props.mediaSrc && store.getters['loading/isConverting'](props.mediaSrc)
);

const hasCaptions = computed<boolean>(() => {
    return props.hasOldCaptions || baseCaptions.value.length > 0;
});

const disabled = computed<boolean>(() => props.readonly || isMediaConverting.value);

const buttonClasses = computed(() => {
    return {
        loading: isCaptioning.value
    };
});

const buttonStyle = computed(() => {
    return !disabled.value && hasCaptions.value ? { color: 'var(--edit-mode-color)' } : {};
});

const tooltip = computed<string>(() => {
    if (isMediaConverting.value) {
        return t('media.preparation.warning');
    }

    if (disabled.value) {
        return '';
    }

    if (isCaptioning.value) {
        return t('Captioning in progress...');
    }

    return t('Caption');
});

const handleCaptioning = () => {
    if (props.hasOldCaptions) {
        openModal('MigrateVideoCaptionsModal', {
            storeModulePath: props.storeModulePath,
            type: props.type,
            parentStoreModulePath: props.parentStoreModulePath,
            callback: () => {
                if (cgsEnabled.value) {
                    generatorFormRef.value?.startCaptioning();
                } else {
                    resetCaptions();
                    emit('openEditor');
                }
            }
        });
    } else if (hasCaptions.value || !cgsEnabled.value) {
        emit('openEditor');
    }
};

watch(isCaptioning, (newValue) => {
    if (newValue) {
        dropdownRef.value?.close();
    }
});
</script>
