<!--
  -- Component name: Scroll2Color
  -- Type: Transition
  -- Uses: color.start
  -- Tags: scroll
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <rect class="scroll-color-2" width="100%" height="100%" :fill="state.color.start" />
    </svg>
</template>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true // The transition component is placed above the in and out elements
        };
    },

    computed: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            this._timeline = t;
            t.set(['#' + this.containerID + ' .scroll-color-2'], { autoAlpha: 0.5 }, 0);
            t.fromTo(
                [this.backgroundInElement, this.sequenceInElement],
                { yPercent: 100 },
                { yPercent: 0, duration: 0.3 },
                0
            );
            t.fromTo(
                ['#' + this.containerID + ' .scroll-color-2'],
                { yPercent: 100 },
                { yPercent: 0, duration: 0.3 },
                0
            );
            t.fromTo(
                ['#' + this.containerID + ' .scroll-color-2'],
                { yPercent: 0 },
                { yPercent: -100, duration: 0.3 },
                0.6
            );
            return t;
        }
    }
};
</script>
