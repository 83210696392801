import { TTS } from 'cte-video-studio';
import card from '../card';
import { getPrefixedUuid } from '@/js/utils.js';
import voice from './voice.js';
import media from './media.js';

const state = () => ({
    ...card.state(),

    category: TTS.VOICE_CATEGORY
});

// Getters
const getters = {
    media: (state) => {
        return state[state.category];
    },

    track: (state, getters) => {
        return getters[state.category + '/track'];
    },

    volume: (state, getters) => {
        return getters[state.category + '/volume'];
    },

    start: (state, getters) => {
        return getters[state.category + '/start'];
    },

    sourceRef(state, getters) {
        return getters[state.category + '/sourceRef'];
    },

    playbackRate(state, getters) {
        return getters[state.category + '/playbackRate'];
    },

    timerange(state, getters) {
        return getters[state.category + '/timerange'];
    },

    timerangeSegments(state, getters) {
        return getters[state.category + '/timerangeSegments'];
    },

    captions(state, getters) {
        return getters[state.category + '/captions'];
    },

    captioning(state, getters) {
        return getters[state.category + '/captioning'];
    },

    captioningStatus(state, getters) {
        return getters[state.category + '/captioningStatus'];
    },

    totalDuration(state, getters) {
        return getters[state.category + '/totalDuration'];
    }
};

// Mutations
const mutations = {
    ...card.mutations,

    setCategory(state, category) {
        state.category = category;
    }
};

// Actions
const actions = {
    init({ commit, dispatch, rootState }, data) {
        dispatch('setDefaultVoiceId');

        if (data.cardShown) commit('showCard', data.cardShown);
        if (data.category) commit('setCategory', data.category);
        if (data.audio) dispatch('audio/init', data.audio);
        if (data.voice) dispatch('voice/init', data.voice);
        if (data.recording) dispatch('recording/init', data.recording);
    },

    setDefaultVoiceId({ commit, rootState }) {
        if (rootState?.branding?.defaultVoiceId) commit('voice/setVoiceId', rootState.branding.defaultVoiceId);
    },

    pasteCard({ dispatch, state }, data) {
        dispatch('init', data);
    },

    /*
     Move this action in voice module after merge of edition and videostudio
     */
    handleVoiceResult({ dispatch, commit, getters, rootState }, result) {
        dispatch('voice/handleVoiceResult', result);
        if (!getters['voice/sourceRef']) {
            commit('voice/setSourceRef', getPrefixedUuid(rootState.ui.prefixes.mediaReference));
        }
        dispatch('setUpToDate', true);
    },

    setStatus({ state, commit }, status) {
        commit(state.category + '/setStatus', status);
    },

    setUpToDate({ state, commit }, upToDate) {
        commit(state.category + '/setUpToDate', upToDate);
    },

    setVolume({ state, commit }, volume) {
        commit(state.category + '/setVolume', volume);
    },

    setStart({ state, commit }, volume) {
        commit(state.category + '/setStart', volume);
    },

    setPlaybackRate({ state, commit }, playbackRate) {
        commit(state.category + '/setPlaybackRate', playbackRate);
    },

    setTimerange({ state, commit }, timerange) {
        commit(state.category + '/setTimerange', timerange);
    },

    setTimerangeSegments({ state, commit }, segments) {
        commit(state.category + '/setTimerangeSegments', segments);
    },

    setTotalDuration({ state, commit }, totalDuration) {
        commit(state.category + '/setTotalDuration', totalDuration);
    },

    setCaptions({ state, commit }, captions) {
        commit(state.category + '/setCaptions', captions);
    }
};

export default {
    namespaced: true,

    state,
    modules: {
        voice: voice,
        audio: media,
        recording: media
    },
    getters,
    mutations,
    actions
};
