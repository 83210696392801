<!--
  -- Component name: SG4
  -- Type: Transition
  -- Uses: 
  -- Tags: sg
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        :viewBox="dataForView.viewBox"
        preserveAspectRatio="xMidYMid slice"
    >
        <defs>
            <mask :id="svgMaskID">
                <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
                <g class="sg-logo-mask">
                    <rect
                        class="sg-logo-top-mask"
                        :x="dataForView.border"
                        :y="dataForView.border"
                        :width="dataForView.rectangleWidth"
                        :height="dataForView.rectangleHeight"
                    />
                    <rect
                        class="sg-logo-bottom-mask"
                        :x="dataForView.border"
                        :y="dataForView.bottomRectangleY"
                        :width="dataForView.rectangleWidth"
                        :height="dataForView.rectangleHeight"
                    />
                </g>
            </mask>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" fill="#fff" :mask="svgMask" />
        <g class="sg-logo">
            <rect
                class="sg-logo-top"
                :x="dataForView.border"
                :y="dataForView.border"
                :width="dataForView.rectangleWidth"
                :height="dataForView.rectangleHeight"
                fill="#e9041e"
            />
            <rect
                class="sg-logo-bottom"
                :x="dataForView.border"
                :y="dataForView.bottomRectangleY"
                :width="dataForView.rectangleWidth"
                :height="dataForView.rectangleHeight"
            />
        </g>
    </svg>
</template>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true, // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
            borderSize: 10,
            logoScaleDown: 0.9,
            BORDER_SIZE_RATIO: 0.03832,
            HEIGHT_RATIO: 0.43284,
            BOTTOM_RATIO: 0.54817
        };
    },

    computed: {
        svgMaskID() {
            return this.containerID + '-sgtest-mask';
        },

        svgMask() {
            return 'url(#' + this.svgMaskID + ')';
        },

        isLandscapeFormat() {
            return this.format.width > this.format.height;
        },

        isPortraitFormat() {
            return this.format.width < this.format.height;
        },

        isSquareFormat() {
            return this.format.width === this.format.height;
        },

        dataForView() {
            if (this.isLandscapeFormat) {
                const size = 415;
                const border = (size * this.BORDER_SIZE_RATIO) / 2;

                return {
                    scaleUp: 5,
                    size,
                    viewBox: '0 0 1920 1080',
                    rectangleWidth: size - border * 2,
                    rectangleHeight: size * this.HEIGHT_RATIO,
                    bottomRectangleY: size * this.BOTTOM_RATIO,
                    border,
                    duration: 1
                };
            }

            if (this.isSquareFormat) {
                const size = 360;
                const border = (size * this.BORDER_SIZE_RATIO) / 2;

                return {
                    scaleUp: 3,
                    size,
                    viewBox: '0 0 1000 1000',
                    rectangleWidth: size - border * 2,
                    rectangleHeight: size * this.HEIGHT_RATIO,
                    bottomRectangleY: size * this.BOTTOM_RATIO,
                    border,
                    duration: 0.7
                };
            }

            if (this.isPortraitFormat) {
                const size = 370;
                const border = (size * this.BORDER_SIZE_RATIO) / 2;

                return {
                    scaleUp: 5.5,
                    size,
                    viewBox: '0 0 1080 1920',
                    rectangleWidth: size - border * 2,
                    rectangleHeight: size * this.HEIGHT_RATIO,
                    bottomRectangleY: size * this.BOTTOM_RATIO,
                    border,
                    duration: 0.85
                };
            }
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            this._timeline = t;
            t.set('#' + this.containerID + ' .sg-logo-mask', {
                transform:
                    'translateX(50%) translateX(-' +
                    this.dataForView.size / 2 +
                    'px) translateY(50%) translateY(-' +
                    this.dataForView.size / 2 +
                    'px)'
            });
            t.set('#' + this.containerID + ' .sg-logo', {
                transform:
                    'translateX(50%) translateX(-' +
                    this.dataForView.size / 2 +
                    'px) translateY(50%) translateY(-' +
                    this.dataForView.size / 2 +
                    'px)'
            });

            //Scaling down logo
            t.to(
                ['#' + this.containerID + ' .sg-logo', '#' + this.containerID + ' .sg-logo-mask'],
                {
                    scale: this.logoScaleDown,
                    duration: 0.4,
                    ease: 'power2.out',
                    transformOrigin: '50% 50%'
                },
                0.5
            );

            t.fromTo(
                '#' + this.containerID + ' .sg-logo',
                {
                    opacity: 1
                },
                {
                    opacity: 0.5,
                    duration: 0.2,
                    ease: 'power2.out'
                },
                0.5
            );

            // Scaling up logo
            t.to(
                ['#' + this.containerID + ' .sg-logo', '#' + this.containerID + ' .sg-logo-mask'],
                {
                    scale: this.dataForView.scaleUp,
                    duration: 0.8,
                    ease: 'power2.out',
                    transformOrigin: '50% 50%'
                },
                1
            );

            t.fromTo(
                '#' + this.containerID + ' .sg-logo',
                {
                    opacity: 0.5
                },
                {
                    opacity: 0,
                    duration: 0.8,
                    ease: 'none'
                },
                1
            );

            t.to(
                [
                    '#' + this.containerID + ' .sg-logo-top',
                    '#' + this.containerID + ' .sg-logo-top-mask',
                    '#' + this.containerID + ' .sg-logo-bottom',
                    '#' + this.containerID + ' .sg-logo-bottom-mask'
                ],
                { duration: 0.5, scaleY: 1.2, scaleX: 1.4, ease: 'power2.inOut', transformOrigin: '50% 50%' }
            );

            return t;
        }
    }
};
</script>
