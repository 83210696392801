import gsap from 'gsap';
import CaptionsAnimationInterface from '@/js/video-studio/components/captions/animations/CaptionsAnimationInterface.ts';

const CaptionsBasicAnimation: CaptionsAnimationInterface = {
    getTimeline(): GSAPTimeline {
        return gsap.timeline({ paused: true });
    }
};

export default CaptionsBasicAnimation;
