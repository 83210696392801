<template>
    <ui-dropdown
        class="audio-dropdown centered"
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="bottom-end"
        menu-strategy="fixed"
        :toggle-style="toggleStyle"
        :disabled="disableDropDown"
        :icon-only="iconOnly"
        :tooltip="$t('Volume')"
    >
        <template #dropdown-toggle>
            <svg-icon :icon="isAudioCategory || isRecordingCategory ? 'volume-ai' : 'audio-icon'" />
            <span v-if="!iconOnly">{{ $t('Music') }}</span>
        </template>
        <div>
            <ui-range-input
                class="ui-content-row"
                v-model="voiceOverVolumeModel"
                :horizontal="false"
                :label="$t('Audio volume')"
                :max="200"
                :disabled="readOnly"
            />
        </div>

        <div v-if="isAudioCategory || isRecordingCategory" class="ui-informative-block border-rounded">
            <div class="ui-informative-block-row">
                <strong>{{ $t('audio-isolation.improve-label') }}</strong>
                <fa-icon v-if="isImproving" class="icon" icon="fa-solid fa-spinner-third" spin />
                <ui-switch-button
                    v-model="audioImproved"
                    :tooltip="!user.client.enableTts && $t('studio.features.recording-disabled')"
                    :show-label="false"
                    :disabled="readOnly || !user.client.enableTts || isImproving"
                />
            </div>

            <ui-voice-isolator-informative-note />
        </div>
    </ui-dropdown>
</template>
<script>
import { useStore } from 'vuex';
import { defineComponent, computed, ref } from 'vue';
import UiRangeInput from '../../../../../../components/UiRangeInput.vue';
import UiNumberInput from '../../../../../../components/UiNumberInput.vue';
import UiDropdown from '../../../../../../components/UiDropdown.vue';
import { useHistory } from '../../../../../composables/useHistory';
import { useVoiceOver } from '../../../../../composables/useVoiceOver';
import { Sound } from 'cte-video-studio';
import UiSwitchButton from '@/js/components/UiSwitchButton.vue';
import UiVoiceIsolatorInformativeNote from '@/js/videos/components/ui/body/cards/menus/UiVoiceIsolatorInformativeNote.vue';
import { useAuth } from '@/js/composables/useAuth.js';

export default defineComponent({
    components: { UiVoiceIsolatorInformativeNote, UiSwitchButton, UiRangeInput, UiNumberInput, UiDropdown },
    props: { useInSequence: Boolean, seqId: String, readOnly: Boolean, iconOnly: Boolean },
    setup(props) {
        const useInSequence = ref(props.useInSequence);
        const seqId = ref(props.seqId);

        const store = useStore();
        const { user } = useAuth();
        const { saveHistoryStep } = useHistory();
        const {
            storeModulePath,
            voiceOverMediaState,
            audioImproved,
            isImproving,
            isAudioCategory,
            isRecordingCategory
        } = useVoiceOver(useInSequence, seqId);

        const defaultVolume = Sound.VOLUME_DEFAULT;

        const voiceOverVolume = computed(() => {
            return store.getters[storeModulePath.value + '/volume'] ?? defaultVolume;
        });

        const voiceOverVolumeModel = computed({
            get: () => {
                return Math.round(100 * voiceOverVolume.value);
            },
            set: (value) => {
                saveHistoryStep(() => {
                    store.dispatch(storeModulePath.value + '/setVolume', value / 100);
                });
            }
        });

        const disableDropDown = computed(() => {
            return props.readOnly || !voiceOverMediaState?.value?.src;
        });

        const toggleStyle = computed(() => {
            return (voiceOverVolume.value !== defaultVolume ||
                ((isAudioCategory.value || isRecordingCategory.value) && audioImproved.value)) &&
                voiceOverMediaState?.value?.src
                ? { color: 'var(--edit-mode-color)' }
                : {};
        });

        return {
            user,
            voiceOverVolume,
            voiceOverVolumeModel,
            disableDropDown,
            toggleStyle,
            audioImproved,
            isImproving,
            isAudioCategory,
            isRecordingCategory
        };
    }
});
</script>

<style></style>
