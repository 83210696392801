<template>
    <dropdown
        :model-value="selectedBrandForDropdown"
        @update:model-value="handleDropdownSelection"
        v-tooltip="$t('Choose brand')"
    >
        <template #dropdown-selected-value>
            <image-fallback
                v-if="selectedDisplayBrand"
                :src="selectedDisplayBrand.logoUrl"
                class="dropdown-list-item-image"
            />
            <span v-if="selectedDisplayBrand">{{ selectedDisplayBrand.name }}</span>
        </template>
        <UiTextInput
            id="brand-selector-search"
            type="search"
            :placeholder="$t('Search')"
            :show-label="false"
            icon="search-icon"
            v-model="searchFilter"
        />
        <ui-infinite-scroll-paginator v-model="page" :max-page="totalPages" :loading="isLoading">
            <dropdown-item v-for="brand in brands" :key="brand.id" :value="brand">
                <image-fallback :src="brand.logoUrl" class="dropdown-list-item-image" />
                <span class="dropdown-list-item-label">{{ brand.name }}</span>
            </dropdown-item>
            <template v-slot:loading>
                <LoadingMarker v-if="isLoading" />
            </template>
            <template v-slot:empty-data v-if="!isLoading && brands.length === 0">
                <div>{{ $t('search.any_result') }}</div>
            </template>
        </ui-infinite-scroll-paginator>
    </dropdown>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from 'vue';
// @ts-ignore
import { useBrands } from '@/js/composables/useBrands.js';
// @ts-ignore
import { default as DropdownItem } from '@/js/components/dropdown/DropdownItem.vue';
// @ts-ignore
import { default as Dropdown } from '@/js/components/dropdown/Dropdown.vue';
// @ts-ignore
import { default as ImageFallback } from '@/js/components/ImageFallback.vue';
// @ts-ignore
import { useAuth } from '@/js/composables/useAuth.js';
// @ts-ignore
import { default as UiInfiniteScrollPaginator } from '@/js/components/UiInfiniteScrollPaginator.vue';
// @ts-ignore
import { default as LoadingMarker } from '@/js/components/LoadingMarker.vue';
// @ts-ignore
import { default as UiTextInput } from '@/js/components/UiTextInput.vue';
import { debounce } from 'lodash';
import { useStore } from 'vuex';
// @ts-ignore
import UsesTooltip from '@/js/mixins/UsesTooltip.js';

interface Brand {
    id: string;
    name: string;
    logoUrl?: string;
    enabledTemplateLanguages?: string[];
    [key: string]: any;
}

const props = defineProps({
    modelValue: {
        type: String,
        default: null
    },
    storageKey: {
        type: String,
        default: 'selectedBrandId'
    }
});

const vTooltip = UsesTooltip.directives.tooltip;

const store = useStore();
const emit = defineEmits(['update:modelValue']);

const { page, totalPages, isLoading, brands, fetchBrands, resetDataForBrands } = useBrands();
const { user } = useAuth();

const STORAGE_KEY = computed(() => `${user.value?.id || 'user'}-${props.storageKey}`);

// Référence pour l'ID de la marque sélectionnée
const selectedBrandId = ref<string | null>(props.modelValue);

// Référence pour la marque à afficher dans le dropdown
const selectedDisplayBrand = ref<Brand | null>(null);

// Computed pour le v-model du dropdown - ne changera que lors d'une sélection intentionnelle
const selectedBrandForDropdown = computed(() => {
    return selectedDisplayBrand.value;
});

// Fonction qui gère la sélection du dropdown
const handleDropdownSelection = (brand: Brand | null) => {
    selectedDisplayBrand.value = brand;
    selectedBrandId.value = brand ? brand.id : null;
};

const searchFilter = ref('');

const debouncedSearch = debounce(() => {
    // Ne pas modifier selectedDisplayBrand ici
    resetDataForBrands();
    fetchBrands({
        search: searchFilter.value,
        page: 1
    });
}, 500);

const updateTemplateLanguages = (languages: string[]) => {
    store.commit('branding/enableTemplateLanguages', languages);
};

watch(searchFilter, () => {
    debouncedSearch();
});

// Mettre à jour selectedDisplayBrand lorsque brands change
const updateSelectedDisplayBrand = () => {
    if (selectedBrandId.value && brands.value && brands.value.length > 0) {
        const found = brands.value.find((brand: Brand) => brand.id === selectedBrandId.value);
        if (found) {
            selectedDisplayBrand.value = found;
        }
    }
};

// Restore selected brand from localStorage or select first one
const restoreSelectedBrand = () => {
    if (!brands.value || brands.value.length === 0) return;

    // Try to restore from localStorage first
    const savedBrandId = localStorage.getItem(STORAGE_KEY.value);

    if (savedBrandId && brands.value.find((brand: Brand) => brand.id === savedBrandId)) {
        selectedBrandId.value = savedBrandId;
        updateSelectedDisplayBrand();
        localStorage.removeItem('lastFolderId');
        return;
    }

    // If no brand is selected and we couldn't restore from localStorage, select the first one
    if (!selectedBrandId.value && brands.value.length > 0) {
        selectedBrandId.value = brands.value[0].id;
        selectedDisplayBrand.value = brands.value[0];
        localStorage.removeItem('lastFolderId');
    }
};

// Force selection after brands are loaded
const forceInitialSelection = () => {
    // Ensure we have brands and none is selected yet
    if (brands.value && brands.value.length > 0 && !selectedBrandId.value) {
        selectedBrandId.value = brands.value[0].id;
    }
};

watch(
    brands,
    () => {
        restoreSelectedBrand();
        updateSelectedDisplayBrand();
        nextTick(() => {
            forceInitialSelection();
        });
    },
    { immediate: true, deep: true }
);

watch(page, () => {
    fetchBrands({
        search: searchFilter.value,
        page: 1 // Forcer le retour à la première page lors d'une recherche
    });
});

// Watch for selectedDisplayBrand changes to update template languages
watch(selectedDisplayBrand, (newValue) => {
    if (newValue) {
        updateTemplateLanguages(newValue.enabledTemplateLanguages || []);
    }
});

// Save selected brand to localStorage and emit event when it changes
watch(selectedBrandId, (newValue) => {
    if (newValue) {
        localStorage.setItem(STORAGE_KEY.value, newValue);
        emit('update:modelValue', newValue);
    } else {
        localStorage.removeItem(STORAGE_KEY.value);
        emit('update:modelValue', null);
    }
});

// Watch for external modelValue changes
watch(
    () => props.modelValue,
    (newValue) => {
        if (newValue !== selectedBrandId.value) {
            selectedBrandId.value = newValue;
        }
    },
    { immediate: true }
);

onMounted(() => {
    console.log('BrandSelector mounted, fetching brands');
    fetchBrands({
        search: searchFilter.value,
        page: 1 // Forcer le retour à la première page lors d'une recherche
    });

    // Add a fallback to ensure a brand is selected after initial load
    setTimeout(() => {
        if (brands.value && brands.value.length > 0 && !selectedBrandId.value) {
            selectedBrandId.value = brands.value[0].id;
        }
    }, 500);
});
</script>
