<template>
    <div class="ui-infinite-scroll" ref="container">
        <ui-scrollable @scroll="checkMarkerPosition" overflow="visible" ref="scrollableContainer">
            <slot></slot>
            <div ref="loadingMarker">
                <slot name="loading"></slot>
            </div>
            <div class="empty-data">
                <slot name="empty-data"></slot>
            </div>
        </ui-scrollable>
    </div>
</template>

<script setup>
import { computed, nextTick, onMounted, onUnmounted, ref, watch } from 'vue';
import useAutoOffset from '@/js/composables/useAutoOffset';
import UiScrollable from '@/js/components/UiScrollable.vue';

const props = defineProps({
    modelValue: Number,
    maxPage: Number,
    loading: Boolean,
    useScrollable: {
        type: Boolean,
        default: true
    }
});

const emit = defineEmits(['update:modelValue']);

const container = ref(null);
const scrollableContainer = ref(null);
const loadingMarker = ref(null);

const { offset } = useAutoOffset();

let page = ref(props.modelValue);
let scrollPosition = 0;
let isThrottled = false;
const throttleTime = 500; // ms between checks

const hasMoreData = computed(() => {
    return page.value < props.maxPage;
});

const getScrollContainer = () => {
    return props.useScrollable ? scrollableContainer.value.$el : window;
};

const getScrollPosition = () => {
    return props.useScrollable ? getScrollContainer().scrollTop : window.pageYOffset;
};

const setScrollPosition = (position) => {
    if (props.useScrollable) {
        getScrollContainer().scrollTop = position;
    } else {
        window.scrollTo(0, position);
    }
};

const checkMarkerPosition = () => {
    if (props.loading || !hasMoreData.value || isThrottled) return;

    const marker = loadingMarker.value;
    const currentOffset = props.useScrollable ? container.value.getBoundingClientRect().bottom : window.innerHeight;

    if (marker && marker.getBoundingClientRect().top - currentOffset < offset.value) {
        scrollPosition = getScrollPosition();
        page.value++;
        emit('update:modelValue', page.value);

        // Add throttling to prevent multiple calls
        isThrottled = true;
        setTimeout(() => {
            isThrottled = false;
        }, throttleTime);
    }
};

watch(
    () => props.modelValue,
    (newValue) => {
        nextTick(() => {
            if (newValue === 1) {
                reset();
            }
            setScrollPosition(scrollPosition);
        });
    }
);

const reset = () => {
    page.value = 1;
    scrollPosition = 0;
};

defineExpose({ reset, checkMarkerPosition });

onMounted(() => {
    if (!props.useScrollable) {
        document.addEventListener('scroll', checkMarkerPosition);
        window.addEventListener('resize', checkMarkerPosition);
    }
});

onUnmounted(() => {
    if (!props.useScrollable) {
        document.removeEventListener('scroll', checkMarkerPosition);
        window.removeEventListener('resize', checkMarkerPosition);
    }
});
</script>
<style scoped>
.ui-infinite-scroll {
    width: 100%;
}

.ui-infinite-scroll .empty-data > * {
    font-size: 16px;
    display: flex;
    justify-content: center;
    padding-bottom: 10px;
    padding-top: 10px;
}
</style>
