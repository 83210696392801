import { ref, watch, nextTick, inject, onMounted, onBeforeUnmount } from 'vue';
import { debounce } from 'lodash';

import { usePreview } from '@video-composables/usePreview';
import { useLoading } from '@video-composables/useLoading';
import { useAssetMedia } from '@video-composables/useAssetMedia';

let currentExtraction = null;

export function useMediaPlayer(
    videoSource,
    mediaType,
    onDurationLoaded,
    onAudioDataLoaded,
    sequenceProps,
    dragHandle,
    sequenceTotalTime,
    sequenceId,
    elementId
) {
    const videoStudio = inject('$videoStudio');
    const { currentTimelineId } = usePreview();
    const { studioLoading } = useLoading();
    const { getAssetMedia } = useAssetMedia();

    const EPSILON = 0.001;
    const isUpdating = ref(false);
    const videoWidth = ref(0);
    const videoHeight = ref(0);
    const videoEndTime = ref(0);
    const hiddenVideoElement = ref(null);

    watch(studioLoading, (newValue) => {
        if (newValue) {
            isUpdating.value = false;
        }
    });

    watch(() => videoSource.value.src, handleSourceChange);

    async function waitForVideoLoad(assetVideo) {
        // If it's an image (img element)
        if (assetVideo instanceof HTMLImageElement) {
            return assetVideo;
        }

        // If it's a video object with stage getBackgroundElement
        const video = assetVideo.getVideoElement?.() || assetVideo;

        // If video is already loaded (readyState >= 2), return directly
        if (video.readyState >= 2) {
            return video;
        }

        // Otherwise, wait for canplay event
        return new Promise((resolve) => {
            const onCanPlay = () => {
                video.removeEventListener('canplay', onCanPlay);
                resolve(video);
            };
            video.addEventListener('canplay', onCanPlay);
        });
    }

    async function handleSourceChange(newSrc) {
        if (!newSrc) return;

        await nextTick();
        const assetMedia = getAssetMedia(mediaType.value, sequenceId, elementId);
        if (!assetMedia) {
            console.error('AssetMedia not found');
            return;
        }

        const video = await waitForVideoLoad(assetMedia);

        videoEndTime.value = video.duration;

        if (['audio', 'tts'].includes(mediaType.value)) {
            videoWidth.value = 1920;
            videoHeight.value = 100;
        } else {
            videoWidth.value = video.videoWidth;
            videoHeight.value = video.videoHeight;
        }

        onDurationLoaded(video.duration);
        onAudioDataLoaded(videoSource.value.src);
        sequenceProps.rate = await calculateMediaRate(video);
    }

    async function calculateMediaRate(media) {
        return 100;

        // // Si c'est un fichier audio, retourner directement 1
        // if (['audio', 'tts'].includes(mediaType.value)) {
        //     return 1;
        // }

        // const videoDuration = media.duration;
        // const frameCount = media.mozDecodedFrames || media.webkitDecodedFrames || media.decodedFrames;
        // const timeBetweenFrames = videoDuration / frameCount;

        // // Si les valeurs sont invalides ou si c'est un fichier audio, retourner 1
        // if (isNaN(timeBetweenFrames) || !isFinite(timeBetweenFrames)) {
        //     return 1;
        // }

        // return Math.max(1, Math.round(timeBetweenFrames / (1 / 30)));
    }

    function estimateFPS(video) {
        if (!video) return 0;

        const commonFrameRates = [24, 30, 60];
        return commonFrameRates.reduce((closestRate, currentRate) => {
            const currentDifference = Math.abs(videoEndTime.value / video.duration - 1 / currentRate);
            const closestDifference = Math.abs(videoEndTime.value / video.duration - 1 / closestRate);
            return currentDifference < closestDifference ? currentRate : closestRate;
        }, commonFrameRates[0]);
    }

    async function calculateBitrate(source, duration) {
        //simplification pour ne pas avoir à charger le fichier audio
        return 100;
    }

    function createHiddenVideoElement() {
        if (!hiddenVideoElement.value) {
            hiddenVideoElement.value = document.createElement('video');
            hiddenVideoElement.value.style.display = 'none';
            document.body.appendChild(hiddenVideoElement.value);
        }
    }

    onBeforeUnmount(() => {
        if (hiddenVideoElement.value) {
            hiddenVideoElement.value.remove();
        }
    });

    const extractFrames = async (canvasContainer, canvasWidth, canvasHeight) => {
        if (!canvasContainer) return;

        if (currentExtraction) {
            currentExtraction.cancel();
        }

        createHiddenVideoElement();

        const assetMedia = getAssetMedia(mediaType.value, sequenceId, elementId);
        if (!assetMedia) {
            console.error('AssetMedia not found');
            return;
        }

        hiddenVideoElement.value.src = videoSource.value.src;
        const video = await waitForVideoLoad(hiddenVideoElement.value);

        // Vérification adaptée pour inclure les fichiers audio
        if (!['audio', 'tts'].includes(mediaType.value) && (!video.videoWidth || !video.videoHeight)) {
            console.error('Invalid video properties:', {
                width: video.videoWidth,
                height: video.videoHeight,
                duration: video.duration
            });
            return;
        }

        if (!video.duration) {
            console.error('Invalid media duration');
            return;
        }

        // Vérifier si canvasContainer est un élément canvas ou un conteneur
        const isCanvasElement = canvasContainer.tagName === 'CANVAS';
        let mainContext = null;
        let canvasElements = [];

        if (isCanvasElement) {
            // Utilisation traditionnelle avec un seul canvas
            mainContext = canvasContainer.getContext('2d');
            if (!mainContext) return;

            canvasContainer.width = canvasWidth;
            canvasContainer.height = canvasHeight;
        } else {
            // Nettoyer le conteneur de canvas existant
            while (canvasContainer.firstChild) {
                canvasContainer.removeChild(canvasContainer.firstChild);
            }
        }

        const videoAspectRatio = video.videoWidth / video.videoHeight;
        const frameHeight = canvasHeight;
        const frameWidth = Math.ceil(frameHeight * videoAspectRatio);

        // Calculer le nombre de frames nécessaires pour remplir exactement la largeur du canvas
        const totalFrameCount = Math.max(2, Math.ceil(canvasWidth / frameWidth));
        // Calculer l'espacement exact entre les frames pour remplir précisément la largeur du canvas
        const spacing = canvasWidth / (totalFrameCount - 1);

        if (!isCanvasElement) {
            // Utiliser plusieurs canvas pour éviter les limitations de taille
            const MAX_CANVAS_WIDTH = 2000; // Taille maximale d'un canvas pour éviter les problèmes de performance
            const framesPerCanvas = Math.floor(MAX_CANVAS_WIDTH / frameWidth);
            const canvasCount = Math.ceil(totalFrameCount / framesPerCanvas);

            // Créer les canvas avec des dimensions exactes
            for (let i = 0; i < canvasCount; i++) {
                const canvas = document.createElement('canvas');
                const currentFrameCount =
                    i === canvasCount - 1 ? totalFrameCount - framesPerCanvas * i : framesPerCanvas;

                // Calculer la largeur exacte du canvas pour qu'il corresponde au nombre de frames
                const exactWidth =
                    i === canvasCount - 1
                        ? canvasWidth - i * framesPerCanvas * frameWidth // Dernière section: largeur restante exacte
                        : currentFrameCount * frameWidth;

                canvas.width = exactWidth;
                canvas.height = frameHeight;
                canvas.style.position = 'absolute';
                canvas.style.left = `${i * framesPerCanvas * frameWidth}px`;
                canvas.style.top = '0';

                canvasContainer.appendChild(canvas);
                canvasElements.push({
                    canvas,
                    context: canvas.getContext('2d'),
                    startFrameIndex: i * framesPerCanvas,
                    frameCount: currentFrameCount
                });
            }
        }

        const videoDuration = video.duration;
        const timeBetweenFrames = Math.max(0.1, videoDuration / (totalFrameCount - 1));

        if (!isFinite(timeBetweenFrames) || timeBetweenFrames <= 0) {
            return;
        }

        // Effacer tous les canvas
        if (isCanvasElement) {
            mainContext.clearRect(0, 0, canvasContainer.width, canvasContainer.height);
        } else {
            canvasElements.forEach(({ context, canvas }) => {
                context.clearRect(0, 0, canvas.width, canvas.height);
            });
        }

        let isCancelled = false;
        currentExtraction = {
            cancel: () => {
                isCancelled = true;
            }
        };

        function seekVideo(video, time) {
            return new Promise((resolve) => {
                const onSeeked = () => {
                    video.removeEventListener('seeked', onSeeked);
                    resolve();
                };
                video.addEventListener('seeked', onSeeked);
                const clampedTime = Math.max(0, Math.min(time, video.duration));
                video.currentTime = clampedTime;
            });
        }

        // Dessiner la première frame
        seekVideo(video, 0)
            .then(() => {
                if (isCancelled) return;

                if (isCanvasElement) {
                    // Dessiner la première frame partout dans le canvas unique
                    for (let i = 0; i < totalFrameCount; i++) {
                        drawFrameOnSingleCanvas(i, 0);
                    }
                } else {
                    // Dessiner la première frame sur tous les canvas multiples
                    canvasElements.forEach(({ context, frameCount, canvas }) => {
                        for (let i = 0; i < frameCount; i++) {
                            // Calculer la largeur exacte pour chaque frame
                            const currentWidth =
                                i === frameCount - 1 && canvas === canvasElements[canvasElements.length - 1].canvas
                                    ? canvas.width - i * frameWidth // Dernière frame du dernier canvas
                                    : frameWidth;

                            context.drawImage(video, i * frameWidth, 0, currentWidth, frameHeight);
                        }
                    });
                }

                // Dessiner la dernière frame
                return seekVideo(video, Math.min(videoEndTime.value, video.duration));
            })
            .then(() => {
                if (isCancelled) return;

                if (isCanvasElement) {
                    // Dessiner la dernière frame sur le canvas unique
                    drawFrameOnSingleCanvas(totalFrameCount - 1, videoEndTime.value);
                } else {
                    // Dessiner la dernière frame sur le dernier canvas
                    const lastCanvas = canvasElements[canvasElements.length - 1];
                    const lastFrameIndex = lastCanvas.frameCount - 1;

                    // Calculer la position exacte de la dernière frame
                    const xPosition = lastFrameIndex * frameWidth;

                    // Calculer la largeur exacte de la dernière frame pour qu'elle s'arrête précisément à la fin du canvas
                    const widthToDraw = lastCanvas.canvas.width - xPosition;

                    // Dessiner la frame avec la largeur exacte
                    if (widthToDraw > 0) {
                        lastCanvas.context.drawImage(video, xPosition, 0, widthToDraw, frameHeight);
                    }
                }

                // Commencer à traiter les frames
                processFrame(0);
            });

        function processFrame(globalFrameIndex) {
            if (isCancelled || globalFrameIndex >= totalFrameCount - 1) {
                currentExtraction = null;
                return;
            }

            const frameTime = globalFrameIndex * timeBetweenFrames;

            if (!isFinite(frameTime) || frameTime < 0 || frameTime > video.duration) {
                console.error('Skipping invalid frame time:', {
                    frameTime,
                    frameIndex: globalFrameIndex,
                    timeBetweenFrames,
                    duration: video.duration
                });
                processFrame(globalFrameIndex + 1);
                return;
            }

            seekVideo(video, frameTime).then(() => {
                if (isCancelled) return;

                if (isCanvasElement) {
                    // Dessiner sur le canvas unique
                    drawFrameOnSingleCanvas(globalFrameIndex, frameTime);
                } else {
                    // Trouver le canvas approprié pour cette frame
                    const canvasIndex = Math.floor(globalFrameIndex / canvasElements[0].frameCount);
                    const localFrameIndex = globalFrameIndex % canvasElements[0].frameCount;

                    if (canvasElements[canvasIndex]) {
                        const { context } = canvasElements[canvasIndex];
                        // Dessiner la frame
                        context.drawImage(video, localFrameIndex * frameWidth, 0, frameWidth, frameHeight);
                    }
                }

                // Utilise nextTick pour permettre au navigateur de mettre à jour l'UI
                nextTick(() => processFrame(globalFrameIndex + 1));
            });
        }

        function drawFrameOnSingleCanvas(frameIndex, time) {
            // Calculer la position du centre de la frame
            const centerX = frameIndex * spacing;

            // Calculer la position de départ pour dessiner la frame
            let xPosition = centerX - frameWidth / 2;
            let widthToDraw = frameWidth;

            // Ajuster la position pour les frames aux extrémités
            if (frameIndex === 0) {
                xPosition = 0;
            } else if (frameIndex === totalFrameCount - 1) {
                // Pour la dernière frame, s'assurer qu'elle s'arrête exactement à la fin du canvas
                xPosition = Math.min(canvasWidth - widthToDraw, centerX - frameWidth / 2);

                // Si la position est négative ou trop proche de la fin, ajuster
                if (xPosition < 0) {
                    xPosition = 0;
                    widthToDraw = canvasWidth;
                } else if (xPosition + widthToDraw > canvasWidth) {
                    widthToDraw = canvasWidth - xPosition;
                }
            }

            // S'assurer que la largeur à dessiner est positive et ne dépasse pas le canvas
            if (widthToDraw <= 0) {
                widthToDraw = 1; // Garantir une largeur minimale
            }

            // Dessiner la frame avec la largeur ajustée
            mainContext.drawImage(video, xPosition, 0, widthToDraw, frameHeight);
        }
    };

    const initializeTimeline = () => {
        nextTick(() => {
            if (currentTimelineId.value) {
                executeSeekWhenReady(sequenceProps.startTime + EPSILON);
            }
        });
    };

    function performSeekOperation(elapsedTime) {
        if (isUpdating.value) {
            return;
        }

        const debouncedSeek = debounce((time) => {
            if (!isUpdating.value) {
                executeSeek(time);
            }
        }, 50);

        debouncedSeek(elapsedTime);
    }

    function simpleSeek(elapsedTime) {
        if (isUpdating.value) {
            isUpdating.value = false;
        }

        if (elapsedTime <= 0) {
            elapsedTime = EPSILON;
        }

        const seekTime = elapsedTime + sequenceProps.startTime;

        executeSeekWhenReady(seekTime);
    }

    function executeSeek(elapsedTime) {
        const seekTime = calculateSeekTime(elapsedTime);

        //verify if the seek time is within the video duration
        if (seekTime > sequenceTotalTime.value) {
            simpleSeek(sequenceTotalTime.value - 1);
            return;
        }

        simpleSeek(seekTime);
    }

    function executeSeekWhenReady(seekTime) {
        // Forcer la réinitialisation du flag isUpdating si nécessaire
        if (isUpdating.value) {
            isUpdating.value = false;
        }

        const performSeek = () => {
            isUpdating.value = true;

            try {
                nextTick(() => {
                    videoStudio.value.studio.$stage.seekSequenceTimeline(currentTimelineId.value, seekTime);

                    // Reset le flag après un court délai pour permettre la stabilisation
                    setTimeout(() => {
                        isUpdating.value = false;
                    }, 50);
                });
            } catch (error) {
                isUpdating.value = false;
            }
        };

        if (!studioLoading.value) {
            performSeek();
        } else {
            const unwatch = watch(studioLoading, (newValue) => {
                if (!newValue) {
                    performSeek();
                    unwatch();
                }
            });
        }
    }

    function calculateSeekTime(elapsedTime) {
        if (dragHandle.value === 'start' && sequenceTotalTime.value > elapsedTime) {
            return elapsedTime + EPSILON;
        } else if (dragHandle.value === 'end' && elapsedTime > sequenceProps.startTime) {
            return elapsedTime - EPSILON;
        }

        return elapsedTime - EPSILON;
    }

    return {
        videoEndTime,
        videoWidth,
        videoHeight,
        extractFrames,
        performSeekOperation,
        initializeTimeline,
        simpleSeek
    };
}
