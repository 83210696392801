<!--
  -- Component name: Number1
  -- Type: Transition
  -- Uses: color.start
  -- Tags: number
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <defs>
            <mask :id="svgMaskID">
                <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
                <path
                    class="color-number"
                    d="M671.2,480.5c-6.7,0-12.7,0-18.7,0c-6.4,0-12.8-0.1-19.2,0c-3.1,0.1-4.5-1.1-4.3-4.2c0.1-1.6,0-3.2,0-4.8
                    c0-70.9,0-141.8,0-212.7c0-2,0-4.1,0-6.9c-8.6,2.2-16.4,4.2-24.2,6.2c-10.1,2.6-20.1,5.2-30.1,7.8c-5.9,1.5-6.2,1.3-6.2-5
                    c0-8.2,0.1-16.3-0.1-24.5c-0.1-3.2,1.2-4.6,4.1-5.4c21.6-5.6,43.1-12.1,64.9-16.7c10-2.1,20.7-0.6,31.1-0.6c0.9,0,2.1,1.6,2.5,2.7
                    c0.4,1.3,0.1,2.8,0.1,4.3c0,84.3,0,168.7,0,253C671.2,475.8,671.2,477.8,671.2,480.5z"
                />
            </mask>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" :fill="state.color.start" :mask="svgMask" />
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
        };
    },

    computed: {
        svgMaskID() {
            return this.containerID + '-number1-mask';
        },

        svgMask() {
            return 'url(#' + this.svgMaskID + ')';
        }
    },

    watch: {
        svgMaskRadius: {
            handler() {
                this.updateTimeline();
            }
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            this._timeline = t;
            t.fromTo(
                '#' + this.svgMaskID + ' .color-number',
                { scale: 1 },
                { scale: 1.5, transformOrigin: '50% 50%', duration: 1 },
                0
            );
            t.fromTo(
                '#' + this.svgMaskID + ' .color-number',
                { scale: 1.5 },
                { scale: 50, translateX: '-100%', transformOrigin: '50% 50%', duration: 0.2 },
                1
            );
            return t;
        }
    }
};
</script>
