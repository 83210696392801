import ClientDTO from '@/js/dto/ClientDTO.js';

export default class FolderDTO {
    constructor(data) {
        this.id = data.id;
        this.publicId = data.public_id;
        this.name = data.name;
        this.shortname = data.shortname;
        this.name = data.name;
        this.typeId = data.type_id;
        this.statusId = data.status_id;
        this.connectionId = data.connection_id;
        this.externalConnectionId = data.external_connection_id;
        this.clientId = data.client_id;
        this.version = data.version;
        this.itemAlias = data.item_alias;
        this.displayModeEnabled = data.display_mode_enabled;
        this.isTemplateContainer = data.is_template_container;
        this.isPattern = data.is_pattern;
        this.isArchive = data.is_archive;
        this.isHidden = data.is_hidden;
        this.patternMetadataId = data.pattern_metadata_id;
        this.createdAt = data.created_at;
        this.updatedAt = data.updated_at;
        this.deletedAt = data.deleted_at;
        this.logo = data.logo;
        this.nbVideos = data.nb_videos;
        this.pivot = data.pivot;
        this.dataConnection = data.data_connection;
        this.clientUsers = data.client_users;
        this.isAllowed = !!data.is_allowed;
        this.isFavourite = data.is_favourite;
        if (data.client) this.client = new ClientDTO(data.client);
    }
}
