<!--
  -- Component name: TranslateBTT
  -- Type: Transition
  -- Uses: 
  -- Tags: translation
  -->
<template></template>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            // isAbove: false, // The transition component is placed above the in and out elements
        };
    },

    computed: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            this._timeline = t;
            t.fromTo(
                [this.backgroundInElement, this.sequenceInElement],
                { yPercent: 100 },
                { duration: 1.3, yPercent: 0, ease: 'power1.inOut' },
                0
            );
            return t;
        }
    }
};
</script>
