import { ref, computed, onMounted } from 'vue';
import SequenceTemplateDataService from '@/js/application/services/sequenceTemplates/SequenceTemplateDataService.js';
import usePagination from '@/js/composables/usePagination';

export function useSequenceTemplate(store) {
    const sequenceTemplates = ref([]);
    const themes = ref([]);
    const isLoading = ref(false);
    const isFirstLoad = ref(true);
    let abortController = null;

    const sequenceTemplateDataService = new SequenceTemplateDataService(store);
    const { page, perPage, totalPages, setPerPage, setTotalPages } = usePagination();

    const fetchSequenceTemplates = async (brandId, options) => {
        if (brandId) {
            if (abortController) {
                abortController.abort();
            }

            isLoading.value = true;
            const isNewRequest = options.page === 1;

            // Only reset templates if it's a new search/filter request
            if (isNewRequest) {
                sequenceTemplates.value = [];
            }

            abortController = new AbortController();
            try {
                const response = await sequenceTemplateDataService.fetchAll(brandId, options);

                if (isNewRequest) {
                    sequenceTemplates.value = response.data.data;
                } else {
                    sequenceTemplates.value = sequenceTemplates.value.concat(response.data.data);
                }

                setPerPage(response.data.meta.per_page);
                setTotalPages(response.data.meta.last_page);

                // Only update the page ref if it's a new request
                if (isNewRequest) {
                    page.value = 1;
                }
            } catch (error) {
                if (error.name === 'AbortError') {
                    console.log('Fetch aborted');
                } else {
                    console.error('Error fetching sequence templates:', error);
                }
            } finally {
                isLoading.value = false;
            }
        }
    };

    const fetchSequenceTemplateThemes = async () => {
        isLoading.value = true;
        try {
            const response = await sequenceTemplateDataService.fetchThemes();
            themes.value = response;
            console.log('themes');
            console.log(themes.value);
        } catch (error) {
            console.log('error');
            console.log(error);
        }
        isLoading.value = false;
    };

    const resetDataForSequenceTemplates = () => {
        sequenceTemplates.value = [];
        page.value = 1;
        totalPages.value = 0;
        isFirstLoad.value = true;
    };

    onMounted(() => {
        fetchSequenceTemplateThemes();
        fetchSequenceTemplates();
    });

    return {
        sequenceTemplates,
        themes,
        isLoading,
        isFirstLoad,
        fetchSequenceTemplates,
        fetchSequenceTemplateThemes,
        resetDataForSequenceTemplates,
        page,
        perPage,
        totalPages
    };
}
