<template>
    <div>
        <div class="ui-switch-button" :class="containerClasses" v-tooltip="tooltipContent">
            <input
                type="checkbox"
                role="switch"
                :id="toggleId"
                :checked="modelValue"
                @change="handleChange"
                @click="handleClickFocus"
                :disabled="disabled"
            />
            <span class="ui-switch-icon switch-icon-off">
                <slot name="switch-icon-off"></slot>
            </span>
            <span class="ui-switch-icon switch-icon-on">
                <slot name="switch-icon-on"></slot>
            </span>
            <label class="ui-switch-toggle" :for="toggleId"></label>
            <label class="ui-switch-label" :class="labelClasses" :for="toggleId" :aria-hidden="!showLabel || null">
                <slot :label="label">{{ label }}</slot>
            </label>
            <span class="ui-switch-cgs-link" v-if="cgsId" @click="openCGS()">{{ $t('Associated terms of use') }}</span>
        </div>

        <TextError :errors="errors" />
    </div>
</template>

<script>
import _isString from 'lodash/isString';
import { UsesTooltip } from '../mixins';
import CGSDataService from '@/js/application/services/cgs/CGSDataService.js';
import TextError from '@/js/components/TextError.vue';

export const UI_SWITCH_BUTTON_CHANGE = 'update:modelValue';

export default {
    components: { TextError },
    mixins: [UsesTooltip],

    emits: [UI_SWITCH_BUTTON_CHANGE],

    props: {
        id: {
            type: String
        },
        modelValue: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ''
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        tooltip: {
            type: [Boolean, String],
            default: false
        },
        cgsId: {
            type: String,
            default: null
        },
        requireCgsApproval: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
            default: null
        }
    },

    data() {
        return {
            //
        };
    },

    computed: {
        containerClasses() {
            return {
                disabled: this.disabled
            };
        },

        toggleId() {
            return this.id + '-switch-toggle';
        },

        labelClasses() {
            return {
                'visually-hidden': !this.showLabel
            };
        },

        tooltipContent() {
            return !this.tooltip || _isString(this.tooltip) ? this.tooltip : this.label;
        }
    },

    watch: {
        //
    },

    methods: {
        handleChange(event) {
            this.$emit(UI_SWITCH_BUTTON_CHANGE, event.target.checked);
        },

        handleClickFocus(event) {
            if (this.$props.requireCgsApproval) {
                event.preventDefault();
                this.openCGS();
                return;
            }
            // Prevent focus to stay on the switch after a click,
            // but not when it comes from a keyboard action
            if (!!event.detail) {
                let target = event.target;
                this.$nextTick(() => target.blur());
            }
        },

        openCGS() {
            new CGSDataService(this.$store).fetchById(this.cgsId).then((cgs) => {
                this.$store.dispatch('modals/openModal', {
                    name: 'CGSModal',
                    data: {
                        cgs,
                        readOnly: !this.$props.requireCgsApproval,
                        callback: (accept) => {
                            this.$emit(UI_SWITCH_BUTTON_CHANGE, accept);
                        }
                    }
                });
            });
        }
    },

    mounted() {
        //
    }
};
</script>