<!--
  -- Component name: White
  -- Type: Transition
  -- Uses: 
  -- Tags: allianz
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container studio-transition-white"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1920 1080"
        preserveAspectRatio="xMidYMid slice"
    >
        <defs>
            <mask :id="svgMaskID">
                <rect class="intro-white-eagle-rect-mask" x="0" y="0" width="100%" height="100%" fill="#fff" />
                <g class="intro-white-eagle-logo-mask" :style="this.logoScaleStyle">
                    <circle
                        class="circle-logo-mask"
                        fill-opacity="0"
                        cx="50%"
                        cy="50.5%"
                        r="70"
                        stroke="#000"
                        stroke-width="11"
                    />
                    <path fill="#000" d="m940.08,513.14h-12.47c-6.17,0-8.58,2.28-8.58,8.61v63.02h21.05v-71.63Z" />
                    <path fill="#000" d="m992.35,513.14h-12.42v71.63h21.03v-63.02c0-6.33-2.44-8.61-8.61-8.61Z" />
                    <path
                        fill="#000"
                        d="m970.57,584.77v-80.92c0-6.45-2.29-8.62-8.55-8.62h-18.93v9.04h1.12c4.32,0,5.16.98,5.16,6.18v74.32h21.19Z"
                    />
                </g>
            </mask>
            <mask :id="svgMaskID2">
                <rect class="intro-white-eagle-rect-mask-2" x="0" y="0" width="100%" height="100%" fill="#fff" />
            </mask>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" fill="#fff" :mask="svgMask" />
        <g class="intro-white-eagle-logo" :style="this.logoScaleStyle" :mask="svgMask2">
            <circle
                class="circle-logo"
                fill-opacity="0"
                cx="50%"
                cy="50.5%"
                r="70"
                stroke="#003781"
                stroke-width="11"
            />
            <path
                class="eagle-path-logo"
                fill="#003781"
                d="m940.08,513.14h-12.47c-6.17,0-8.58,2.28-8.58,8.61v63.02h21.05v-71.63Z"
            />
            <path
                class="eagle-path-logo"
                fill="#003781"
                d="m992.35,513.14h-12.42v71.63h21.03v-63.02c0-6.33-2.44-8.61-8.61-8.61Z"
            />
            <path
                class="eagle-path-logo"
                fill="#003781"
                d="m970.57,584.77v-80.92c0-6.45-2.29-8.62-8.55-8.62h-18.93v9.04h1.12c4.32,0,5.16.98,5.16,6.18v74.32h21.19Z"
            />
        </g>
    </svg>
</template>

<style scoped></style>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true, // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            inElementsAutoAppear: false, // The in elements are automatically added to the timeline and appear from the start
            logoScale: 0,
            eagleScale: 0
        };
    },

    computed: {
        svgMaskID() {
            return this.containerID + '-intro-blue-eagle';
        },

        svgMask() {
            return 'url(#' + this.svgMaskID + ')';
        },

        svgMaskID2() {
            return this.containerID + '-intro-blue-eagle2';
        },

        svgMask2() {
            return 'url(#' + this.svgMaskID2 + ')';
        },

        logoScaleStyle() {
            return {
                transformOrigin: '50% 50%',
                transform: 'scale(' + this.logoScale + ')'
            };
        },

        eagleScaleStyle() {
            return {
                transformOrigin: '50% 50%',
                transform: 'scale(' + this.eagleScale + ')'
            };
        }
    },

    watch: {
        svgMaskRadius: {
            handler() {
                this.updateTimeline();
            }
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline(),
                logoMaxScale = 2.4,
                logoScaleFirstStep = 2,
                logoInitialScale = 0.9;

            if (this.format.width < this.format.height) {
                logoMaxScale = 1.4;
                logoScaleFirstStep = 1.2;
                logoInitialScale = 0.5;
            }

            t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 }, 1);
            t.set([this.backgroundInElement, this.sequenceInElement], { autoAlpha: 1 }, 1);

            // Make the mask background appear
            t.fromTo(
                '#' + this.containerID + ' .intro-white-eagle-rect-mask',
                { translateY: '100%' },
                { translateY: 0, duration: 1, ease: 'power2.out' },
                0
            );
            t.fromTo(
                '#' + this.containerID + ' .intro-white-eagle-rect-mask-2',
                { translateY: '100%' },
                { translateY: 0, duration: 1, ease: 'power2.out' },
                0
            );

            // Zoom in of the white opaque logo
            t.fromTo(
                this,
                { logoScale: logoInitialScale },
                { logoScale: logoScaleFirstStep, duration: 1, ease: 'power2.inOut' },
                0
            );

            // Make the full logo disappear to make the mask appear
            t.fromTo(
                '#' + this.containerID + ' .eagle-path-logo',
                { fillOpacity: 1 },
                { fillOpacity: 0, duration: 0.24 },
                1
            );
            t.fromTo(
                '#' + this.containerID + ' .circle-logo',
                { strokeOpacity: 1 },
                { strokeOpacity: 0, duration: 0.24 },
                1
            );

            t.fromTo(
                this,
                { logoScale: logoScaleFirstStep },
                { logoScale: logoMaxScale, duration: 1.4, ease: 'power0.out' },
                1
            );

            t.fromTo(
                '#' + this.containerID + ' .circle-logo-mask',
                { strokeWidth: 11 },
                { strokeWidth: 0, duration: 0.8, ease: 'power0.out' },
                1
            );

            // Make the mask background disappear
            t.fromTo(
                '#' + this.containerID + ' .intro-white-eagle-rect-mask',
                { translateY: 0 },
                { translateY: '-100%', duration: 1, ease: 'power0.out' },
                1.5
            );

            this._timeline = t;

            return t;
        }
    }
};
</script>
