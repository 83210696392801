<template>
    <ui-dropdown
        :id="seqId + '-music'"
        :caret="false"
        :menu="false"
        class="ui-dropdown-action-music"
        toggle-class="ui-dropdown-action"
        :toggle-style="musicEnabled ? { color: 'var(--edit-mode-color)' } : {}"
    >
        <template #dropdown-toggle>
            <fa-icon class="icon" icon="fa-regular fa-music" />
            <span>{{ $t('Music') }}</span>
        </template>

        <ui-range-input
            :id="cardElementId + '-main-audio-volume'"
            v-model="mainVolume"
            :horizontal="false"
            :label="$t('Music volume for this sequence')"
            :max="200"
            :disabled="readOnly"
        />
        <ui-number-input
            :id="cardElementId + '-main-audio-fade'"
            v-model="mainVolumeFade"
            :min="0"
            :label="$t('Music fade (in s.)')"
            :disabled="readOnly"
        />
    </ui-dropdown>
</template>

<script setup>
import { computed } from 'vue';

import UiDropdown from '@/js/components/UiDropdown.vue';
import UiNumberInput from '@/js/components/UiNumberInput.vue';
import UiRangeInput from '@/js/components/UiRangeInput.vue';

import { conversions } from '@/js/video-studio/utils/index.js';
import { Sound } from '@/js/video-studio/constants/index.js';
import { useSequence } from '@/js/videos/composables/useSequence.js';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import { useStore } from 'vuex';
import { useVideo } from '@/js/videos/composables/useVideo.js';

const props = defineProps({
    seqId: String,
    cardElementId: String
});

const store = useStore();
const { saveHistoryStep } = useHistory();
const { readOnly } = useVideo();
const { sequenceState, sequenceStoreModulePath } = useSequence(props.seqId);

const mainVolume = computed({
    get: () => {
        return Math.round(100 * conversions.optionValue(sequenceState.value.audio.volume, [], Sound.VOLUME_DEFAULT));
    },
    set: (value) => {
        saveHistoryStep(() => {
            store.commit(sequenceStoreModulePath.value + '/setMainVolume', {
                value: (value / 100).toFixed(2),
                custom: null
            });
        });
    }
});

const mainVolumeFade = computed({
    get: () => store.getters[sequenceStoreModulePath.value + '/mainVolumeFade'],
    set(value) {
        if (value !== mainVolumeFade.value) {
            saveHistoryStep(() => {
                store.commit(sequenceStoreModulePath.value + '/setMainVolumeFade', { value });
            });
        }
    }
});

const musicEnabled = computed(() => mainVolume.value !== 100 || mainVolumeFade.value !== Sound.FADE_DEFAULT);
</script>
