<!--
  -- Component name: MacifIntroImage
  -- Type: Transition
  -- Uses: 
  -- Tags: macif
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container studio-transition-macif-intro-image"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1920 1080"
        preserveAspectRatio="xMidYMid slice"
    >
        <defs>
            <mask :id="svgMaskRectID">
                <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
                <rect
                    class="macif-intro-image-rect-mask"
                    x="1124.4"
                    y="573.25"
                    width="187.6"
                    height="31.91"
                    :style="this.maskStyle"
                />
            </mask>
            <clipPath :id="clipPathText1ID">
                <rect width="412.64" height="62" x="928" y="471" stroke-width="0" />
            </clipPath>
            <clipPath :id="clipPathText2ID">
                <rect width="412.64" height="65" x="928" y="540" stroke-width="0" />
            </clipPath>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" fill="#fff" :mask="svgMaskRect" />
        <g class="macif-intro-image" :style="transitionStyle">
            <g class="macif-intro-image-logo">
                <path fill="#0a2d82" d="M878.56,423v234.6a521.6,521.6,0,0,1-270.2,0V423a521.31,521.31,0,0,1,270.2,0" />
                <path fill="#d3d62e" d="M771.54,501.31a27.12,27.12,0,1,1-27.11-27.14,27.13,27.13,0,0,1,27.11,27.14" />
                <path
                    fill="#d3d62e"
                    d="M804.85,501.31H788.22a3.69,3.69,0,0,0-3,2.72,41,41,0,0,1-40.86,38.27c-21.71,0-41-18.33-41-41a41,41,0,0,1,37.46-40.82h0a3.65,3.65,0,0,0,3.5-3.67V441.45a3.51,3.51,0,0,0-3.5-3.52h0a63.45,63.45,0,0,0-60,63.38c0,33.89,28.43,63.53,63.5,63.53s61.65-26.64,63.41-60.15a3.54,3.54,0,0,0-3-3.38"
                />
                <path
                    fill="#fff"
                    d="M677.63,620c0-2.6,0-5.82.08-8.68h-.08a54.86,54.86,0,0,1-5,5.2L666,623h-.57L659,616.55a53.81,53.81,0,0,1-5-5.2h-.16c.09,2.86.16,6,.16,8.68v16.2h-11.6V586.81H644l12.85,11.6c2,1.84,6.29,6,9.31,9.32h.17c2.86-3.12,7.39-7.48,9.41-9.32l12.44-11.6h1.5v49.42h-12Z"
                />
                <path
                    fill="#fff"
                    d="M713.16,620.33h11.15l-.57-1.16c-1.45-3-3.19-6.82-5-11.58h-.1c-1.76,4.79-3.53,8.57-5,11.58Zm15.61,9.44H708.63l-3.12,6.46H692.57l25.12-49.42h2.55l25.09,49.42H732Z"
                />
                <path
                    fill="#fff"
                    d="M787.15,631.34a24.62,24.62,0,0,1-16.8,6.32c-15.13,0-25-11.11-25-25.45,0-15.3,10.68-25.4,24.45-25.4A25.37,25.37,0,0,1,787,593.12l-6.65,8.46a14.18,14.18,0,0,0-9.91-4c-7.89,0-12.68,6.24-12.68,14.56s4.79,14.6,12.91,14.6a13.65,13.65,0,0,0,9.89-3.86Z"
                />
                <rect fill="#fff" x="793.04" y="588.26" width="12.01" height="47.99" />
                <polygon
                    fill="#fff"
                    points="827.13 599.08 827.13 610.35 845.43 610.35 845.43 620.5 827.13 620.5 827.13 636.25 815.13 636.25 815.13 588.26 850.56 588.26 848.2 599.08 827.13 599.08"
                />
            </g>
            <rect class="macif-intro-image-rect" x="1124.4" y="573.25" width="187.6" height="31.91" fill="#d3d62e" />
            <g :style="clipPathText1Style">
                <g class="macif-intro-image-text-1">
                    <path fill="#0a2d82" d="M946.11,474.93h9v40.82h25.33v7.6H946.11V474.93Z" />
                    <path
                        fill="#0a2d82"
                        d="M1013.67,489.9c2.88,2.59,4.31,6.53,4.31,11.79v21.66h-8.18v-4.5a9.93,9.93,0,0,1-4.52,3.69,17.59,17.59,0,0,1-7.1,1.29,17.24,17.24,0,0,1-7.27-1.43,11.43,11.43,0,0,1-4.81-3.93,10.1,10.1,0,0,1-1.7-5.72,9.79,9.79,0,0,1,3.7-8q3.72-3,11.65-3h9.55v-.54a7.57,7.57,0,0,0-2.31-6c-1.55-1.38-3.83-2.07-6.88-2.07a20,20,0,0,0-6.13,1,15.29,15.29,0,0,0-5.07,2.69l-3.39-6.29a21.16,21.16,0,0,1,7-3.38,30.8,30.8,0,0,1,8.65-1.17c5.48,0,9.62,1.29,12.51,3.89Zm-7.89,26.16a8.18,8.18,0,0,0,3.57-4.26v-4.29h-8.93c-5,0-7.49,1.64-7.49,4.9a4.44,4.44,0,0,0,1.86,3.75,8.53,8.53,0,0,0,5.2,1.38,11.23,11.23,0,0,0,5.79-1.48Z"
                    />
                    <path
                        fill="#0a2d82"
                        d="M1093.47,523.35l-.07-32.09-15.92,26.56h-4l-15.92-26.16v31.69H1049V474.93h7.41l19.23,32.1,18.9-32.1h7.41l.07,48.42Z"
                    />
                    <path
                        fill="#0a2d82"
                        d="M1140.84,489.9c2.88,2.59,4.31,6.53,4.31,11.79v21.66H1137v-4.5a9.9,9.9,0,0,1-4.53,3.69,17.53,17.53,0,0,1-7.1,1.29,17.27,17.27,0,0,1-7.27-1.43,11.43,11.43,0,0,1-4.81-3.93,10.09,10.09,0,0,1-1.69-5.72,9.81,9.81,0,0,1,3.69-8q3.72-3,11.65-3h9.56v-.54a7.57,7.57,0,0,0-2.31-6c-1.55-1.38-3.84-2.07-6.89-2.07a20,20,0,0,0-6.12,1,15.24,15.24,0,0,0-5.08,2.69l-3.38-6.29a21.11,21.11,0,0,1,7-3.38,30.85,30.85,0,0,1,8.65-1.17c5.46,0,9.63,1.29,12.51,3.89ZM1133,516.06a8.25,8.25,0,0,0,3.58-4.26v-4.29h-8.94q-7.47,0-7.48,4.9a4.44,4.44,0,0,0,1.86,3.75,8.51,8.51,0,0,0,5.19,1.38,11.34,11.34,0,0,0,5.79-1.48Z"
                    />
                    <path
                        fill="#0a2d82"
                        d="M1163.21,521.42a17.79,17.79,0,0,1-7.12-6.74,20,20,0,0,1,0-19.54,18,18,0,0,1,7.1-6.72,21.54,21.54,0,0,1,10.34-2.43,20,20,0,0,1,9.58,2.21,14.46,14.46,0,0,1,6.2,6.37l-6.65,3.88a10.58,10.58,0,0,0-4-3.84,11.62,11.62,0,0,0-13.25,1.86,12.77,12.77,0,0,0,0,16.83,11.66,11.66,0,0,0,13.27,1.86,10.74,10.74,0,0,0,4-3.84l6.65,3.88a15,15,0,0,1-6.26,6.41,19.48,19.48,0,0,1-9.51,2.24A21.63,21.63,0,0,1,1163.21,521.42Z"
                    />
                    <path
                        fill="#0a2d82"
                        d="M1197.19,478.84a5.09,5.09,0,0,1,0-7.39,6.09,6.09,0,0,1,7.89,0,4.57,4.57,0,0,1,1.6,3.57,5.26,5.26,0,0,1-1.55,3.84,5.39,5.39,0,0,1-4,1.55A5.83,5.83,0,0,1,1197.19,478.84Zm-.43,7.58h8.65v36.93h-8.65Z"
                    />
                    <path
                        fill="#0a2d82"
                        d="M1226.31,484.46v2.22h10.18v6.91h-9.89v29.76H1218V493.61h-6.1V486.7h6.1v-2.36c0-3.95,1.14-7.1,3.45-9.36s5.56-3.43,9.75-3.43a13.43,13.43,0,0,1,7.82,2l-2.44,6.51a8.15,8.15,0,0,0-4.76-1.53C1228.15,478.53,1226.31,480.51,1226.31,484.46Z"
                    />
                    <path
                        fill="#0a2d82"
                        d="M1248.45,513.94a5.61,5.61,0,0,1,1.6,4.15,9.19,9.19,0,0,1-.31,2.31,36.64,36.64,0,0,1-1.29,3.62l-3.53,9.75h-5.6l2.77-10.58a5,5,0,0,1-2.39-2,5.81,5.81,0,0,1-.85-3.15,5.52,5.52,0,0,1,1.59-4.14,5.4,5.4,0,0,1,4-1.6A5.58,5.58,0,0,1,1248.45,513.94Z"
                    />
                </g>
            </g>
            <g :style="clipPathText2Style">
                <g class="macif-intro-image-text-2">
                    <rect x="1124.4" y="573.25" width="187.6" height="31.91" fill="#d3d62e" fill-opacity="0" />
                    <path
                        fill="#0a2d82"
                        d="M955.81,589.79a17.84,17.84,0,0,1-7.13-6.75,20,20,0,0,1,0-19.54,18.05,18.05,0,0,1,7.11-6.72,21.48,21.48,0,0,1,10.34-2.43,19.87,19.87,0,0,1,9.58,2.22,14.41,14.41,0,0,1,6.19,6.36l-6.65,3.89a10.51,10.51,0,0,0-4-3.84,10.79,10.79,0,0,0-5.21-1.29,10.91,10.91,0,0,0-8,3.15,12.73,12.73,0,0,0,0,16.82,11.61,11.61,0,0,0,13.27,1.86,10.74,10.74,0,0,0,4-3.84l6.65,3.89a15.09,15.09,0,0,1-6.27,6.41,19.48,19.48,0,0,1-9.51,2.24A22.31,22.31,0,0,1,955.81,589.79Z"
                    />
                    <path
                        fill="#0a2d82"
                        d="M997.94,541.49a5.51,5.51,0,0,1,1.59,4.07,9.41,9.41,0,0,1-.3,2.43c-.22.79-.62,2-1.22,3.53l-3.6,9.82h-5.6l2.77-10.66a4.51,4.51,0,0,1-2.39-1.9,5.93,5.93,0,0,1-.85-3.22,5.66,5.66,0,0,1,9.6-4.07Z"
                    />
                    <path
                        fill="#0a2d82"
                        d="M1040.83,576h-29a10,10,0,0,0,4,6.46,13,13,0,0,0,8,2.38,13.47,13.47,0,0,0,10-4l4.65,5.34a15.8,15.8,0,0,1-6.29,4.5,24.84,24.84,0,0,1-19.3-.9,17.44,17.44,0,0,1-7.17-6.75,20.32,20.32,0,0,1-.07-19.51,17.4,17.4,0,0,1,6.81-6.75,19.81,19.81,0,0,1,9.82-2.43,19.19,19.19,0,0,1,9.65,2.39,17,17,0,0,1,6.65,6.72,20.37,20.37,0,0,1,2.38,10A18.4,18.4,0,0,1,1040.83,576Zm-25.57-12.29a10.46,10.46,0,0,0-3.5,6.53h21a10.12,10.12,0,0,0-10.44-9A10.71,10.71,0,0,0,1015.26,563.72Z"
                    />
                    <path
                        fill="#0a2d82"
                        d="M1051.91,591a21.75,21.75,0,0,1-6.84-3l3.31-6.58a21.68,21.68,0,0,0,6,2.67,24.91,24.91,0,0,0,6.89,1q7.76,0,7.75-4.07a2.74,2.74,0,0,0-2-2.7,33.75,33.75,0,0,0-6.34-1.45,49.47,49.47,0,0,1-7.44-1.6,11.4,11.4,0,0,1-5-3.14,8.83,8.83,0,0,1-2.12-6.27,9.85,9.85,0,0,1,4.39-8.41q4.4-3.15,11.86-3.15a34.62,34.62,0,0,1,7.61.86,21.67,21.67,0,0,1,6.22,2.31l-3.32,6.58a20.54,20.54,0,0,0-10.58-2.76,11.71,11.71,0,0,0-5.76,1.14,3.41,3.41,0,0,0-2,3,3,3,0,0,0,2.12,2.93,33.35,33.35,0,0,0,6.53,1.62,50.83,50.83,0,0,1,7.27,1.6,11.36,11.36,0,0,1,4.88,3,8.49,8.49,0,0,1,2,6.1,9.62,9.62,0,0,1-4.5,8.29c-3,2.07-7.08,3.12-12.25,3.12a33.21,33.21,0,0,1-8.77-1.16Z"
                    />
                    <path
                        fill="#0a2d82"
                        d="M1107.12,589.72a9.94,9.94,0,0,1-3.69,1.85,16.87,16.87,0,0,1-4.6.62c-4,0-7.2-1.07-9.41-3.19s-3.32-5.22-3.32-9.27V562H1080V555h6.1v-8.43h8.65V555h9.89V562h-9.89v17.59a5.86,5.86,0,0,0,1.31,4.12,4.93,4.93,0,0,0,3.82,1.43,7.52,7.52,0,0,0,4.83-1.52l2.41,6.15Z"
                    />
                    <path fill="#0a2d82" d="M1167.93,554.76l-15.84,36.93h-8.94l-15.85-36.93h9l11.48,27.4,11.85-27.4Z" />
                    <path
                        fill="#0a2d82"
                        d="M1178.8,589.79a17.71,17.71,0,0,1-7-6.75,19,19,0,0,1-2.53-9.79,18.77,18.77,0,0,1,2.53-9.75,17.84,17.84,0,0,1,7-6.72,20.89,20.89,0,0,1,10.1-2.43,21.24,21.24,0,0,1,10.18,2.43,17.9,17.9,0,0,1,7,6.72,18.84,18.84,0,0,1,2.52,9.75,19,19,0,0,1-2.52,9.79,17.77,17.77,0,0,1-7,6.75,22.37,22.37,0,0,1-20.28,0Zm18-8.18a12.76,12.76,0,0,0,0-16.72,11.29,11.29,0,0,0-15.75,0,12.89,12.89,0,0,0,0,16.72,11.29,11.29,0,0,0,15.75,0Z"
                    />
                    <path
                        fill="#0a2d82"
                        d="M1252.69,554.76v36.93h-8.22V587a13.62,13.62,0,0,1-5.2,3.83,16.5,16.5,0,0,1-6.71,1.36q-7.41,0-11.66-4.12c-2.83-2.74-4.26-6.81-4.26-12.2V554.76h8.65v19.92c0,3.31.74,5.79,2.24,7.43a8.19,8.19,0,0,0,6.41,2.46,9.7,9.7,0,0,0,7.36-2.86c1.81-1.91,2.74-4.67,2.74-8.27V554.76Z"
                    />
                    <path
                        fill="#0a2d82"
                        d="M1266.65,591a21.69,21.69,0,0,1-6.83-3l3.31-6.58a21.54,21.54,0,0,0,6,2.67,24.8,24.8,0,0,0,6.89,1c5.17,0,7.74-1.36,7.74-4.07a2.74,2.74,0,0,0-2-2.7,33.75,33.75,0,0,0-6.34-1.45,49,49,0,0,1-7.43-1.6,11.28,11.28,0,0,1-5-3.14,8.78,8.78,0,0,1-2.12-6.27,9.84,9.84,0,0,1,4.38-8.41q4.39-3.15,11.87-3.15a34.6,34.6,0,0,1,7.6.86,21.56,21.56,0,0,1,6.22,2.31l-3.31,6.58a20.57,20.57,0,0,0-10.58-2.76,11.72,11.72,0,0,0-5.77,1.14,3.41,3.41,0,0,0-2,3,3,3,0,0,0,2.12,2.93A33.54,33.54,0,0,0,1278,570a50.52,50.52,0,0,1,7.27,1.6,11.39,11.39,0,0,1,4.89,3,8.53,8.53,0,0,1,2,6.1,9.62,9.62,0,0,1-4.5,8.29c-3,2.07-7.08,3.12-12.25,3.12a33.21,33.21,0,0,1-8.77-1.16Z"
                    />
                    <path
                        fill="#0a2d82"
                        d="M1298.42,590.57a5.52,5.52,0,0,1-1.67-4.12,5.64,5.64,0,0,1,1.62-4.15,6,6,0,0,1,8.1,0,5.65,5.65,0,0,1,1.62,4.15,5.69,5.69,0,0,1-9.67,4.12Z"
                    />
                </g>
            </g>
        </g>
    </svg>
</template>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true, // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
            transitionScale: 1,
            transitionTranslateX: '11.25%'
        };
    },

    computed: {
        svgMaskRectID() {
            return this.containerID + '-macif-intro-image-mask';
        },

        clipPathText1ID() {
            return this.containerID + '-macif-intro-image-clipath-text-1';
        },

        clipPathText2ID() {
            return this.containerID + '-macif-intro-image-clipath-text-2';
        },

        svgMaskRect() {
            return 'url(#' + this.svgMaskRectID + ')';
        },

        clipPathText1Style() {
            return {
                clipPath: 'url(#' + this.clipPathText1ID + ')'
            };
        },

        clipPathText2Style() {
            return {
                clipPath: 'url(#' + this.clipPathText2ID + ')'
            };
        },

        transitionStyle() {
            return {
                transformOrigin: '50% 50%',
                transform: 'scale(' + this.transitionScale + ') translateX(' + this.transitionTranslateX + ')'
            };
        },

        maskStyle() {
            return {
                transformOrigin: '50% 50%',
                transform: 'scale(' + this.transitionScale + ')'
            };
        },

        isPortraitFormat() {
            return this.format.width < this.format.height;
        }
    },

    watch: {
        svgMaskRadius: {
            handler() {
                this.updateTimeline();
            }
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            this._timeline = t;

            if (this.isPortraitFormat) {
                t.set(this, { transitionScale: 0.7, transitionTranslateX: '11.25%' }, 0);
            } else {
                t.set(this, { transitionScale: 1, transitionTranslateX: '11.25%' }, 0);
            }

            // Initialize to 0 the width of the green rectangle that appears later
            // macif-intro-image-rect-mask is used for the transparency effect on the current sequence that is applied at the end of the transition
            t.set(
                '#' + this.containerID + ' .macif-intro-image-rect',
                { attr: { width: '0%', height: 31.91, x: 1124.4, y: 573.25 } },
                0
            );
            t.set(
                '#' + this.containerID + ' .macif-intro-image-rect-mask',
                { attr: { width: '0%', height: 31.91, x: 1124.4, y: 573.25 }, opacity: 0 },
                0
            );
            t.set('#' + this.containerID + ' .macif-intro-image-text-1', { translateY: '100%' }, 0);
            t.set('#' + this.containerID + ' .macif-intro-image-text-2', { translateY: '100%' }, 0);
            t.set('#' + this.containerID + ' .macif-intro-image-logo', { opacity: 0 }, 0);

            // Translate the logo to the left to center the whole svg
            t.fromTo(
                '#' + this.containerID + ' .macif-intro-image-logo',
                { opacity: 0 },
                { opacity: 1, duration: 0.64 },
                0.08
            );
            t.fromTo(
                this,
                { transitionTranslateX: '11.25%' },
                { transitionTranslateX: '0%', duration: 1.6, ease: 'power2.inOut' },
                0.76
            );

            // Translate the text up to make it appear
            t.fromTo(
                '#' + this.containerID + ' .macif-intro-image-text-1',
                { translateY: '100%' },
                { translateY: 0, duration: 0.48 },
                1.28
            );
            t.fromTo(
                '#' + this.containerID + ' .macif-intro-image-text-2',
                { translateY: '100%' },
                { translateY: 0, duration: 0.64 },
                1.48
            );

            // Make the green rectangle appear
            t.fromTo(
                '#' + this.containerID + ' .macif-intro-image-rect-mask',
                { attr: { width: '0%', height: 31.91, x: 1124.4, y: 573.25 } },
                { attr: { width: 187.6, height: 31.91, x: 1124.4, y: 573.25 }, duration: 0.96, ease: 'power2.inOut' },
                1.8
            );
            t.fromTo(
                '#' + this.containerID + ' .macif-intro-image-rect',
                { attr: { width: '0%', height: 31.91, x: 1124.4, y: 573.25 } },
                { attr: { width: 187.6, height: 31.91, x: 1124.4, y: 573.25 }, duration: 0.96, ease: 'power2.inOut' },
                1.8
            );

            // Translate the text up to make it disappear
            t.fromTo(
                '#' + this.containerID + ' .macif-intro-image-text-1',
                { translateY: 0 },
                { translateY: '-100%', duration: 0.68 },
                3.08
            );
            t.fromTo(
                '#' + this.containerID + ' .macif-intro-image-text-2',
                { translateY: 0 },
                { translateY: '-100%', duration: 0.68 },
                3.28
            );

            // Change the opacity of the text
            t.fromTo(
                '#' + this.containerID + ' .macif-intro-image-text-1',
                { opacity: 1 },
                { opacity: 0, duration: 0.33, ease: 'power2.in' },
                3.43
            );
            t.fromTo(
                '#' + this.containerID + ' .macif-intro-image-text-2',
                { opacity: 1 },
                { opacity: 0, duration: 0.25, ease: 'power2.in' },
                3.71
            );

            // Enlarge the green rectangle until it takes up the whole screen
            t.fromTo(
                '#' + this.containerID + ' .macif-intro-image-rect',
                { attr: { width: 187.6, height: 31.91, x: 1124.4, y: 573.25 } },
                { attr: { width: 1920, height: 1080, x: 0, y: 0 }, duration: 1.36, ease: 'power2.inOut' },
                3.24
            );
            t.fromTo(
                '#' + this.containerID + ' .macif-intro-image-rect-mask',
                { attr: { width: 187.6, height: 31.91, x: 1124.4, y: 573.25 } },
                { attr: { width: 1920, height: 1080, x: 0, y: 0 }, duration: 1.36, ease: 'power2.inOut' },
                3.24
            );
            t.to(this, { transitionScale: 1, ease: 'power2.inOut' }, 3.84);

            // Change the opacity of the green rectangle to show the current sequence
            t.fromTo(
                '#' + this.containerID + ' .macif-intro-image-rect',
                { opacity: 1 },
                { opacity: 0, duration: 1, ease: 'power1.in' },
                3.88
            );
            t.set('#' + this.containerID + ' .macif-intro-image-rect-mask', { opacity: 1 }, 3.88);
            t.set('#' + this.containerID + ' .macif-intro-image-logo', { visibility: 'hidden' }, 3.88);

            // Zoom In on the background / new sequence
            if (this.previous)
                t.fromTo(
                    [
                        this.backgroundInElement,
                        this.backgroundOutElement,
                        this.sequenceInElement,
                        this.sequenceOutElement
                    ],
                    { scale: 0.9 },
                    { scale: 1, duration: 0.6, ease: 'power1.easeOut', immediateRender: false },
                    3.88
                );
            return t;
        }
    }
};
</script>
