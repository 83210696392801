import { ref, watch } from 'vue';

export function useVideoSegments(propsSegments, sequenceProps, displayLimitDuration, onSegmentsMerge) {
    const safeSequenceProps = sequenceProps || { sequenceEndTime: 0 };

    const segments = ref(propsSegments);
    const isDragging = ref(false);
    const isUpdating = ref(false);

    const validateSegmentsAgainstMaxDuration = (maxDuration) => {
        if (!maxDuration || !segments.value || segments.value.length === 0) return;

        const totalDuration = segments.value.reduce((acc, segment) => {
            return acc + (segment.end - segment.start);
        }, 0);

        if (totalDuration > maxDuration) {
            if (segments.value.length === 1) {
                const segment = segments.value[0];
                const originalEnd = segment.end;
                segment.end = Math.min(segment.start + maxDuration, originalEnd);
            } else if (segments.value.length > 1) {
                const scaleFactor = maxDuration / totalDuration;
                let remainingDuration = maxDuration;

                for (let i = 0; i < segments.value.length - 1; i++) {
                    const segment = segments.value[i];
                    const originalDuration = segment.end - segment.start;
                    const adjustedDuration = Math.min(originalDuration * scaleFactor, remainingDuration);

                    segment.end = segment.start + adjustedDuration;
                    remainingDuration -= adjustedDuration;
                }

                if (remainingDuration > 0) {
                    const lastSegment = segments.value[segments.value.length - 1];
                    lastSegment.end = lastSegment.start + remainingDuration;
                }
            }

            if (typeof displayLimitDuration === 'function') {
                displayLimitDuration();
            }
        }
    };

    watch(
        () => sequenceProps?.maxSegmentDuration,
        (newValue, oldValue) => {
            if (newValue !== null && segments.value && segments.value.length > 0) {
                validateSegmentsAgainstMaxDuration(newValue);
            }
        },
        { immediate: true }
    );

    const removeSegment = (index) => {
        if (segments.value.length === 1) {
            resetSegments();
            return;
        }
        segments.value.splice(index, 1);
    };

    const resetSegments = () => {
        segments.value = [
            {
                start: 0,
                end: safeSequenceProps.endTime
            }
        ];
    };

    const updateSegmentEnd = (duration) => {
        try {
            if (segments.value[0].end == 0) segments.value[0].end = duration;
        } catch (error) {
            segments.value = [{ start: 0, end: duration }];
        }
    };

    const splitVideo = (currentTime) => {
        const newSegments = [];

        for (const segment of segments.value) {
            if (currentTime >= segment.start && currentTime <= segment.end) {
                if (currentTime > segment.start) {
                    newSegments.push({ start: segment.start, end: currentTime });
                }
                if (currentTime < segment.end) {
                    newSegments.push({ start: currentTime, end: segment.end });
                }
            } else {
                newSegments.push(segment);
            }
        }

        segments.value = newSegments;

        if (safeSequenceProps.maxSegmentDuration) {
            validateSegmentsAgainstMaxDuration(safeSequenceProps.maxSegmentDuration);
        }
    };

    const mergeSegments = () => {
        segments.value.sort((a, b) => a.start - b.start);
        let didMerge = false;

        for (let i = 0; i < segments.value.length - 1; ) {
            const current = segments.value[i];
            const next = segments.value[i + 1];

            if (current.end > next.start) {
                current.end = next.end;
                segments.value.splice(i + 1, 1);
                isDragging.value = false;
                didMerge = true;
            } else {
                i++;
            }
        }

        if (didMerge && onSegmentsMerge) {
            onSegmentsMerge();
        }
    };

    const mergeAdjacentSegments = (currentIndex) => {
        if (currentIndex < segments.value.length - 1) {
            const currentSegment = segments.value[currentIndex];
            const nextSegment = segments.value[currentIndex + 1];
            if (currentSegment.end > nextSegment.start) {
                currentSegment.end = nextSegment.end;
                segments.value.splice(currentIndex + 1, 1);
                isDragging.value = false;

                if (onSegmentsMerge) {
                    onSegmentsMerge();
                }
            }
        }
    };

    const updateSegmentPosition = async (newTime, minSegmentSizeTime, currentSegment, isStartHandle) => {
        if (isUpdating.value) return;

        // Limiter le temps à l'intervalle valide [0, sequenceProps.endTime]
        newTime = Math.max(0, Math.min(newTime, safeSequenceProps.endTime));

        // Check if sequence is in fixed duration mode (not auto mode)
        const isFixedDurationMode = safeSequenceProps.sequenceEndTime !== null && safeSequenceProps.sequenceEndTime > 0;

        const hasMaxDurationLimit =
            safeSequenceProps.maxSegmentDuration !== null && safeSequenceProps.maxSegmentDuration !== undefined;

        if (isFixedDurationMode || hasMaxDurationLimit) {
            const sumOfSegments = segments.value.reduce((acc, segment) => acc + (segment.end - segment.start), 0);
            const currentSegmentDuration = currentSegment.end - currentSegment.start;
            const newSegmentSize = isStartHandle
                ? currentSegmentDuration - (newTime - currentSegment.start)
                : currentSegmentDuration - (currentSegment.end - newTime);
            const newSumOfSegments = sumOfSegments - currentSegmentDuration + newSegmentSize;

            const mediaDurationLimit = safeSequenceProps.endTime;

            const isElementLimitMode = safeSequenceProps.limitType === 'element';

            let animationConstraint = Infinity;
            if (isElementLimitMode && safeSequenceProps.elementAnimationEnd > 0) {
                const animStart = safeSequenceProps.startTime || 0;
                const animEnd = safeSequenceProps.elementAnimationEnd || Infinity;
                animationConstraint = animEnd - animStart;
            }

            const explicitMaxDuration = hasMaxDurationLimit ? safeSequenceProps.maxSegmentDuration : Infinity;

            const availableTimeWithOffset = safeSequenceProps.sequenceEndTime - safeSequenceProps.startTime;

            const adjustedLimit =
                Math.min(
                    isElementLimitMode
                        ? Math.min(mediaDurationLimit, animationConstraint)
                        : Math.min(
                              mediaDurationLimit,
                              availableTimeWithOffset > 0 ? availableTimeWithOffset : Infinity
                          ),
                    explicitMaxDuration
                ) * safeSequenceProps.playbackRate;

            const SNAP_THRESHOLD = 0.02;
            const DRAG_TOLERANCE = 0.5;

            console.log('Checking limits during drag:', {
                newSumOfSegments,
                adjustedLimit,
                difference: newSumOfSegments - adjustedLimit,
                shouldDisplayLimit: newSumOfSegments > adjustedLimit + DRAG_TOLERANCE
            });

            if (Math.abs(newSumOfSegments - adjustedLimit) <= SNAP_THRESHOLD) {
                // Snap to the exact limit for better UX
                if (isStartHandle) {
                    const otherSegmentsDuration = sumOfSegments - currentSegmentDuration;
                    const requiredDuration = adjustedLimit - otherSegmentsDuration;
                    newTime = currentSegment.end - requiredDuration;
                } else {
                    const otherSegmentsDuration = sumOfSegments - currentSegmentDuration;
                    const requiredDuration = adjustedLimit - otherSegmentsDuration;
                    newTime = currentSegment.start + requiredDuration;
                }
            } else if (newSumOfSegments > adjustedLimit + DRAG_TOLERANCE) {
                try {
                    // Appel direct à displayLimitDuration
                    if (typeof displayLimitDuration === 'function') {
                        displayLimitDuration();
                    } else {
                        console.error('displayLimitDuration is not a function or is undefined:', displayLimitDuration);
                    }
                } catch (error) {
                    console.error('Error calling displayLimitDuration during drag:', error);
                }
                return;
            }
        }

        try {
            isUpdating.value = true;

            if (isStartHandle) {
                const newStart = Math.min(newTime, currentSegment.end - minSegmentSizeTime);

                if (currentSegment.end - newStart >= minSegmentSizeTime) {
                    currentSegment.start = newStart;
                }
            } else {
                const newEnd = Math.max(newTime, currentSegment.start + minSegmentSizeTime);

                if (newEnd - currentSegment.start >= minSegmentSizeTime) {
                    currentSegment.end = newEnd;
                }
            }

            const isFixedDurationMode =
                safeSequenceProps.sequenceEndTime !== null && safeSequenceProps.sequenceEndTime > 0;
            const hasMaxDurationLimit =
                safeSequenceProps.maxSegmentDuration !== null && safeSequenceProps.maxSegmentDuration !== undefined;

            if (isFixedDurationMode || hasMaxDurationLimit) {
                const sumOfSegments = segments.value.reduce((acc, segment) => acc + (segment.end - segment.start), 0);

                const isElementLimitMode = safeSequenceProps.limitType === 'element';

                let animationConstraint = Infinity;
                if (isElementLimitMode && safeSequenceProps.elementAnimationEnd > 0) {
                    const animStart = safeSequenceProps.startTime || 0;
                    const animEnd = safeSequenceProps.elementAnimationEnd || Infinity;
                    animationConstraint = animEnd - animStart;
                }

                const explicitMaxDuration = hasMaxDurationLimit ? safeSequenceProps.maxSegmentDuration : Infinity;

                const availableTimeWithOffset = safeSequenceProps.sequenceEndTime - safeSequenceProps.startTime;

                const adjustedLimit =
                    Math.min(
                        isElementLimitMode
                            ? Math.min(safeSequenceProps.endTime, animationConstraint)
                            : Math.min(
                                  safeSequenceProps.endTime,
                                  availableTimeWithOffset > 0 ? availableTimeWithOffset : Infinity
                              ),
                        explicitMaxDuration
                    ) * safeSequenceProps.playbackRate;

                if (sumOfSegments > adjustedLimit) {
                    try {
                        if (typeof displayLimitDuration === 'function') {
                            displayLimitDuration();
                        }
                    } catch (error) {
                        console.error('Error calling displayLimitDuration after update:', error);
                    }
                }
            }
        } finally {
            isUpdating.value = false;
        }
    };

    function findCurrentSegmentIndex(time) {
        return segments.value.findIndex((segment) => time >= segment.start && time <= segment.end);
    }

    const startDrag = () => {
        isDragging.value = true;
    };

    const stopDrag = () => {
        isDragging.value = false;
        // Vérifier si les segments respectent les limites à la fin du drag
        if (safeSequenceProps.maxSegmentDuration) {
            validateSegmentsAgainstMaxDuration(safeSequenceProps.maxSegmentDuration);
        }
    };

    const checkSegmentLimits = () => {
        if (!segments.value || segments.value.length === 0) return;

        const maxDuration = safeSequenceProps.maxSegmentDuration;

        if (!maxDuration) return;

        const totalDuration = segments.value.reduce((acc, segment) => {
            return acc + (segment.end - segment.start);
        }, 0);

        if (totalDuration > maxDuration) {
            console.warn('Segment duration exceeds limit:', { totalDuration, maxDuration });
            if (typeof displayLimitDuration === 'function') {
                displayLimitDuration();
            }
        }
    };

    return {
        segments,
        updateSegmentEnd,
        splitVideo,
        removeSegment,
        mergeSegments,
        mergeAdjacentSegments,
        resetSegments,
        updateSegmentPosition,
        findCurrentSegmentIndex,
        isDragging,
        startDrag,
        stopDrag,
        validateSegmentsAgainstMaxDuration,
        checkSegmentLimits
    };
}
