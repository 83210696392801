<template>
    <ui-nav-item
        :route="{ name: 'ShowFolder', params: { shortname: folder.shortname } }"
        :label="folder.name"
        :right-label-data="folder.nbVideos ? folder.nbVideos.toString() : ''"
        :folder="folder"
        icon="fa-folder"
    />
</template>

<script setup>
import UiNavItem from '@/js/components/UiNavItem.vue';
import FolderDTO from '@/js/dto/FolderDTO.js';
import { onMounted } from 'vue';
import FoldersDataService from '@/js/application/services/folders/FoldersDataService.js';
import { useStore } from 'vuex';

const props = defineProps({
    folder: {
        type: FolderDTO,
        required: true
    }
});

const store = useStore();

onMounted(() => {
    new FoldersDataService(store).fetchEditionByShortname(props.folder.publicId).then((data) => {
        props.folder.nbVideos = data.videos_total;
    });
});
</script>
