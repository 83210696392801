import { debounce } from 'lodash';
import { ref } from 'vue';
import { calculateRelativeTime } from '../helpers/timeCalculations';

export function useDragInteractions({
    segments,
    isDragging,
    updateSegmentPosition,
    mergeAdjacentSegments,
    mergeSegments,
    updateCurrentTimeAndPos,
    startDrag,
    stopDrag,
    sliderWrapper,
    sliderContainer,
    sequenceProps,
    updateSegmentsInStore,
    emit,
    playing,
    toggleTimeline,
    onHandleStopDrag,
    handleEdgeScroll,
    stopEdgeScroll
}) {
    const isSliderDragging = ref(false);
    const startX = ref(0);
    const scrollLeft = ref(0);
    const dragHandle = ref(null);

    const dragSegmentIndex = ref(-1);
    const dragStartTime = ref(0);
    const dragStartPosition = ref(0);
    const dragSegmentIsDragging = ref(false);
    const isHandleDisabled = ref(false);
    const originalSegmentEnd = ref(0);
    const lastMouseX = ref(0);
    const edgeScrollAnimationId = ref(null);
    const isDraggingActive = ref(false);

    const debouncedUpdateCurrentTimeAndPos = debounce(updateCurrentTimeAndPos, 300);

    // handle global mouse up
    const handleGlobalMouseUp = (event) => {
        if (isDraggingActive.value) {
            if (dragSegmentIsDragging.value) {
                stopSegmentDrag();
            } else if (isDragging.value) {
                handleStopDrag();
            } else if (isSliderDragging.value) {
                stopSliderDrag();
            }
            isDraggingActive.value = false;
        }
    };

    if (typeof window !== 'undefined') {
        window.addEventListener('mouseup', handleGlobalMouseUp);
    }

    const startSegmentDrag = (event, index) => {
        startDrag();
        isDraggingActive.value = true;
        dragSegmentIndex.value = index;
        dragStartTime.value = segments.value[index].start;
        dragStartPosition.value = event.clientX;
        lastMouseX.value = event.clientX;
        dragSegmentIsDragging.value = true;
        document.addEventListener('mousemove', handleSegmentDrag);
    };

    const updateSegmentDuringScroll = () => {
        if (!isDragging.value || dragSegmentIndex.value === -1 || !sliderContainer.value) return;

        const scrollInfo = handleEdgeScroll(lastMouseX.value);

        if (scrollInfo.isScrolling && scrollInfo.scrollDirection !== 0) {
            const { width } = sliderContainer.value.getBoundingClientRect();

            const pixelOffset = scrollInfo.scrollDirection * scrollInfo.scrollFactor * 5; // Augmenter la vitesse pour une meilleure réactivité

            lastMouseX.value += pixelOffset;

            const timeOffset = (pixelOffset / width) * sequenceProps.endTime;

            const segment = segments.value[dragSegmentIndex.value];
            const segmentDuration = segment.end - segment.start;
            let newStart = segment.start + timeOffset;

            newStart = Math.max(0, Math.min(newStart, sequenceProps.endTime - segmentDuration));

            segment.start = newStart;
            segment.end = newStart + segmentDuration;

            mergeSegments();
            mergeAdjacentSegments(dragSegmentIndex.value);

            edgeScrollAnimationId.value = requestAnimationFrame(updateSegmentDuringScroll);
        }
    };

    const handleSegmentDrag = (event) => {
        if (!isDragging.value || dragSegmentIndex.value === -1) return;

        lastMouseX.value = event.clientX;

        if (edgeScrollAnimationId.value) {
            cancelAnimationFrame(edgeScrollAnimationId.value);
            edgeScrollAnimationId.value = null;
        }

        const scrollInfo = handleEdgeScroll(event.clientX);

        if (scrollInfo.isScrolling) {
            edgeScrollAnimationId.value = requestAnimationFrame(updateSegmentDuringScroll);
        }

        const { width } = sliderContainer.value.getBoundingClientRect();
        const pixelOffset = event.clientX - dragStartPosition.value;
        const timeOffset = (pixelOffset / width) * sequenceProps.endTime;

        const segment = segments.value[dragSegmentIndex.value];
        const segmentDuration = segment.end - segment.start;
        let newStart = dragStartTime.value + timeOffset;

        newStart = Math.max(0, Math.min(newStart, sequenceProps.endTime - segmentDuration));

        segment.start = newStart;
        segment.end = newStart + segmentDuration;
        dragHandle.value = null;

        mergeSegments();
        mergeAdjacentSegments(dragSegmentIndex.value);
    };

    const stopSegmentDrag = () => {
        stopDrag();
        isDraggingActive.value = false;
        dragSegmentIndex.value = -1;
        dragSegmentIsDragging.value = false;
        document.removeEventListener('mousemove', handleSegmentDrag);

        // stop animation edge scroll
        if (edgeScrollAnimationId.value) {
            cancelAnimationFrame(edgeScrollAnimationId.value);
            edgeScrollAnimationId.value = null;
        }

        if (stopEdgeScroll) {
            stopEdgeScroll();
        }

        mergeSegments();
        updateSegmentsInStore(segments.value);
    };

    const handleStartDrag = (handle, index, direction) => {
        startDrag();
        isDraggingActive.value = true;
        isHandleDisabled.value = false;
        dragHandle.value = handle;
        dragSegmentIndex.value = index;

        if (handle === 'end') {
            originalSegmentEnd.value = segments.value[index].end;
        }
    };

    const updateHandleDuringScroll = () => {
        if (!isDragging.value || !sliderContainer.value || dragSegmentIsDragging.value) return;

        const scrollInfo = handleEdgeScroll(lastMouseX.value);

        if (scrollInfo.isScrolling && scrollInfo.scrollDirection !== 0) {
            const pixelOffset = scrollInfo.scrollDirection * scrollInfo.scrollFactor * 5; // Augmenter la vitesse pour une meilleure réactivité

            const { left, width } = sliderContainer.value.getBoundingClientRect();

            const currentHandlerPos = lastMouseX.value - left;

            lastMouseX.value += pixelOffset;

            const newPos = lastMouseX.value - left;

            const newTime = calculateNewTime(newPos, width);

            emit('force-current-time', newTime);

            const currentSegment = segments.value[dragSegmentIndex.value];

            updateSegmentPosition(newTime, 0.1, currentSegment, dragHandle.value === 'start');
            mergeAdjacentSegments(dragSegmentIndex.value);
            mergeSegments();

            if (playing.value) {
                toggleTimeline();
            }

            // Continuer l'animation
            edgeScrollAnimationId.value = requestAnimationFrame(updateHandleDuringScroll);
        }
    };

    const onDrag = (event) => {
        if (!isDragging.value || !sliderContainer.value || dragSegmentIsDragging.value) return;

        lastMouseX.value = event.clientX;

        if (edgeScrollAnimationId.value) {
            cancelAnimationFrame(edgeScrollAnimationId.value);
            edgeScrollAnimationId.value = null;
        }

        const scrollInfo = handleEdgeScroll(event.clientX);

        if (scrollInfo.isScrolling) {
            edgeScrollAnimationId.value = requestAnimationFrame(updateHandleDuringScroll);
        }

        const { left, width } = sliderContainer.value.getBoundingClientRect();
        const newPos = event.clientX - left;
        const newTime = calculateNewTime(newPos, width);

        // Emit force-current-time event with absolute time
        emit('force-current-time', newTime);

        const currentSegment = segments.value[dragSegmentIndex.value];

        // Update segment
        updateSegmentPosition(newTime, 0.1, currentSegment, dragHandle.value === 'start');
        mergeAdjacentSegments(dragSegmentIndex.value);
        mergeSegments();

        if (playing.value) {
            toggleTimeline();
        }
    };

    const calculateNewTime = (newPos, containerWidth) => {
        const rawTime = (newPos / containerWidth) * sequenceProps.endTime;
        return Math.max(0, Math.min(rawTime, sequenceProps.endTime));
    };

    const handleStopDrag = () => {
        if (!isDragging.value) return;

        updateSegmentsInStore(segments.value);
        stopDrag();
        isDraggingActive.value = false;

        if (edgeScrollAnimationId.value) {
            cancelAnimationFrame(edgeScrollAnimationId.value);
            edgeScrollAnimationId.value = null;
        }

        if (stopEdgeScroll) {
            stopEdgeScroll();
        }

        if (!onHandleStopDrag) {
            dragHandle.value = null;
            dragSegmentIndex.value = -1;
            return;
        }

        const currentSegmentIndex = dragSegmentIndex.value;
        const currentHandle = dragHandle.value;
        const currentSegment = segments.value[currentSegmentIndex];

        dragHandle.value = null;
        dragSegmentIndex.value = -1;

        if (!currentSegment) return;

        const timeOffset = 0.01;

        const timeProps = {
            segments: segments.value.slice(0, currentSegmentIndex + 1),
            playbackRate: sequenceProps.playbackRate,
            videoEndTime: sequenceProps.endTime
        };

        let relativeTime = calculateRelativeTime(
            currentHandle === 'start' ? currentSegment.start + timeOffset : currentSegment.end - timeOffset,
            timeProps
        );

        onHandleStopDrag(relativeTime, currentHandle);
    };

    const startSliderDrag = (event, wrapperEl) => {
        if (isDragging.value || !wrapperEl) {
            !wrapperEl && console.warn('sliderWrapper is not initialized');
            return;
        }

        isSliderDragging.value = true;
        isDraggingActive.value = true;
        startX.value = event.pageX - wrapperEl.offsetLeft;
        scrollLeft.value = wrapperEl.scrollLeft;
        lastMouseX.value = event.clientX;
        document.addEventListener('mousemove', onSliderDrag);
    };

    const onSliderDrag = (event) => {
        if (!isSliderDragging.value || !sliderWrapper.value) return;

        lastMouseX.value = event.clientX;

        if (handleEdgeScroll) {
            handleEdgeScroll(event.clientX);
        }

        const x = event.pageX - sliderWrapper.value.offsetLeft;
        const smoothingFactor = 2 + sequenceProps.zoomLevel;
        const walk = (x - startX.value) / smoothingFactor;

        sliderWrapper.value.scrollLeft = scrollLeft.value - walk;
    };

    const stopSliderDrag = () => {
        isSliderDragging.value = false;
        isDraggingActive.value = false;
        updateSegmentsInStore(segments.value);
        document.removeEventListener('mousemove', onSliderDrag);

        if (edgeScrollAnimationId.value) {
            cancelAnimationFrame(edgeScrollAnimationId.value);
            edgeScrollAnimationId.value = null;
        }

        if (stopEdgeScroll) {
            stopEdgeScroll();
        }
    };

    const cleanup = () => {
        if (typeof window !== 'undefined') {
            window.removeEventListener('mouseup', handleGlobalMouseUp);
        }
    };

    return {
        handleStartDrag,
        dragHandle,
        onDrag,
        handleStopDrag,
        startSliderDrag,
        onSliderDrag,
        stopSliderDrag,
        dragSegmentIndex,
        startSegmentDrag,
        dragSegmentIsDragging,
        isHandleDisabled,
        originalSegmentEnd,
        cleanup
    };
}
