<template>
    <ul v-if="props.errors && props.errors.length" class="text-error-list">
        <li v-for="(message, i) in props.errors" :key="i" class="text-error">{{ message }}</li>
    </ul>
</template>

<script setup lang="ts">
const props = defineProps({
    errors: {
        type: Array<String>,
        default: null
    }
});
</script>