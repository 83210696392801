<template>
    <main class="ui-iframe-view">
        <iframe width="100%" height="100%" :src="src" allowfullscreen></iframe>
    </main>
</template>

<script setup>
import { computed } from 'vue';
import { useAuth } from '@/js/composables/useAuth.js';

const { user } = useAuth();

const urls = {
    fr: 'https://www.2emotion.com/outil-ia/',
    en: 'https://www.2emotion.com/en/ai-tools/'
};

const src = computed(() => {
    return user.value.language === 'fr_FR' ? urls.fr : urls.en;
});
</script>
