import SequenceTemplateApiService from '@/js/infrastructure/api/sequenceTemplates/SequenceTemplateApiService.js';

export default class SequenceTemplateDataService {
    constructor(store) {
        this.sequenceTemplateApiService = new SequenceTemplateApiService(store);
    }

    async duplicate(sequence) {
        let response = await this.sequenceTemplateApiService.duplicate(sequence);
        return response.data;
    }

    async fetchAll(brandId, options) {
        let response = await this.sequenceTemplateApiService.fetchAll(brandId, options);
        return response.data;
    }

    async fetchThemes() {
        let response = await this.sequenceTemplateApiService.fetchSequenceTemplateThemes();
        return response.data;
    }
}
