<template>
    <div class="ui-range-input" :class="containerClasses">
        <label :id="labelId" class="ui-range-label" :class="labelClasses" :for="inputId" :aria-hidden="!showLabel">
            <slot>{{ label }}</slot>
        </label>
        <div class="ui-range-input-group">
            <input
                type="range"
                ref="range"
                :id="rangeId"
                :style="rangeStyles"
                :aria-labelledby="labelId"
                v-model="inputValue"
                :step="step"
                :min="min"
                :max="max"
                :disabled="disabled"
                @change="handleChange"
            />
            <input
                type="text"
                ref="input"
                :id="inputId"
                class="ui-range-number-input"
                :value="formattedTime"
                :disabled="disabled"
                @input="handleTextInput"
            />
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, defineEmits, defineProps, ref, watch } from 'vue';
import { conversions } from '@/js/video-studio/utils';

const props = defineProps({
    id: String,
    modelValue: {
        type: Number,
        default: 0
    },
    defaultValue: {
        type: Number,
        default: 0
    },
    step: {
        type: Number,
        default: 1
    },
    min: {
        type: Number,
        default: 0
    },
    max: {
        type: Number,
        default: 86400 // 24 hours in seconds
    },
    horizontal: {
        type: Boolean,
        default: true
    },
    disabled: {
        type: Boolean,
        default: false
    },
    label: {
        type: String,
        default: ''
    },
    showLabel: {
        type: Boolean,
        default: true
    }
});

const emit = defineEmits(['update:modelValue']);

const inputValue = ref(!isNaN(props.modelValue) ? props.modelValue : props.defaultValue);

const containerClasses = computed(() => ({
    horizontal: props.horizontal,
    disabled: props.disabled
}));

const rangeId = computed(() => `${props.id}-range`);
const inputId = computed(() => `${rangeId.value}-input`);
const labelId = computed(() => `${rangeId.value}-label`);

const labelClasses = computed(() => ({
    'visually-hidden': !props.showLabel
}));

const rangeStyles = computed(() => ({
    '--range-value': `${(100 * (inputValue.value - props.min)) / (props.max - props.min)}%`
}));

const formattedTime = computed(() => {
    return conversions.hundredths(inputValue.value);
});

watch(
    () => props.modelValue,
    (newValue) => {
        inputValue.value = !isNaN(newValue) ? newValue : props.defaultValue;
    }
);

const handleChange = () => {
    if (!/\S/.test(inputValue.value.toString()) || isNaN(inputValue.value)) {
        inputValue.value = props.defaultValue;
    }
    if (props.min != null) inputValue.value = Math.max(props.min, inputValue.value);
    if (props.max != null) inputValue.value = Math.min(props.max, inputValue.value);
    emit('update:modelValue', inputValue.value);
};

const handleTextInput = (event: Event) => {
    const target = event.target as HTMLInputElement;
    const [minutes, secondsPart] = target.value.split(':');
    const [seconds, hundredths] = secondsPart.split('.');

    const totalSeconds = parseInt(minutes) * 60 + parseInt(seconds) + parseInt(hundredths || '0') / 100;

    if (!isNaN(totalSeconds)) {
        inputValue.value = Math.min(Math.max(totalSeconds, props.min), props.max);
        emit('update:modelValue', inputValue.value);
    }
};
</script>
