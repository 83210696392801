<template>
    <div class="current-time-bar-wrapper" :style="wrapperStyle" :class="{ locked: isLocked }">
        <div class="current-time-bar" :class="{ locked: isLocked }">
            <div class="grab-area" :class="{ locked: isLocked }" @pointerdown.stop="handleGrabAreaClick">
                <div class="inverted-house-container" @pointerdown.stop="handleHeadClick">
                    <div class="square"></div>
                    <div class="inverted-triangle"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const props = defineProps({
    position: {
        type: Number,
        required: true
    },
    canvasWidth: {
        type: Number,
        required: true
    },
    isDragging: {
        type: Boolean,
        required: true
    },
    isScrolling: {
        type: Boolean,
        required: true
    },
    isLocked: {
        type: Boolean,
        default: false
    }
});

const emit = defineEmits(['startDrag', 'update:isLocked']);

const wrapperStyle = computed(() => ({
    transform: `translateX(${Math.min(Math.max(props.position, 0), props.canvasWidth)}px)`
}));

const handleGrabAreaClick = (event: PointerEvent) => {
    if (!props.isLocked) {
        (event.target as HTMLElement).setPointerCapture(event.pointerId);
        emit('startDrag', event);
    }
};

const handleHeadClick = (event: PointerEvent) => {
    event.stopPropagation();

    if (props.isLocked) {
        // First unlock
        emit('update:isLocked', false);

        // Then immediately start dragging to follow cursor
        // Use setTimeout with 0 delay to ensure unlock is processed first
        setTimeout(() => {
            (event.target as HTMLElement).setPointerCapture(event.pointerId);
            emit('startDrag', event);
        }, 0);
        return;
    }

    // Only start drag if not locked
    (event.target as HTMLElement).setPointerCapture(event.pointerId);
    emit('startDrag', event);
};
</script>

<style scoped>
.current-time-bar-wrapper {
    position: absolute;
    top: 0;
    width: 1px;
    height: 100%;
    z-index: 60;
    will-change: transform;
}

.current-time-bar-wrapper.locked {
    pointer-events: none;
}

.current-time-bar {
    position: absolute;
    left: 50%;
    top: -5px;
    width: 1px;
    height: 65px;
    background-color: rgb(255, 255, 255);
    transform: translateX(-50%);
}

.current-time-bar.locked {
    pointer-events: none;
    cursor: default !important;
}

.grab-area {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    width: 12px;
    height: 110px;
    cursor: col-resize;
}

.grab-area.locked {
    cursor: default;
    pointer-events: none;
    cursor: default !important;
}

.inverted-house-container {
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
    width: 11px;
    height: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 2;
    cursor: col-resize;
    /* Always keep clickable, even when locked */
    pointer-events: auto !important;
}

.square {
    width: 11px;
    height: 12px;
    background-color: white;
    pointer-events: none;
    border-radius: 3px 3px 0px 0px;
}

.inverted-triangle {
    width: 0;
    height: 0;
    border-left: 5.5px solid transparent;
    border-right: 5.5px solid transparent;
    border-top: 7px solid white;
    pointer-events: none;
}

/* Add styles for grabbing cursor when dragging */
.grab-area:active {
    cursor: grabbing;
}
</style>
