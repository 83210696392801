import { useStore } from 'vuex';
import { computed } from 'vue';

export function useCaptionsEditor() {
    const store = useStore();

    const editorState = computed(() => store.state.ui.captionsEditor);
    const currentCaptionIndex = computed(() => editorState.value.currentCaptionIndex);

    const openEditor = (
        mediaId: string,
        storeModulePath: string,
        type: string,
        parentStoreModulePath: string,
        sequenceId?: string,
        closeWhenClickOutside?: boolean
    ): void => {
        store.dispatch('ui/captionsEditor/openEditor', {
            mediaId,
            storeModulePath,
            type,
            parentStoreModulePath,
            sequenceId,
            closeWhenClickOutside
        });
    };

    const closeEditor = (): void => {
        store.dispatch('ui/captionsEditor/closeEditor');
    };

    return {
        editorState,
        currentCaptionIndex,
        openEditor,
        closeEditor
    };
}
