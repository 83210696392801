<template>
    <ui-library-selector
        id="ui-caption-editor-animations-list"
        v-model="animation"
        :default-value="CAPTION_DEFAULT_ANIMATION"
        library="captionAnimations"
        preview-path="captions/animations/"
        :show-label="true"
        :label="$t('Select an animation')"
        icon="fa-solid fa-sparkles"
        @[librarySelectorShowEvent]="openLibrary"
        @[librarySelectorPreviewChangeEvent]="handlePreviewChange"
    />
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { useStore } from 'vuex';
import { useCaptions } from '@/js/videos/composables/caption/useCaptions.ts';
import { useCaptionsEditor } from '@/js/videos/composables/caption/useCaptionsEditor.ts';
import UiLibrarySelector, {
    UI_LIBRARY_SELECTOR_PREVIEW_CHANGE,
    UI_LIBRARY_SELECTOR_SHOW
} from '@/js/components/UiLibrarySelector.vue';
import VideoStudio from '@/js/video-studio/VideoStudio.vue';

const store = useStore();
const { editorState } = useCaptionsEditor();
const { animation, CAPTION_DEFAULT_ANIMATION } = useCaptions(
    editorState.value.storeModulePath,
    editorState.value.type,
    editorState.value.parentStoreModulePath
);

const videoStudio: VideoStudio = inject('$videoStudio');

const librarySelectorShowEvent: string = UI_LIBRARY_SELECTOR_SHOW;
const librarySelectorPreviewChangeEvent: string = UI_LIBRARY_SELECTOR_PREVIEW_CHANGE;

const libraryTagOrders = computed(() => store.state.branding.libraries.tagOrders);

const openLibrary = (selector: UiLibrarySelector) => {
    store.commit('ui/setCurrentLibrary', {
        id: selector.library,
        category: selector.category,
        tagOrder: selector.tagOrder || libraryTagOrders.value[selector.library] || null,
        selectedItemId: selector.modelValue,
        selector
    });
};

const handlePreviewChange = (value: string) => {
    animation.value = value;
    videoStudio.value.studio.$stage.playTimeline();
};
</script>
