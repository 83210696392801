<template>
    <ui-video-card
        :id="cardElementId"
        :card-name="$t('Message {number}', { number })"
        classes="ui-video-card-message"
        :selected="editing"
        @click.left="select"
    >
        <template #selector>
            <ui-library-selector
                ref="librarySelector"
                :id="cardElementId + '-type'"
                v-model="messageType"
                :default-value="messageDefaultType"
                library="messages"
                preview-path="messages/"
                :label="$t('Choose an animation')"
                :disabled="readOnly"
                icon="fa-solid fa-sparkles"
                :show-label="false"
                @[librarySelectorShowEvent]="openMessageLibrary"
                @[librarySelectorPreviewChangeEvent]="previewMessageType"
            />
        </template>

        <template #content>
            <span>{{ animationName }}</span>
        </template>

        <template #actions-menu-content>
            <ui-card-sequence-menu-content
                :id="id"
                :seq-id="seqId"
                :enable-duplicate="true"
                :can-duplicate="canDuplicateMessage"
                :duplicate-actions="duplicateMessage"
                :remove-actions="removeMessage"
                :element-store-module-path="elementStoreModulePath"
            />
        </template>

        <template #actions-tools>
            <ui-message-tools :seq-id="seqId" :element-id="elementId" :card-element-id="cardElementId" />
        </template>

        <template #second-row>
            <ui-text-editor
                ref="messageTextEditor"
                :id="cardElementId + '-text'"
                v-model="messageText"
                :message-type="messageType"
                :previewing="isMessagePreview"
                :font="messageFont"
                :font-size="messageFontSize"
                :align="messageAlign"
                :palette="messageColors"
                :marks="messageMarks"
                :label="$t('Message text')"
                :show-label="false"
                :disabled="readOnly"
                :enable-align-justify="false"
                :enable-font-selection="true"
                :expandable="true"
                @[textEditorFontChangeEvent]="messageFont = $event"
                @[textEditorFontSizeChangeEvent]="messageFontSize = $event"
                @[textEditorAlignChangeEvent]="messageAlign = $event"
                @[textEditorPreviewChangeEvent]="previewMessageText"
            />
        </template>

        <template v-if="messageUses.includes('images')" #third-row>
            <ui-message-images :seq-id="seqId" :element-id="elementId" :card-element-id="cardElementId" />
        </template>
    </ui-video-card>
</template>

<script setup>
import { computed, inject, onMounted, ref, watch } from 'vue';
import UiVideoCard from '@/js/videos/components/ui/body/UiVideoCard.vue';
import UiLibrarySelector, {
    UI_LIBRARY_SELECTOR_SHOW,
    UI_LIBRARY_SELECTOR_PREVIEW_CHANGE
} from '@/js/components/UiLibrarySelector.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { MESSAGE_ELEMENT_ID } from '@/js/constants/index.js';
import UiCardSequenceMenuContent from '@/js/videos/components/ui/body/cards/menus/UiCardSequenceMenuContent.vue';
import { useMessage } from '@/js/videos/composables/useMessage.js';
import { Message } from '@/js/video-studio/constants/index.js';
import UiMessageTools from '@/js/videos/components/ui/body/cards/tools/message/UiMessageTools.vue';
import UiTextEditor, {
    UI_TEXT_EDITOR_FONT_CHANGE,
    UI_TEXT_EDITOR_FONT_SIZE_CHANGE,
    UI_TEXT_EDITOR_ALIGN_CHANGE,
    UI_TEXT_EDITOR_PREVIEW_CHANGE
} from '@/js/components/UiTextEditor.vue';
import { useEditingElement } from '@/js/videos/composables/useEditingElement.js';
import UiMessageImages from '@/js/videos/components/ui/body/cards/tools/message/UiMessageImages.vue';

const props = defineProps({
    id: {
        type: String,
        default: MESSAGE_ELEMENT_ID
    },
    elementId: {
        type: String,
        default: ''
    },
    seqId: String,
    editing: Boolean,
    number: {
        type: Number,
        default: 1
    }
});

const messageDefaultType = Message.DEFAULT;
const librarySelectorShowEvent = UI_LIBRARY_SELECTOR_SHOW;
const librarySelectorPreviewChangeEvent = UI_LIBRARY_SELECTOR_PREVIEW_CHANGE;
const textEditorFontChangeEvent = UI_TEXT_EDITOR_FONT_CHANGE;
const textEditorFontSizeChangeEvent = UI_TEXT_EDITOR_FONT_SIZE_CHANGE;
const textEditorAlignChangeEvent = UI_TEXT_EDITOR_ALIGN_CHANGE;
const textEditorPreviewChangeEvent = UI_TEXT_EDITOR_PREVIEW_CHANGE;

const librarySelector = ref(null);
const messageTextEditor = ref(null);

const cardElementId = computed(() => `${props.seqId}-${props.elementId}`);
const animationName = computed(() => librarySelector.value?.currentLabel || 'null');

const videoStudio = inject('$videoStudio');
const { readOnly, openLibrary } = useVideo();
const {
    elementStoreModulePath,
    messageText,
    messageType,
    canDuplicateMessage,
    isMessagePreview,
    messageFont,
    messageFontSize,
    messageAlign,
    messageMarks,
    messageColors,
    messageUses,
    duplicateMessage,
    removeMessage,
    openMessageLibrary,
    previewMessageType,
    previewMessageText
} = useMessage(props.seqId, props.elementId);

const { editingElement } = useEditingElement();
watch(editingElement, (newValue, oldValue) => {
    if (newValue?.id === props.elementId) {
        messageTextEditor.value.expandEditor();
    } else if (oldValue?.id === props.elementId) {
        messageTextEditor.value.reduceEditor();
    }
});

const select = () =>
    videoStudio.value.studio.$stage.getSequenceElement(props.seqId).startEditingElement(null, props.elementId);

// expand editor when element is being edited before card is mounted
onMounted(() => {
    if (props.editing) messageTextEditor.value.expandEditor();
});
</script>
