<!--
  -- Component name: Number10
  -- Type: Transition
  -- Uses: color.start
  -- Tags: number
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <defs>
            <mask :id="svgMaskID">
                <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
                <path
                    class="color-number"
                    d="M821.4,349.7c-1,29.8-2.4,56.2-11,81.6c-7.1,20.8-18.9,38.1-38.9,48.5c-12,6.3-25,9.2-38.5,10.5
				c-15.5,1.5-30.6,0.3-45.5-3.8c-28.4-7.8-46.1-26.9-55.6-54.2c-4.9-14.1-8-28.6-8.8-43.5c-0.8-15.9-2.3-31.9-1.7-47.8
				c0.9-25.5,3.1-50.9,13.2-74.9c11.8-28.1,32.8-44.6,62.7-49.7c18-3.1,36-2.8,53.7,1.3c28.1,6.5,46.6,24.1,57.2,50.5
				c6.5,16.3,9.3,33.4,10.8,50.8C820.1,330.3,820.8,341.6,821.4,349.7z M778.1,350.2c-0.2-3.8-0.3-10.9-1.2-17.8
				c-1.7-14.9-3.3-29.8-6-44.4c-2.1-11.1-6.7-21.3-15.6-29.1c-13.4-11.7-29.5-12.4-45.8-10.3c-22,2.9-32.8,18.2-38.2,38.1
				c-5.4,20.2-6.6,40.9-6.8,61.6c-0.1,10.5,0.4,21.1,0.9,31.6c0.7,15.2,2.7,30.2,7.9,44.6c10.7,29.6,32.7,35.6,57.9,32.3
				c21.2-2.7,33.2-16.4,38.9-36.2C776.6,398.7,777.5,376.1,778.1,350.2z"
                />
                <path
                    class="color-number"
                    d="M528,256.4c-20.7,5.2-40.2,10.2-60.5,15.3c0-11.7-0.1-22.2,0.2-32.7c0-1.1,2-2.9,3.3-3.2
				c21.6-5.8,43-12.3,64.9-16.9c10.1-2.1,21-0.6,31.6-0.5c1,0,2.2,1.8,2.7,3c0.5,1.1,0.1,2.5,0.1,3.8c0,84.6,0,169.2,0,253.8
				c0,7.1,0,7.1-7,7.1c-9.8,0-19.5-0.2-29.3,0.1c-4.5,0.1-6.1-1.2-6-5.9c0.2-23.4,0.1-46.7,0.1-70.1c0-48.9,0-97.9,0-146.8
				C528,261.5,528,259.4,528,256.4z"
                />
            </mask>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" :fill="state.color.start" :mask="svgMask" />
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
        };
    },

    computed: {
        svgMaskID() {
            return this.containerID + '-number10-mask';
        },

        svgMask() {
            return 'url(#' + this.svgMaskID + ')';
        }
    },

    watch: {
        svgMaskRadius: {
            handler() {
                this.updateTimeline();
            }
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            this._timeline = t;
            t.fromTo(
                '#' + this.svgMaskID + ' .color-number',
                { scale: 0.8 },
                { scale: 1.2, transformOrigin: '50% 50%', duration: 1 },
                0
            );
            t.fromTo(
                '#' + this.svgMaskID + ' .color-number',
                { scale: 1.5 },
                { scale: 50, translateX: '-100%', transformOrigin: '50% 50%', duration: 0.2 },
                1
            );
            return t;
        }
    }
};
</script>
