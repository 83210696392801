<template>
    <div class="ui-content-row">
        <label>{{ $t('captions.editor.text.shadow.label') }}</label>
        <div class="captions-editor-flex-list">
            <ui-color-selector
                id="captions-animation-text-shadow-color"
                :color="{ value: config.textShadow.color.value }"
                :show-label="false"
                :palette="brandPalette"
                :enable-other-colors="brandEnableOtherColors"
                dropdown-container="#studio-ui"
                @[colorSelectorChangeEvent]="updateConfigAttribute('textShadow.color.value', $event?.value)"
            />
            <dropdown class="hide-chevron captions-shadow-style-dropdown">
                <template #dropdown-selected-value>
                    <fa-icon class="icon" icon="fa-regular fa-ellipsis" />
                </template>
                <div class="ui-content-row">
                    <ui-range-input
                        :id="`captions-editor-shadow-x`"
                        class="ui-content-row"
                        v-model="config.textShadow.distance"
                        :min="0"
                        :max="10"
                        :step="1"
                        :horizontal="false"
                        :label="$t('captions.editor.text.shadow.distance.label')"
                        @update:model-value="updateConfigAttribute('textShadow.distance', $event)"
                    />
                    <ui-range-input
                        :id="`captions-editor-shadow-angle`"
                        class="ui-content-row"
                        v-model="config.textShadow.angle"
                        :min="0"
                        :max="360"
                        :step="1"
                        :horizontal="false"
                        :label="$t('captions.editor.text.shadow.angle.label')"
                        @update:model-value="updateConfigAttribute('textShadow.angle', $event)"
                    />
                    <ui-range-input
                        :id="`captions-editor-shadow-blur`"
                        class="ui-content-row"
                        v-model="config.textShadow.blur"
                        :min="0"
                        :max="30"
                        :step="1"
                        :horizontal="false"
                        :label="$t('captions.editor.text.shadow.blur.label')"
                        @update:model-value="updateConfigAttribute('textShadow.blur', $event)"
                    />
                    <ui-range-input
                        :id="`captions-editor-shadow-alpha`"
                        class="ui-content-row"
                        v-model="config.textShadow.color.alpha"
                        :min="0"
                        :max="1"
                        :step="0.05"
                        :horizontal="false"
                        :label="$t('captions.editor.text.shadow.alpha.label')"
                        @update:model-value="updateConfigAttribute('textShadow.color.alpha', $event)"
                    />
                </div>
            </dropdown>
        </div>
    </div>
</template>

<script setup lang="ts">
import UiRangeInput from '@/js/components/UiRangeInput.vue';
import UiColorSelector, { UI_COLOR_SELECTOR_CHANGE } from '@/js/components/UiColorSelector.vue';
import Dropdown from '@/js/components/dropdown/Dropdown.vue';
import { useCaptions } from '@/js/videos/composables/caption/useCaptions.ts';
import { useCaptionsEditor } from '@/js/videos/composables/caption/useCaptionsEditor.ts';
import { useBrand } from '@/js/videos/composables/useBrand.js';

const colorSelectorChangeEvent: string = UI_COLOR_SELECTOR_CHANGE;

const { editorState } = useCaptionsEditor();
const { config, updateConfigAttribute } = useCaptions(
    editorState.value.storeModulePath,
    editorState.value.type,
    editorState.value.parentStoreModulePath
);
const { brandPalette, brandEnableOtherColors } = useBrand();
</script>
