<template>
    <div class="ui-content-row">
        <label>{{ $t('captions.editor.alignment.label') }}</label>
        <div class="captions-editor-flex-list">
            <dropdown v-model="alignment" class="hide-chevron dropdown-small captions-alignment-dropdown">
                <template #dropdown-selected-value>
                    <fa-icon class="icon" :icon="'fa-solid fa-align-' + config.alignment" />
                </template>
                <div class="ui-content-row">
                    <dropdown-item
                        v-for="align in Align.TEXT_ALIGNMENTS_WITH_JUSTIFY"
                        :key="align"
                        :value="align"
                        :class="{ selected: alignment === align }"
                    >
                        <fa-icon class="icon" :icon="'fa-solid fa-align-' + align" />
                        <span>{{ $t(`studio.text_alignments.${align}`) }}</span>
                    </dropdown-item>
                </div>
            </dropdown>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, Ref, watch } from 'vue';
import { useCaptions } from '@/js/videos/composables/caption/useCaptions.ts';
import { useCaptionsEditor } from '@/js/videos/composables/caption/useCaptionsEditor.ts';
import Dropdown from '@/js/components/dropdown/Dropdown.vue';
import DropdownItem from '@/js/components/dropdown/DropdownItem.vue';
import { Align } from '@/js/video-studio/constants';

const { editorState } = useCaptionsEditor();
const { config, updateConfigAttribute } = useCaptions(
    editorState.value.storeModulePath,
    editorState.value.type,
    editorState.value.parentStoreModulePath
);

const alignment: Ref<string> = ref(config.value.alignment);

watch(alignment, (newAlignment) => {
    updateConfigAttribute('alignment', newAlignment);
});
</script>
