<template>
    <ui-media-selector
        v-if="isAudioCategory"
        :id="cardElementId + '-audio'"
        v-model:media="voiceOverMediaAudioModel"
        :type="mediaTypeAudio"
        :label="$t('studio.tts_categories.audio')"
        :showLabel="false"
        :disabled="readOnly"
        icon="fa-regular fa-volume"
        :trigger-opening="triggerSelection && isAudioCategory && !libraryId"
        @[mediaSelectorShowEvent]="openMediaLibrary"
        @[mediaSelectorChangeEvent]="resetVoiceOverCaptions"
    />

    <ui-voice-over-selector
        v-else-if="isVoiceCategory"
        :id="cardElementId + '-tts'"
        :disabled="readOnly"
        :seqId="seqId"
        :use-in-sequence="useInSequence"
        :trigger-opening="triggerSelection && isVoiceCategory && !libraryId"
    />

    <ui-recording-selector
        v-else-if="isRecordingCategory"
        :id="cardElementId + '-recording'"
        v-model:recording="voiceOverRecordingModel"
        :type="RECORDING_TYPE_MICROPHONE"
        :label="$t('studio.tts_categories.recording')"
        :showLabel="false"
        :disabled="readOnly"
        :trigger-opening="triggerSelection && isRecordingCategory && !libraryId"
        @[recordingSelectorChangeEvent]="resetVoiceOverCaptions"
    />
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';

import { useVideo } from '@/js/videos/composables/useVideo.js';
import UiMediaSelector, {
    UI_MEDIA_SELECTOR_CHANGE,
    UI_MEDIA_SELECTOR_SHOW,
    UI_MEDIA_TYPE_AUDIO
} from '@/js/components/UiMediaSelector.vue';
import UiRecordingSelector, { UI_RECORDING_SELECTOR_CHANGE } from '@/js/components/UiRecordingSelector.vue';
import UiVoiceOverSelector from '@/js/components/UiVoiceOverSelector.vue';
import { useVoiceOver } from '@/js/videos/composables/useVoiceOver.js';
import { RECORDING_TYPE_MICROPHONE } from '@/js/constants/index.js';
import { useCaptions } from '@/js/videos/composables/caption/useCaptions.ts';

const props = defineProps({
    seqId: String,
    cardElementId: String,
    useInSequence: Boolean,
    triggerSelection: Boolean
});

const useInSequence = ref(props.useInSequence);
const sequenceId = ref(props.seqId);

const store = useStore();
const { readOnly, openMediaLibrary } = useVideo();
const {
    storeModulePath,
    isAudioCategory,
    isVoiceCategory,
    isRecordingCategory,
    voiceOverMediaAudioModel,
    voiceOverRecordingModel,
    voiceOverCaptions,
    voiceOverCategory
} = useVoiceOver(useInSequence, sequenceId);

const { resetCaptions: resetCaptionsAudio } = useCaptions(
    `${storeModulePath.value}/audio`,
    'voiceover',
    storeModulePath.value
);
const { resetCaptions: resetCaptionsVoice } = useCaptions(
    `${storeModulePath.value}/voice`,
    'voiceover',
    storeModulePath.value
);
const { resetCaptions: resetCaptionsRecording } = useCaptions(
    `${storeModulePath.value}/recording`,
    'voiceover',
    storeModulePath.value
);

const mediaTypeAudio = UI_MEDIA_TYPE_AUDIO;
const mediaSelectorShowEvent = UI_MEDIA_SELECTOR_SHOW;
const mediaSelectorChangeEvent = UI_MEDIA_SELECTOR_CHANGE;
const recordingSelectorChangeEvent = UI_RECORDING_SELECTOR_CHANGE;

const libraryId = computed(() => store.state.ui.currentLibrary.id);
const resetVoiceOverCaptions = () => {
    voiceOverCaptions.value = [];

    if (isVoiceCategory.value) {
        resetCaptionsVoice();
    } else if (isAudioCategory.value) {
        resetCaptionsAudio();
    } else if (isRecordingCategory.value) {
        resetCaptionsRecording();
    }
};
</script>
