<template>
    <button
        class="preview-action preview-action-play"
        id="video-onboarding-step-4"
        @click="play"
        data-bs-html="true"
        v-tooltip.light="sprintf($t('Full video preview in %1$s'), '<strong>SD</strong>')"
    >
        <span class="action-icon">
            <svg-icon icon="rotate-play-icon" class="play" />
        </span>
        <span class="action-label">{{ $t('Full preview') }}</span>
    </button>
</template>

<script>
import { printf as sprintf } from 'fast-printf';
import UsesTooltip from '@/js/mixins/UsesTooltip.js';

export default {
    name: 'FullPreviewButton',

    inject: ['$videoStudio'],
    mixins: [UsesTooltip],

    methods: {
        sprintf,

        play() {
            this.$store.commit('preview/setCurrentTimelineId', null);
            this.$videoStudio.studio.$stage.seekTimeline(0);
            this.$videoStudio.studio.$stage.playTimeline();
        }
    }
};
</script>
