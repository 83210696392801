<template>
    <div>
        <div class="gap" :style="initialGapStyle" @click="handleGapClick" />
        <div
            v-for="(segment, index) in segments"
            :key="`gap-${index}`"
            class="gap"
            :style="getGapStyle(index)"
            @click="handleGapClick"
        />
        <div class="gap" :style="finalGapStyle" @click="handleGapClick" />
    </div>
</template>

<script setup lang="ts">
import { type GapStyle, type Segment } from '@/js/videos/types/segments';

// Define props with TypeScript types
interface Props {
    initialGapStyle: GapStyle;
    finalGapStyle: GapStyle;
    segments: Segment[];
    getGapStyle: (index: number) => GapStyle;
}

// Declare props with withDefaults for type safety
defineProps<Props>();

// Define emits
const emit = defineEmits(['click']);

// Handle click event and emit it to parent
const handleGapClick = (event: MouseEvent) => {
    event.stopPropagation();
    emit('click', event);
};
</script>

<style scoped>
.gap {
    position: absolute;
    top: 0;
    height: 58px;
    background-color: rgb(44 52 58 / 76%);
    pointer-events: auto;
    z-index: 11;
    border-block: 2px solid #29303a;
    cursor: pointer;
}
</style>
