<!--
  -- Component name: MirrorArrow
  -- Type: Transition
  -- Uses: 
  -- Tags: mirror
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <defs>
            <clipPath :id="svgMaskID" clipPathUnits="objectBoundingBox" :style="clipPathTransform">
                <path
                    d="M-284,4228c-1,0-2,0-4,0c0-820,0-1640,0-2460c2.7-1.2,5.5-2.3,8.2-3.6c21.4-10.8,42.8-21.7,64.1-32.6
				c283.1-144.2,566.2-288.4,849.1-432.8c6.2-3.2,10.7-2.7,16.6,0.3c80,41,160.2,81.7,240.3,122.6
				c220.8,112.5,441.5,224.9,662.3,337.3c5,2.5,10.2,4.5,15.3,6.8c0,820,0,1640,0,2460c-2.4-0.9-5-1.7-7.3-2.9
				c-88.7-45.2-177.5-90.4-266.2-135.6c-211.9-107.9-423.8-215.7-635.4-324.1c-13.1-6.7-22.6-6.4-35.5,0.2
				C324.1,3916.7,24.3,4069.3-275.3,4222C-278.4,4223.6-281.1,4226-284,4228z"
                />
                <path
                    d="M-288,1191.9c3.1-1.1,6.3-1.8,9.2-3.2c131.8-67.1,263.6-134.2,395.3-201.3C288.4,899.9,460.2,812.4,632,724.7
				c5-2.5,8.8-2.7,13.8-0.1c176.8,90.3,353.7,180.3,530.6,270.5c126.1,64.3,252.3,128.5,378.4,192.8c3.5,1.8,7,3.7,10.8,5.6
				c0,116.6,0,232.8,0,350.7c-309.2-157.5-617.7-314.6-926.4-471.9c-20,10.1-39.9,20.2-59.8,30.3
				c-184.9,94.2-369.8,188.3-554.7,282.5c-100.9,51.4-201.8,102.8-302.7,154.1c-3.3,1.7-6.6,3.2-9.8,4.8
				C-288,1426.6-288,1309.3-288,1191.9z"
                />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            needsRefresh: true, // The transition needs Chrome and Safari to force a re-rendering during the animation
            keepActive: true
            // isAbove: false, // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
        };
    },

    computed: {
        svgMaskID() {
            return this.containerID + '-mirror-arrow-mask';
        },

        svgMask() {
            return 'url(#' + this.svgMaskID + ')';
        },

        clipPathTransform() {
            return this.format && this.getClipPathTransform(1280, 720);
        }
    },

    watch: {
        svgMaskRadius: {
            handler() {
                this.updateTimeline();
            }
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            this._timeline = t;
            t.set(this, { inClipPathProxy: this.svgMask }, 0.0001);
            t.fromTo('#' + this.svgMaskID + ' path', { y: 0 }, { duration: 0.9, y: '-=2560' }, 0);
            return t;
        }
    }
};
</script>
