<!--
  -- Component name: Number5
  -- Type: Transition
  -- Uses: color.start
  -- Tags: number
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <defs>
            <mask :id="svgMaskID">
                <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
                <path
                    class="color-number"
                    d="M577.5,226.5c49.7,0,99.4,0,149.5,0c0.1,1.1,0.2,2.2,0.2,3.3c0,9-0.1,17.9,0.1,26.9c0.1,3.1-1,3.9-4,3.9
				c-35.6-0.1-71.2-0.1-106.8-0.1c-4.8,0-4.8,0-5.2,4.7c-1.4,15.5-2.8,30.9-4.2,46.4c-0.6,6-1.2,12-1.8,18.2
				c4.2-0.3,8.2-0.8,12.2-0.9c12.4-0.3,24.9-1,37.3-0.6c13.6,0.5,27.1,2.8,39.5,8.7c21.2,10.1,34.6,26.4,38.9,49.8
				c3.7,20,3.5,39.9-3.1,59.4c-8.7,25.5-27.6,39.5-52.8,46.6c-19.3,5.5-39.1,5.6-58.8,4.7c-17.9-0.9-35.7-3.5-53.2-7.9
				c-2.9-0.7-3.5-1.8-3.1-4.8c1.3-9.2,2.1-18.4,3.1-28c1.6,0,2.9-0.2,4.2,0c15.7,2.5,31.3,5.4,47,7.3c14,1.8,28,1.5,41.8-2
				c19-4.8,30.6-18.2,32.6-37.5c1.1-10.3,1.4-20.6-1.3-30.8c-4.8-18.3-17.4-27.7-35.3-30.7c-14.9-2.6-29.8-2.1-44.7-0.2
				c-10.3,1.3-20.7,2.5-31.1,3.5c-1.6,0.2-3.4-1-4.9-1.7c-0.8-0.4-1.2-1.4-2-1.9c-6.9-4.4-6.5-10.8-5.5-17.7
				c1-6.5,1.2-13.1,1.8-19.7c0.9-9.2,1.8-18.4,2.6-27.6c0.8-8.5,1.5-17.1,2.3-25.6c0.9-9.4,1.8-18.9,2.7-28.3
				c0.5-5.3,1-10.6,1.5-15.9C577.2,227.5,577.4,227.1,577.5,226.5z"
                />
            </mask>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" :fill="state.color.start" :mask="svgMask" />
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
        };
    },

    computed: {
        svgMaskID() {
            return this.containerID + '-number5-mask';
        },

        svgMask() {
            return 'url(#' + this.svgMaskID + ')';
        }
    },

    watch: {
        svgMaskRadius: {
            handler() {
                this.updateTimeline();
            }
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            this._timeline = t;
            t.fromTo(
                '#' + this.svgMaskID + ' .color-number',
                { scale: 1 },
                { scale: 1.5, transformOrigin: '50% 50%', duration: 1 },
                0
            );
            t.fromTo(
                '#' + this.svgMaskID + ' .color-number',
                { scale: 1.5 },
                { scale: 50, translateX: '-100%', transformOrigin: '50% 50%', duration: 0.2 },
                1
            );
            return t;
        }
    }
};
</script>
