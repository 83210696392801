<!--
  -- Component name: ColorOpen
  -- Type: Transition
  -- Uses: color.start
  -- Tags: color
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <rect class="color-open-top" y="-50%" width="100%" :fill="state.color.start" height="100%" />
        <rect class="color-open-bottom" y="50%" width="100%" :fill="state.color.start" height="100%" />
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            isAbove: true
        };
    },

    computed: {},

    watch: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            this._timeline = t;
            t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 }, 0.001);
            t.fromTo(
                '#' + this.containerID + ' .color-open-top',
                { yPercent: 0 },
                { yPercent: -50, duration: 0.3, ease: 'power1.in' },
                0.1
            );
            t.fromTo(
                '#' + this.containerID + ' .color-open-bottom',
                { yPercent: 0 },
                { yPercent: 50, duration: 0.3, ease: 'power1.in' },
                0.1
            );
            return t;
        }
    }
};
</script>
