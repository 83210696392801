import FetchBrandsApiService from '@/js/infrastructure/api/brands/FetchBrandsApiService.js';
import PaginatedResultDTO from '@/js/dto/PaginatedResultDTO.js';
import BrandDTO from '@/js/dto/BrandDTO.js';
import MinimalBrandDTO from '@/js/dto/MinimalBrandDTO.js';

export default class FetchBrandsDataService {
    constructor(store) {
        this.fetchBrandsApiService = new FetchBrandsApiService(store);
    }

    async fetchBrands(options) {
        let data = (await this.fetchBrandsApiService.fetchBrands(options)).data;
        return new PaginatedResultDTO(data);
    }

    async fetchBrandsForOrganization(organizationId, options) {
        let data = (await this.fetchBrandsApiService.fetchMinimalBrandsForOrganization(organizationId, options)).data;
        return new PaginatedResultDTO(data, {
            organization_brands_count: data.organization_brands_count,
            organization_max_brands: data.organization_max_brands
        });
    }

    async fetchBrandById(brandId) {
        const data = (await this.fetchBrandsApiService.fetchBrandById(brandId)).data;
        return new BrandDTO(data);
    }

    async fetchBrandResources(brandId) {
        return (await this.fetchBrandsApiService.fetchBrandResources(brandId)).data;
    }

    async fetchBrandColorsFromFolderId(folderId) {
        return (await this.fetchBrandsApiService.fetchBrandColors(folderId)).data;
    }

    async fetchPaginatedMasters(options) {
        let data = (await this.fetchBrandsApiService.fetchMasters(options)).data;
        return new PaginatedResultDTO(data);
    }

    async storeBrand(brand) {
        let data = (await this.fetchBrandsApiService.storeBrand(brand)).data;
        return new MinimalBrandDTO(data);
    }

    async duplicateBrand(brandId, newName) {
        let data = (await this.fetchBrandsApiService.duplicateBrand(brandId, newName)).data;
        return new MinimalBrandDTO(data);
    }

    async applyFolderToBrand(brandId, folderId) {
        let data = (await this.fetchBrandsApiService.applyFolderToBrand(brandId, folderId)).data;
        return new MinimalBrandDTO(data);
    }

    async copyBrandToOrganization(brandId, organizationId) {
        let data = (await this.fetchBrandsApiService.copyBrandToOrganization(brandId, organizationId)).data;
        return new MinimalBrandDTO(data);
    }

    async deleteBrand(brandId) {
        return (await this.fetchBrandsApiService.deleteBrand(brandId)).data;
    }
}