import axios from 'axios';

export default class AudioIsolationService {
    constructor() {
        this.axiosInstance = axios.create({
            withCredentials: true,
            baseURL: `${import.meta.env.VITE_LEGACY_APP_URL}/front`
        });
    }

    isolate = async (variationUuid) => {
        try {
            const response = await this.axiosInstance.post(`media/${variationUuid}/audio-isolation`);
            return response.data;
        } catch (err) {
            console.error(err);
            return { error: err.response?.data?.error };
        }
    };

    cancel = async (variationUuid) => {
        try {
            const response = await this.axiosInstance.get(`media/${variationUuid}/audio-isolation/cancel`);
            return response.data;
        } catch (err) {
            console.error(err);
            return { error: err.response?.data?.error };
        }
    };
}
