<template>
    <template v-for="visualId in visualIds" :key="visualId">
        <captions
            v-if="hasSrc(visualId)"
            :ref="
                (el) => {
                    visualCaptionRefs[visualId] = el;
                }
            "
            :store-module-path="`sequences/${seqId}/${visualId}/video`"
            type="visual"
            :parent-store-module-path="`sequences/${seqId}/${visualId}`"
            :sequence-id="seqId"
            @update="updateTimeline"
        />
    </template>
</template>

<script setup lang="ts">
import { computed, defineProps, ref } from 'vue';
import { useStore } from 'vuex';
import gsap from 'gsap';
import Captions from '@/js/video-studio/components/captions/Captions.vue';

const { seqId } = defineProps<{
    seqId: string;
}>();

const emit = defineEmits<{
    (e: 'update'): void;
}>();

const store = useStore();

const visualIds = computed<string[]>(() => store.getters[`sequences/${seqId}/visualIds`]);

const visualCaptionRefs = ref<Record<string, any>>([]);

let timeline: GSAPTimeline = gsap.timeline();

const buildTimeline = (): void => {
    timeline = gsap.timeline({ paused: true });

    visualIds.value.forEach((visualId: string) => {
        if (visualCaptionRefs.value[visualId]) {
            timeline.add(visualCaptionRefs.value[visualId].getTimeline().paused(false), 0);
        }
    });
};

const updateTimeline = (): void => {
    buildTimeline();
    emit('update');
};

const getTimeline = (): GSAPTimeline => {
    buildTimeline();
    return timeline;
};

const hasSrc = (visualId: string): boolean => {
    return !!store.getters[`sequences/${seqId}/${visualId}/video/track`];
};

defineExpose<{
    getTimeline: () => GSAPTimeline;
}>({
    getTimeline
});
</script>
