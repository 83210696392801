<template>
    <div class="ui-content-row">
        <label>{{ $t('captions.editor.interline.label') }}</label>
        <div class="captions-editor-flex-list">
            <dropdown v-model="interline" class="hide-chevron dropdown-small captions-interline-dropdown">
                <template #dropdown-selected-value>
                    <fa-icon class="icon" :icon="'fa-solid fa-line-height'" />
                </template>
                <div class="ui-content-row">
                    <dropdown-item
                        v-for="value in Interline.VALUES"
                        :key="value"
                        :value="value"
                        :class="{ selected: value === interline }"
                    >
                        {{ value }}
                    </dropdown-item>
                </div>
            </dropdown>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, Ref, watch } from 'vue';
import { useCaptions } from '@/js/videos/composables/caption/useCaptions.ts';
import { useCaptionsEditor } from '@/js/videos/composables/caption/useCaptionsEditor.ts';
import Dropdown from '@/js/components/dropdown/Dropdown.vue';
import DropdownItem from '@/js/components/dropdown/DropdownItem.vue';
import { Interline } from '@/js/video-studio/constants';

const { editorState } = useCaptionsEditor();
const { config, updateConfigAttribute } = useCaptions(
    editorState.value.storeModulePath,
    editorState.value.type,
    editorState.value.parentStoreModulePath
);

const interline: Ref<number> = ref(config.value.interline);

watch(interline, (newInterline) => {
    updateConfigAttribute('interline', newInterline);
});
</script>
