<template>
    <ui-caption-button
        :store-module-path="`${mediaModulePath}`"
        type="voiceover"
        :parent-store-module-path="`${ttsModulePath}`"
        :has-old-captions="hasOldCaptions"
        :media-id="mediaState.src__id"
        :media-src="mediaState.src"
        :readonly="disabled"
        @open-editor="handleOpenEditor"
    />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import UiCaptionButton from '@/js/videos/components/ui/body/selectors/caption/button/UiCaptionButton.vue';
import { useCaptionsEditor } from '@/js/videos/composables/caption/useCaptionsEditor.ts';
import { useMedia } from '@/js/videos/composables/useMedia.ts';

const props = defineProps<{
    category: string;
    useInSequence: boolean;
    seqId?: string;
    readOnly: boolean;
}>();

const { openEditor } = useCaptionsEditor();

const ttsModulePath = computed(() => {
    return props.useInSequence ? 'sequences/' + props.seqId + '/tts' : 'settings/tts';
});

const mediaModulePath = computed(() => {
    return ttsModulePath.value + '/' + props.category;
});

const { mediaState } = useMedia(mediaModulePath.value);

const oldCaptions = computed(() => mediaState.value?.captions || []);

const hasOldCaptions = computed(() => {
    return oldCaptions.value.length > 0 && oldCaptions.value[0].text.length > 0;
});

const disabled = computed(() => {
    return props.readOnly || !mediaState.value?.src;
});

const handleOpenEditor = (): void => {
    openEditor(mediaState.value?.src__id, mediaModulePath.value, 'voiceover', ttsModulePath.value, props.seqId);
};
</script>
