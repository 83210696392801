<!--
  -- Component name: MacifIntroSignature
  -- Type: Transition
  -- Uses: 
  -- Tags: macif
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container studio-transition-macif-intro-signature"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1920 1080"
        preserveAspectRatio="xMidYMid slice"
    >
        <defs>
            <mask :id="svgMaskRectID">
                <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
                <rect
                    class="macif-intro-signature-rect-mask"
                    x="775"
                    y="544"
                    width="762"
                    height="64"
                    :style="transitionScaleStyle"
                />
            </mask>
        </defs>
        <rect x="0" y="0" fill="#fff" width="1920" height="1080" :mask="svgMaskRect" />
        <g class="macif-intro-signature-group" :style="transitionScaleStyle">
            <rect class="macif-intro-signature-rect" fill="#d3d62e" x="775" y="544" width="762" height="64" />
            <g class="macif-intro-signature-word-1">
                <path fill="#0a2d82" d="m136.99,483.63h17.98v81.62h50.63v15.22h-68.61v-96.83Z" />
                <path
                    fill="#0a2d82"
                    d="m280.72,537.17v43.3h-16.32v-8.99c-4.15,6.36-12.17,9.96-23.24,9.96-16.88,0-27.53-9.27-27.53-22.13s8.3-22,30.71-22h19.09v-1.11c0-10.1-6.09-16.05-18.4-16.05-8.3,0-16.88,2.77-22.41,7.33l-6.78-12.59c7.89-6.09,19.37-9.13,31.26-9.13,21.3,0,33.62,10.1,33.62,31.4Zm-17.29,20.2v-8.58h-17.85c-11.76,0-14.94,4.43-14.94,9.82,0,6.23,5.26,10.24,14.11,10.24s15.77-3.87,18.68-11.48Z"
                />
            </g>
            <g class="macif-intro-signature-word macif-intro-signature-word-2">
                <path
                    fill="#0a2d82"
                    d="m431.65,580.46l-.14-64.19-31.82,53.12h-8.02l-31.82-52.29v63.36h-17.15v-96.83h14.8l38.46,64.19,37.77-64.19h14.8l.14,96.83h-17.02Z"
                />
                <path
                    fill="#0a2d82"
                    d="m534.99,537.17v43.3h-16.32v-8.99c-4.15,6.36-12.17,9.96-23.24,9.96-16.88,0-27.53-9.27-27.53-22.13s8.3-22,30.71-22h19.09v-1.11c0-10.1-6.09-16.05-18.4-16.05-8.3,0-16.88,2.77-22.41,7.33l-6.78-12.59c7.89-6.09,19.37-9.13,31.26-9.13,21.3,0,33.62,10.1,33.62,31.4Zm-17.29,20.2v-8.58h-17.85c-11.76,0-14.94,4.43-14.94,9.82,0,6.23,5.26,10.24,14.11,10.24s15.77-3.87,18.68-11.48Z"
                />
                <path
                    fill="#0a2d82"
                    d="m551.73,543.53c0-22.13,16.6-37.77,39.98-37.77,14.39,0,25.87,5.95,31.54,17.15l-13.28,7.75c-4.43-7.05-11.07-10.24-18.4-10.24-12.73,0-22.41,8.85-22.41,23.1s9.68,23.1,22.41,23.1c7.33,0,13.97-3.18,18.4-10.24l13.28,7.75c-5.67,11.07-17.15,17.29-31.54,17.29-23.38,0-39.98-15.77-39.98-37.9Z"
                />
                <path
                    fill="#0a2d82"
                    d="m635.84,484.04c0-5.81,4.7-10.38,11.07-10.38s11.07,4.29,11.07,9.96c0,6.09-4.56,10.79-11.07,10.79s-11.07-4.57-11.07-10.38Zm2.35,22.55h17.29v73.87h-17.29v-73.87Z"
                />
                <path
                    fill="#0a2d82"
                    d="m697.26,502.72v4.43h20.34v13.83h-19.78v59.48h-17.29v-59.48h-12.17v-13.83h12.17v-4.7c0-15.49,9.27-25.59,26.42-25.59,5.95,0,11.76,1.25,15.63,4.01l-4.84,13c-2.63-1.8-5.95-3.04-9.55-3.04-7.19,0-10.93,4.01-10.93,11.9Z"
                />
                <path
                    fill="#0a2d82"
                    d="m744.71,569.95c0,3.04-.69,5.53-3.18,11.9l-7.06,19.5h-11.21l5.53-21.17c-3.87-1.66-6.5-5.26-6.5-10.24,0-6.92,4.84-11.48,11.21-11.48s11.21,4.7,11.21,11.48Z"
                />
            </g>
            <g class="macif-intro-signature-word macif-intro-signature-word-3">
                <path
                    fill="#0a2d82"
                    d="m793.68,543.53c0-22.13,16.6-37.77,39.98-37.77,14.39,0,25.87,5.95,31.54,17.15l-13.28,7.75c-4.43-7.05-11.07-10.24-18.4-10.24-12.73,0-22.41,8.85-22.41,23.1s9.68,23.1,22.41,23.1c7.33,0,13.97-3.18,18.4-10.24l13.28,7.75c-5.67,11.07-17.15,17.29-31.54,17.29-23.38,0-39.98-15.77-39.98-37.9Z"
                />
                <path
                    fill="#0a2d82"
                    d="m900.48,488.19c0,3.18-.55,5.53-3.04,11.9l-7.19,19.64h-11.21l5.53-21.3c-3.87-1.52-6.5-5.12-6.5-10.24,0-6.78,4.84-11.34,11.21-11.34s11.21,4.7,11.21,11.34Z"
                />
                <path
                    fill="#0a2d82"
                    d="m983.06,549.06h-57.96c2.08,10.79,11.07,17.71,23.93,17.71,8.3,0,14.8-2.63,20.06-8.02l9.27,10.65c-6.64,7.89-17.02,12.04-29.74,12.04-24.76,0-40.81-15.91-40.81-37.9s16.19-37.77,38.18-37.77,37.35,15.08,37.35,38.18c0,1.38-.14,3.46-.28,5.12Zm-58.1-11.48h41.92c-1.38-10.65-9.55-17.98-20.89-17.98s-19.37,7.19-21.03,17.98Z"
                />
                <path
                    fill="#0a2d82"
                    d="m991.5,573.13l6.64-13.14c6.5,4.29,16.46,7.33,25.73,7.33,10.93,0,15.49-3.04,15.49-8.16,0-14.11-45.65-.83-45.65-30.3,0-13.97,12.59-23.1,32.51-23.1,9.82,0,21.03,2.35,27.67,6.36l-6.64,13.14c-7.05-4.15-14.11-5.53-21.17-5.53-10.51,0-15.49,3.46-15.49,8.3,0,14.94,45.65,1.66,45.65,30.57,0,13.83-12.73,22.83-33.48,22.83-12.31,0-24.62-3.6-31.26-8.3Z"
                />
                <path
                    fill="#0a2d82"
                    d="m1115.59,576.45c-4.15,3.32-10.38,4.98-16.6,4.98-16.19,0-25.45-8.58-25.45-24.9v-35.55h-12.17v-13.83h12.17v-16.88h17.29v16.88h19.78v13.83h-19.78v35.14c0,7.19,3.6,11.07,10.24,11.07,3.6,0,7.05-.97,9.68-3.04l4.84,12.31Z"
                />
            </g>
            <g class="macif-intro-signature-word macif-intro-signature-word-4">
                <path
                    fill="#0a2d82"
                    d="m1237.19,506.59l-31.68,73.87h-17.85l-31.68-73.87h17.98l22.96,54.78,23.66-54.78h16.6Z"
                />
                <path
                    fill="#0a2d82"
                    d="m1239.82,543.53c0-22.13,16.6-37.77,39.29-37.77s39.43,15.63,39.43,37.77-16.46,37.9-39.43,37.9-39.29-15.77-39.29-37.9Zm61.28,0c0-14.11-9.41-23.1-22-23.1s-21.86,8.99-21.86,23.1,9.41,23.1,21.86,23.1,22-8.99,22-23.1Z"
                />
                <path
                    fill="#0a2d82"
                    d="m1406.65,506.59v73.87h-16.46v-9.41c-5.53,6.78-14.25,10.38-23.79,10.38-18.95,0-31.82-10.38-31.82-32.65v-42.19h17.29v39.84c0,13.42,6.36,19.78,17.29,19.78,12.04,0,20.2-7.47,20.2-22.27v-37.35h17.29Z"
                />
                <path
                    fill="#0a2d82"
                    d="m1420.91,573.13l6.64-13.14c6.5,4.29,16.46,7.33,25.73,7.33,10.93,0,15.49-3.04,15.49-8.16,0-14.11-45.65-.83-45.65-30.3,0-13.97,12.59-23.1,32.51-23.1,9.82,0,21.03,2.35,27.67,6.36l-6.64,13.14c-7.05-4.15-14.11-5.53-21.17-5.53-10.51,0-15.49,3.46-15.49,8.3,0,14.94,45.65,1.66,45.65,30.57,0,13.83-12.73,22.83-33.48,22.83-12.31,0-24.62-3.6-31.26-8.3Z"
                />
                <path
                    fill="#0a2d82"
                    d="m1494.78,569.95c0-6.92,5.12-11.48,11.34-11.48s11.34,4.56,11.34,11.48-5.12,11.48-11.34,11.48-11.34-4.84-11.34-11.48Z"
                />
            </g>
        </g>
    </svg>
</template>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true, // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
            transitionScale: 1
        };
    },

    computed: {
        svgMaskRectID() {
            return this.containerID + '-macif-intro-signature-mask';
        },

        svgMaskRect() {
            return 'url(#' + this.svgMaskRectID + ')';
        },

        transitionScaleStyle() {
            return {
                transformOrigin: '50% 50%',
                transform: 'scale(' + this.transitionScale + ')'
            };
        },

        isPortraitFormat() {
            return this.format.width < this.format.height;
        },

        isSquareFormat() {
            return this.format.width === this.format.height;
        }
    },

    watch: {
        svgMaskRadius: {
            handler() {
                this.updateTimeline();
            }
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            this._timeline = t;

            if (this.isPortraitFormat) {
                t.set(this, { transitionScale: 0.3 }, 0);
            } else if (this.isSquareFormat) {
                t.set(this, { transitionScale: 0.55 }, 0);
            } else {
                t.set(this, { transitionScale: 1 }, 0);
            }

            // Initialize to 0 the width of the green rectangle that appears later
            // macif-intro-signature-rect-mask is used for the transparency effect on the current sequence that is applied at the end of the transition
            t.set(
                '#' + this.containerID + ' .macif-intro-signature-rect',
                { attr: { width: '0%', height: 64, x: 775, y: 544 } },
                0
            );
            t.set(
                '#' + this.containerID + ' .macif-intro-signature-rect-mask',
                { attr: { width: '0%', height: 64, x: 775, y: 544 }, opacity: 0 },
                0
            );
            t.set('#' + this.containerID + ' .macif-intro-signature-word', { translateY: '100%', opacity: 0 }, 0);

            let position = 0;
            for (let i = 1; i < 5; i++) {
                // Translate the text up to make it appear
                t.fromTo(
                    '#' + this.containerID + ' .macif-intro-signature-word-' + i,
                    { translateY: '100%' },
                    { translateY: 0, duration: 0.76 },
                    position
                );
                t.fromTo(
                    '#' + this.containerID + ' .macif-intro-signature-word-' + i,
                    { opacity: 0 },
                    { opacity: 1, duration: 0.68, ease: 'power.out' },
                    position
                );
                position = position + 0.24;
            }

            // Make the green rectangle appear
            t.fromTo(
                '#' + this.containerID + ' .macif-intro-signature-rect-mask',
                { attr: { width: '0%', height: 64, x: 775, y: 544 } },
                { attr: { width: 762, height: 64, x: 775, y: 544 }, duration: 0.88, ease: 'power4.inOut' },
                0.8
            );
            t.fromTo(
                '#' + this.containerID + ' .macif-intro-signature-rect',
                { attr: { width: '0%', height: 64, x: 775, y: 544 } },
                { attr: { width: 762, height: 64, x: 775, y: 544 }, duration: 0.88, ease: 'power4.inOut' },
                0.8
            );

            position = 1.96;
            for (let i = 1; i < 5; i++) {
                // Translate the text up to make it appear
                t.fromTo(
                    '#' + this.containerID + ' .macif-intro-signature-word-' + i,
                    { translateY: 0 },
                    { translateY: '-100%', duration: 0.72 },
                    position
                );
                t.fromTo(
                    '#' + this.containerID + ' .macif-intro-signature-word-' + i,
                    { opacity: 1 },
                    { opacity: 0, duration: 0.56, ease: 'power.out' },
                    position + 0.12
                );
                position = position + 0.12;
            }

            // Enlarge the green rectangle until it takes up the whole screen
            t.fromTo(
                '#' + this.containerID + ' .macif-intro-signature-rect',
                { attr: { width: 762, height: 64, x: 775, y: 544 } },
                { attr: { width: 1920, height: 1080, x: 0, y: 0 }, duration: 1.04, ease: 'power2.inOut' },
                2.28
            );
            t.fromTo(
                '#' + this.containerID + ' .macif-intro-signature-rect-mask',
                { attr: { width: 762, height: 64, x: 775, y: 544 } },
                { attr: { width: 1920, height: 1080, x: 0, y: 0 }, duration: 1.04, ease: 'power2.inOut' },
                2.28
            );
            t.to(this, { transitionScale: 1, duration: 0.36 }, 2.96);

            // Change the opacity of the green rectangle to show the current sequence
            t.fromTo(
                '#' + this.containerID + ' .macif-intro-signature-rect',
                { opacity: 1 },
                { opacity: 0, duration: 0.88, ease: 'power1.in' },
                2.48
            );
            t.set('#' + this.containerID + ' .macif-intro-signature-rect-mask', { opacity: 1 }, 2.48);

            // Zoom In on the background / new sequence
            if (this.previous)
                t.fromTo(
                    [
                        this.backgroundInElement,
                        this.backgroundOutElement,
                        this.sequenceInElement,
                        this.sequenceOutElement
                    ],
                    { scale: 0.9 },
                    { scale: 1, duration: 0.88, ease: 'power1.out', immediateRender: false },
                    2.48
                );

            return t;
        }
    }
};
</script>
