<!--
  -- Component name: AyvensLogo1
  -- Type: Transition
  -- Uses: 
  -- Tags: ayvens
  -->
<template>
    <div></div>
</template>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: false, // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            inElementsAutoAppear: false // The in elements are automatically added to the timeline and appear from the start,
            // keepActive: true
        };
    },

    watch: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            const t = gsap.timeline();
            this._timeline = t;

            // set initial mask style
            // using mask image as data-uri because it does not work properly with inline HTML reference
            t.set(this.backgroundInElement, {
                maskImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 151.64 113.69'%3E%3Cpath d='M102.03 112.98h49.61V0h-31.61c0 39.37-6.33 77.51-18 112.98ZM85.64 0 0 113.69h1.47c61.58-.8 111.95-51.92 111.95-113.68H85.64Z' style='fill:%230f3549;stroke-width:0'/%3E%3C/svg%3E")`,
                maskPosition: 'center',
                maskRepeat: 'no-repeat',
                maskSize: '20%'
            });

            // initial fade animation
            t.fromTo(
                this.backgroundInElement,
                {
                    autoAlpha: 0
                },
                {
                    autoAlpha: 1,
                    duration: 0.6,
                    ease: 'linear'
                },
                0.01
            );

            // scale animation
            t.fromTo(
                this.backgroundInElement,
                {
                    maskSize: '20%'
                },
                {
                    maskSize: '700%',
                    duration: 1.15,
                    ease: 'expo.in'
                },
                0.2
            );

            return t;
        }
    }
};
</script>
