<template>
    <ui-track-caption-selector :seq-id="seqId" :card-element-id="cardElementId" :read-only="readOnly" />

    <ui-track-time-range-selector :seq-id="seqId" />

    <ui-track-volume-selector :seq-id="seqId" :card-element-id="cardElementId" />

    <ui-track-duration-selector :seq-id="seqId" :card-element-id="cardElementId" />
</template>

<script setup>
import UiTrackCaptionSelector from '@/js/videos/components/ui/body/selectors/caption/UiTrackCaptionSelector.vue';
import UiTrackTimeRangeSelector from '@/js/videos/components/ui/body/selectors/time-range/UiTrackTimeRangeSelector.vue';
import UiTrackDurationSelector from '@/js/videos/components/ui/body/selectors/duration/UiTrackDurationSelector.vue';
import UiTrackVolumeSelector from '@/js/videos/components/ui/body/selectors/volume/UiTrackVolumeSelector.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';

const props = defineProps({
    seqId: String,
    cardElementId: String
});

const { readOnly } = useVideo();
</script>
