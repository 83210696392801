<template>
    <ui-dropdown
        :id="cardElementId + '-effects'"
        class="ui-visual-effect-selector-dropdown"
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="bottom"
        menu-strategy="fixed"
        :disabled="readOnly"
        :toggle-style="toggleStyle"
        :icon-only="true"
        :tooltip="$t('Effects')"
    >
        <template #dropdown-toggle>
            <fa-icon class="icon" icon="fa-regular fa-sparkles" />
        </template>

        <ui-library-selector
            v-if="!isLottieCategory && !isAnimatedCategory"
            ref="librarySelector"
            :id="cardElementId + '-type'"
            v-model="visualType"
            :default-value="visualDefaultTypes[visualCategory]"
            library="visuals"
            :category="visualCategoryForLibrary"
            :preview-path="visualCategoryPreviewPath"
            :label="visualTypeDefaultLabel[visualCategory]"
            :disabled="readOnly"
            icon="fa-solid fa-sparkles"
            @[librarySelectorShowEvent]="openLibrary"
            @[librarySelectorPreviewChangeEvent]="previewVisualType"
        />

        <ui-switch-button
            :id="cardElementId + '-animation-enabled'"
            v-if="visualUses.includes('animation.enabled')"
            :label="$t('Enable animation')"
            v-model="visualAnimationEnabled"
            :disabled="readOnly"
        />

        <ui-switch-button
            v-if="!isLottieCategory"
            :id="cardElementId + '-enable-color'"
            :label="$t('Change color')"
            v-model="enableVisualColor"
            :disabled="readOnly"
        />

        <div v-if="enableVisualColor || isLottieCategory" class="ui-video-card-visual-effect-colors">
            <ui-color-selector
                :id="cardElementId + '-color-start'"
                selector-class="visual-effect-color-selector"
                v-model:color="visualColorStart"
                :default-color="''"
                :palette="brandPalette"
                :enable-other-colors="brandEnableOtherColors"
                :show-label="false"
                :disabled="readOnly"
                v-tooltip="$t(colorsTooltip.first)"
            />
            <ui-color-selector
                v-if="visualUses.includes('color.second')"
                :id="cardElementId + '-color-second'"
                selector-class="visual-effect-color-selector"
                v-model:color="visualColorSecond"
                :default-color="''"
                :palette="brandPalette"
                :enable-other-colors="brandEnableOtherColors"
                :show-label="false"
                :disabled="readOnly"
                v-tooltip="$t(colorsTooltip.second)"
            />
            <ui-color-selector
                v-if="visualUses.includes('color.third')"
                :id="cardElementId + '-color-third'"
                selector-class="visual-effect-color-selector"
                v-model:color="visualColorThird"
                :default-color="defaultThirdColor"
                :palette="thirdColorPalette"
                :enable-other-colors="false"
                :add-black-and-white="!isLottieCategory"
                :disabled="readOnly"
                :is-brand-palette="false"
                :show-label="false"
                v-tooltip="$t(colorsTooltip.third)"
            />
        </div>

        <ui-range-input
            :id="cardElementId + '-opacity'"
            v-model="visualOpacity"
            :label="$t('Opacity')"
            :disabled="readOnly"
        />
    </ui-dropdown>
</template>

<script setup>
import { computed, ref } from 'vue';
import UiDropdown from '@/js/components/UiDropdown.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import UiSwitchButton from '@/js/components/UiSwitchButton.vue';
import UiLibrarySelector, {
    UI_LIBRARY_SELECTOR_SHOW,
    UI_LIBRARY_SELECTOR_PREVIEW_CHANGE
} from '@/js/components/UiLibrarySelector.vue';
import UiRangeInput from '@/js/components/UiRangeInput.vue';
import { useVisual } from '@/js/videos/composables/useVisual.js';
import { Visual } from '@/js/video-studio/constants/index.js';
import UiColorSelector from '@/js/components/UiColorSelector.vue';
import { useBrand } from '@/js/videos/composables/useBrand.js';
import UsesTooltip from '@/js/mixins/UsesTooltip.js';

const vTooltip = UsesTooltip.directives.tooltip;

const props = defineProps({
    seqId: String,
    cardElementId: String,
    elementId: String
});

const librarySelectorShowEvent = UI_LIBRARY_SELECTOR_SHOW;
const librarySelectorPreviewChangeEvent = UI_LIBRARY_SELECTOR_PREVIEW_CHANGE;

const { readOnly, openLibrary } = useVideo();
const { brandPalette, brandEnableOtherColors } = useBrand();
const {
    visualType,
    visualCategory,
    isLottieCategory,
    isAnimatedCategory,
    enableVisualColor,
    visualAnimationEnabled,
    visualOpacity,
    visualColorStart,
    visualColorSecond,
    visualColorThird,
    defaultThirdColor,
    thirdColorPalette,
    visualUses,
    visualDefaultTypes,
    visualCategoryPreviewPath,
    visualTypeDefaultLabel,
    visualCategoryForLibrary,
    previewVisualType
} = useVisual(props.seqId, props.elementId);

const librarySelector = ref(null);
const colorsTooltip = computed(() =>
    isLottieCategory.value
        ? { first: 'Clothing', second: 'Object', third: 'Skin' }
        : { first: 'Color 1', second: 'Color 2', third: 'Color 3' }
);

const toggleStyle = computed(() =>
    visualType.value !== Visual.DEFAULT ||
    enableVisualColor.value ||
    visualOpacity.value !== 100 ||
    (visualUses.value.includes('animation.enabled') && visualAnimationEnabled.value)
        ? { color: 'var(--edit-mode-color)' }
        : {}
);

const showLibrary = () => librarySelector.value.showLibrary();
defineExpose({ showLibrary });
</script>
