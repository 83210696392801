import FoldersApiService from '@/js/infrastructure/api/folders/FoldersApiService.js';
import FolderDTO from '@/js/dto/FolderDTO.js';
import PaginatedResultDTO from '@/js/dto/PaginatedResultDTO.js';

export default class FoldersDataService {
    constructor(store) {
        this.foldersApiService = new FoldersApiService(store);
    }

    async fetchFoldersData(clientId, options) {
        let data = (await this.foldersApiService.fetchFoldersData(clientId, options)).data;
        return new PaginatedResultDTO(data);
    }

    async fetchByShortname(shortname) {
        let data = (await this.foldersApiService.fetchByShortname(shortname)).data;
        return new FolderDTO(data);
    }

    async fetchEditionByShortname(shortname) {
        return (await this.foldersApiService.fetchEditionByShortname(shortname)).data;
    }

    async fetchFolderUsers(folderShortname, options) {
        let data = (await this.foldersApiService.fetchFolderUsers(folderShortname, options)).data;
        return new PaginatedResultDTO(data);
    }

    async updateFolderName(folderShortname, newName) {
        return await this.foldersApiService.updateFolderName(folderShortname, newName);
    }

    async addFavourite(folderUuid) {
        this.foldersApiService.addFavourite(folderUuid);
    }

    async removeFavourite(folderUuid) {
        this.foldersApiService.removeFavourite(folderUuid);
    }
}
