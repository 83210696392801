<template>
    <div class="studio-logo studio-container" v-if="enabled" :style="borderStyles">
        <component :is="logoAnimationComponent" ref="$logoAnimation" />
    </div>
</template>

<script>
import { Align, Dimension, Logo } from '../constants';
import { mapGetters, mapState } from 'vuex';
import { defineAsyncComponent } from 'vue';

export default {
    inject: ['$stage'],

    data() {
        return {};
    },

    computed: {
        ...mapState('settings', {
            enabled: (state) => state.logo.enabled,
            bordersEnabled: (state) => state.borders.enabled
        }),

        ...mapGetters({
            logoAnimation: 'settings/logoAnimation',
            logoPosition: 'settings/logoPosition',
            borders: 'settings/borders'
        }),

        logoAnimationComponent() {
            const componentName = Logo.PREFIX_CLASS + this.logoAnimation;
            return defineAsyncComponent(() => import(`./logos/${componentName}.vue`));
            //return Logo.PREFIX_CLASS + this.logoAnimation;
        },

        borderStyles() {
            let bordersToAdd = [],
                side,
                styles = {};

            if (this.bordersEnabled) {
                if (this.logoPosition.alignH == Align.LEFT) bordersToAdd.push('left');
                else if (this.logoPosition.alignH == Align.RIGHT) bordersToAdd.push('right');
                if (this.logoPosition.alignV == Align.TOP) bordersToAdd.push('top');
                else if (this.logoPosition.alignV == Align.BOTTOM) bordersToAdd.push('bottom');

                for (side in this.borders) {
                    if (bordersToAdd.indexOf(side) != -1 && this.borders[side].size > 0) {
                        styles[side] = Math.ceil(this.borders[side].size / this.$stage.scale) + Dimension.PIXEL_UNIT;
                        if (side == 'right') styles['left'] = Dimension.AUTO;
                        else if (side == 'bottom') styles['top'] = Dimension.AUTO;
                    }
                }
            }

            return styles;
        }
    }
};
</script>
