<!--
  -- Component name: Tablet
  -- Type: Transition
  -- Uses: color.start,scene
  -- Tags: digital
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :style="transitionStyles"
        :id="this.containerID"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <g class="transition-scene" :style="sceneStyles">
            <path d="M1280,720V170H0V720Z" fill="#e6ceaa" />
            <path d="M1282.58,397.05V393H-2.35v4.1Z" fill="#bc8751" fill-opacity="0.15" />
            <path
                d="M1282.58,384.25V382.2H1188.8q-2.5,0-8,.2c-3.6.17-6.36.23-8.29.2-2.47,0-6.18.08-11.11.25s-8.64.25-11.11.25l-21.74-.1Q1040,383,993,383q-14.73-.26-22.13-.25-26,0-77.44-.25t-76.32-.25h-299q-43,.65-105.78.4l-105.37-.4H-2.35v2.05h293.1q124.13,3.11,245.84,0H817.13q55.47,0,170.26.25,8.21,0,24.79.3,20,0,59.65-.3,37.68-.25,58.28-.25Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1076.42,374.5q55.55-.3,92.33.35h113.83V370.7H1182.2l-10.87.05c-4.29,0-7.84,0-10.63-.05q-29.29-1.35-88.63.8-56.74,2.1-88.38.15-17.48-.94-25.6-.95l-140.88.05q-66.4,1-94.5,1.15-51.45.19-94.51-1.2H297q-12.88.26-18.68.25l-72.13.35c-2,0-5.09-.1-9.17-.3s-6.9-.3-8.78-.3H-2.35v4.15H958.09q16.33,0,25,.15Q1013.87,375,1076.42,374.5Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1231.46,359.25q-3.86,0-12.8.2t-14,.15q-3.87,0-11.68.2t-11.67.15q-34.62-2.15-104.65-.1-71,2.1-104.32.2-13.05-.8-19.16-.8H836.53L725.44,360q-65.69.5-108.75-.75h-619v2.05h619q16.57,0,25,.1,96.36,3,194.81-.1H950.12q12.63,0,19.24.15,37.35,1.6,107.06.3,73.9-1.39,106-.45h100.14v-2.05Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1193.06,349.55q3.06,0,8.22-.15,5.63-.25,7.88-.25h73.42v-2H1218.1q-2.58,0-8.94.2c-4,.13-7.19.18-9.49.15q-14.58.35-21.9.15-36.63-2-110.28.15-73.18,2.06-110.21,0-11.19-.64-16.82-.65H855.69q-12.72.25-19.16.3Q735,351.35,598,347.6c-2.2,0-5.52-.08-10-.25s-7.81-.25-10.06-.25H341.3q-28.17-.9-98,.85-63.35,1.6-97.4-.45-10.31-.4-27.69-.4H-2.35v2H127.9q30.75.94,104.32-.4,69.07-1.31,106.51.4H598q6.69,0,20.21.35,52.49,2.14,109.08,2.05,47-.1,109.23-1.85,3.22,0,9.58-.25t9.58-.3h84.53q5.71,0,17.06.4,39.21,1.5,119,0Q1155.47,348.05,1193.06,349.55Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1282.58,311.15V307h-82.83q-14.65,0-22.14-.15-34.69-1.39-112.14.5-73.25,1.8-110.76-.35H855.93q-6.67,0-18.11.35-14.25.5-22.62,1.35-50.38,2.66-108,1.45Q638.67,308,598,307L359.9,307q-6,0-17.71-.6-35-1.84-92.9,1-58.84,2.85-92,1.25-2.9-.11-19.16-1-13.36-.75-18.27-.8-66.82-.8-122.2,0V311q12.15-.4,33.49-.55,42.66-.4,88.71.6,19.32.34,26.32.65,30.68,1.2,97.24-.6,65.54-1.75,98.37-.3,8,.34,18.12.35l238.11,0q70,1.8,118.18,1.95,62.14.26,120.34-1.95l103.93,0q13,0,16.74.1,37,2.06,110.29-.15,73.48-2.21,110.2-.25,8.85.3,22.06.3Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1282.58,324.8v-4.15h-36.79q-5,0-19.4.4-13.92.4-22.86.35-3.94,0-11.91.5t-11.92.45q-14.25.11-25-.15-106.26.1-169.78-.25-11-.26-20.85-.25-93.61-.19-149.89,1-22.05.3-32.92.5-83.88,1.6-163.17-1.7-3.39,0-10.06-.4T598,320.65H373.74q-66.09-1.15-121.31,1.15-34.77,1.45-39.28,1.6-21.9.64-44.12.3-13.28,0-21-.25-11.75-.45-20.36-1.65-2.75-.1-11.92-.7-7.24-.45-11.59-.45H-2.35v4.15h96.2q7.73,0,24.39-.15,13.44.56,42.9,2.15,26.25,1.09,43.31-.2,39-2.7,68.43-3.45,36.78-.94,68.18.75l9.5.6q5.47.3,9.34.3H578q6.67,0,20-.2,94.28,1.31,106.18,1.4,61.83.45,108.35-.8l24.23-.65,130.41.05q29.15.9,104.89-.65,67.62-1.4,105.06.85Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1282.58,185.7v-4.15h-131.7q-29.78-.9-104.32.6Q978,183.55,940,181.6l-259.28,0q-13.37-.15-20.21-.2-52.08-2.44-109.08-2.25-45.89.15-109.23,2.2-12.81.25-19.16.25H338.48q-7.56,0-17.06-.25-39.12-1.7-119,.1-79.12,1.8-116.8,0-11,.11-16.1.1H-2.35v4.15h63q2.65,0,8.94-.4c4-.27,7.19-.38,9.49-.35q2.75,0,11-.4,6.84-.35,11-.2,36.7,2.25,110.28,0,73.18-2.25,110.21.1,2.49.1,8.45.75,5.4.56,8.37.55H423q3.3,0,9.58-.3,6.6-.26,9.58-.3,104.88-4.69,238.52-.5,3.38,0,10.06.55t10.06.55H937.4q31.71,2.06,98-.3,65.69-2.35,97.4-.2,10.31.5,27.69.5Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1282.58,202.05v-4.8q-12.32.41-33.89.6-43.15.35-89.19-.8-14.49-.35-27-1-30.67-1.4-97.24.5-65.53,1.85-98.37.15-1.21,0-8.86-.65c-3.76-.3-6.84-.45-9.26-.45h-218c-2.09,0-5.45.07-10.06.2q-6.53.19-10.06.15-67.79-2-118.18-2.05-61.41,0-120.34,2.45-3.15,0-9.58-.35c-4.24-.27-7.44-.4-9.58-.4H338.24q-11.19.4-16.74.2-37-2.35-110.2.4Q137.71,199,101,196.7q-2.66-.1-11-.6-6.84-.45-11-.45H-2.35v8.2H79q12.07,0,22.06.2,23.91,1.09,55.87.25,8.13-.2,56.35-2.15,71.07-2.85,110.68,1.7h16.43q13.68,0,41.13-.35t41.3-.35q5.23,0,18.11-.8,13.68-.9,22.62-1.95,49.91-3,107.87-1.4,34.21.9,109.24,3.95,3.38,0,10.06.45t10.06.45h218q1.86,0,9.1-.25a74.47,74.47,0,0,1,8.77,0q23,1.4,48.46.65,15.87-.45,44.6-2.3,29.7-1.95,44.28-2.4,25.27-.86,47.73.4l19.4,1.2q13.29.85,18.36.9Q1226.31,203,1282.58,202.05Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1282.58,220.45v-6.2h-97.73q-3.78,0-11.91.25t-12.48.2q-28.1-1.69-42.82-2.3-26.17-1.16-43.31.35-38.4,3-68.51,3.85-36.54,1-68.18-.95-1.6-.1-9.42-.85-5.79-.56-9.42-.55h-218q-3.3,0-10.06.3t-10.06.25q-82.76-1.25-106.18-1.4-61.43-.41-108.35,1-16,.45-24.23.75l-108.6-.1q-3.7,0-10.95-.15t-10.86-.15q-22-.75-51.93-.2L206.71,216q-66.74,1.76-105.14-1.7H-2.35v6.2H32.91q5,0,19.4-.65,13.92-.6,22.86-.55,3.87,0,11.91-.75,7.82-.7,11.92-.75,12.63-.1,25,.2,107.31-.1,169.78.3,8.68.25,20.85.35,94.26.19,150-1.1,11-.34,32.92-.55,81.22-1.69,163.09,2.15c2.2,0,5.56.27,10.06.7s7.81.65,10.07.65H905q65.85,1.16,121.31-1.5t83.4-2.15q14.32,0,21,.25a175,175,0,0,1,20.36,1.7q3.06.15,11.92,1,7.32.7,11.59.7Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1160.86,294.55q-12.31,0-25.84-.15-49.26.19-146.42.2l-171.23.15q-16.41.25-24.63.35-92,1-174.6-.25-13.44.25-20.13.25H356.11q-3.78,0-8.93-.2l-9.26-.45q-46.53-.45-74-.1-39,.45-73.25,2.6-24.15,0-31.8-.15-16.59-.35-31-1.7H-2.35v2h106.5q18.12,0,23.43.2,5.14,1.09,17.63,1,6.75-.11,18.11-.4,26.72.41,105.13-1.55,63.84-1.54,105.29.75H598q6.69,0,20.13-.2,71.8,1,163.17.35l32.92-.25q51.45-.15,153.76-.15,5.31,0,21.33.2,59.89-.15,175.41,0h117.85v-2h-33.33q-23.51-.25-38.32-.35Q1177.45,294.56,1160.86,294.55Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1136,285.5l18.6.05q42.18,0,56.34-.15l38.32.4h33.33v-2.05h-32.52q-22.95-.3-37.35-.4-51.21-.25-77-.25h-26.41q-48.45.15-103.6,0l-228.21.2-139.19.15c-2.41,0-5.77,0-10.06.1q-7,.15-10.06.15H351.52q-39.43-1.65-110.2-.4-75.51,1.35-108,.4H-2.35v2.05h111q19.32,0,24.15.15,35.25,1.91,108.43-.3,72.69-2.25,110.28-.1,6,0,18,.25H618.14c2,0,5.34,0,10.06-.1,4.29-.1,7.65-.13,10.06-.1q70.2,1.15,156.41,0l22.78-.2q61.11-1,154.08-.7l22,.1q29.63.3,71.56.5Q1089.15,285.4,1136,285.5Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1282.58,264.1c2.74,0,3.19-.17,1.37-.4s-2.25-.39-1.53-.4l-77.2.2q-51.6-.3-118.09-.15-14.82,0-117.13.4l-26.16.1q-57.89.4-131.62.15l-170.74.5q-61.17.15-211.15-.55-133.21-.65-211.23-.15l-221.45-.3v1.35l221.61.25q229.92-.45,404.51.35l189-.15q65.6.19,131.77-.15l26.73-.25,118.33-.45q67.62-.19,117.13.1Q1224.3,264.51,1282.58,264.1Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1282.58,580.7v-4.1H1182.2q-14.49.2-21.5,0-29.29-1.35-88.63.85-56.82,2.14-88.38.2c-2.37-.07-6.71-.28-13.05-.65q-8-.45-12.55-.45H817.21q-64.23,1-94.5,1.15-51.29.19-94.51-1.15H297q-12.88.3-18.68.3-51.12.35-72.13.45c-2,0-5.09-.13-9.17-.4s-6.9-.35-8.78-.35H-2.35v4.1H628.2q8,0,23.91.2,54.57,0,165.1.2H958.09q19.23,0,25,.1,30.75,0,93.3-.55,55.71-.3,92.33.3l20.13-.1q5.23,0,15.21-.15Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1231.46,562.6q-3.86,0-12.8.25t-14,.25q-3.87,0-11.68.25t-11.67.25q-34.62-2.15-104.65-.15-71,2.06-104.32.15c-2,0-5.24-.22-9.75-.55q-6.11-.45-9.41-.45H836.53l-111.09.8q-65.69.45-108.75-.8h-619v2.1h619q16.57,0,25,.1,97.57,3,194.81-.1H950.12q12.63,0,19.24.15,37.18,1.6,107.06.25,73.74-1.4,106-.4h100.14v-2.1Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1282.58,512.55V508.4h-82.83q-14.65,0-22.14-.15-34.69-1.39-112.14.5-73.25,1.8-110.76-.35H855.93q-6.75,0-18.11.35-14.25.5-22.62,1.4-50.48,2.66-108,1.45Q638.67,509.4,598,508.35l-238.11,0q-7.65,0-17.71-.6-35-1.85-92.9,1-58.92,2.85-92,1.3-28.9-1.75-37.43-1.85-66.82-.79-122.2,0v4.2q12.15-.41,33.49-.6,42.66-.35,88.71.6,4.26.1,12.56.25,7.56.15,13.76.4,30.68,1.2,97.24-.6,65.54-1.75,98.37-.3,6.69.34,18.12.35l238.11,0q70,1.8,118.18,2,62.14.25,120.34-2l103.93,0q13,0,16.74.1,36.94,2.05,110.29-.15t110.2-.2q9.25.26,22.06.25Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1282.58,494.85V492.8h-36.79q-6.12,0-19.4-.2-15.21-.19-22.86-.25-4.11,0-11.91-.1-8.22-.11-11.92-.1-14.25.11-25-.15-99.9.19-169.78-.2-3.37-.1-20.85-.2-84.27-.15-149.89,1.15-28.34.75-32.92.85-45.48.86-80.9.7-43-.19-82.27-1.8-3.63,0-10.06.15-7,.15-10.07.15H373.74q-67.86-2.35-121.31.1-26.16,1.35-39.28,1.85-22.23.86-44.12.5a309.71,309.71,0,0,1-41.37-2.45q-4.83-.19-11.92-.15c-5.2.1-9.07.15-11.59.15H-2.35v2.05h96.2q7.73,0,24.39-.25,13.44.56,42.9,2.1,26.18,1.05,43.31-.25,38.57-2.65,68.43-3.4,36.63-.9,68.18.8c1,0,4.13.23,9.5.6q5.71.41,9.34.4H578q6.67,0,20-.3,85.5,1.2,106.18,1.3,61.5.4,108.35-.85l24.23-.65q36.08,0,108.6,0,7.32,0,21.81.15,34.14,1.05,104.89.15t105.06.15Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1160.86,477.75q-12.31,0-25.84-.15-49.26.19-146.42.2H817.37q-16.41,0-24.63.1-77.19.86-174.6-.65-3.39,0-10.06-.25T598,476.7H356.11q-13,0-18.19-.25-42.66-.45-74,.05-38.07.64-73.25,2.85-21.66.34-31.8.2a183.84,183.84,0,0,1-31-2.85H-2.35v4.1h106.5c2.52,0,6.39,0,11.59-.05q7,0,11.84.1,5.14,1.15,17.63.95,6.75,0,18.11-.4,26.33.41,105.13-1.45,63.75-1.45,105.29.85H598q3.3,0,10.07-.2t10.06-.2q62.46.9,163.17.15,21.9-.1,32.92-.25,51.45-.1,153.76-.1,5.55,0,21.33.35,33.81-.1,87.42.05,65.77.19,88,.2h117.85v-4.1h-33.33q-4.18,0-17.55.25-12.46.26-20.77.2t-25.11.3Q1169.16,477.75,1160.86,477.75Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1212.71,465.6q8.68.11,20.2,0,6.28,0,17.15,0h32.52v-2.05h-32.52q-50.16,0-150.62-.4t-150.77-.4l-25.12.15q-48.62-.3-75.43.35l-50.39.45q-13.61-.15-20.28-.15H344.12q-14.56,0-19.88-.15-28.19-2-85.41.25-57.57,2.25-84.77.35-12.88-.45-27.13-.45H-2.35v2.05l135.64.1q31.47,1.5,103.12-.35,68.43-1.8,103.28.4,6.53,0,19.8-.15l418,.1q6.67,0,20.28.15Q1002.45,464.2,1212.71,465.6Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1250.06,452.55h32.52v-4.1l-309-.05q-4.18,0-12.56.2t-12.63.15q-29.72-.94-75.19-.5-73,.75-76.8.75c-2,0-5.18-.1-9.58-.3q-6.27-.26-9.58-.25l-244.55-.05q-35.26-1.25-86.06-.75-83.55.8-85.41.8H329.87q-12.31,0-18.92-.1-56.26.11-162.69.1H-2.35v4.1H151.48l82.68-1.2q50.86-.71,82.75,1,6.93.15,19.48.15H658.31c2.25,0,5.61-.07,10.06-.2s7.86-.25,10.06-.25q17.79,0,50.31-.25t48.87-.25l45.32-.2q41.94-.79,125.74-.5,8.37,0,25.11.25,42.75,0,138.22.75Q1201.43,452.54,1250.06,452.55Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1250.22,424.35h32.36V422.3h-32.36q-41.13,0-117.21-.4-81.78-.4-118.25-.45-4.66,0-13.12.15-9.18.15-13.2.15-121.8-1.65-210.35.95-2.73,0-9.74-.2-6.52-.19-10.06-.2H568.15q-68.43-.49-103.68-.8-63.77-.5-105.06.75l-361.76.05v2.05H342.19q2.32,0,102.23-.8,62.15-.49,104.09.7l229.58.1q95.55-2.81,210.35-.65,8.76,0,26.32.3Q1125.28,424.35,1250.22,424.35Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1237,411q29,.15,45.56.15v-3.9q-4.51.08-45.72.35-50.23-1.05-116.73-.7-9.09,0-116.56,1l-26.57.2q-30.82-.5-103.44-.2-66,.3-102.8-.65l-95.15-.1q-78.79-.25-172.67-.1L482.82,407q-65,0-124.13.35H332.85q-201.09.25-335.2.65v3.35H2.48q104.09,0,312.74-.25,8.7,0,26.08-.15,81.56-.9,163.82-.6l23.74,0q75.43-.5,138.7.3l92.34-.05q46-1,106.26-.75,29.46.11,103.68,1l27.53,0q82.68-.45,119.86-.5Q1184.05,410.24,1237,411Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1282.58,255.2v-2H1058.63q-10.07.15-15.13.15-58.29-1.16-140.88-.7-93.37.75-140.71.9L214,253.3q-13.84-.15-20.53-.15H-2.35v2H221.92q21.42,0,69.15.25,8.21,0,24.63.3,120.58.34,135.24.35,76.88.11,135.64-.4l168.09-.2q94.26,0,283.27-.1,7.72,0,23.43-.2Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M217,434.9l27.13.05q-12.3-.3-19.24-.3H198.5l8.13.15q6.68.15,10.38.1M1043.34,436q3.86,0,11.67-.65c4.77-.4,8.45-.61,11-.65h-2q-10.05.25-15.13.25-58-1.15-141.28-.7-93.61.85-141.11.95L306,435.1l-61.9-.15q6.36.17,14.17.4,23.18.7,36,.7,4.18,0,12.4.7t12.31.65q118.5.35,135.64.35,77,0,136.05-.45,7,0,21.33-.05l107.55-.1q7.15,0,21.41-.15,3.06,0,9.18-.15t9.09-.2q49.11,0,142.33-.35Q998.66,436,1043.34,436Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1282.58,245.25V241.1H780.67q-323.44-3.1-646.33,0H-2.35v4.15l152.47.05,315.79-.75q188.3-.5,312.66.75l23.11-.1q46.37-.1,140.31-.85,83.23-.34,140.87.9Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1282.58,232.35v-4.1q-29.31,0-96,.7-65.6.7-96.27.65-4.35,0-13.21.5t-13.52.45q-57.15,1.35-136.2.85-91.53-.85-137.09-1.25-4.18,0-12.72-.35t-12.64-.35q-130-1.39-312.34-1.45-209.3.25-314.11.25H-2.35v4.1H145l310.33-1.55q186-.9,311.61,1.55Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1159.5,527.2q-19.49-.56-27-.9-30.75-1.39-97.24.5-65.61,1.85-98.37.2-11.92-1-18.12-1h-218q-13.44.3-20.12.3-67.55-2-118.18-2-61.33-.11-120.34,2.4-3.15,0-9.58-.3c-4.24-.23-7.44-.35-9.58-.35H338.24q-11.19.3-16.74.15-37-2.35-110.2.35-73.59,2.65-110.29.4-14.73-.9-22-.9H-2.35v8.2H79q12.07,0,22.06.2,23.91,1.1,55.87.25,8.13-.2,56.35-2.15,71.07-2.85,110.68,1.7h16.43q13.68,0,41.13-.4t41.3-.4q5.14,0,18.11-.85,13.6-.9,22.62-2,49.91-3,107.87-1.35,34.21.94,109.24,4,3.38,0,10.06.5t10.06.5h218q2.26,0,9.1-.35a86.59,86.59,0,0,1,8.77-.05q23,1.4,48.46.65,15.87-.45,44.6-2.35,58.85-3.86,92-2.05l19.4,1.2q13.29.86,18.36.9,66.81.9,123.08,0v-4.8q-12.32.4-33.89.6Q1205.54,528.36,1159.5,527.2Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1160.46,547.7q-28.1-1.69-42.82-2.35-26.25-1.2-43.31.3-38.8,3-68.51,3.9-36.71,1.05-68.18-.95-1.7-.11-9.42-.75-5.79-.5-9.42-.5h-218q-3.3,0-10.06.25t-10.06.2q-82.61-1.31-106.18-1.5-61.43-.45-108.35,1l-24.23.75q-72.3,0-108.6,0-14.56-.19-21.81-.2-30.19-1-104.81.45-68.1,1.35-105.14-.9H-2.35v4.15H32.91q4.26,0,19.4-.2,13.61-.15,22.86-.1,3.63,0,11.91-.3,7.65-.25,11.92-.3,14.66-.11,25,.2,101.6-.19,169.78.25,8.28.26,20.85.25,89.1.26,150-1.2,11-.3,32.92-.75,88.78-1.85,163.09,1.7,3.39,0,10.06.25t10.07.2H905q67.45,2.29,121.31-.55,13.92-.71,39.28-2.1,22.14-.95,44.12-.55,26,0,41.37,2.4,16.11.79,23.51.8h108v-4.15h-97.73q-3.78,0-11.91.2T1160.46,547.7Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1282.58,602.85V598.7h-131.7q-29.78-.9-104.32.6Q978,600.7,940,598.75l-259.28,0q-13.37-.15-20.21-.2-52.08-2.44-109.08-2.25-45.89.15-109.23,2.2-12.81.25-19.16.25H338.48q-7.56,0-17.06-.25-39.12-1.69-119,.1-79.12,1.8-116.8.05-11,.1-16.1.1H-2.35v4.15h63q2.65,0,8.94-.4c4-.27,7.19-.38,9.49-.35q2.75,0,11-.4,6.84-.35,11-.2,36.7,2.25,110.28,0,73.18-2.25,110.21.1,2.49.11,8.45.75,5.4.56,8.37.55H423q3.3,0,9.58-.3,6.6-.25,9.58-.3,104.88-4.69,238.52-.5,3.38,0,10.06.55t10.06.55H937.4q31.71,2.05,98-.3,65.69-2.35,97.4-.2,10.31.49,27.69.5Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1282.58,619.2v-4.8q-12.32.4-33.89.6-43.15.35-89.19-.8-14.49-.35-27-1-30.67-1.39-97.24.5-65.53,1.85-98.37.15-1.21,0-8.86-.65c-3.76-.3-6.84-.45-9.26-.45h-218c-2.09,0-5.45.07-10.06.2q-6.53.19-10.06.15-67.79-1.95-118.18-2-61.41,0-120.34,2.45-3.15,0-9.58-.35c-4.24-.27-7.44-.4-9.58-.4H338.24q-11.19.41-16.74.2-37-2.36-110.2.4-73.59,2.7-110.29.45-2.66-.11-11-.6-6.84-.45-11-.45H-2.35V621H79q12.07,0,22.06.2,23.91,1.09,55.87.25,8.13-.19,56.35-2.15Q284.38,616.45,324,621h16.43q13.68,0,41.13-.35t41.3-.35q5.23,0,18.11-.8,13.68-.9,22.62-2,49.91-3,107.87-1.4,34.21.9,109.24,4,3.38,0,10.06.45t10.06.45h218q1.86,0,9.1-.25a74.47,74.47,0,0,1,8.77,0q23,1.39,48.46.65,15.87-.45,44.6-2.3,29.7-2,44.28-2.4,25.27-.86,47.73.4l19.4,1.2q13.29.86,18.36.9Q1226.31,620.1,1282.58,619.2Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1282.58,637.6v-6.2h-97.73q-3.78,0-11.91.25t-12.48.2q-28.1-1.7-42.82-2.3-26.17-1.15-43.31.35-38.4,3-68.51,3.85-36.54,1-68.18-1-1.6-.1-9.42-.85-5.79-.56-9.42-.55h-218q-3.3,0-10.06.3t-10.06.25q-82.76-1.25-106.18-1.4-61.43-.4-108.35,1.05-16,.45-24.23.75l-108.6-.1q-3.7,0-10.95-.15T311.52,632q-22-.75-51.93-.2l-52.88,1.35q-66.74,1.75-105.14-1.7H-2.35v6.2H32.91q5,0,19.4-.65,13.92-.6,22.86-.55,3.87,0,11.91-.75,7.82-.71,11.92-.75,12.63-.1,25,.2,107.31-.11,169.78.3,8.68.26,20.85.35,94.26.19,150-1.1,11-.35,32.92-.55,81.22-1.7,163.09,2.15c2.2,0,5.56.27,10.06.7s7.81.65,10.07.65H905q65.85,1.15,121.31-1.5t83.4-2.15q14.32,0,21,.25a175,175,0,0,1,20.36,1.7q3.06.15,11.92,1,7.32.71,11.59.7Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1160.86,711.7q-12.31,0-25.84-.15-49.26.19-146.42.2l-171.23.15q-16.41.26-24.63.35-92,1.05-174.6-.25-13.44.25-20.13.25H356.11q-3.78,0-8.93-.2l-9.26-.45q-46.53-.45-74-.1-39,.45-73.25,2.6-24.15,0-31.8-.15-16.59-.35-31-1.7H-2.35v2h106.5q18.12,0,23.43.2,5.14,1.1,17.63,1,6.75-.11,18.11-.4,26.72.4,105.13-1.55,63.84-1.54,105.29.75H598q6.69,0,20.13-.2,71.8,1,163.17.35l32.92-.25q51.45-.15,153.76-.15,5.31,0,21.33.2,59.89-.15,175.41,0h117.85v-2h-33.33q-23.51-.25-38.32-.35Q1177.45,711.71,1160.86,711.7Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1136,702.65l18.6.05q42.18,0,56.34-.15l38.32.4h33.33V700.9h-32.52q-22.95-.3-37.35-.4-51.21-.25-77-.25h-26.41q-48.45.15-103.6,0l-228.21.2-139.19.15c-2.41,0-5.77,0-10.06.1q-7,.15-10.06.15H351.52q-39.43-1.65-110.2-.4-75.51,1.35-108,.4H-2.35V703h111q19.32,0,24.15.15,35.25,1.9,108.43-.3,72.69-2.25,110.28-.1,6,0,18,.25H618.14c2,0,5.34,0,10.06-.1,4.29-.1,7.65-.13,10.06-.1q70.2,1.15,156.41,0l22.78-.2q61.11-1,154.08-.7l22,.1q29.63.3,71.56.5Q1089.15,702.56,1136,702.65Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1282.58,681.25c2.74,0,3.19-.17,1.37-.4s-2.25-.39-1.53-.4l-77.2.2q-51.6-.3-118.09-.15-14.82,0-117.13.4l-26.16.1q-57.89.4-131.62.15l-170.74.5q-61.17.15-211.15-.55-133.21-.65-211.23-.15l-221.45-.3V682l221.61.25q229.92-.45,404.51.35l189-.15q65.6.19,131.77-.15l26.73-.25,118.33-.45q67.62-.2,117.13.1Q1224.3,681.66,1282.58,681.25Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1282.58,672.35V670.3H1058.63q-10.07.15-15.13.15-58.29-1.16-140.88-.7-93.37.75-140.71.9L214,670.45q-13.84-.15-20.53-.15H-2.35v2.05H221.92q21.42,0,69.15.25,8.21,0,24.63.3,120.58.35,135.24.35,76.88.11,135.64-.4l168.09-.2q94.26,0,283.27-.1,7.72,0,23.43-.2Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1282.58,662.4v-4.15H780.67q-323.44-3.11-646.33,0H-2.35v4.15l152.47.05,315.79-.75q188.3-.5,312.66.75l23.11-.1Q848,662.24,942,661.5q83.23-.35,140.87.9Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
            <path
                d="M1282.58,649.5v-4.1q-29.31,0-96,.7-65.6.71-96.27.65-4.35,0-13.21.5t-13.52.45q-57.15,1.35-136.2.85-91.53-.85-137.09-1.25-4.18,0-12.72-.35t-12.64-.35q-130-1.4-312.34-1.45-209.3.26-314.11.25H-2.35v4.1H145L455.29,648q186-.9,311.61,1.55Z"
                fill="#bc8751"
                fill-opacity="0.15"
            />
        </g>
        <g class="transition-device">
            <path
                d="M897.65,332.12a16.56,16.56,0,0,0-12.15-5.05H867.9v0L423.25,327a16.56,16.56,0,0,0-12.15,5.05,16.39,16.39,0,0,0-5.05,12.1L406,599.45a17.16,17.16,0,0,0,17.2,17.2l413,.05h49.3a17.07,17.07,0,0,0,17.15-17.2l0-255.3A16.49,16.49,0,0,0,897.65,332.12Z"
                fill="#183341"
            />
            <path d="M858.13,471.88A16.75,16.75,0,1,0,863,460,16.13,16.13,0,0,0,858.13,471.88Z" fill="#6e8993" />
            <path
                d="M417.53,471.85a2.3,2.3,0,0,0,4.6,0,2.25,2.25,0,0,0-.67-1.63,2.32,2.32,0,0,0-3.26,0A2.25,2.25,0,0,0,417.53,471.85Z"
                fill="#6e8993"
            />
        </g>
        <path class="transition-screen" d="M847,354.42H433V587.25H847Z" fill="#fff" />
    </svg>
</template>

<style lang="scss" scoped>
.studio-sequence-transition {
    transform-origin: 0 0;
}
</style>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            // isAbove: false, // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start

            baseSize: {
                width: 1280,
                height: 720
            },
            screenOrigin: {
                x: 433,
                y: 354.42
            },
            screenSize: {
                width: 414,
                height: 233
            }
        };
    },

    computed: {
        transitionStyles() {
            return {
                backgroundColor: this.state.color.start
            };
        },

        sceneStyles() {
            return {
                visibility: this.state.scene ? 'visible' : 'hidden'
            };
        },

        transitionTransformPosition() {
            let s = this.baseSize.height / this.screenSize.height;
            return {
                scale: s,
                xPercent: (-100 * s * this.screenOrigin.x) / this.baseSize.width,
                yPercent: (-100 * s * this.screenOrigin.y) / this.baseSize.height
            };
        },

        sequenceTransformPosition() {
            return {
                scale: this.screenSize.height / this.baseSize.height,
                xPercent: (100 * this.screenOrigin.x) / this.baseSize.width,
                yPercent: (100 * this.screenOrigin.y) / this.baseSize.height
            };
        }
    },

    watch: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            this._timeline = t;
            t.fromTo(
                this.$el,
                { scale: 1, xPercent: 0, yPercent: 0 },
                { duration: 0.6, ...this.transitionTransformPosition, ease: 'power1.inOut' },
                0.8
            );
            t.fromTo(
                [this.backgroundInElement, this.sequenceInElement],
                { transformOrigin: '0 0', ...this.sequenceTransformPosition },
                { duration: 0.6, transformOrigin: '0 0', scale: 1, xPercent: 0, yPercent: 0, ease: 'power1.inOut' },
                0.8
            );
            return t;
        }
    }
};
</script>
