// useTimelineControls.js
import { debounce, throttle } from 'lodash';
import { ref, computed } from 'vue';
import { calculateRelativeTime, calculateAbsoluteTime, calculateTimePosition } from '../helpers/timeCalculations';

export function useTimelineControls(
    { FRAME_RATE, FRAME_WIDTH },
    { segments, videoEndTime, updateCurrentTimeAndPos, zoomLevel, isUpdating, sequenceProps },
    simpleSeek,
    emit
) {
    const currentTimePos = ref(0);
    const currentTimeDrag = ref(false);

    const seekTime = ref(0);

    // Throttle the simpleSeek to avoid low performance on gsap
    const throttledSimpleSeek = throttle((time) => {
        simpleSeek(time);
    }, 50);

    const canvasWidth = computed(() => {
        return videoEndTime.value * FRAME_RATE * FRAME_WIDTH * zoomLevel.value;
    });

    const startCurrentTimeDrag = (event) => {
        currentTimeDrag.value = true;
    };

    const getCurrentRealTime = () => {
        return (currentTimePos.value / canvasWidth.value) * videoEndTime.value;
    };

    const onCurrentTimeDrag = (event, sliderContainer, canSplit) => {
        if (!currentTimeDrag.value) return;

        if (!event.target.hasMouseUpListener) {
            window.addEventListener('mouseup', stopCurrentTimeDrag);
            event.target.hasMouseUpListener = true;
        }

        if (!sliderContainer) return;

        const rect = sliderContainer.getBoundingClientRect();
        let newX = event.clientX - rect.left;

        newX = Math.max(0, Math.min(newX, rect.width));

        const canvasWidthValue = canvasWidth.value || 1;
        const videoEndTimeValue = videoEndTime.value || 0;

        const newTime = (newX / canvasWidthValue) * videoEndTimeValue;

        if (!isFinite(newTime)) {
            console.warn('Invalid newTime value detected:', newTime);
            return;
        }

        currentTimePos.value = newX;

        if (canSplit) {
            const timeProps = {
                segments: segments.value || [],
                playbackRate: sequenceProps.playbackRate || 1,
                videoEndTime: videoEndTimeValue
            };

            let elapsedTime = 0;
            let lastSegmentEnd = 0;
            let isWithinSegment = false;

            if (segments.value && segments.value.length > 0) {
                for (const segment of segments.value) {
                    if (!segment || typeof segment.start !== 'number' || typeof segment.end !== 'number') {
                        continue;
                    }

                    if (newTime < segment.start) {
                        break;
                    }

                    if (newTime >= segment.start && newTime <= segment.end) {
                        elapsedTime += newTime - segment.start;
                        isWithinSegment = true;
                        break;
                    } else {
                        elapsedTime += segment.end - Math.max(segment.start, lastSegmentEnd);
                    }

                    lastSegmentEnd = segment.end;
                }

                if (!isWithinSegment) {
                    const lastSegment = segments.value[segments.value.length - 1];
                    if (lastSegment && newTime > lastSegment.end) {
                        elapsedTime = segments.value.reduce((total, segment) => {
                            if (!segment || typeof segment.start !== 'number' || typeof segment.end !== 'number') {
                                return total;
                            }
                            return total + (segment.end - segment.start);
                        }, 0);
                    }
                }
            }

            const playbackRate = sequenceProps.playbackRate || 1;
            const adjustedTime = Math.round((elapsedTime / playbackRate) * 1000) / 1000;

            if (isFinite(adjustedTime) && isFinite(newTime)) {
                seekTime.value = calculateRelativeTime(newTime, timeProps);

                emit('force-current-time', newTime);
                throttledSimpleSeek(adjustedTime);
            }
        }
    };

    const stopCurrentTimeDrag = () => {
        currentTimeDrag.value = false;
        window.removeEventListener('mouseup', stopCurrentTimeDrag);
        throttledSimpleSeek.cancel();
        simpleSeek(seekTime.value);
    };

    const updateCurrentTimeAndPosition = (newTime) => {
        if (isUpdating.value) return;
        try {
            isUpdating.value = true;

            const timeProps = {
                segments: segments.value,
                playbackRate: sequenceProps.playbackRate,
                videoEndTime: videoEndTime.value
            };

            const targetTime = calculateAbsoluteTime(newTime, timeProps);
            currentTimePos.value = calculateTimePosition(targetTime, timeProps, canvasWidth.value);
        } finally {
            isUpdating.value = false;
        }
    };

    return {
        currentTimePos,
        canvasWidth,
        updateCurrentTimeAndPosition,
        startCurrentTimeDrag,
        onCurrentTimeDrag,
        stopCurrentTimeDrag,
        getCurrentRealTime,
        currentTimeDrag
    };
}
