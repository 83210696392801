<template>
    <div class="ui-content-row ui-captions-editor-style-text-format">
        <label>{{ $t('captions.editor.text.format.label') }}</label>
        <div class="captions-editor-flex-list">
            <button
                class="ui-simple-button"
                :class="{ selected: config.bold }"
                :title="$t('Bold')"
                @click="updateConfigAttribute('bold', !config.bold)"
            >
                <span class="visually-hidden">{{ $t('Bold') }}</span>
                <fa-icon class="icon" icon="fa-solid fa-bold" />
            </button>
            <button
                class="ui-simple-button"
                :class="{ selected: config.italic }"
                :title="$t('Italic')"
                @click="updateConfigAttribute('italic', !config.italic)"
            >
                <span class="visually-hidden">{{ $t('Italic') }}</span>
                <fa-icon class="icon" icon="fa-solid fa-italic" />
            </button>
            <button
                class="ui-simple-button"
                :class="{ selected: config.uppercase }"
                :title="$t('Uppercase')"
                @click="updateConfigAttribute('uppercase', !config.uppercase)"
            >
                <span class="visually-hidden">{{ $t('Uppercase') }}</span>
                <fa-icon class="icon" icon="fa-solid fa-font-case" />
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { useCaptions } from '@/js/videos/composables/caption/useCaptions.ts';
import { useCaptionsEditor } from '@/js/videos/composables/caption/useCaptionsEditor.ts';

const { editorState } = useCaptionsEditor();
const { config, updateConfigAttribute } = useCaptions(
    editorState.value.storeModulePath,
    editorState.value.type,
    editorState.value.parentStoreModulePath
);
</script>
