import ToastNotificationService from '@/js/application/services/Notification/ToastNotificationService.js';
import i18n from '@/libs/i18n.js';

export function useNotification(type, useTranslation = true) {
    let notifyService;
    const { t } = i18n.global;

    switch (type) {
        case 'toast':
            notifyService = new ToastNotificationService();
            break;
        default:
            notifyService = new ToastNotificationService();
            break;
    }

    const loading = (message, duration) => {
        message = useTranslation ? t(message) : message;
        notifyService.loading(message, duration);
    };

    const success = (message, duration) => {
        message = useTranslation ? t(message) : message;
        notifyService.success(message, duration);
    };

    const error = (message, duration) => {
        message = useTranslation ? t(message) : message;
        notifyService.error(message, duration);
    };

    const warning = (message, duration) => {
        message = useTranslation ? t(message) : message;
        notifyService.warning(message, duration);
    };

    const info = (message, duration) => {
        message = useTranslation ? t(message) : message;
        notifyService.info(message, duration);
    };

    return {
        loading,
        success,
        error,
        warning,
        info
    };
}
