import { ref } from 'vue';
import { gsap } from 'gsap';

export function useScrolling(container, currentTimePos, elementsAreDragging) {
    const scrollAnimation = ref(null);
    const isScrolling = ref(false);
    const edgeScrollAnimation = ref(null);

    const startContinuousScroll = () => {
        if (scrollAnimation.value) return;
        const animate = () => {
            if (container.value && !elementsAreDragging.value) {
                const containerWidth = container.value.clientWidth;
                const targetScrollLeft = currentTimePos.value - containerWidth / 2;

                gsap.to(container.value, {
                    scrollLeft: targetScrollLeft,
                    duration: 0.01,
                    ease: 'none',
                    onStart: () => {
                        isScrolling.value = true;
                    },
                    onComplete: () => {
                        isScrolling.value = false;
                    }
                });
            }

            scrollAnimation.value = requestAnimationFrame(animate);
        };

        scrollAnimation.value = requestAnimationFrame(animate);
    };

    const stopContinuousScroll = () => {
        if (scrollAnimation.value) {
            cancelAnimationFrame(scrollAnimation.value);
            scrollAnimation.value = null;
        }
        isScrolling.value = false;
    };

    const scrollToCurrentTime = () => {
        if (container.value && !elementsAreDragging.value) {
            const containerWidth = container.value.clientWidth;
            const targetScrollLeft = currentTimePos.value - containerWidth / 2;

            gsap.to(container.value, {
                scrollLeft: targetScrollLeft,
                duration: 0.3,
                ease: 'power2.out'
            });
        }
    };

    // Edge scroll
    const handleEdgeScroll = (mouseX) => {
        if (!container.value) return { isScrolling: false, scrollAmount: 0 };

        // Stop the previous edge scroll if it exists
        if (edgeScrollAnimation.value) {
            gsap.killTweensOf(container.value, 'scrollLeft');
            cancelAnimationFrame(edgeScrollAnimation.value);
            edgeScrollAnimation.value = null;
        }

        const containerRect = container.value.getBoundingClientRect();
        const edgeThreshold = 60; // Zone de détection en pixels près des bords
        const scrollSpeed = 2; // Vitesse de défilement

        // Capture la position Y de la souris
        const mouseY = window.event ? window.event.clientY : null;

        // Calculate the distance to the edges
        const distanceToLeftEdge = mouseX - containerRect.left;
        const distanceToRightEdge = containerRect.right - mouseX;

        let scrollDirection = 0; // 0: pas de défilement, -1: gauche, 1: droite
        let scrollFactor = 0;

        const animateEdgeScroll = () => {
            if (!container.value) return;

            let scrollAmount = 0;

            // Scroll to the left if close to the left edge
            if (distanceToLeftEdge < edgeThreshold) {
                const factor = 1 - distanceToLeftEdge / edgeThreshold;
                scrollAmount = -scrollSpeed * factor;
                scrollDirection = -1;
                scrollFactor = factor;
                isScrolling.value = true;
            }
            // Scroll to the right if close to the right edge
            else if (distanceToRightEdge < edgeThreshold) {
                const factor = 1 - distanceToRightEdge / edgeThreshold;
                scrollAmount = scrollSpeed * factor;
                scrollDirection = 1;
                scrollFactor = factor;
                isScrolling.value = true;
            }
            // No scrolling if far from the edges
            else {
                isScrolling.value = false;
                return;
            }

            // Apply the scrolling
            container.value.scrollLeft += scrollAmount;
            edgeScrollAnimation.value = requestAnimationFrame(animateEdgeScroll);
        };

        // Start the animation if we are close to an edge
        if (distanceToLeftEdge < edgeThreshold || distanceToRightEdge < edgeThreshold) {
            edgeScrollAnimation.value = requestAnimationFrame(animateEdgeScroll);
        }

        return {
            isScrolling: isScrolling.value,
            scrollDirection,
            scrollFactor,
            containerRect,
            edgeThreshold,
            mouseY // Ajouter la position Y de la souris
        };
    };

    const stopEdgeScroll = () => {
        if (edgeScrollAnimation.value) {
            cancelAnimationFrame(edgeScrollAnimation.value);
            edgeScrollAnimation.value = null;
        }
        isScrolling.value = false;
    };

    return {
        startContinuousScroll,
        stopContinuousScroll,
        scrollToCurrentTime,
        handleEdgeScroll,
        stopEdgeScroll,
        isScrolling
    };
}
