<template>
    <template v-for="category in categories" :key="category">
        <voice-over-captions
            v-if="category === voiceOverCategory"
            :ref="
                (el) => {
                    captionsRef[category] = el;
                }
            "
            :store-module-path="`settings/tts/${category}`"
            parent-store-module-path="settings/tts"
            @update="updateTimeline"
        />
    </template>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import gsap from 'gsap';
import VoiceOverCaptions from '@/js/video-studio/components/captions/VoiceOverCaptions.vue';
import { TTS } from '@/js/video-studio/constants';

const emit = defineEmits<{
    (e: 'update'): void;
}>();

const captionsRef = ref<Record<string, any>>([]);

const categories = TTS.CATEGORIES;

const store = useStore();

const voiceOverCategory = computed(() => store.state.settings.tts.category);

let timeline: GSAPTimeline = gsap.timeline();

const buildTimeline = (): void => {
    timeline = gsap.timeline({ paused: true });

    categories.forEach((category: string) => {
        if (captionsRef.value[category]) {
            timeline.add(captionsRef.value[category].getTimeline().paused(false), 0);
        }
    });
};

const updateTimeline = (): void => {
    buildTimeline();
    emit('update');
};

const getTimeline = (): GSAPTimeline => {
    buildTimeline();
    return timeline;
};

defineExpose<{
    getTimeline: () => GSAPTimeline;
}>({
    getTimeline
});
</script>
