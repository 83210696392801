import { ref } from 'vue';
import { useStore } from 'vuex';
import FoldersDataService from '@/js/application/services/folders/FoldersDataService.js';
import FolderDTO from '@/js/dto/FolderDTO.js';
import UserDataService from '@/js/application/services/user/UserDataService.js';
import usePagination from '@/js/composables/usePagination.js';
import ClientDataService from '@/js/application/services/client/ClientDataService.js';

// it is outside the function so state is kept between components
const currentActiveFolder = ref(null); // public ID

export function useFolders() {
    const store = useStore();

    const { page, totalPages, isLoading, paginationRequest, resetData } = usePagination();

    const folders = ref([]);

    const fetchFolders = async (clientId, options) => {
        if (!clientId) {
            throw new Error('Client id is required while fetching folders');
        }

        let paginatedResult = await paginationRequest(() =>
            new FoldersDataService(store).fetchFoldersData(clientId, options)
        );
        if (paginatedResult) addFolders(paginatedResult.data);
    };

    const fetchUserFolders = async (userId, options) => {
        if (!userId) {
            throw new Error('User ID is required while fetching folders');
        }

        let paginatedResult = await paginationRequest(() => new UserDataService(store).getFolders(userId, options));
        if (paginatedResult) addFolders(paginatedResult.data);
    };

    const fetchClientFoldersGrant = async (clientId, options) => {
        let paginatedResult = await paginationRequest(() =>
            new ClientDataService(store).fetchClientFoldersGrant(clientId, options)
        );
        if (paginatedResult) addFolders(paginatedResult.data);
    };

    const addFolders = (items) => {
        const folderDTOs = items.map((item) => new FolderDTO(item));
        folders.value = folders.value.concat(folderDTOs);

        const favouritesToInitialize = folderDTOs.filter((folder) => folder.isFavourite);
        if (favouritesToInitialize.length > 0) {
            store.dispatch('favourites/initializeFavourites', favouritesToInitialize);
        }
    };

    const resetDataForFolders = () => {
        folders.value = [];
        resetData();
    };

    const addFavourite = async (folderUuid) => {
        if (!folderUuid) {
            throw new Error(' folder uuid is required while adding a favourite folder');
        }

        new FoldersDataService(store).addFavourite(folderUuid);
    };

    const removeFavourite = async (folderUuid) => {
        if (!folderUuid) {
            throw new Error('folder uuid is required while removing a favourite folder');
        }

        new FoldersDataService(store).removeFavourite(folderUuid);
    };

    return {
        currentActiveFolder,
        folders,
        page,
        totalPages,
        isLoading,
        fetchFolders,
        fetchUserFolders,
        fetchClientFoldersGrant,
        resetDataForFolders,
        addFavourite,
        removeFavourite
    };
}
