<template>
    <sequence-visuals-captions ref="sequenceVisualsCaptionsRef" :seq-id="seqId" @update="updateTimeline" />
    <sequence-background-captions ref="sequenceBackgroundCaptionsRef" :seq-id="seqId" @update="updateTimeline" />
    <sequence-voice-over-captions ref="sequenceVoiceOverCaptionsRef" :seq-id="seqId" @update="updateTimeline" />
    <sequence-audio-captions ref="sequenceAudioCaptionsRef" :seq-id="seqId" @update="updateTimeline" />
</template>

<script setup lang="ts">
import { defineProps, ref } from 'vue';
import gsap from 'gsap';
import SequenceVisualsCaptions from '@/js/video-studio/components/captions/sequence-captions/SequenceVisualsCaptions.vue';
import SequenceBackgroundCaptions from '@/js/video-studio/components/captions/sequence-captions/SequenceBackgroundCaptions.vue';
import SequenceVoiceOverCaptions from '@/js/video-studio/components/captions/sequence-captions/SequenceVoiceOverCaptions.vue';
import SequenceAudioCaptions from '@/js/video-studio/components/captions/sequence-captions/SequenceAudioCaptions.vue';

const { seqId } = defineProps<{
    seqId: string;
}>();

const emit = defineEmits<{
    (e: 'update'): void;
}>();

const sequenceVisualsCaptionsRef = ref<typeof SequenceVisualsCaptions | null>(null);
const sequenceBackgroundCaptionsRef = ref<typeof SequenceBackgroundCaptions | null>(null);
const sequenceVoiceOverCaptionsRef = ref<typeof SequenceVoiceOverCaptions | null>(null);
const sequenceAudioCaptionsRef = ref<typeof SequenceAudioCaptions | null>(null);

let timeline: GSAPTimeline = gsap.timeline();

const buildTimeline = (): void => {
    timeline.seek(0);
    timeline.clear();
    timeline.kill();
    timeline = gsap.timeline({ paused: true });

    if (sequenceVisualsCaptionsRef.value) {
        timeline.add(sequenceVisualsCaptionsRef.value.getTimeline().paused(false), 0);
    }

    if (sequenceBackgroundCaptionsRef.value) {
        timeline.add(sequenceBackgroundCaptionsRef.value.getTimeline().paused(false), 0);
    }

    if (sequenceVoiceOverCaptionsRef.value) {
        timeline.add(sequenceVoiceOverCaptionsRef.value.getTimeline().paused(false), 0);
    }

    if (sequenceAudioCaptionsRef.value) {
        timeline.add(sequenceAudioCaptionsRef.value.getTimeline().paused(false), 0);
    }
};

const updateTimeline = (): void => {
    buildTimeline();
    emit('update');
};

const getTimeline = (): GSAPTimeline => {
    buildTimeline();
    return timeline;
};

defineExpose<{
    getTimeline: () => GSAPTimeline;
}>({
    getTimeline
});
</script>
