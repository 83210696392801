<!--
  -- Component name: ScrollColor
  -- Type: Transition
  -- Uses: color.start
  -- Tags: scroll
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        :viewBox="'0 0 ' + format.width + ' ' + format.height"
    >
        <defs>
            <mask :id="svgMaskID">
                <rect x="0" y="0" :width="format.width" :height="format.height" fill="#fff" />
                <rect class="scroll-color-mask" x="0" y="0" :width="format.width" :height="format.height"></rect>
            </mask>
        </defs>
        <rect :width="format.width" :height="format.height" :fill="state.color.start" :mask="svgMask" />
    </svg>
</template>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            // isAbove: false, // The transition component is placed above the in and out elements
            inElementsAutoAppear: false
        };
    },

    computed: {
        svgMaskID() {
            return this.containerID + '-scroll-color-mask';
        },

        svgMask() {
            return 'url(#' + this.svgMaskID + ')';
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            this._timeline = t;
            t.fromTo(
                '#' + this.svgMaskID + ' .scroll-color-mask',
                { scale: 1 },
                { scale: 0.8, transformOrigin: '50% 50%', duration: 0.3 }
            );
            if (this.previous) {
                t.fromTo(
                    [this.backgroundOutElement, this.sequenceOutElement],
                    { scale: 1 },
                    { scale: 0.8, duration: 0.3, immediateRender: false, overwrite: false },
                    '<'
                );
                t.fromTo(
                    ['#' + this.svgMaskID + ' .scroll-color-mask', this.backgroundOutElement, this.sequenceOutElement],
                    { yPercent: 0 },
                    { yPercent: -100, duration: 0.7, ease: 'power2.out', immediateRender: false, overwrite: false }
                );
            }

            t.add(
                [
                    gsap.set([this.backgroundInElement, this.sequenceInElement], { autoAlpha: 1 }),
                    gsap.fromTo(
                        [this.backgroundInElement, this.sequenceInElement],
                        { yPercent: 100 },
                        { yPercent: 0, duration: 0.7, ease: 'power2.out', overwrite: false }
                    )
                ],
                '<'
            );
            t.fromTo(
                ['#' + this.svgMaskID + ' .scroll-color-mask', this.backgroundInElement, this.sequenceInElement],
                { scale: 0.8 },
                { scale: 1, duration: 0.2, overwrite: false }
            );

            return t;
        }
    }
};
</script>
