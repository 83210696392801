<template>
    <header class="ui-captions-editor-header">
        <h5 class="ui-captions-editor-title">{{ $t('Captions') }}</h5>
        <button class="ui-captions-editor-close ui-action-button" @click="closeEditor">
            <span class="visually-hidden">{{ $t('Close') }}</span>
            <fa-icon class="icon" icon="fa-solid fa-xmark" />
        </button>
    </header>
</template>

<script setup lang="ts">
import { useCaptionsEditor } from '@/js/videos/composables/caption/useCaptionsEditor.ts';

const { closeEditor } = useCaptionsEditor();
</script>
