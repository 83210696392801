import { computed } from 'vue';

export function useSegmentStyles({ sequenceProps, segments, canvasWidth }) {
    const MIN_SEGMENT_SIZE_PX = 14;
    const FRAME_BORDER = 2;
    const FRAME_HEIGHT = 58;
    const GAP_COLOR = 'rgba(44, 52, 58, 0.76)';
    const SMALL_SEGMENT_WIDTH = 14;

    const getHandlePos = (time, isEndHandle = false) => {
        const containerWidth = canvasWidth.value;
        const position = (time / sequenceProps.endTime) * containerWidth;

        if (isEndHandle) {
            return position - MIN_SEGMENT_SIZE_PX;
        }

        return position;
    };

    const getGapStyle = (index) => {
        if (index >= segments.value.length - 1) return {};
        
        const currentSegment = segments.value[index];
        const nextSegment = segments.value[index + 1];
        const isCurrentSmall = isSmallSegment(currentSegment);
        const isNextSmall = isSmallSegment(nextSegment);
        
        let leftOffset, gapWidth;
        
        if (isCurrentSmall) {
            const currentStartPos = getHandlePos(currentSegment.start);
            const currentEndPos = getHandlePos(currentSegment.end);
            leftOffset = (currentStartPos + currentEndPos) / 2 + SMALL_SEGMENT_WIDTH / 2;
        } else {
            leftOffset = getHandlePos(currentSegment.end);
        }
        
        if (isNextSmall) {
            const nextStartPos = getHandlePos(nextSegment.start);
            const nextEndPos = getHandlePos(nextSegment.end);
            const nextCenterPos = (nextStartPos + nextEndPos) / 2;
            gapWidth = nextCenterPos - leftOffset - SMALL_SEGMENT_WIDTH / 2;
        } else {
            gapWidth = getHandlePos(nextSegment.start) - leftOffset;
        }

        gapWidth = Math.max(0, gapWidth);

        return {
            top: `-${FRAME_BORDER}px`,
            left: `${leftOffset}px`,
            width: `${gapWidth}px`,
            height: `${FRAME_HEIGHT + FRAME_BORDER * 2}px`,
            backgroundColor: GAP_COLOR
        };
    };

    const getInitialGapStyle = computed(() => {
        if (!segments.value.length) return {};
        
        const firstSegment = segments.value[0];
        let gapWidth;
        
        if (isSmallSegment(firstSegment)) {
            const startPos = getHandlePos(firstSegment.start);
            const endPos = getHandlePos(firstSegment.end);
            const centerPos = (startPos + endPos) / 2;
            gapWidth = centerPos - SMALL_SEGMENT_WIDTH / 2;
        } else {
            gapWidth = getHandlePos(firstSegment.start);
        }

        return {
            top: `-${FRAME_BORDER}px`,
            left: '0',
            width: `${Math.max(0, gapWidth)}px`,
            height: `${FRAME_HEIGHT + FRAME_BORDER * 2}px`,
            backgroundColor: GAP_COLOR
        };
    });

    const getFinalGapStyle = computed(() => {
        if (!segments.value.length) return {};
        
        const lastSegment = segments.value[segments.value.length - 1];
        let leftOffset;
        
        if (isSmallSegment(lastSegment)) {
            const startPos = getHandlePos(lastSegment.start);
            const endPos = getHandlePos(lastSegment.end);
            leftOffset = (startPos + endPos) / 2;
        } else {
            leftOffset = getHandlePos(lastSegment.end);
        }
        
        const gapWidth = Math.max(0, canvasWidth.value - leftOffset);

        return {
            top: `-${FRAME_BORDER}px`,
            left: `${leftOffset}px`,
            width: `${gapWidth}px`,
            height: `${FRAME_HEIGHT + FRAME_BORDER * 2}px`,
            backgroundColor: GAP_COLOR
        };
    });

    const recalculateGapStyles = () => {
        segments.value.forEach((segment, index) => {
            if (index < segments.value.length - 1) {
                segment.gapStyle = getGapStyle(index);
            }
        });
    };

    function isSmallSegment(segment) {
        const startPos = getHandlePos(segment.start);
        const endPos = getHandlePos(segment.end);
        return endPos - startPos < MIN_SEGMENT_SIZE_PX * 2;
    }

    return {
        getHandlePos,
        getGapStyle,
        getInitialGapStyle,
        getFinalGapStyle,
        MIN_SEGMENT_SIZE_PX,
        recalculateGapStyles
    };
}
