import { Sound } from '@/js/video-studio/constants/index.js';

export default {
    data() {
        return {
            audioContext: null,
            gainNode: null,
            sourceNode: null
        };
    },

    methods: {
        setAmplification(element, value) {
            if (!this.audioContext) {
                this.audioContext = new (window.AudioContext || window.webkitAudioContext)();
                this.gainNode = this.audioContext.createGain();
            }

            if (element !== this.sourceNode?.mediaElement) {
                this.disconnectNodes();

                this.sourceNode = this.audioContext.createMediaElementSource(element);
                this.sourceNode.connect(this.gainNode);
                this.gainNode.connect(this.audioContext.destination);
            }

            if (this.audioContext.state === 'suspended') this.audioContext.resume();
            if (this.gainNode) {
                this.gainNode.gain.value = Math.max(Sound.VOLUME_DEFAULT, value);
            }
        },

        disconnectNodes() {
            this.sourceNode?.disconnect();
            this.gainNode?.disconnect();
        }
    },

    beforeUnmount() {
        this.disconnectNodes();
        this.audioContext?.close();
    }
};
