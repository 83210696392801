<template>
    <ui-card :id="id" :class="'ui-card-' + id" :title="$t('Template')">
        <template #header-icon>
            <fa-icon class="icon" icon="fa-solid fa-clapperboard-play" />
        </template>
        <div v-if="isCTE" class="video-template-thumbnail ui-card-row">
            <ui-file-selector
                id="template-thumbnail"
                :accept="['image/png', 'image/jpeg', 'image/jpg']"
                icon="fa-regular fa-image"
                :label="$t('Thumbnail')"
                file-type="templateThumbnail"
                @update-file="saveThumbnailFile"
                @remove-file="removeThumbnail"
            />
        </div>
        <div v-if="isCTE" class="video-template-language ui-card-row">
            <label>{{ $t('Language') }}</label>
            <ui-dropdown
                id="video-template-language"
                :select="true"
                v-model="videoTemplateLanguage"
                :disabled="readOnly"
            >
                <ui-dropdown-item
                    v-for="lang in availableLanguages"
                    :key="lang.value"
                    :value="lang.value"
                    :label="lang.label"
                />
            </ui-dropdown>
        </div>
        <div class="video-template-categories ui-card-row">
            <label>{{ $t('Categories') }}</label>
            <ui-dropdown
                id="video-template-categories"
                :select="true"
                :multiple="true"
                v-model="videoTemplateCategories"
                menu-strategy="fixed"
                :disabled="readOnly"
            >
                <template #dropdown-menu-top>
                    <ui-text-input
                        id="template-categories-filter"
                        type="search"
                        :placeholder="$t('Search')"
                        :show-label="false"
                        icon="search-icon"
                        v-model="videoTemplateCategoryFilter"
                    />
                </template>
                <ui-dropdown-item
                    v-for="category in filteredAvailableCategories"
                    :key="category.name"
                    :value="category.id"
                    :label="getTemplateCategoryLabel($t, templateType, category.name, $store.state.ui.organizationName)"
                >
                    <template #dropdown-menu-item-label>
                        <svg-icon
                            v-if="templateType === 'templates'"
                            :icon="`template-category-${category.name}-icon`"
                        />
                        {{
                            getTemplateCategoryLabel($t, templateType, category.name, $store.state.ui.organizationName)
                        }}
                    </template>
                </ui-dropdown-item>
            </ui-dropdown>
        </div>
        <ThemeSelector v-if="sequenceMode && isCTE" v-model="videoTemplateThemes" :disabled="readOnly" />
        <fieldset class="video-template-formats ui-card-row">
            <legend>{{ $t('Compatible formats') }}</legend>
            <div class="ui-format-selection">
                <label
                    v-for="(format, index) in availableTemplateFormats"
                    :key="format.ratioString"
                    :id="formatElementIds[index]"
                    class="video-format"
                    :for="formatInputIds[index]"
                >
                    <input
                        type="checkbox"
                        :id="formatInputIds[index]"
                        name="video-template-format"
                        class="video-format-input"
                        :checked="videoTemplateFormats.includes(format.ratioString)"
                        :value="format.ratioString"
                        :disabled="readOnly"
                        @change="handleTemplateFormatsChange($event, format.ratioString)"
                    />
                    <svg-icon :icon="'video-format-' + format.ratioString + '-icon'" class="video-format-icon" />
                    <span class="visually-hidden">{{ format.label }}</span>
                    <div class="video-format-selected">
                        <fa-icon class="icon" icon="fa-solid fa-check" />
                    </div>
                </label>
            </div>
        </fieldset>

        <ui-text-input
            id="video-template-description"
            class="ui-card-row"
            :textarea="true"
            :label="$t('Description')"
            :horizontal="false"
            :model-value="videoTemplateDescription"
            :disabled="readOnly"
            @[textInputInputEvent]="_debouncedHandleDescriptionInput"
        />
    </ui-card>
</template>

<script>
import { useAuth } from '@/js/composables/useAuth';
import { mapState, mapGetters, mapMutations } from 'vuex';
import _debounce from 'lodash/debounce';
import _isEqual from 'lodash/isEqual';
import * as UiComponents from '../../../../../components';
import { UI_TEXT_INPUT_INPUT } from '@/js/components/UiTextInput.vue';
import { ModifiesHistory, SavesVideo } from '../../../../mixins';
import { getTemplateCategoryLabel } from '@/js/utils.js';
import ThemeSelector from '../selectors/theme/ThemeSelector.vue';

const UPDATE_INPUT_DELAY = 1500;

export default {
    mixins: [ModifiesHistory, SavesVideo],

    components: {
        ...UiComponents,
        ThemeSelector
    },

    props: {
        id: {
            type: String,
            default: 'settings-template'
        }
    },

    setup() {
        const { isCTE } = useAuth();
        return { isCTE };
    },

    data() {
        return {
            textInputInputEvent: UI_TEXT_INPUT_INPUT,
            templateType: '',
            videoTemplateCategoryFilter: ''
        };
    },

    computed: {
        ...mapState({
            readOnly: (state) => state.ui.readOnly,
            sequenceMode: (state) => state.ui.sequenceMode,
            shortLanguage: (state) => state.ui.shortLanguage,
            availableTemplateLanguages: (state) => state.ui.availableTemplateLanguages,
            defaultTemplateCategory: (state) => state.ui.defaultTemplateCategory,
            availableTemplateCategories: (state) => state.ui.availableTemplateCategories,
            availableThemes: (state) => state.ui.availableThemes,
            availableTemplateFormats: (state) => state.ui.availableTemplateFormats,
            state: (state) => state.settings
        }),

        ...mapGetters({
            currentFormat: 'display/formatRatioString'
        }),

        availableLanguages() {
            return this.availableTemplateLanguages
                .map((language) => ({ value: language, label: this.$t('studio.template_languages.' + language) }))
                .sort((languageA, languageB) => languageA.label.localeCompare(languageB.label, this.shortLanguage));
        },

        videoTemplateLanguage: {
            get() {
                return this.state.language;
            },
            set(value) {
                if (this.availableTemplateLanguages.includes(value)) {
                    this.startHistoryStep();
                    this.setTemplateLanguage(value);
                    this.saveVideo();
                }
            }
        },

        availableCategories() {
            // Note: This computed property is just an alias since
            // alphabetical sorting has been removed
            return [...this.availableTemplateCategories];
        },

        filteredAvailableCategories() {
            const search = this.videoTemplateCategoryFilter.trim().toLowerCase();

            if (!search) return this.availableCategories;

            return this.availableCategories.filter((category) =>
                getTemplateCategoryLabel(
                    this.$t,
                    this.templateType,
                    category.name,
                    this.$store.state.ui.organizationName
                )
                    .toLowerCase()
                    .includes(search)
            );
        },

        availableCategoryIds() {
            return this.availableTemplateCategories.map((category) => category.id);
        },

        videoTemplateCategories: {
            get() {
                return this.state.categories;
            },
            set(categories) {
                categories = categories.filter((category) => this.availableCategoryIds.includes(category));
                if (!categories.length)
                    categories = [
                        this.availableTemplateCategories.find(
                            (category) => category.name == this.defaultTemplateCategory
                        )?.id
                    ];
                if (!_isEqual(categories, this.videoTemplateCategories)) {
                    this.startHistoryStep();
                    this.setTemplateCategories(categories);
                    this.saveVideo();
                }
            }
        },

        availableFormats() {
            return this.availableTemplateFormats.map((format) => format.ratioString);
        },

        formatElementIds() {
            return this.availableFormats.map((format) => 'video-template-format-' + format);
        },

        formatInputIds() {
            return this.availableFormats.map((format) => 'video-template-format-' + format + '-input');
        },

        videoTemplateFormats() {
            return this.state.compatibleFormats;
        },

        videoTemplateDescription: {
            get() {
                return this.state.description;
            },
            set(value) {
                if (value !== this.state.description) {
                    this.startHistoryStep();
                    this.setTemplateDescription(value);
                    this.saveVideo();
                }
            }
        },

        videoTemplateThemes: {
            get() {
                return this.state.themes || [];
            },
            set(themes) {
                themes = themes.filter((theme) => this.availableThemeIds.includes(theme));
                if (!_isEqual(themes, this.videoTemplateThemes)) {
                    this.startHistoryStep();
                    this.setTemplateThemes(themes);
                    this.saveVideo();
                    console.log('themes', themes);
                }
            }
        },

        availableThemeIds() {
            return this.availableThemes.map((theme) => theme.id);
        }
    },

    watch: {
        //
    },

    methods: {
        ...mapMutations({
            setTemplateLanguage: 'settings/setLanguage',
            setTemplateCategories: 'settings/setCategories',
            setTemplateCompatibleFormats: 'settings/setCompatibleFormats',
            setTemplateDescription: 'settings/setDescription',
            setFileToType: 'ui/files/setFileToType',
            removeFileOfType: 'ui/files/removeFileOfType',
            setTemplateThemes: 'settings/setThemes'
        }),

        handleTemplateFormatsChange(event, format) {
            let formats = [...this.videoTemplateFormats];

            if (event.target.checked && this.availableFormats.includes(format)) {
                formats.push(format);
            } else if (formats.length == 1) {
                formats = [this.currentFormat];
                if (format === this.currentFormat) event.target.checked = true;
            } else {
                formats.splice(formats.indexOf(format), 1);
            }

            if (!_isEqual(formats, this.videoTemplateFormats)) {
                this.startHistoryStep();
                this.setTemplateCompatibleFormats(formats);
                this.saveVideo();
            }
        },

        handleDescriptionInput(value) {
            this.videoTemplateDescription = value;
        },

        saveThumbnailFile(data) {
            this.startHistoryStep();
            this.setFileToType({ type: 'templateThumbnail', data });
            this.saveVideo();
        },

        removeThumbnail() {
            this.startHistoryStep();
            this.removeFileOfType('templateThumbnail');
            this.saveVideo();
        },

        getTemplateCategoryLabel
    },

    created() {
        this._debouncedHandleDescriptionInput = _debounce(this.handleDescriptionInput, UPDATE_INPUT_DELAY);
        this.templateType = this.sequenceMode ? 'sequenceTemplates' : 'templates';
    },

    mounted() {
        //
    }
};
</script>
