import { useStore } from 'vuex';
import { computed, ComputedRef, ref, Ref, WritableComputedRef } from 'vue';
import { CaptionConfig, Captions, CaptionUnit, RelativeCaptionUnit } from '@/js/videos/types/captions.ts';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import { useMedia } from '@/js/videos/composables/useMedia.js';
import Duration from '@/js/video-studio/constants/duration.js';
import { Segment } from '@/js/videos/types/segments.ts';
import { Palette } from '@/js/videos/types/palette.ts';
import { ComponentPosition, ComponentSize } from '@/js/video-studio/types.ts';

export function useCaptions(
    storeModulePath: string,
    type?: string,
    parentStoreModulePath?: string,
    sequenceId?: string
) {
    const CAPTION_DEFAULT_ANIMATION: string = 'CaptionsRetroAnimation';
    const WORDS_NUMBER_LIST: number[] = [1, 3, 6];
    const DEFAULT_WORDS_NUMBER: number = 6;
    const WORDS_BY_LINE: number = 3;

    const store = useStore();
    const { saveHistoryStep } = useHistory(store);

    const {
        mediaState,
        segments: mediaSegments,
        playbackRate,
        startTime: elementStartTime,
        endTime: elementEndTime
    } = useMedia(storeModulePath);

    const commitModulePath = computed<string>(() => {
        if (type === 'background' || type === 'audio') {
            return parentStoreModulePath + '/newCaptions';
        } else {
            return storeModulePath + '/newCaptions';
        }
    });

    const globalStartTime = computed(() => {
        if (type === 'visual' || type === 'voiceover') {
            return store.getters[`${parentStoreModulePath}/start`];
        }

        if (type === 'audio') {
            return store.getters[`${parentStoreModulePath}/trackStart`];
        }

        return elementStartTime.value;
    });

    const globalEndTime = computed(() => {
        let endTiming: number | null = elementEndTime.value;

        if (type === 'visual') {
            endTiming = store.getters[`${parentStoreModulePath}/end`];
        }

        let sequenceEndTiming: number | null = null;
        if (sequenceId) {
            let sequenceDuration = store.state.sequences[sequenceId]?.options.duration;
            if (sequenceDuration) {
                sequenceEndTiming = sequenceDuration;
            }
        }

        let finalEndTime: number = Duration.END_DEFAULT;

        if (endTiming && endTiming !== Duration.END_DEFAULT && sequenceEndTiming) {
            finalEndTime = Math.min(endTiming, sequenceEndTiming);
        } else if (endTiming && endTiming !== Duration.END_DEFAULT && !sequenceEndTiming) {
            finalEndTime = endTiming;
        } else if (sequenceEndTiming) {
            finalEndTime = sequenceEndTiming;
        }

        return finalEndTime;
    });

    const segments = computed(() => {
        if (type === 'audio') {
            return store.getters[`${parentStoreModulePath}/trackTimerangeSegments`];
        }

        return mediaSegments.value;
    });

    const captions: ComputedRef<Captions> = computed(() => {
        return mediaState.value.newCaptions;
    });

    const captionsList: ComputedRef<CaptionUnit[]> = computed(() => {
        return captions.value.captions;
    });

    const baseCaptions: ComputedRef<CaptionUnit[]> = computed(() => {
        return captions.value.baseCaptions;
    });

    const isCaptioning: ComputedRef<boolean> = computed(() => {
        return captions.value.status === 'processing';
    });

    const isTranslating: ComputedRef<boolean> = computed(() => {
        return captions.value.status === 'translating';
    });

    const animation = computed({
        get: () => mediaState.value.newCaptions.animation,
        set: (value) => {
            saveHistoryStep(() => {
                store.commit(`${commitModulePath.value}/updateAnimation`, value);
                handleAnimationChange();
            });
        }
    });

    const config: ComputedRef<CaptionConfig> = computed(() => mediaState.value.newCaptions.config);

    const palette: ComputedRef<Palette> = computed(() => {
        if (type === 'background' || type === 'audio') {
            return store.getters[`${parentStoreModulePath}/newCaptions/palette`];
        }

        return store.getters[`${storeModulePath}/newCaptions/palette`];
    });

    const position: WritableComputedRef<ComponentPosition> = computed({
        get: () => mediaState.value.newCaptions.position,
        set: (value) => {
            saveHistoryStep(() => {
                store.commit(`${commitModulePath.value}/updatePosition`, value);
            });
        }
    });

    const size: WritableComputedRef<ComponentSize> = computed({
        get: () => mediaState.value.newCaptions.size,
        set: (value) => {
            saveHistoryStep(() => {
                store.commit(`${commitModulePath.value}/updateSize`, value);
            });
        }
    });

    const manuallyUpdated: WritableComputedRef<boolean> = computed({
        get: () => mediaState.value.newCaptions.manuallyUpdated,
        set: (value) => {
            store.commit(`${commitModulePath.value}/updateManuallyUpdated`, value);
        }
    });

    const updateConfigAttribute = (nestedAttribute: string, value: any): void => {
        let newConfig = { ...config.value };

        const keys: string[] = nestedAttribute.split('.');
        let current: any = newConfig;

        for (let i = 0; i < keys.length - 1; i++) {
            const key = keys[i];

            if (current[key] === undefined || typeof current[key] !== 'object') {
                current[key] = {};
            }

            current = current[key];
        }

        current[keys[keys.length - 1]] = value;

        updateConfig(newConfig);
    };

    const updateConfig = (value: CaptionConfig): void => {
        saveHistoryStep(() => {
            store.commit(`${commitModulePath.value}/updateConfig`, value);
        });
    };

    const font = computed({
        get: () => {
            if (type === 'background' || type === 'audio') {
                return store.getters[`${parentStoreModulePath}/newCaptions/font`];
            }

            return store.getters[`${storeModulePath}/newCaptions/font`];
        },
        set: (value) => {
            saveHistoryStep(() => {
                store.commit(`${commitModulePath.value}/updateFont`, value);
            });
        }
    });

    const fontSize = computed({
        get: () => mediaState.value.newCaptions.fontSize,
        set: (value) => {
            saveHistoryStep(() => {
                store.commit(`${commitModulePath.value}/updateFontSize`, value);
            });
        }
    });

    const wordsNumber: WritableComputedRef<number> = computed({
        get: () => mediaState.value.newCaptions.wordsNumber,
        set: (value) => {
            saveHistoryStep(() => {
                store.commit(`${commitModulePath.value}/updateWordsNumber`, value);
                handleWordsNumberChange();
            });
        }
    });

    const setPaletteColor = (index: number, color: string): void => {
        saveHistoryStep(() => {
            store.commit(`${commitModulePath.value}/updatePaletteColor`, {
                index,
                color
            });
        });
    };

    const mergeCaptionUnits = (chunkSize: number): CaptionUnit[] => {
        if (chunkSize === 1) {
            return baseCaptions.value;
        }

        let baseList: CaptionUnit[] = baseCaptions.value.slice();
        let mergedCaptions: CaptionUnit[] = [];

        for (let i = 0; i < baseList.length; i += chunkSize) {
            const chunk = baseList.slice(i, i + chunkSize);
            /* chunk.forEach((b) => {
                b.text = b.text.replace(/ /g, '&nbsp;');
            }); */
            const line = chunk.map((b) => b.text).join(' ');
            const mergedText = addLineBreaks(line);
            const mergedStartTime = chunk[0].startTime;
            const mergedEndTime = chunk[chunk.length - 1].endTime;

            mergedCaptions.push({
                text: mergedText,
                startTime: mergedStartTime,
                endTime: mergedEndTime
            });
        }

        return mergedCaptions;
    };

    const getAnimationData = (name: string): object | null => {
        return store.getters['branding/libraries/findLibraryItem']('captionAnimations', null, name);
    };

    const getAnimationConfig = (name: string): CaptionConfig | null => {
        let animationData = getAnimationData(name);
        return animationData?.config;
    };

    const handleAnimationChange = (): void => {
        let animationConfig: CaptionConfig | null = getAnimationConfig(animation.value);
        if (animationConfig) {
            let newConfig: CaptionConfig = Object.assign({}, config.value, animationConfig);
            store.commit(`${commitModulePath.value}/updateConfig`, newConfig);
        }
    };

    const handleWordsNumberChange = (): void => {
        store.commit(`${commitModulePath.value}/updateCaptions`, mergeCaptionUnits(wordsNumber.value));
    };

    const removeCaptionUnit = (index: number): void => {
        saveHistoryStep(() => {
            store.commit(`${commitModulePath.value}/removeCaptionUnit`, index);
        });
    };

    const updateCaptionUnit = (index: number, value: CaptionUnit): void => {
        saveHistoryStep(() => {
            store.commit(`${commitModulePath.value}/updateCaptionUnit`, { index, value });
        });
    };

    const relativeCaptions: ComputedRef<RelativeCaptionUnit[]> = computed(() => {
        return (
            captionsList.value
                // .sort((a: CaptionUnit, b: CaptionUnit) => a.startTime - b.startTime)
                .map((caption: CaptionUnit, index: number): RelativeCaptionUnit => {
                    let relativeStartTime = roundTiming(calculateRelativeTime(caption.startTime));
                    let relativeEndTime = roundTiming(calculateRelativeTime(caption.endTime));
                    return {
                        ...caption,
                        relativeStartTime,
                        relativeEndTime,
                        isVisible: isVisible(caption, relativeStartTime),
                        initialIndex: index
                    };
                })
        );
    });

    const mediaDuration: ComputedRef<number> = computed(() => {
        let mediaDuration: number;

        if (type === 'audio') {
            mediaDuration = store.getters[`${parentStoreModulePath}/trackTotalDuration`];
        } else if (type === 'background') {
            mediaDuration = store.getters[`${parentStoreModulePath}/totalDuration`];
        } else {
            mediaDuration = store.getters[`${storeModulePath}/totalDuration`];
        }

        return mediaDuration;
    });

    const mediaId: ComputedRef<string> = computed(() => {
        if (type === 'audio') {
            return mediaState.value.track.src__id;
        }

        return mediaState.value.src__id;
    });

    const mediaRelativeDuration: ComputedRef<number> = computed(() => {
        return calculateRelativeTime(mediaDuration.value);
    });

    const filteredCaptions: ComputedRef<RelativeCaptionUnit[]> = computed(() => {
        return relativeCaptions.value.filter((caption) => caption.isVisible);
    });

    const resetCaptions = (): void => {
        saveHistoryStep(() => {
            store.commit(`${commitModulePath.value}/reset`);
        });
        createDefaultEmptyCaption();
    };

    const createDefaultEmptyCaption = (): void => {
        saveHistoryStep(() => {
            captions.value.captions.push({
                text: '',
                startTime: 0,
                endTime: mediaDuration.value || Duration.READING_DEFAULT
            });
        });
    };

    const isVisible = (caption: CaptionUnit, relativeStartTime: number): boolean => {
        if (globalEndTime.value === Duration.END_DEFAULT && segments.value.length === 0) {
            return true;
        }

        if (
            globalEndTime.value === Duration.END_DEFAULT &&
            segments.value.length === 1 &&
            segments.value[0].start === 0 &&
            segments.value[0].end === -1
        ) {
            return true;
        }

        const startTime = caption.startTime;
        const endTime = caption.endTime;

        let inSegment = true;

        if (segments.value.length > 0) {
            inSegment = segments.value.some((segment: Segment) => {
                return (
                    (startTime >= segment.start && startTime < segment.end) ||
                    (endTime > segment.start && endTime <= segment.end) ||
                    (startTime < segment.start && endTime > segment.end)
                );
            });
        }

        if (!globalEndTime.value || globalEndTime.value === Duration.END_DEFAULT) {
            return inSegment;
        }

        return inSegment && relativeStartTime < globalEndTime.value;
    };

    const calculateRelativeTimeBetweenSegments = (captionTime: number): number => {
        if (segments.value.length === 0) {
            return captionTime;
        }

        if (segments.value.length === 1 && segments.value[0].start === 0 && segments.value[0].end === -1) {
            return captionTime;
        }

        let accumulatedDuration: number = 0;

        for (const segment of segments.value) {
            // Si le temps est avant ce segment, on retourne le temps accumulé
            if (captionTime < segment.start) {
                return accumulatedDuration;
            }

            // Si le temps est dans ce segment
            if (captionTime <= segment.end) {
                return accumulatedDuration + (captionTime - segment.start);
            }

            // Ajouter la durée de ce segment
            accumulatedDuration += segment.end - segment.start;
        }

        // Si on arrive ici, le temps est après tous les segments
        return accumulatedDuration;
    };

    const addLineBreaks = (text: string): string => {
        const nbLines: number = wordsNumber.value / WORDS_BY_LINE;

        if (nbLines < 2) {
            return text;
        }

        const words: string[] = text.split(/\s+/);
        let result: string = '';

        for (let i = 0; i < words.length; i++) {
            result += words[i];

            if ((i + 1) % WORDS_BY_LINE === 0 && i + 1 < words.length) {
                result += '\n';
            } else if (i + 1 < words.length) {
                result += ' ';
            }
        }

        // Tips to avoid linebreaks when there is a space in captionUnit.text
        //result = result.replace(/&nbsp;/g, ' ');

        return result;
    };

    const calculateRelativeTime = (captionTime: number): number => {
        return calculateRelativeTimeBetweenSegments(captionTime) / playbackRate.value + globalStartTime.value;
    };

    const calculateBaseTimeBetweenSegments = (relativeTime: number): number => {
        if (segments.value.length === 0) {
            return relativeTime;
        }

        if (segments.value.length === 1 && segments.value[0].start === 0 && segments.value[0].end === -1) {
            return relativeTime;
        }

        let accumulatedDuration: number = 0;

        for (const segment of segments.value) {
            const segmentDuration: number = segment.end - segment.start;

            if (relativeTime <= accumulatedDuration + segmentDuration) {
                return segment.start + (relativeTime - accumulatedDuration);
            }

            accumulatedDuration += segmentDuration;
        }

        // Si on dépasse tous les segments, on retourne le temps relatif
        return relativeTime;
    };

    const calculateBaseTime = (relativeTime: number): number => {
        return calculateBaseTimeBetweenSegments((relativeTime - globalStartTime.value) * playbackRate.value);
    };

    const roundTiming = (timing: number): number => {
        return Math.round(timing * 100) / 100;
    };

    const resetOldCaptions = (): void => {
        if (type === 'audio') {
            store.commit(`${parentStoreModulePath}/setTrackCaptions`, []);
        } else if (type === 'background') {
            store.commit(`${parentStoreModulePath}/setCaptions`, []);
        } else {
            store.commit(`${storeModulePath}/setCaptions`, []);
        }
    };

    return {
        CAPTION_DEFAULT_ANIMATION,
        WORDS_NUMBER_LIST,
        DEFAULT_WORDS_NUMBER,
        commitModulePath,
        captions,
        baseCaptions,
        isCaptioning,
        isTranslating,
        font,
        fontSize,
        animation,
        config,
        wordsNumber,
        palette,
        position,
        size,
        manuallyUpdated,
        mediaRelativeDuration,
        mediaId,
        relativeCaptions,
        filteredCaptions,
        resetCaptions,
        createDefaultEmptyCaption,
        getAnimationData,
        updateConfigAttribute,
        setPaletteColor,
        removeCaptionUnit,
        updateCaptionUnit,
        calculateBaseTime,
        roundTiming,
        resetOldCaptions
    };
}
