<template>
    <main class="ui-iframe-view">
        <iframe width="100%" height="100%" :src="src" allowfullscreen></iframe>
    </main>
</template>

<script setup>
import { computed } from 'vue';
import { useAuth } from '@/js/composables/useAuth.js';
import Roles from '@/js/constants/roles.js';

const { user } = useAuth();

const urls = {
    homeFR: 'https://elearning.2emotion.com/promotion/',
    homeEN: 'https://elearning.2emotion.com/en/promotion/',
    user: 'https://elearning.2emotion.com/?userName=##UUID##'
};

const homeUrlByLang = computed(() => {
    return user.value.language === 'fr_FR' ? urls.homeFR : urls.homeEN;
});

const hasAccess = computed(() => {
    return user.value.eLearningAccess || user.value.role.id === Roles.ADMIN_ROLE_ID;
});

const src = computed(() => {
    return hasAccess.value ? urls.user.replace('##UUID##', user.value.uuid) : homeUrlByLang.value;
});
</script>
