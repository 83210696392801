<template>
    <dropdown v-model="writableWordsNumber" class="hide-chevron captions-words-dropdown">
        <template #dropdown-selected-value>
            <fa-icon class="icon" icon="fa-regular fa-list-ol" />
            <span>{{ $t(`captions.editor.words.label`) }}</span>
        </template>
        <dropdown-item
            v-for="wordNumber in WORDS_NUMBER_LIST"
            :key="wordNumber"
            :value="wordNumber"
            :class="{ selected: wordsNumber === wordNumber }"
        >
            {{ $t(`captions.editor.words.${wordNumber}`) }}
        </dropdown-item>
    </dropdown>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useCaptions } from '@/js/videos/composables/caption/useCaptions.ts';
import { useCaptionsEditor } from '@/js/videos/composables/caption/useCaptionsEditor.ts';
import DropdownItem from '@/js/components/dropdown/DropdownItem.vue';
import Dropdown from '@/js/components/dropdown/Dropdown.vue';
import { useModal } from '@/js/composables/useModal';

const { openModal } = useModal();
const { editorState } = useCaptionsEditor();
const { WORDS_NUMBER_LIST, wordsNumber, manuallyUpdated } = useCaptions(
    editorState.value.storeModulePath,
    editorState.value.type,
    editorState.value.parentStoreModulePath
);

const writableWordsNumber = computed<number>({
    get: () => wordsNumber.value,
    set: (newValue: number) => {
        if (manuallyUpdated.value) {
            openModal('CaptionsWordsNumberChangeModal', {
                acceptCallback: () => {
                    wordsNumber.value = newValue;
                    manuallyUpdated.value = false;
                }
            });
        } else {
            wordsNumber.value = newValue;
        }
    }
});
</script>
