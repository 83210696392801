<template>
    <div class="ui-content-row">
        <label>{{ $t('captions.editor.text.color.label') }}</label>
        <ui-color-selector
            id="captions-animation-text-color"
            :color="{ value: config.color }"
            :palette="brandPalette"
            :enable-other-colors="brandEnableOtherColors"
            :show-label="false"
            dropdown-container="#studio-ui"
            @[colorSelectorChangeEvent]="updateConfigAttribute('color', $event?.value)"
        />
    </div>
</template>

<script setup lang="ts">
import UiColorSelector, { UI_COLOR_SELECTOR_CHANGE } from '@/js/components/UiColorSelector.vue';
import { useCaptions } from '@/js/videos/composables/caption/useCaptions.ts';
import { useCaptionsEditor } from '@/js/videos/composables/caption/useCaptionsEditor.ts';
import { useBrand } from '@/js/videos/composables/useBrand.js';

const colorSelectorChangeEvent: string = UI_COLOR_SELECTOR_CHANGE;

const { editorState } = useCaptionsEditor();
const { config, updateConfigAttribute } = useCaptions(
    editorState.value.storeModulePath,
    editorState.value.type,
    editorState.value.parentStoreModulePath
);
const { brandPalette, brandEnableOtherColors } = useBrand();
</script>
