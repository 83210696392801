<template>
    <div
        class="caption-content"
        :style="{ height: CAPTION_HEIGHT + Dimension.PIXEL_UNIT }"
        :class="{
            active: isActive,
            'between-segments': !item.isVisible
        }"
        @click="$emit('click')"
    >
        <ui-text-input
            :textarea="true"
            :label="$t('Caption text')"
            :show-label="false"
            :model-value="item.text"
            class="caption-content-text"
            @[textInputInputEvent]="$emit('text-change', $event)"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
        />
        <button class="caption-action-button" @click="$emit('play')">
            <fa-icon icon="fa-regular fa-play" class="icon" />
        </button>
        <div class="caption-content-timing">
            <dropdown class="hide-border hide-chevron">
                <template #dropdown-selected-value>
                    {{ formatTime(item.relativeStartTime) }} -
                    {{ formatTime(item.relativeEndTime) }}
                </template>
                <div class="caption-content-timing-list">
                    <div class="caption-content-timing-item">
                        <ui-time-range-input
                            :id="`captions-editor-caption-${index}-start`"
                            class="ui-content-row"
                            v-model="item.relativeStartTime"
                            :min="minTiming"
                            :max="maxTiming"
                            :step="CAPTION_TIMING_UPDATE_STEP"
                            :horizontal="false"
                            :label="$t('captions.editor.text.start.label', [formatTime(minTiming)])"
                            @update:model-value="$emit('start-change', $event)"
                        />
                    </div>
                    <div class="caption-content-timing-item">
                        <ui-time-range-input
                            :id="`captions-editor-caption-${index}-end`"
                            class="ui-content-row"
                            v-model="item.relativeEndTime"
                            :min="minTiming"
                            :max="maxTiming"
                            :step="CAPTION_TIMING_UPDATE_STEP"
                            :horizontal="false"
                            :label="$t('captions.editor.text.end.label', [formatTime(maxTiming)])"
                            @update:model-value="$emit('end-change', $event)"
                        />
                    </div>
                </div>
            </dropdown>
        </div>
        <div class="caption-content-actions">
            <dropdown class="hide-chevron hide-border">
                <template #dropdown-selected-value>
                    <svg-icon icon="menu-icon" />
                </template>
                <button class="ui-simple-button" @click="$emit('add')">
                    <fa-icon icon="fa-regular fa-message-plus" class="icon" />
                    <span>{{ $t('Add caption') }}</span>
                </button>
                <button class="ui-simple-button" @click="$emit('remove')">
                    <fa-icon icon="fa-regular fa-trash-can" class="icon" />
                    <span>{{ $t('Remove caption') }}</span>
                </button>
            </dropdown>
        </div>
    </div>
</template>

<script setup lang="ts">
import { UI_TEXT_INPUT_INPUT } from '@/js/components/UiTextInput.vue';
import { Dimension } from '@/js/video-studio/constants';
import { conversions } from 'cte-video-studio';
import Dropdown from '@/js/components/dropdown/Dropdown.vue';
import UiTextInput from '@/js/components/UiTextInput.vue';
import UiTimeRangeInput from '@/js/components/UiTimeRangeInput.vue';

const CAPTION_HEIGHT = 70;
const CAPTION_TIMING_UPDATE_STEP: number = 0.01;
const textInputInputEvent: string = UI_TEXT_INPUT_INPUT;

defineProps<{
    item: any;
    index: number;
    isActive: boolean;
    minTiming: number;
    maxTiming: number;
}>();

defineEmits<{
    (e: 'click'): void;
    (e: 'text-change', value: string): void;
    (e: 'focus'): void;
    (e: 'blur'): void;
    (e: 'play'): void;
    (e: 'start-change', value: number): void;
    (e: 'end-change', value: number): void;
    (e: 'add'): void;
    (e: 'remove'): void;
}>();

const formatTime = (time: number): string => {
    return conversions.hundredths(time);
};
</script>
