<!--
  -- Component name: SG7
  -- Type: Transition
  -- Uses: color.start
  -- Tags: sg
  -->
<template>
    <svg
        ref="$sgLogo"
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        :viewBox="dataForView.viewBox"
        preserveAspectRatio="xMidYMid slice"
    >
        <g class="sg-logo">
            <rect class="sg-container" x="0" y="0" width="100%" height="100%" fill="#fff" />
            <rect class="sg-top" x="0" y="0" width="100%" height="50%" :fill="state.color.start" />
            <rect class="sg-bottom" x="0" :y="dataForView.size" width="100%" height="50%" fill="#000" />
        </g>
    </svg>
</template>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            isAbove: true,
            borderSize: 10,
            logoScaleDown: 0.9,
            BORDER_SIZE_RATIO: 0.03832,
            HEIGHT_RATIO: 0.43284,
            BOTTOM_RATIO: 0.54817
        };
    },

    computed: {
        isLandscapeFormat() {
            return this.format.width > this.format.height;
        },

        isPortraitFormat() {
            return this.format.width < this.format.height;
        },

        isSquareFormat() {
            return this.format.width === this.format.height;
        },

        dataForView() {
            if (this.isLandscapeFormat) {
                return {
                    size: 540,
                    viewBox: '0 0 1920 1080'
                };
            }

            if (this.isSquareFormat) {
                return {
                    size: 500,
                    viewBox: '0 0 1000 1000'
                };
            }

            if (this.isPortraitFormat) {
                return {
                    size: 960,
                    viewBox: '0 0 1080 1920'
                };
            }
        }
    },

    watch: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            const t = gsap.timeline();
            this._timeline = t;

            t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 }, 0.001);
            t.set('#' + this.containerID + ' .sg-logo', {
                transform: 'translateX(50%) translateX(-50%) translateY(50%) translateY(-50%)'
            });
            t.set('#' + this.containerID + ' .sg-container', { width: '0%', height: '0%', fillOpacity: 1 });

            t.fromTo(
                '#' + this.containerID + ' .sg-top',
                {
                    xPercent: -100
                },
                {
                    xPercent: 0,
                    duration: 0.7,
                    ease: 'power3.inOut'
                },
                1
            );

            t.fromTo(
                '#' + this.containerID + ' .sg-bottom',
                {
                    xPercent: -100
                },
                {
                    xPercent: 0,
                    duration: 0.7,
                    ease: 'power3.inOut'
                },
                1.1
            );

            t.to(
                '#' + this.containerID + ' .sg-container',
                { width: '100%', height: '100%', duration: 0.01, ease: 'none' },
                1.8
            );

            t.to('#' + this.containerID + ' .sg-top', { yPercent: -100, duration: 0.9, ease: 'power3.inOut' }, 1.9);
            t.to('#' + this.containerID + ' .sg-bottom', { yPercent: 100, duration: 0.9, ease: 'power3.inOut' }, 1.9);

            t.to('#' + this.containerID + ' .sg-container', { fillOpacity: 0, duration: 1, ease: 'none' }, 2);
            return t;
        }
    }
};
</script>
