import { ref, computed } from 'vue';

export function useAudioWaveform(canvasWidth) {
    const audioData = ref([]);
    const segmentedAudioData = ref([]);
    const isLoading = ref(false);
    const amplificationFactor = ref(5.0); // Augmentation du facteur d'amplification par défaut (était 3.5)
    const amplificationThreshold = ref(0.8); // Réduction du seuil pour amplifier plus de données (était 1)
    const MAX_CANVAS_WIDTH = 4000; // Largeur maximale pour un canvas individuel

    // Calcul du nombre de segments nécessaires
    const segmentCount = computed(() => {
        return Math.max(1, Math.ceil(canvasWidth.value / MAX_CANVAS_WIDTH));
    });

    const loadAudioData = async (audioSrc, timeRange = null) => {
        isLoading.value = true;
        try {
            const arrayBuffer = await fetchAudioBuffer(audioSrc);

            const audioContext = new (window.AudioContext || window.webkitAudioContext)({
                // Utiliser une fréquence d'échantillonnage élevée pour une meilleure qualité
                sampleRate: 48000
            });

            // Détecter si c'est un fichier MP3 avant le décodage
            const isMP3 =
                typeof audioSrc === 'string' &&
                (audioSrc.toLowerCase().endsWith('.mp3') || audioSrc.toLowerCase().includes('audio/mp3'));

            // Décoder le buffer audio
            const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

            const numberOfChannels = audioBuffer.numberOfChannels;
            const length = audioBuffer.length;
            const duration = audioBuffer.duration;
            const sampleRate = audioBuffer.sampleRate;

            // Ajuster les paramètres d'amplification en fonction du type de fichier et de ses caractéristiques
            if (isMP3) {
                // Analyser un échantillon pour déterminer les meilleurs paramètres
                const channel0Data = audioBuffer.getChannelData(0);
                const sampleSize = Math.min(10000, channel0Data.length);
                let maxValue = 0;
                let avgValue = 0;

                for (let i = 0; i < sampleSize; i++) {
                    const value = Math.abs(channel0Data[i]);
                    maxValue = Math.max(maxValue, value);
                    avgValue += value;
                }
                avgValue /= sampleSize;

                // Calculer le facteur d'amplification en fonction du rapport signal/bruit
                const signalToNoiseRatio = maxValue > 0 ? maxValue / avgValue : 1;
                const dynamicFactor = Math.min(12.0, Math.max(6.0, 8.0 * (1 + Math.log10(signalToNoiseRatio))));
                amplificationFactor.value = dynamicFactor;
                amplificationThreshold.value = 0.4; // Seuil plus bas pour capturer plus de détails
            }

            // Calculer les indices de début et de fin si une plage temporelle est spécifiée
            let startSample = 0;
            let endSample = length;

            if (timeRange && timeRange.start !== undefined && timeRange.end !== undefined) {
                // Convertir les temps en indices d'échantillons
                startSample = Math.floor(timeRange.start * sampleRate);
                endSample = Math.ceil(timeRange.end * sampleRate);

                // S'assurer que les indices sont dans les limites valides
                startSample = Math.max(0, startSample);
                endSample = Math.min(length, endSample);
            }

            // Calculer la longueur effective des données à traiter
            const effectiveLength = endSample - startSample;

            // Déterminer le nombre optimal de segments en fonction de la taille des données
            // Pour les fichiers plus longs, utiliser plus de segments pour un traitement parallèle efficace
            const optimalSegmentCount = Math.min(
                8, // Maximum 8 segments pour éviter trop de parallélisme
                Math.max(1, Math.ceil(effectiveLength / 1000000)) // 1 segment par million d'échantillons
            );

            segmentedAudioData.value = Array(segmentCount.value).fill(null);

            // Analyser un échantillon des données brutes pour comprendre leur distribution
            if (numberOfChannels > 0) {
                const channel0Data = audioBuffer.getChannelData(0);
                let nonZeroCount = 0;
                let minValue = 1,
                    maxValue = 0;
                const sampleSize = Math.min(10000, channel0Data.length);

                for (let i = 0; i < sampleSize; i++) {
                    const value = Math.abs(channel0Data[i]);
                    if (value > 0.001) nonZeroCount++;
                    minValue = Math.min(minValue, value);
                    maxValue = Math.max(maxValue, value);
                }

                // Détecter les silences et les zones de faible amplitude
                let silenceCount = 0;
                let lowAmplitudeCount = 0;
                let previousValue = 0;
                let transitionCount = 0;

                for (let i = 0; i < sampleSize; i++) {
                    const value = Math.abs(channel0Data[i]);
                    if (value < 0.0001) {
                        silenceCount++;
                    } else if (value < 0.01) {
                        lowAmplitudeCount++;
                    }

                    // Détecter les transitions (changements brusques d'amplitude)
                    if (i > 0 && Math.abs(value - previousValue) > 0.05) {
                        transitionCount++;
                    }
                    previousValue = value;
                }
            }

            // Traiter les segments avec les indices ajustés
            // Utiliser Promise.all pour traiter tous les segments en parallèle
            const segmentPromises = [];
            for (let segmentIndex = 0; segmentIndex < segmentCount.value; segmentIndex++) {
                // Calculer les indices de début et de fin pour ce segment
                const segmentSize = Math.ceil(effectiveLength / segmentCount.value);
                const segmentStartSample = startSample + segmentIndex * segmentSize;
                const segmentEndSample = Math.min(endSample, startSample + (segmentIndex + 1) * segmentSize);

                // Ajouter un chevauchement pour éviter les artefacts aux bords des segments
                const overlap = Math.min(100, Math.floor(segmentSize * 0.05)); // 5% de chevauchement
                const processStartSample = Math.max(startSample, segmentStartSample - (segmentIndex > 0 ? overlap : 0));
                const processEndSample = Math.min(
                    endSample,
                    segmentEndSample + (segmentIndex < segmentCount.value - 1 ? overlap : 0)
                );

                segmentPromises.push(
                    processSegment(
                        audioBuffer,
                        segmentIndex,
                        numberOfChannels,
                        length,
                        processStartSample,
                        processEndSample,
                        isMP3
                    )
                );
            }

            // Attendre que tous les segments soient traités
            await Promise.all(segmentPromises);

            // Fusionner les données segmentées en un seul tableau
            if (segmentCount.value === 1) {
                audioData.value = segmentedAudioData.value[0];
            } else {
                // Concaténer tous les segments
                const mergedData = [];
                segmentedAudioData.value.forEach((segment, index) => {
                    if (segment) {
                        // Si ce n'est pas le premier segment, supprimer le chevauchement au début
                        const overlapStart = index > 0 ? Math.min(100, Math.floor(segment.length * 0.05)) : 0;

                        // Si ce n'est pas le dernier segment, supprimer le chevauchement à la fin
                        const overlapEnd =
                            index < segmentCount.value - 1 ? Math.min(100, Math.floor(segment.length * 0.05)) : 0;

                        // Ajouter les données sans le chevauchement
                        mergedData.push(...segment.slice(overlapStart, segment.length - overlapEnd));
                    } else {
                        console.warn(`[useAudioWaveform] Segment ${index + 1} manquant`);
                    }
                });
                audioData.value = mergedData;
            }

            // Post-traitement pour les fichiers MP3 pour combler les trous potentiels
            if (isMP3 && audioData.value.length > 0) {
                // Analyser les données avant post-traitement
                let zeroCount = 0;
                for (let i = 0; i < audioData.value.length; i++) {
                    if (Math.abs(audioData.value[i]) < 0.001) zeroCount++;
                }
                audioData.value = processMP3AudioData(audioData.value);

                // Analyser les données après post-traitement
                zeroCount = 0;
                for (let i = 0; i < audioData.value.length; i++) {
                    if (Math.abs(audioData.value[i]) < 0.001) zeroCount++;
                }
            }

            // Retourner des informations supplémentaires sur l'audio
            return {
                duration,
                sampleRate,
                numberOfChannels,
                isMP3
            };
        } catch (error) {
            console.error('[useAudioWaveform] Erreur lors du chargement des données audio:', error);
            return null;
        } finally {
            isLoading.value = false;
        }
    };

    // Fonction pour traiter spécifiquement les données audio MP3 et combler les trous
    const processMP3AudioData = (data) => {
        const processedData = [...data];
        const threshold = 0.001; // Seuil pour détecter un "trou"

        // Compter les trous avant traitement
        let holesCount = 0;
        let maxHoleLength = 0;
        let currentHoleLength = 0;
        let inHole = false;

        for (let i = 0; i < processedData.length; i++) {
            if (processedData[i] < threshold) {
                if (!inHole) {
                    inHole = true;
                    holesCount++;
                    currentHoleLength = 1;
                } else {
                    currentHoleLength++;
                }
            } else {
                if (inHole) {
                    maxHoleLength = Math.max(maxHoleLength, currentHoleLength);
                    inHole = false;
                }
            }
        }

        // Parcourir les données pour détecter et combler les trous
        let filledHoles = 0;

        // Première passe: combler les petits trous (jusqu'à 5 échantillons)
        for (let i = 1; i < processedData.length - 1; i++) {
            // Si la valeur actuelle est très faible (trou) mais les valeurs adjacentes sont significatives
            if (processedData[i] < threshold) {
                // Chercher la fin du trou
                let holeEnd = i;
                while (holeEnd < processedData.length - 1 && processedData[holeEnd] < threshold) {
                    holeEnd++;
                }

                // Si le trou n'est pas trop grand et qu'on a des valeurs non nulles avant et après
                const holeSize = holeEnd - i;
                if (
                    holeSize <= 5 &&
                    i > 0 &&
                    processedData[i - 1] >= threshold &&
                    processedData[holeEnd] >= threshold
                ) {
                    // Interpolation linéaire
                    const startValue = processedData[i - 1];
                    const endValue = processedData[holeEnd];
                    for (let j = i; j < holeEnd; j++) {
                        const ratio = (j - i + 1) / (holeSize + 1);
                        processedData[j] = startValue * (1 - ratio) + endValue * ratio;
                        filledHoles++;
                    }
                }

                // Avancer à la fin du trou
                i = holeEnd;
            }
        }

        // Deuxième passe: s'assurer qu'il n'y a pas de valeurs nulles isolées
        for (let i = 1; i < processedData.length - 1; i++) {
            if (
                processedData[i] < threshold &&
                processedData[i - 1] >= threshold &&
                processedData[i + 1] >= threshold
            ) {
                // Moyenne des valeurs adjacentes
                processedData[i] = (processedData[i - 1] + processedData[i + 1]) / 2;
                filledHoles++;
            }
        }

        // Troisième passe: appliquer un lissage pour adoucir les transitions
        const smoothedData = [...processedData];
        for (let i = 2; i < smoothedData.length - 2; i++) {
            // Appliquer un lissage uniquement aux zones de transition
            if (
                Math.abs(smoothedData[i] - smoothedData[i - 1]) > 0.05 ||
                Math.abs(smoothedData[i] - smoothedData[i + 1]) > 0.05
            ) {
                // Moyenne pondérée des 5 points (plus de poids au point central)
                smoothedData[i] =
                    smoothedData[i - 2] * 0.1 +
                    smoothedData[i - 1] * 0.2 +
                    smoothedData[i] * 0.4 +
                    smoothedData[i + 1] * 0.2 +
                    smoothedData[i + 2] * 0.1;
            }
        }

        // Vérifier s'il reste des trous
        let remainingHoles = 0;
        for (let i = 0; i < smoothedData.length; i++) {
            if (smoothedData[i] < threshold) {
                remainingHoles++;
            }
        }

        // Quatrième passe: normalisation pour éviter les valeurs trop faibles
        let maxValue = 0;
        for (let i = 0; i < smoothedData.length; i++) {
            maxValue = Math.max(maxValue, smoothedData[i]);
        }

        // Si l'amplitude maximale est trop faible, amplifier les données
        if (maxValue < 0.5) {
            const amplificationFactor = 0.5 / maxValue;

            for (let i = 0; i < smoothedData.length; i++) {
                smoothedData[i] *= amplificationFactor;
            }
        }

        return smoothedData;
    };

    // Fonction pour traiter un segment individuel
    const processSegment = (
        audioBuffer,
        segmentIndex,
        numberOfChannels,
        length,
        startSample = 0,
        endSample = 0,
        isMP3 = false
    ) => {
        return new Promise((resolve, reject) => {
            // Utiliser les indices spécifiés ou calculer les indices par défaut
            const segmentStartSample =
                startSample > 0 ? startSample : Math.floor((segmentIndex / segmentCount.value) * length);
            const segmentEndSample =
                endSample > 0 ? endSample : Math.floor(((segmentIndex + 1) / segmentCount.value) * length);
            const segmentLength = segmentEndSample - segmentStartSample;

            // Créer des copies des données de canal pour ce segment spécifique
            const channelDataArray = [];
            for (let channel = 0; channel < numberOfChannels; channel++) {
                // Obtenir les données du canal et les copier dans un nouveau Float32Array
                const originalData = audioBuffer.getChannelData(channel);
                const channelCopy = new Float32Array(segmentLength);

                // Copier uniquement la partie du segment
                for (let i = 0; i < segmentLength; i++) {
                    channelCopy[i] = originalData[segmentStartSample + i];
                }

                channelDataArray.push(channelCopy);
            }

            const worker = new Worker(new URL('../workers/audioWorker.js', import.meta.url), {
                type: 'module'
            });

            // Créer un tableau de buffers à transférer
            const transferBuffers = channelDataArray.map((data) => data.buffer);

            worker.postMessage(
                {
                    channelDataArray,
                    canvasWidth: canvasWidth.value,
                    numberOfChannels,
                    length: segmentLength,
                    amplificationFactor: amplificationFactor.value,
                    amplificationThreshold: amplificationThreshold.value,
                    segmentCount: segmentCount.value,
                    segmentIndex,
                    isMP3 // Passer l'information sur le type de fichier au worker
                },
                transferBuffers // Transférer les buffers copiés
            );

            worker.onmessage = (e) => {
                if (e.data.error) {
                    console.error(`Erreur lors du traitement du segment ${segmentIndex}:`, e.data.error);
                    reject(e.data.error);
                } else {
                    // Stocker les données de ce segment
                    segmentedAudioData.value[e.data.segmentIndex] = e.data.audioData;
                    resolve();
                }
                worker.terminate();
            };

            worker.onerror = (error) => {
                console.error(`Erreur du worker pour le segment ${segmentIndex}:`, error);
                reject(error);
                worker.terminate();
            };
        });
    };

    const fetchAudioBuffer = async (audioSrc) => {
        if (audioSrc instanceof Blob) {
            return await audioSrc.arrayBuffer();
        } else {
            const response = await fetch(audioSrc);
            return await response.arrayBuffer();
        }
    };

    const setAmplificationFactor = (factor) => {
        amplificationFactor.value = factor;
    };

    const setAmplificationThreshold = (threshold) => {
        amplificationThreshold.value = threshold;
    };

    return {
        audioData,
        segmentedAudioData,
        loadAudioData,
        isLoading,
        setAmplificationFactor,
        setAmplificationThreshold,
        segmentCount
    };
}
