<template>
    <div class="edit-client-privacy">
        <p class="edit-client-privacy-disclaimer">
            {{ $t('client-parameters-privacy-text') }}
        </p>
        <p class="edit-client-privacy-disclaimer">{{ $t('Choose how we use your data:') }}</p>
        <div class="edit-client-privacy-list">
            <ui-switch-button
                id="edit-client-enable-ai"
                :label="$t('Activate AI subtitling')"
                v-model="client.enableAi"
                cgs-id="1"
                :require-cgs-approval="needCGSAgreement(1)"
            />
            <ui-switch-button
                id="edit-client-enable-tts"
                :label="$t('Activate AI tools for audio')"
                v-model="client.enableTts"
                cgs-id="2"
                :require-cgs-approval="needCGSAgreement(2)"
            />
            <ui-switch-button
                id="edit-client-enable-gen-image-ai"
                :label="$t('Activate AI image generation')"
                v-model="client.enableGenImageAi"
                cgs-id="3"
                :require-cgs-approval="needCGSAgreement(3)"
            />
            <ui-switch-button
                v-if="isCTE"
                :label="$t('videoCompanionEnabled')"
                v-model="client.videoCompanionEnabled"
            />
        </div>
    </div>
</template>

<script setup>
import UiSwitchButton from '@/js/components/UiSwitchButton.vue';
import { useAuth } from '@/js/composables/useAuth.js';

const client = defineModel();

const { isCTE, isClientAdmin } = useAuth();

const needCGSAgreement = (cgvId) => {
    return (
        !isCTE.value &&
        isClientAdmin.value &&
        !client.value.cgvAgreements.find((cgv) => cgv.cgvId === cgvId && cgv.accepted)
    );
};
</script>
