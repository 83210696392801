import { Dimension, Timeline, Transition as TransitionConstants } from '../constants';
import gsap from 'gsap';
import { mapGetters, mapState } from 'vuex';

export default {
    props: {
        id: String,
        detection: Object,
        format: Object,
        currentSequences: Array
    },

    data() {
        return {
            active: false,
            needsRefresh: false,
            isAbove: false,
            swapElements: false,
            inElementsAutoAppear: true,
            keepActive: false
        };
    },

    computed: {
        ...mapState({
            sequence(state) {
                return state.sequences[this.id];
            },
            state(state) {
                return state.sequences[this.id].transition;
            }
        }),

        ...mapGetters({
            formatRatio: 'display/formatRatio'
        }),

        previous() {
            return this.$store.getters['sequences/' + this.id + '/previousVisible'];
        },

        containerID() {
            return this.id + '-transition';
        },

        selector() {
            return '#' + this.containerID;
        },

        classes() {
            return {
                'studio-active': this.currentSequences.indexOf(this.id) != -1 && this.active,
                'studio-above': this.isAbove
            };
        },

        sequenceInElement() {
            return this.sequence?.el || this._emptyObjects.sequenceInElement;
        },
        sequenceInParentElement() {
            return (
                (this.sequenceInElement &&
                    this.sequenceInElement.closest &&
                    this.sequenceInElement.closest('.studio-sequence-content')) ||
                this._emptyObjects.sequenceInParentElement
            );
        },

        backgroundInElement() {
            return this.sequence?.background?.el || this._emptyObjects.backgroundInElement;
        },
        backgroundInParentElement() {
            return (
                (this.backgroundInElement &&
                    this.backgroundInElement.closest &&
                    this.backgroundInElement.closest('.studio-sequence-bg')) ||
                this._emptyObjects.backgroundInParentElement
            );
        },

        sequenceOutElement() {
            return (this.previous && this.previous.el) || this._emptyObjects.sequenceOutElement;
        },
        sequenceOutParentElement() {
            return (
                (this.sequenceOutElement &&
                    this.sequenceOutElement.closest &&
                    this.sequenceOutElement.closest('.studio-sequence-content')) ||
                this._emptyObjects.sequenceOutParentElement
            );
        },

        backgroundOutElement() {
            return (this.previous && this.previous.background.el) || this._emptyObjects.backgroundOutElement;
        },
        backgroundOutParentElement() {
            return (
                (this.backgroundOutElement &&
                    this.backgroundOutElement.closest &&
                    this.backgroundOutElement.closest('.studio-sequence-bg')) ||
                this._emptyObjects.backgroundOutParentElement
            );
        },

        elements() {
            return [
                this.sequenceInElement,
                this.backgroundInElement,
                this.sequenceOutElement,
                this.backgroundOutElement
            ];
        },

        inClipPathProxy: {
            get() {
                return (
                    this.sequenceInElement?.style?.webkitClipPath || this.sequenceInElement?.style?.clipPath || 'none'
                );
            },
            set(value) {
                if (this.backgroundInElement.style)
                    this.backgroundInElement.style.clipPath = this.backgroundInElement.style.webkitClipPath = value;
                if (this.sequenceInElement.style)
                    this.sequenceInElement.style.clipPath = this.sequenceInElement.style.webkitClipPath = value;
            }
        },
        outClipPathProxy: {
            get() {
                return (
                    this.sequenceOutElement?.style?.webkitClipPath || this.sequenceOutElement?.style?.clipPath || 'none'
                );
            },
            set(value) {
                if (this.backgroundOutElement.style)
                    this.backgroundOutElement.style.clipPath = this.backgroundOutElement.style.webkitClipPath = value;
                if (this.sequenceOutElement.style)
                    this.sequenceOutElement.style.clipPath = this.sequenceOutElement.style.webkitClipPath = value;
            }
        }
    },

    watch: {
        format(newValue, oldValue) {
            if (newValue.width != oldValue.width || newValue.height != oldValue.height) this.updateTimeline();
        },

        elements: {
            handler(newValue, oldValue) {
                let hasChanged = newValue.reduce((changed, element, index) => {
                    return changed || element != oldValue[index];
                }, false);

                if (hasChanged) this.updateTimeline();
            },
            deep: true
        }
    },

    methods: {
        update(event) {
            this.updateTimeline();
        },

        updateTimeline() {
            this.$store.commit('sequences/' + this.id + '/setTransitionTimeline', () => this.getTimelineBase());
        },

        releaseTimeline() {
            this.resetElementProperties([this.backgroundInElement, this.sequenceInElement]);
            this.resetSVGElements();
            this.resetTransitionProperties();

            if (this.$el && typeof this.$el.querySelectorAll === 'function') {
                const rectElements = this.$el.querySelectorAll('rect');
                rectElements.forEach((rect) => {
                    if (rect.hasAttribute('data-original-mask')) {
                        rect.setAttribute('mask', rect.getAttribute('data-original-mask'));
                    } else if (rect.hasAttribute('mask')) {
                        rect.removeAttribute('mask');
                    }

                    if (rect.hasAttribute('data-original-fill')) {
                        rect.setAttribute('fill', rect.getAttribute('data-original-fill'));
                    }
                });
            }
        },

        resetElementProperties(elements) {
            if (!elements || elements.length === 0) return;

            gsap.set(elements, {
                clearProps: 'opacity,visibility,transformOrigin,transform,clipPath,webkitClipPath'
            });
        },

        resetSVGElements() {
            if (!this.$el || typeof this.$el.querySelectorAll !== 'function') return;

            const svgElements = this.$el.querySelectorAll(
                'path, polygon, rect, circle, ellipse, g, svg, text, tspan, line, polyline'
            );
            if (!svgElements.length) return;

            svgElements.forEach((element) => this.resetSVGElement(element));

            this.resetTransitionProperties();
        },

        resetSVGElement(element) {
            gsap.set(element, { clearProps: 'all' });

            // Reset common attributes to all SVG elements
            [
                'fill',
                'stroke',
                'opacity',
                'visibility',
                'display',
                'mask',
                'class',
                'fill-opacity',
                'stroke-opacity',
                'stroke-width'
            ].forEach((attr) => {
                const originalAttr = element.getAttribute('data-original-' + attr);
                if (originalAttr) {
                    element.setAttribute(attr, originalAttr);
                }
            });

            const tagName = element.tagName.toLowerCase();

            switch (tagName) {
                case 'path':
                    this.resetPathElement(element);
                    break;
                case 'polygon':
                    this.resetPolygonElement(element);
                    break;
                case 'rect':
                    this.resetRectElement(element);
                    break;
                case 'g':
                    this.resetGroupElement(element);
                    break;
                case 'text':
                case 'tspan':
                    this.resetTextElement(element);
                    break;
                case 'circle':
                case 'ellipse':
                    this.resetCircleElement(element);
                    break;
                case 'line':
                    this.resetLineElement(element);
                    break;
                case 'polyline':
                    this.resetPolylineElement(element);
                    break;
            }
        },

        resetPathElement(element) {
            const originalD = element.getAttribute('data-original-d');
            if (originalD) {
                element.setAttribute('d', originalD);
            } else if (element.getAttribute('d')) {
                element.setAttribute('data-original-d', element.getAttribute('d'));
            }
        },

        resetPolygonElement(element) {
            const originalPoints = element.getAttribute('data-original-points');
            if (originalPoints) {
                element.setAttribute('points', originalPoints);
            } else if (element.getAttribute('points')) {
                element.setAttribute('data-original-points', element.getAttribute('points'));
            }
        },

        resetRectElement(element) {
            ['x', 'y', 'width', 'height', 'transform', 'fill', 'mask', 'class'].forEach((attr) => {
                const originalAttr = element.getAttribute('data-original-' + attr);
                if (originalAttr) {
                    element.setAttribute(attr, originalAttr);
                } else if (element.getAttribute(attr)) {
                    element.setAttribute('data-original-' + attr, element.getAttribute(attr));
                }
            });
        },

        resetGroupElement(element) {
            ['transform', 'opacity', 'visibility', 'display'].forEach((attr) => {
                const originalAttr = element.getAttribute('data-original-' + attr);
                if (originalAttr) {
                    element.setAttribute(attr, originalAttr);
                }
            });
        },

        resetTextElement(element) {
            ['x', 'y', 'dx', 'dy', 'transform', 'text-anchor', 'dominant-baseline'].forEach((attr) => {
                const originalAttr = element.getAttribute('data-original-' + attr);
                if (originalAttr) {
                    element.setAttribute(attr, originalAttr);
                }
            });
        },

        resetCircleElement(element) {
            const attrs =
                element.tagName.toLowerCase() === 'circle'
                    ? ['cx', 'cy', 'r', 'transform']
                    : ['cx', 'cy', 'rx', 'ry', 'transform'];

            attrs.forEach((attr) => {
                const originalAttr = element.getAttribute('data-original-' + attr);
                if (originalAttr) {
                    element.setAttribute(attr, originalAttr);
                }
            });
        },

        resetLineElement(element) {
            ['x1', 'y1', 'x2', 'y2', 'transform'].forEach((attr) => {
                const originalAttr = element.getAttribute('data-original-' + attr);
                if (originalAttr) {
                    element.setAttribute(attr, originalAttr);
                }
            });
        },

        resetPolylineElement(element) {
            const originalPoints = element.getAttribute('data-original-points');
            if (originalPoints) {
                element.setAttribute('points', originalPoints);
            }
        },

        resetTransitionProperties() {
            if (this.transitionScale !== undefined) this.transitionScale = 1;
            if (this.transitionTranslateX !== undefined) this.transitionTranslateX = '0%';
            if (this.transitionTranslateY !== undefined) this.transitionTranslateY = '0%';
        },

        getTimeline() {
            return gsap.timeline();
        },

        getTimelineBase() {
            let t = this.getTimeline();
            t.vars.id = Timeline.TRANSITION_TIMELINE_ID;
            t.set(this, { active: true }, 0.0001);
            if (this.inElementsAutoAppear)
                t.set([this.backgroundInElement, this.sequenceInElement], { autoAlpha: 1 }, 0.0001);
            if (this.previous) {
                if (this.swapElements) {
                    t.set(
                        [this.backgroundOutParentElement, this.sequenceOutParentElement],
                        { zIndex: TransitionConstants.SWAP_ELEMENTS_INDEX },
                        0.0001
                    );
                    t.set([this.backgroundOutParentElement, this.sequenceOutParentElement], { zIndex: null });
                }
                t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 });
            }
            if (!this.keepActive) t.set(this, { active: false, inClipPathProxy: 'none', outClipPathProxy: 'none' });
            return t;
        },

        enableRefresh(enable) {
            this.$store.commit('sequences/' + this.id + '/enableTransitionRefresh', enable);
        },

        getClipPathTransform(width, height, cover) {
            let vr = width / height,
                fr = this.format.width / this.format.height,
                c = cover !== undefined ? cover : true,
                sw = 1,
                sh = 1,
                tx = 0,
                ty = 0,
                transform = [],
                zoom = 1;

            if (this.detection.browser.chrome || this.detection.browser.edgeChromium) zoom = window.devicePixelRatio;

            if (c ^ (vr < fr)) {
                sh = 1 / height;
                sw = sh / fr;
            } else {
                sw = 1 / width;
                sh = sw * fr;
            }
            tx = c ^ (vr < fr) ? (zoom * (this.format.width - (this.format.height / height) * width)) / 2 : 0;
            ty = c ^ (vr < fr) ? 0 : (zoom * (this.format.height - (this.format.width / width) * height)) / 2;

            if (tx != 0) transform.push('translateX(' + tx + Dimension.PIXEL_UNIT + ')');
            if (ty != 0) transform.push('translateY(' + ty + Dimension.PIXEL_UNIT + ')');
            if (sw != 1 || sh != 1) transform.push('scale(' + sw + ', ' + sh + ')');
            if (this.detection.browser.edge) transform.reverse();
            transform = transform.join(' ');

            return { transform };
        }
    },

    beforeCreate() {
        this._willUpdateTimeline = false;
        this._emptyObjects = {
            sequenceInElement: {},
            sequenceInParentElement: {},
            backgroundInElement: {},
            backgroundInParentElement: {},
            sequenceOutElement: {},
            sequenceOutParentElement: {},
            backgroundOutElement: {},
            backgroundOutParentElement: {}
        };
    },

    created() {
        this.enableRefresh(this.needsRefresh);
    },

    mounted() {
        if (this.$el && typeof this.$el.querySelectorAll === 'function') {
            const svgElements = this.$el.querySelectorAll(
                'path, polygon, rect, circle, ellipse, g, svg, text, tspan, line, polyline'
            );
            svgElements.forEach((element) => {
                const tagName = element.tagName.toLowerCase();

                // Save common attributes to all SVG elements
                [
                    'fill',
                    'stroke',
                    'opacity',
                    'visibility',
                    'display',
                    'mask',
                    'class',
                    'fill-opacity',
                    'stroke-opacity',
                    'stroke-width'
                ].forEach((attr) => {
                    if (element.getAttribute(attr)) {
                        element.setAttribute('data-original-' + attr, element.getAttribute(attr));
                    }
                });

                if (tagName === 'path' && element.getAttribute('d')) {
                    element.setAttribute('data-original-d', element.getAttribute('d'));
                }

                if ((tagName === 'polygon' || tagName === 'polyline') && element.getAttribute('points')) {
                    element.setAttribute('data-original-points', element.getAttribute('points'));
                }

                if (tagName === 'rect') {
                    ['x', 'y', 'width', 'height', 'transform', 'fill', 'mask', 'class'].forEach((attr) => {
                        if (element.getAttribute(attr)) {
                            element.setAttribute('data-original-' + attr, element.getAttribute(attr));
                        }
                    });
                }

                if (tagName === 'g') {
                    ['transform', 'opacity', 'visibility', 'display'].forEach((attr) => {
                        if (element.getAttribute(attr)) {
                            element.setAttribute('data-original-' + attr, element.getAttribute(attr));
                        }
                    });
                }

                if (tagName === 'text' || tagName === 'tspan') {
                    ['x', 'y', 'dx', 'dy', 'transform', 'text-anchor', 'dominant-baseline'].forEach((attr) => {
                        if (element.getAttribute(attr)) {
                            element.setAttribute('data-original-' + attr, element.getAttribute(attr));
                        }
                    });
                }

                if (tagName === 'circle' || tagName === 'ellipse') {
                    const attrs =
                        tagName === 'circle' ? ['cx', 'cy', 'r', 'transform'] : ['cx', 'cy', 'rx', 'ry', 'transform'];

                    attrs.forEach((attr) => {
                        if (element.getAttribute(attr)) {
                            element.setAttribute('data-original-' + attr, element.getAttribute(attr));
                        }
                    });
                }

                if (tagName === 'line') {
                    ['x1', 'y1', 'x2', 'y2', 'transform'].forEach((attr) => {
                        if (element.getAttribute(attr)) {
                            element.setAttribute('data-original-' + attr, element.getAttribute(attr));
                        }
                    });
                }
            });
        }

        this.$nextTick(() => {
            this.updateTimeline();
        });
    }
};
