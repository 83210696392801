<template>
    <div :class="['current-time', positionClass]" :style="style">
        {{ formattedTime }}
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { formatTime } from '@/js/videos/utils/time';
import type { CSSProperties } from 'vue';

// Types
interface Props {
    currentTime: number;
    position: 'left' | 'right' | 'center';
    x: number;
    y: number;
    rate?: number;
}

// Constants
const FRAME_RATE_THRESHOLD = 100;
const DEFAULT_FRAME_RATE = 100; // 100 is the frame rate of the video to feat with gsap (should be 25 / 30 / 60)

// Props definition
const props = withDefaults(defineProps<Props>(), {
    rate: DEFAULT_FRAME_RATE
});

// Computed properties
const formattedTime = computed(() => {
    return formatTime(props.currentTime || 0, props.rate, undefined, {
        includeFrames: true,
        padFrames: props.rate > FRAME_RATE_THRESHOLD
    });
});

const positionClass = computed(() => `current-time-${props.position}`);

const style = computed(
    (): CSSProperties => ({
        position: 'fixed' as const,
        left: `${props.x}px`,
        top: `${props.y - 9}px`
    })
);
</script>

<style scoped>
.current-time {
    --triangle-size: 5px;

    position: relative;
    color: white;
    font-size: 12px;
    white-space: nowrap;
    background-color: var(--edit-mode-color);
    padding: 3px 6px;
    border-radius: 4px;
    user-select: none;
    pointer-events: none;
    z-index: 9999;
}

.current-time::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    border: var(--triangle-size) solid transparent;
    border-top-color: var(--edit-mode-color);
}

.current-time-center {
    transform: translateX(-50%);
}

.current-time-left {
    transform: translateX(0);
}

.current-time-left::after {
    left: 10px;
}

.current-time-right {
    transform: translateX(-100%);
}

.current-time-right::after {
    left: calc(100% - 10px);
}
</style>
