<template>
    <div class="ui-content-row" v-if="hasColor">
        <label>{{ $t('captions.editor.colors.label') }}</label>
        <div class="captions-editor-flex-list">
            <template v-for="colorIndex in [1, 2, 3]" :key="colorIndex">
                <ui-color-selector
                    v-if="animationUses.includes(`color${colorIndex}`)"
                    :id="`captions-animation-color${colorIndex}`"
                    :color="{ value: palette[`color${colorIndex}`] }"
                    :palette="brandPalette"
                    :enable-other-colors="brandEnableOtherColors"
                    :show-label="false"
                    dropdown-container="#studio-ui"
                    @[colorSelectorChangeEvent]="setPaletteColor(colorIndex, $event?.value)"
                />
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ComputedRef } from 'vue';
import { useCaptions } from '@/js/videos/composables/caption/useCaptions.ts';
import { useCaptionsEditor } from '@/js/videos/composables/caption/useCaptionsEditor.ts';
import UiColorSelector, { UI_COLOR_SELECTOR_CHANGE } from '@/js/components/UiColorSelector.vue';
import { useBrand } from '@/js/videos/composables/useBrand.js';

const colorSelectorChangeEvent: string = UI_COLOR_SELECTOR_CHANGE;

const { editorState } = useCaptionsEditor();
const { palette, animation, getAnimationData, setPaletteColor } = useCaptions(
    editorState.value.storeModulePath,
    editorState.value.type,
    editorState.value.parentStoreModulePath
);
const { brandPalette, brandEnableOtherColors } = useBrand();

const animationData: ComputedRef<any> = computed(() => getAnimationData(animation.value));

const animationUses: ComputedRef<string[]> = computed(() => animationData.value?.uses ?? []);

const hasColor: ComputedRef<boolean> = computed(
    () =>
        animationUses.value.includes('color1') ||
        animationUses.value.includes('color2') ||
        animationUses.value.includes('color3')
);
</script>
