<template>
    <ui-card :hide-header="true" :shadow="true" class="ui-view-list-item">
        <router-link :to="{ name: 'ShowFolder', params: { shortname: folder.shortname } }" class="card-item-link" />
        <div class="card-project-data">
            <div class="card-project-image">
                <image-fallback :src="folder.logo ?? videoDefaultThumbnail" loading="lazy" />
            </div>
            <div class="card-project-info">
                <div class="card-project-name">{{ folder.name }}</div>
                <div class="card-project-videos">
                    <span class="items-total">{{ folder.nbVideos }}</span>
                    <span class="items-alias">{{ $t('Videos') }}</span>
                </div>
            </div>
        </div>
        <div class="card-project-team">
            <button
                v-if="
                    folder.clientId !== 1 &&
                    (user.role.id === Roles.SUPERVISOR_ROLE_ID || user.role.id === Roles.ADMIN_ROLE_ID)
                "
                class="ui-simple-button"
                v-tooltip="$t('Define folder access')"
                @click="shareFolder(folder)"
            >
                <fa-icon class="icon" icon="fa-solid fa-user-plus" />
                <span>{{ $t('Share') }}</span>
            </button>
        </div>
        <div class="card-project-actions">
            <FavouriteButton :folder="folder"></FavouriteButton>

            <ui-follow-project-button
                v-if="user.role.id === Roles.SUPERVISOR_ROLE_ID && user.enableNotifications"
                v-tooltip="$t('Receive activity notification emails')"
                :folder="folder"
            />
        </div>
    </ui-card>
</template>

<script setup>
import videoDefaultThumbnail from '@/assets/images/video-default-thumbnail.png';
import Roles from '@/js/constants/roles.js';
import UiFollowProjectButton from '@/js/components/UiFollowProjectButton.vue';
import UiCard from '@/js/components/UiCard.vue';
import FolderDTO from '@/js/dto/FolderDTO.js';
import { useAuth } from '@/js/composables/useAuth.js';
import { useModal } from '@/js/composables/useModal.js';
import { onMounted } from 'vue';
import FoldersDataService from '@/js/application/services/folders/FoldersDataService.js';
import { useStore } from 'vuex';
import UsesTooltip from '@/js/mixins/UsesTooltip.js';
import FavouriteButton from '@/js/components/folders/FavouriteButton.vue';
import ImageFallback from '@/js/components/ImageFallback.vue';

const props = defineProps({
    folder: {
        type: FolderDTO,
        required: true
    }
});

const vTooltip = UsesTooltip.directives.tooltip;

const store = useStore();
const { user } = useAuth();
const { openModal } = useModal();

const shareFolder = (folder) => {
    openModal('ShareFolderModal', {
        folder
    });
};

onMounted(() => {
    new FoldersDataService(store).fetchEditionByShortname(props.folder.publicId).then((data) => {
        props.folder.nbVideos = data.videos_total;
        props.folder.logo = data.brand?.logo?.media_url;
    });
});
</script>