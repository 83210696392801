<template>
    <virtual-scroll
        class="captions"
        ref="scroller"
        :items="relativeCaptions"
        :item-height="CAPTION_HEIGHT"
        key-field="initialIndex"
        v-slot="{ item, index }"
    >
        <ui-captions-editor-content-item
            :item="item"
            :index="index"
            :is-active="index === activeCaptionIndex"
            :min-timing="getRelativeCaptionMinTiming(index)"
            :max-timing="getRelativeCaptionMaxTiming(index)"
            @click="handleClickCaption(index)"
            @text-change="_debouncedHandleCaptionTextChange(index, $event)"
            @focus="setActiveCaption(index)"
            @blur="setActiveCaption(index)"
            @play="playCaption(index)"
            @start-change="_debouncedHandleCaptionStartChange(index, $event)"
            @end-change="_debouncedHandleCaptionEndChange(index, $event)"
            @add="addCaption(index)"
            @remove="handleRemoveCaptionUnit(index)"
        />
    </virtual-scroll>
</template>

<script setup lang="ts">
import { computed, inject, nextTick, onMounted, Ref, ref, watch } from 'vue';
import { useStore } from 'vuex';
import _debounce from 'lodash/debounce';
import { useCaptions } from '@/js/videos/composables/caption/useCaptions.ts';
import VideoStudio from '@/js/video-studio/VideoStudio.vue';
import { useCaptionsEditor } from '@/js/videos/composables/caption/useCaptionsEditor.ts';
import { RecycleScroller } from 'vue-virtual-scroller';
import VirtualScroll from '@/js/components/VirtualScroll.vue';
import UiCaptionsEditorContentItem from './UiCaptionsEditorContentItem.vue';

const CAPTION_HEIGHT = 70;
const UPDATE_CAPTION_DELAY: number = 500;

const videoStudio: VideoStudio = inject('$videoStudio');

const store = useStore();
const { editorState } = useCaptionsEditor();
const {
    captions,
    relativeCaptions,
    removeCaptionUnit,
    updateCaptionUnit,
    calculateBaseTime,
    roundTiming,
    manuallyUpdated,
    createDefaultEmptyCaption,
    mediaRelativeDuration
} = useCaptions(
    editorState.value.storeModulePath,
    editorState.value.type,
    editorState.value.parentStoreModulePath,
    editorState.value.sequenceId
);

const { currentCaptionIndex } = useCaptionsEditor();

const activeCaptionIndex: Ref<number | null> = ref(null);
const scroller = ref<RecycleScroller | null>(null);
const disableCurrentCaptionIndexWatcher: Ref<boolean> = ref(false);

const currentTimelineId = computed(() => store.state.preview.currentTimelineId);

const currentStoreModulePathCaptionIndex = computed(() => {
    return currentCaptionIndex.value[editorState.value.storeModulePath];
});

const getRelativeCaptionMinTiming = (index: number): number => {
    if (index === 0) {
        return 0;
    }

    return roundTiming(relativeCaptions.value[index - 1].relativeEndTime);
};

const getRelativeCaptionMaxTiming = (index: number): number => {
    if (index === relativeCaptions.value.length - 1) {
        return roundTiming(mediaRelativeDuration.value);
    }

    return roundTiming(relativeCaptions.value[index + 1].relativeStartTime);
};

const setActiveCaption = (index: number): void => {
    activeCaptionIndex.value = index;
};

const handleClickCaption = (index: number): void => {
    setActiveCaption(index);

    let caption = relativeCaptions.value[index];

    if (caption) {
        videoStudio.value.studio.$stage.seekSequenceTimeline(
            currentTimelineId.value,
            caption.relativeStartTime + 0.001
        );
    }
};

const playCaption = (index: number): void => {
    let caption = relativeCaptions.value[index];

    if (!caption) {
        return;
    }

    disableCurrentCaptionIndexWatcher.value = true;
    videoStudio.value.studio.$stage.seekSequenceTimeline(currentTimelineId.value, caption.relativeStartTime);
    videoStudio.value.studio.$stage.playTimeline();

    setTimeout(
        () => {
            videoStudio.value.studio.$stage.pauseTimeline();
            disableCurrentCaptionIndexWatcher.value = false;
        },
        (caption.relativeEndTime - caption.relativeStartTime) * 1000
    );
};

const scrollToActiveCaption = (): void => {
    if (!scroller.value || !activeCaptionIndex.value) {
        return;
    }

    scroller.value.scrollToIndex(activeCaptionIndex.value, 'center', true);
};

const addCaption = (index: number): void => {
    let caption = relativeCaptions.value[index];

    if (!caption) {
        return;
    }

    let endTime = calculateBaseTime(caption.relativeEndTime);
    let newCaption = {
        text: '',
        startTime: endTime,
        endTime: endTime + 0.01
    };

    captions.value.captions.splice(index + 1, 0, newCaption);
    manuallyUpdated.value = true;
};

const handleRemoveCaptionUnit = (index: number): void => {
    removeCaptionUnit(index);

    if (captions.value.captions.length === 0) {
        createDefaultEmptyCaption();
    }

    manuallyUpdated.value = true;
};

const _debouncedHandleCaptionTextChange = _debounce((index: number, value: string): void => {
    captions.value.captions[index].text = value;
    updateCaptionUnit(index, captions.value.captions[index]);
    manuallyUpdated.value = true;
}, UPDATE_CAPTION_DELAY);

const _debouncedHandleCaptionStartChange = _debounce((index: number, value: number): void => {
    captions.value.captions[index].startTime = calculateBaseTime(value);
    updateCaptionUnit(index, captions.value.captions[index]);
    manuallyUpdated.value = true;
}, UPDATE_CAPTION_DELAY);

const _debouncedHandleCaptionEndChange = _debounce((index: number, value: number): void => {
    captions.value.captions[index].endTime = calculateBaseTime(value);
    updateCaptionUnit(index, captions.value.captions[index]);
    manuallyUpdated.value = true;
}, UPDATE_CAPTION_DELAY);

const handleCurrentCaptionIndexChange = (): void => {
    if (currentStoreModulePathCaptionIndex.value === null) {
        return;
    }

    setActiveCaption(currentStoreModulePathCaptionIndex.value);
    nextTick(scrollToActiveCaption);
};

watch(currentStoreModulePathCaptionIndex, () => {
    if (disableCurrentCaptionIndexWatcher.value) {
        return;
    }

    handleCurrentCaptionIndexChange();
});

watch(activeCaptionIndex, () => {
    nextTick(scrollToActiveCaption);
});

onMounted(() => {
    handleCurrentCaptionIndexChange();
});
</script>
