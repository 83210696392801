<template>
    <main class="ui-view ui-view-light-grey-bg" id="brands-view">
        <ui-view-header :title="$t('brand_center.title')" :enable-search="true" v-model="searchFilter">
            <template #header-tools-left>
                <client-selector v-if="isCTE" v-model="selectedClient" />
            </template>
            <template #header-tools>
                <div class="view-header-action" v-if="isClientAdmin || isCTE">
                    <button
                        class="ui-simple-button-transparent"
                        id="add-new-brand"
                        :disabled="isLoading || brandLimitReached"
                        v-tooltip="createBrandToolTip"
                        @click="openCreateBrandModal"
                    >
                        <fa-icon class="icon" icon="fa-solid fa-plus" />
                    </button>
                </div>
            </template>
        </ui-view-header>
        <h3 class="brand_counter" v-if="brandLimitDisplay">
            {{ $t('brand_center.overview.list_title') }} <span class="count">{{ brandLimitDisplay }}</span>
        </h3>
        <div class="ui-view-items-list">
            <UiInfiniteScrollPaginator
                v-model="page"
                :max-page="totalPages"
                :loading="isLoading"
                :use-scrollable="false"
            >
                <router-link
                    v-for="brand of minimalBrands"
                    :key="brand.id"
                    :to="{ name: 'EditBrand', params: { id: brand.id } }"
                    class="view-brand-list-item"
                >
                    <ui-card :hide-header="true" :shadow="true">
                        <div class="card-brand-data">
                            <div class="card-brand-image">
                                <image-fallback :src="brand.logoUrl" loading="lazy" />
                            </div>
                            <div class="card-brand-info">
                                <div class="card-brand-name">{{ brand.name }}</div>
                                <div v-if="isCTE">
                                    <div class="card-orga-name">
                                        {{
                                            $t('brand_center.overview.associated_with', {
                                                organization_name: brand.organizationName
                                            })
                                        }}
                                    </div>
                                    <div v-if="brand.masterName" class="card-master-name">
                                        {{
                                            $t('brand_center.overview.inherited_from', {
                                                master_name: brand.masterName
                                            })
                                        }}
                                    </div>
                                </div>

                                <div class="card-folder-count">
                                    <span class="count">{{ brand.foldersTotal }}</span>
                                    {{ $t('brand_center.overview.folders') }}
                                </div>
                            </div>
                        </div>

                        <div class="card-actions" v-if="isClientAdmin || isCTE" onclick="return">
                            <ui-dropdown
                                :ref="'$template-' + brand.id + '-menu'"
                                :id="'template-' + brand.id + '-menu'"
                                class="ui-template-actions"
                                :toggle-class="['ui-action-button', 'ui-loading-button']"
                                :icon="true"
                                :caret="false"
                                menu-placement="bottom-end"
                            >
                                <template #dropdown-toggle>
                                    <span class="visually-hidden">Template actions...</span>
                                    <svg-icon icon="menu-icon" />
                                    <svg-icon class="loader" icon="circle-loader" />
                                </template>

                                <ui-dropdown-item :disabled="brandLimitReached" @click="openDuplicateModal(brand)">{{
                                    $t('brand_center.actions.duplicate')
                                }}</ui-dropdown-item>

                                <ui-dropdown-item @click="openApplyToFolderModal(brand)">{{
                                    $t('brand_center.actions.apply_to_folder')
                                }}</ui-dropdown-item>

                                <ui-dropdown-item v-if="isCTE" @click="openCopyToOrganizationModal(brand)">{{
                                    $t('brand_center.actions.copy_to_client')
                                }}</ui-dropdown-item>

                                <ui-dropdown-item
                                    class="ui-dropdown-item-danger"
                                    :v-tooltip="isLastOrganizationBrandTooltip"
                                    :disabled="isLastOrganizationBrand"
                                    @click="openDeleteModal(brand)"
                                >
                                    {{ $t('brand_center.actions.delete') }}</ui-dropdown-item
                                >
                            </ui-dropdown>
                        </div>
                    </ui-card>
                </router-link>
                <template v-slot:loading>
                    <div class="brands-loader-container">
                        <LoadingMarker v-if="isLoading" />
                    </div>
                </template>

                <template v-slot:empty-data>
                    <div v-if="!minimalBrands.length && !isLoading">{{ $t('brand_center.overview.empty') }}</div>

                    <div v-if="isClientAdmin || isCTE" class="ui-brands-view-create">
                        <button
                            class="ui-simple-button ui-simple-button-select"
                            @click="openCreateBrandModal"
                            :disabled="isLoading || brandLimitReached"
                            v-tooltip="brandLimitReached ? createBrandToolTip : null"
                        >
                            <fa-icon icon="fa-solid fa-plus" class="icon" />
                            <span>{{ $t('brand_center.actions.create') }}</span>
                        </button>
                    </div>
                </template>
            </UiInfiniteScrollPaginator>
        </div>
    </main>
</template>

<script setup lang="ts">
import '@/sass/views/brands-view.scss';
// @ts-ignore
import { default as UiCard } from '@/js/components/UiCard.vue';
// @ts-ignore
import { useBrands } from '@/js/composables/useBrands.js';
// @ts-ignore
import { default as ImageFallback } from '@/js/components/ImageFallback.vue';
// @ts-ignore
import { default as UiInfiniteScrollPaginator } from '@/js/components/UiInfiniteScrollPaginator.vue';
// @ts-ignore
import { default as UiViewHeader } from '@/js/components/UiViewHeader.vue';
import { computed, onMounted, ref, watch } from 'vue';
import { debounce } from 'lodash';
// @ts-ignore
import { default as LoadingMarker } from '@/js/components/LoadingMarker.vue';
import { useI18n } from 'vue-i18n';
// @ts-ignore
import { default as UiDropdown } from '@/js/components/UiDropdown.vue';
// @ts-ignore
import { default as UiDropdownItem } from '@/js/components/UiDropdownItem.vue';
// @ts-ignore
import { default as ClientSelector } from '@/js/components/clients/ClientSelector.vue';
// @ts-ignore
import { useAuth } from '@/js/composables/useAuth.js';
// @ts-ignore
import { useModal } from '@/js/composables/useModal.js';
// @ts-ignore
import { useNotification } from '@/js/composables/useNotification.js';
// @ts-ignore
import { UsesTooltip } from '@/js/mixins';

interface Client {
    id: string | number;
    name: string;
    shortname: string;
    [key: string]: any;
}

interface Brand {
    id: string | number;
    name: string;
    logoUrl?: string;
    organizationName: string;
    masterName?: string;
    foldersTotal: number;
    [key: string]: any;
}

const { t } = useI18n();

const { openModal, closeModal } = useModal();
const { user, isCTE, isClientAdmin } = useAuth();
const {
    page,
    totalPages,
    minimalBrands,
    isLoading,
    totalBrandsForOrganization,
    maxBrandsForOrganization,
    fetchBrandsForOrganization
} = useBrands();

const { success } = useNotification();
const vTooltip = UsesTooltip.directives.tooltip;

const selectedClient = ref<Client>(user.value.client);
const searchFilter = ref('');

const options = computed(() => ({
    page: page.value,
    search: searchFilter.value
}));

const brandLimitDisplay = computed(() => {
    if (minimalBrands.value) {
        if (maxBrandsForOrganization.value !== undefined) {
            if (maxBrandsForOrganization.value === 0) {
                return `(${totalBrandsForOrganization.value ?? 0}/∞)`;
            }
            return `(${totalBrandsForOrganization.value ?? 0}/${maxBrandsForOrganization.value})`;
        }
        return `${totalBrandsForOrganization.value ?? 0}`;
    }
    return '';
});

const brandLimitReached = computed(() => {
    if (isCTE.value) {
        return false;
    }

    if (isClientAdmin.value && maxBrandsForOrganization.value === 0) {
        return false;
    }
    return isClientAdmin.value && maxBrandsForOrganization.value
        ? totalBrandsForOrganization.value >= maxBrandsForOrganization.value
        : false;
});

const createBrandToolTip = computed(() => {
    if (isClientAdmin.value && brandLimitReached.value) {
        return t('brand_center.actions.brand_limit_reached');
    }
    return t('brand_center.actions.create');
});

const isLastOrganizationBrand = computed(() => {
    return totalBrandsForOrganization.value === 1;
});

const isLastOrganizationBrandTooltip = computed(() => {
    return isLastOrganizationBrand.value ? t('brand_center.actions.brand_delete_last') : '';
});

watch(page, () => {
    fetchBrandsForOrganization(selectedClient.value.id, options.value);
});

watch(searchFilter, () => {
    debouncedSearch();
});

watch(selectedClient, () => {
    fetchBrandsForOrganization(selectedClient.value.id, options.value, true);
});

const debouncedSearch = debounce(() => {
    fetchBrandsForOrganization(selectedClient.value.id, options.value, true);
}, 500);

const openCreateBrandModal = () => {
    if (isClientAdmin.value && brandLimitReached.value) {
        return;
    }
    openModal('CreateBrandModal', { defaultClient: selectedClient.value, onCreateCallback: brandCreatedCallback });
};

const openDuplicateModal = (brand: Brand) => {
    if (isClientAdmin.value && brandLimitReached.value) {
        return;
    }

    openModal('DuplicateBrandModal', { brand, onDuplicateCallback: brandDuplicateCallback });
};

const openApplyToFolderModal = (brand: Brand) => {
    openModal('ApplyToFolderModal', {
        brand,
        defaultClient: selectedClient.value,
        onAppliedCallback: appliedBrandCallback
    });
};

const openDeleteModal = (brand: Brand) => {
    if (isLastOrganizationBrand.value) return;

    openModal('DeleteBrandModal', { brand, onDeleteCallback: deleteBrandCallback });
};

const openCopyToOrganizationModal = (brand: Brand) => {
    if (!isCTE) return;

    openModal('CopyBrandToOrganizationModal', { brand, onCopiedCallback: copiedBrandCallback });
};

const appliedBrandCallback = (brand: Brand | null) => {
    if (brand) {
        fetchBrandsForOrganization(selectedClient.value.id, options.value, true);
        closeModal();
        success('brand_center.notifications.applied_to_folder');
    }
};

const brandCreatedCallback = (brand: Brand | null) => {
    if (brand && brand.organizationName === selectedClient.value.name) {
        fetchBrandsForOrganization(selectedClient.value.id, options.value, true);
        closeModal();
    }
    success('brand_center.notifications.created');
};

const brandDuplicateCallback = (brand: Brand | null) => {
    if (brand && brand.organizationName === selectedClient.value.name) {
        fetchBrandsForOrganization(selectedClient.value.id, options.value, true);
        closeModal();
    }
    success('brand_center.notifications.duplicated');
};

const copiedBrandCallback = (brand: Brand | null) => {
    if (brand) {
        closeModal();
        success('brand_center.notifications.copied_to_client');
    }
};

const deleteBrandCallback = (brand: Brand | null) => {
    if (brand && brand.organizationName === selectedClient.value.name) {
        fetchBrandsForOrganization(selectedClient.value.id, options.value, true);
        closeModal();
    }
    success('brand_center.notifications.deleted');
};

onMounted(() => {
    fetchBrandsForOrganization(selectedClient.value.id, options.value);
});
</script>