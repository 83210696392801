<!--
  -- Component name: Number4
  -- Type: Transition
  -- Uses: color.start
  -- Tags: number
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <defs>
            <mask :id="svgMaskID">
                <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
                <path
                    class="color-number"
                    d="M521.4,435.7c0-9.5-0.1-18.3,0.1-27c0-1.3,0.9-2.7,1.7-3.9c40.2-56.5,80.4-112.9,120.6-169.4
				c1.6-2.3,3.4-3.2,6.2-3.2c15.6,0.1,31.3,0.2,46.9,0c4.1-0.1,5.1,1.3,5.1,5.2c-0.1,53.8-0.1,107.5-0.1,161.3c0,5.5,0,5.5,5.3,5.5
				c8.3,0,16.6,0.1,24.8,0c2.5,0,3.8,0.5,3.8,3.4c-0.2,8.3-0.1,16.6,0,24.8c0,2.6-1,3.4-3.5,3.3c-8.7-0.1-17.3,0.1-26-0.1
				c-3.4-0.1-4.6,0.9-4.5,4.5c0.2,18.3,0,36.5,0.1,54.8c0,3.5-0.9,4.8-4.6,4.8c-10.6-0.2-21.3-0.2-31.9,0c-3.5,0.1-4.5-1.2-4.5-4.6
				c0.1-18.3,0-36.5,0.1-54.8c0-3.7-1.2-4.7-4.7-4.6c-43.1,0.1-86.2,0.1-129.3,0.1C525.4,435.7,523.7,435.7,521.4,435.7z M661,271.1
				c-32.3,44.7-63.9,88.6-95.8,132.7c1,0.2,1.4,0.4,1.7,0.4c30.2,0,60.4,0,90.6,0.1c3,0,3.5-1.4,3.4-3.9c-0.1-12.2,0-24.4,0-36.7
				C661,333.2,661,302.7,661,271.1z"
                />
            </mask>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" :fill="state.color.start" :mask="svgMask" />
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
        };
    },

    computed: {
        svgMaskID() {
            return this.containerID + '-number4-mask';
        },

        svgMask() {
            return 'url(#' + this.svgMaskID + ')';
        }
    },

    watch: {
        svgMaskRadius: {
            handler() {
                this.updateTimeline();
            }
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            this._timeline = t;
            t.fromTo(
                '#' + this.svgMaskID + ' .color-number',
                { scale: 1 },
                { scale: 1.5, transformOrigin: '50% 50%', duration: 1 },
                0
            );
            t.fromTo(
                '#' + this.svgMaskID + ' .color-number',
                { scale: 1.5 },
                { scale: 50, translateX: '-100%', transformOrigin: '50% 50%', duration: 0.2 },
                1
            );
            return t;
        }
    }
};
</script>
