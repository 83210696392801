<template>
    <div class="language-selector">
        <Dropdown v-model="selectedLanguageValue">
            <template #dropdown-selected-value>
                <div class="selected-language">
                    {{ selectedLanguageLabel || $t('Language') }}
                </div>
            </template>

            <DropdownItem
                v-for="option in languageOptions"
                :key="option.value"
                :value="option.value"
                class="language-option"
            >
                <span class="dropdown-list-item-label">{{ option.label }}</span>
            </DropdownItem>
        </Dropdown>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
// @ts-ignore
import Dropdown from '@/js/components/dropdown/Dropdown.vue';
// @ts-ignore
import DropdownItem from '@/js/components/dropdown/DropdownItem.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

interface LanguageOption {
    value: string;
    label: string;
}

const props = defineProps<{
    modelValue: string;
}>();

const emit = defineEmits<{
    (e: 'update:modelValue', value: string): void;
}>();

const store = useStore();
const { t } = useI18n();

const selectedLanguageValue = computed({
    get: () => props.modelValue,
    set: (value: string) => {
        emit('update:modelValue', value);
    }
});

const languageOptions = computed<LanguageOption[]>(() => {
    const enabledLanguages = store.state.branding.enabledTemplateLanguages || [];
    return enabledLanguages.map((lang: string) => ({
        value: lang,
        label: t(`languages.${lang}`) || lang
    }));
});

const selectedLanguageLabel = computed(() => {
    const selectedOption = languageOptions.value.find(
        (option: LanguageOption) => option.value === selectedLanguageValue.value
    );
    return selectedOption ? selectedOption.label : '';
});
</script>

<style scoped>
.flag-icon {
    display: inline-block;
    margin-right: 0.5rem;
}

.mr-2 {
    margin-right: 0.5rem;
}
</style>
