<!--
  -- Component name: TranslateRTL
  -- Type: Transition
  -- Uses: 
  -- Tags: translation
  -->
<template></template>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {};
    },

    computed: {},

    methods: {
        getTimeline() {
            let t = gsap.timeline();
            this._timeline = t;
            t.fromTo(
                [this.backgroundInElement, this.sequenceInElement],
                { xPercent: 100 },
                { duration: 1.3, xPercent: 0, ease: 'power1.inOut' },
                0
            );
            return t;
        }
    }
};
</script>
