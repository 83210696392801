<template>
    <captions-editing-container
        v-if="filteredCaptions.length > 0"
        :store-module-path="storeModulePath"
        :type="type"
        :parent-store-module-path="parentStoreModulePath"
        :sequence-id="sequenceId"
    >
        <captions-text-container :captions="captions" :font="font" :palette="palette">
            <captions-animation
                :captions="filteredCaptions"
                :animation="animation"
                :store-module-path="storeModulePath"
                ref="animationComponentRef"
                @update="updateTimeline"
            />
        </captions-text-container>
    </captions-editing-container>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import gsap from 'gsap';
import { useCaptions } from '@/js/videos/composables/caption/useCaptions.ts';
import CaptionsTextContainer from '@/js/video-studio/components/captions/CaptionsTextContainer.vue';
import CaptionsEditingContainer from '@/js/video-studio/components/captions/CaptionsEditingContainer.vue';
import CaptionsAnimation from '@/js/video-studio/components/captions/CaptionsAnimation.vue';

const { storeModulePath, type, parentStoreModulePath, sequenceId } = defineProps<{
    storeModulePath: string;
    type: string;
    parentStoreModulePath: string;
    sequenceId?: string;
}>();

const emit = defineEmits<{
    (e: 'update'): void;
}>();

const animationComponentRef = ref<typeof CaptionsAnimation | null>(null);

const { animation, captions, palette, filteredCaptions, font } = useCaptions(
    storeModulePath,
    type,
    parentStoreModulePath,
    sequenceId
);

let timeline: GSAPTimeline = gsap.timeline();

const buildTimeline = (): void => {
    timeline.seek(0);
    timeline.clear();
    timeline.kill();
    timeline = gsap.timeline({ paused: true });

    if (animationComponentRef.value) {
        timeline.add(animationComponentRef.value.getTimeline().paused(false), 0);
    }
};

const updateTimeline = (): void => {
    buildTimeline();
    emit('update');
};

const getTimeline = (): GSAPTimeline => {
    buildTimeline();
    return timeline;
};

watch(filteredCaptions, updateTimeline, { deep: true });

defineExpose<{
    getTimeline: () => GSAPTimeline;
}>({
    getTimeline
});
</script>
