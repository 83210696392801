<template>
    <div class="ui-captions-editor show" ref="captionsEditorRef">
        <ui-captions-editor-header />
        <ui-captions-editor-style />
        <ui-captions-editor-content />
        <ui-captions-editor-footer />
    </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref } from 'vue';
import { useCaptionsEditor } from '@/js/videos/composables/caption/useCaptionsEditor.ts';
import UiCaptionsEditorContent from '@/js/videos/components/ui/body/editors/captions/UiCaptionsEditorContent.vue';
import UiCaptionsEditorStyle from '@/js/videos/components/ui/body/editors/captions/UiCaptionsEditorStyle.vue';
import UiCaptionsEditorFooter from '@/js/videos/components/ui/body/editors/captions/UiCaptionsEditorFooter.vue';
import UiCaptionsEditorHeader from '@/js/videos/components/ui/body/editors/captions/UiCaptionsEditorHeader.vue';

const { editorState, closeEditor } = useCaptionsEditor();

const captionsEditorRef = ref<HTMLDivElement | null>(null);

const handleClickOutside = (e: MouseEvent) => {
    if (!captionsEditorRef.value?.contains(e.target as Node)) {
        closeEditor();
    }
};

onMounted(() => {
    if (editorState.value.closeWhenClickOutside) {
        document.addEventListener('click', handleClickOutside);
    }
});

onUnmounted(() => {
    document.removeEventListener('click', handleClickOutside);
});
</script>
