<template>
    <captions
        v-if="enableCaptions"
        ref="captionsRef"
        :store-module-path="storeModulePath"
        type="voiceover"
        :parent-store-module-path="parentStoreModulePath"
        @update="updateTimeline"
    />
</template>

<script setup lang="ts">
import { computed, defineProps, ref } from 'vue';
import gsap from 'gsap';
import { useMedia } from '@/js/videos/composables/useMedia.ts';
import Captions from '@/js/video-studio/components/captions/Captions.vue';

const props = defineProps<{
    storeModulePath: string;
    parentStoreModulePath: string;
}>();

const emit = defineEmits<{
    (e: 'update'): void;
}>();

const { mediaState } = useMedia(props.storeModulePath);

const enableCaptions = computed(() => {
    return !!mediaState.value.src;
});

const captionsRef = ref<typeof Captions | null>(null);

let timeline: GSAPTimeline = gsap.timeline();

const buildTimeline = (): void => {
    timeline = gsap.timeline({ paused: true });

    if (captionsRef.value) {
        timeline.add(captionsRef.value.getTimeline().paused(false), 0);
    }
};

const updateTimeline = (): void => {
    buildTimeline();
    emit('update');
};

const getTimeline = (): GSAPTimeline => {
    buildTimeline();
    return timeline;
};

defineExpose<{
    getTimeline: () => GSAPTimeline;
}>({
    getTimeline
});
</script>
