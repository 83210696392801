<template>
    <header class="ui-header">
        <div class="ui-header-brand">
            <a :href="backUrl" class="ui-header-brand-logo" :title="backLabel" v-once>
                <span class="visually-hidden">{{ backLabel }}</span>
                <svg-id-randomized-icon icon="cte-logo" />
            </a>
            <a :href="backUrl" class="ui-header-back" :title="backLabel" v-once>
                <span class="visually-hidden">{{ backLabel }}</span>
                <svg-icon icon="chevron-left-icon" />
            </a>
        </div>
        <div class="ui-header-content">
            <div class="ui-header-details">
                <h1 class="ui-header-title" :aria-description="$t('Video name')">
                    <input
                        id="video-name-input"
                        ref="$videoNameInput"
                        class="video-name"
                        v-model.trim.lazy="videoName"
                        :disabled="readOnly"
                        @focus="startEditVideoName"
                        @blur="stopEditVideoName"
                        @keyup.enter="stopEditVideoName"
                    />
                    <label class="visually-hidden" for="video-name-input">{{ $t('Edit video name') }}</label>
                    <span class="video-name-placeholder" aria-hidden="true">{{ videoName }}</span>
                </h1>

                <div class="ui-header-actions">
                    <button
                        v-if="!autoSave"
                        class="ui-action-button action-save"
                        :title="saveLabel"
                        :disabled="readOnly"
                        @click="manualSaveVideo"
                    >
                        <span class="visually-hidden" v-once>{{ saveLabel }}</span>
                        <fa-icon class="icon" icon="fa-floppy-disk" />
                    </button>
                    <button
                        class="ui-action-button action-undo"
                        :title="undoLabel"
                        :disabled="!canUndo"
                        v-tooltip="$t('Undo')"
                        @click="undo"
                    >
                        <span class="visually-hidden" v-once>{{ undoLabel }}</span>
                        <svg-icon icon="undo-icon" />
                    </button>
                    <button
                        class="ui-action-button action-redo"
                        :title="redoLabel"
                        :disabled="!canRedo"
                        v-tooltip="$t('Redo')"
                        @click="redo"
                    >
                        <span class="visually-hidden" v-once>{{ redoLabel }}</span>
                        <svg-icon icon="redo-icon" />
                    </button>
                    <!--<ui-switch-button
                        class="action-edition-mode video-onboarding-step-5"
                        id="edition-mode"
                        :label="$t('Change edition mode')"
                        :show-label="false"
                        v-model="editionModeToggle"
                        :disabled="readOnly || !canChangeEditionMode"
                        :tooltip="$t('Switch mode')"
                    >
                        <template #switch-icon-off>
                            <svg-icon icon="content-mode-icon" />
                        </template>
                        <template #switch-icon-on>
                            <svg-icon icon="emotion-mode-icon" />
                        </template>
                    </ui-switch-button>-->
                </div>
            </div>
            <div class="ui-header-feedback" :class="feedbackClasses">
                <span>{{ feedbackMessage }}&nbsp;</span>
                <a v-if="!!feedbackDetails" href="#" :title="feedbackDetails" @click.prevent="">{{
                    $t('Details...')
                }}</a>
            </div>
        </div>
    </header>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { printf as sprintf } from 'fast-printf';
import {
    CONTENT_EDITION_MODE,
    EDITION_MODE_CLASS_PREFIX,
    EMOTION_EDITION_MODE,
    SEQUENCE_TEMPLATE_STATUS_COMPLETED,
    SEQUENCE_TEMPLATE_STATUS_ERROR,
    STATUS_AUTH_ERROR,
    STATUS_REFRESHING_TOKEN,
    STATUS_SAVE_ERROR,
    STATUS_SAVED,
    STATUS_SAVING,
    STATUS_UNSAVED,
    TTS_STATUS_COMPLETED,
    TTS_STATUS_ERROR
} from '../../../../constants';
import { getShortcutString } from '../../../../utils';
import { UiIcon, UiSwitchButton } from '../../../../components';
import { ProvidesIcons, UsesTooltip } from '../../../../mixins';
import { ModifiesHistory, SavesVideo } from '../../../mixins';
import router from '@/router/index.js';

export default {
    mixins: [ProvidesIcons, ModifiesHistory, SavesVideo, UsesTooltip],

    components: {
        UiIcon,
        UiSwitchButton
    },

    data() {
        return {
            editVideoName: false
        };
    },

    computed: {
        ...mapState({
            state: (state) => state.ui,
            readOnly: (state) => state.ui.readOnly,
            autoSave: (state) => state.ui.autoSave,
            sequenceMode: (state) => state.ui.sequenceMode,
            editionMode: (state) => state.ui.editionMode,
            settings: (state) => state.settings,
            isCte: (state) => state.ui.isCte
        }),

        ...mapGetters('ui', ['canChangeEditionMode']),

        ...mapGetters('ui/history', ['canUndo', 'canRedo']),

        ...mapGetters('detection', ['shortcutKey']),

        backLabel() {
            return !this.settings.isTemplate ? this.$t('Back to project') : this.$t('Back to brand');
        },

        saveLabel() {
            // TRANSLATORS: Placeholder is for keyboard shortcut
            return sprintf(this.$t('Save (%1$s)'), getShortcutString(this.shortcutKey, 'S'));
        },

        undoLabel() {
            // TRANSLATORS: Placeholder is for keyboard shortcut
            return sprintf(this.$t('Undo (%1$s)'), getShortcutString(this.shortcutKey, 'Z'));
        },

        redoLabel() {
            // TRANSLATORS: Placeholder is for keyboard shortcut
            return sprintf(this.$t('Redo (%1$s)'), getShortcutString(this.shortcutKey, 'Z', true));
        },

        videoName: {
            get() {
                return this.settings.name;
            },
            set(value) {
                value = value.replace(/\s+/g, ' ');
                if (!/\S/.test(value))
                    value = !this.sequenceMode ? this.$t('Untitled video') : this.$t('Untitled sequence');
                this.startHistoryStep();
                this.$store.commit('settings/setName', value);
                if (this.sequenceMode) {
                    this.$store.commit(
                        'sequences/' + this.$store.getters['sequences/childAt'](0).id + '/setName',
                        value
                    );
                }
                this.saveVideo();
            }
        },

        editionModeToggle: {
            get() {
                return this.editionMode == EMOTION_EDITION_MODE;
            },
            set(value) {
                this.updateEditionMode(value ? EMOTION_EDITION_MODE : CONTENT_EDITION_MODE);
            }
        },

        hasFeedbackSuccess() {
            return (
                this.state.ttsStatus == TTS_STATUS_COMPLETED ||
                this.state.sequenceTemplateStatus == SEQUENCE_TEMPLATE_STATUS_COMPLETED
            );
        },

        hasFeedbackError() {
            return (
                this.readOnly ||
                (this.state.ttsStatus !== TTS_STATUS_COMPLETED && !!this.state.ttsStatus) ||
                this.state.sequenceTemplateStatus == SEQUENCE_TEMPLATE_STATUS_ERROR ||
                this.state.saveStatus == STATUS_SAVE_ERROR ||
                this.state.saveStatus == STATUS_AUTH_ERROR
            );
        },

        feedbackClasses() {
            return {
                'ui-header-feedback-success': this.hasFeedbackSuccess,
                'ui-header-feedback-error': this.hasFeedbackError
            };
        },

        feedbackMessage() {
            if (this.readOnly) return this.$t('studio.notices.read_only');

            if (!!this.state.ttsStatus) {
                switch (this.state.ttsStatus) {
                    case TTS_STATUS_COMPLETED:
                        return this.$t('AI Voice is ready');
                    case TTS_STATUS_ERROR:
                        return this.$t('errors.tts.default');
                    default:
                        return this.$t('errors.tts.' + this.state.ttsStatus);
                }
            }

            if (!!this.state.sequenceTemplateStatus) {
                switch (this.state.sequenceTemplateStatus) {
                    case SEQUENCE_TEMPLATE_STATUS_COMPLETED:
                        return this.$t('The template has been created');
                    case SEQUENCE_TEMPLATE_STATUS_ERROR:
                        return this.$t('errors.templates.create');
                }
            }

            switch (this.state.saveStatus) {
                case STATUS_SAVING:
                case STATUS_REFRESHING_TOKEN:
                    return this.$t('Saving modifications...');
                case STATUS_SAVED:
                    return this.$t('Modifications saved');
                case STATUS_UNSAVED:
                    return this.$t('Modifications unsaved');
                case STATUS_SAVE_ERROR:
                    return this.$t(
                        'errors.' +
                            (this.settings.isTemplate ? 'templates' : 'videos') +
                            '.' +
                            this.state.saveError.error
                    );
                case STATUS_AUTH_ERROR:
                    return this.$t('errors.' + this.state.saveError.error);
            }

            return '';
        },

        feedbackDetails() {
            if (this.readOnly && this.settings.needsMigration)
                return this.$t('errors.' + (this.settings.isTemplate ? 'templates' : 'videos') + '.version');

            if (this.hasFeedbackError) {
                if (!!this.state.sequenceTemplateError) {
                    return this.state.sequenceTemplateError.details || '';
                }
            }

            return this.state.saveError?.details || '';
        },

        backUrl() {
            if (!this.settings.isTemplate) {
                return (
                    window.location.origin +
                    router.resolve({
                        name: 'ShowFolder',
                        params: {
                            shortname: this.state.folderUuid
                        }
                    }).href
                );
            } else {
                const currentUrl = window.location.href;
                const match = currentUrl.match(/(https?:\/\/[^\/]+\/brands\/[^\/]+)/);
                return match ? match[1] + '#templates' : currentUrl;
            }
        }
    },

    watch: {
        editionModeToggle(newValue) {
            document.body.classList.toggle(EDITION_MODE_CLASS_PREFIX + EMOTION_EDITION_MODE, newValue);
            document.body.classList.toggle(EDITION_MODE_CLASS_PREFIX + CONTENT_EDITION_MODE, !newValue);
        }
    },

    methods: {
        ...mapActions({
            updateEditionMode: 'ui/updateEditionMode'
        }),

        sprintf,

        initKeyboardShortcuts() {
            document.addEventListener('keydown', this.handleKeyboardShortcuts);
        },

        handleKeyboardShortcuts(event) {
            if (event[this.shortcutKey] && !event.target.classList.contains('ProseMirror')) {
                switch (true) {
                    // Save: Ctrl/Cmd+S
                    case event.key.toUpperCase() == 'S' && !event.shiftKey:
                        event.preventDefault();
                        if (!event.repeat) this.manualSaveVideo();
                        break;
                    // Undo: Ctrl/Cmd+Z
                    case event.key.toUpperCase() == 'Z' && !event.shiftKey:
                        event.preventDefault();
                        if (!event.repeat && this.canUndo) this.undo();
                        break;
                    // Redo: Ctrl/Cmd+Shift+Z
                    case event.key.toUpperCase() == 'Z' && event.shiftKey:
                        event.preventDefault();
                        if (!event.repeat && this.canRedo) this.redo();
                        break;
                }
            }
        },

        manualSaveVideo() {
            if (!this.readOnly) {
                this.saveVideo(true);
            }
        },

        startEditVideoName() {
            this.editVideoName = true;
            this.$nextTick(() => {
                this.$refs['$videoNameInput'].setSelectionRange(0, this.videoName.length);
            });
        },

        stopEditVideoName() {
            this.editVideoName = false;
            this.$refs['$videoNameInput'].blur();
        }
    },

    mounted() {
        if (!this.isReadOnly) {
            this.initKeyboardShortcuts();
        }
    }
};
</script>
