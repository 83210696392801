<!--
  -- Component name: BubbleOpener
  -- Type: Transition
  -- Uses: color.start
  -- Tags: color
  -->
<template>
    <svg class="studio-sequence-transition studio-container" :class="classes" :id="this.containerID">
        <defs>
            <mask :id="svgMaskID">
                <rect x="0" y="0" width="100%" height="100%" fill="#fff" :letter-spacing="svgMaskRefreshValue" />
                <circle cx="50%" cy="50%" r="0" />
            </mask>
        </defs>
        <circle class="bubble-opener" cx="50%" cy="50%" r="0" :fill="state.color.start" :mask="svgMask" />
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            needsRefresh: true, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true, // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            inElementsAutoAppear: false // The in elements are automatically added to the timeline and appear from the start
        };
    },

    computed: {
        svgMaskID() {
            return this.containerID + '-bubble-opener-mask';
        },

        svgMaskRadius() {
            return Math.ceil(Math.sqrt(Math.pow(this.format.width, 2) + Math.pow(this.format.height, 2))) / 2;
        },

        svgMask() {
            return 'url(#' + this.svgMaskID + ')';
        },

        svgMaskRefreshValue() {
            return this.format && Math.random();
        }
    },

    watch: {
        svgMaskRadius: {
            handler() {
                this.updateTimeline();
            }
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline(),
                radius = this.svgMaskRadius + 25;
            t.fromTo(
                '#' + this.containerID + ' .bubble-opener',
                { attr: { r: 0 } },
                { duration: 0.5, attr: { r: radius }, ease: 'power2.in' },
                '-=1'
            );
            t.set([this.backgroundInElement, this.sequenceInElement], { autoAlpha: 1 }, 0.5);
            t.fromTo(
                '#' + this.svgMaskID + ' circle',
                { attr: { r: 0 } },
                { duration: 0.5, attr: { r: radius * 0.5 }, ease: 'power1.out' },
                0.8
            );
            t.fromTo(
                '#' + this.svgMaskID + ' circle',
                { attr: { r: radius * 0.5 } },
                { duration: 1, attr: { r: radius * 0.7 } },
                1.3
            );
            t.fromTo(
                '#' + this.svgMaskID + ' circle',
                { attr: { r: radius * 0.7 } },
                { duration: 0.2, attr: { r: radius }, ease: 'power1.in' },
                2.3
            );
            this._timeline = t;

            return t;
        }
    }
};
</script>
