<template>
    <ui-caption-button
        :store-module-path="elementStoreModulePath + '/video'"
        type="visual"
        :parent-store-module-path="elementStoreModulePath"
        :has-old-captions="hasOldCaptions"
        :media-id="videoState?.src__id"
        :media-src="videoState?.src"
        :readonly="disabled"
        @open-editor="handleOpenEditor"
    />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useElement } from '@/js/videos/composables/useElement';
import UiCaptionButton from '@/js/videos/components/ui/body/selectors/caption/button/UiCaptionButton.vue';
import { useCaptionsEditor } from '@/js/videos/composables/caption/useCaptionsEditor.ts';

const props = defineProps<{
    seqId: string;
    elementId: string;
    readOnly: boolean;
}>();

const { elementStoreModulePath, videoState } = useElement(props.seqId, props.elementId);
const { openEditor } = useCaptionsEditor();

const oldCaptions = computed(() => videoState.value.captions || []);

const hasOldCaptions = computed(() => {
    return oldCaptions.value.length > 0 && oldCaptions.value[0].text.length > 0;
});

const disabled = computed(() => {
    return props.readOnly || !videoState.value?.src;
});

const handleOpenEditor = (): void => {
    openEditor(
        videoState.value?.src__id,
        elementStoreModulePath.value + '/video',
        'visual',
        elementStoreModulePath.value,
        props.seqId
    );
};
</script>
