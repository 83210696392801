<!--
  -- Component name: SG6
  -- Type: Transition
  -- Uses: color.start
  -- Tags: sg
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <rect class="color-move-1" fill="#000" x="0" y="0" width="100%" height="100%" />
        <rect class="color-move-3" :fill="state.color.start" x="0" y="0" width="100%" height="100%" />
        <rect class="color-move-2" fill="#fff" x="0" y="0" width="100%" height="100%" />
    </svg>
</template>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            isAbove: true
        };
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            const t = gsap.timeline();
            this._timeline = t;

            t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 }, 0.001);

            t.fromTo(
                '#' + this.containerID + ' .color-move-1',
                { yPercent: -100 },
                {
                    yPercent: 100,
                    duration: 2,
                    ease: CustomEase.create('custom', 'M0,0 C0.272,0 0.554,0.77 0.666,0.77 0.849,0.77 0.944,0.814 1,1 ')
                },
                0.2
            );
            t.fromTo(
                '#' + this.containerID + ' .color-move-1',
                { height: '50%' },
                { height: '150%', duration: 2, ease: 'power1.inOut' },
                0.2
            );

            t.fromTo(
                '#' + this.containerID + ' .color-move-2',
                { yPercent: -100 },
                { yPercent: 100, duration: 2, ease: 'power1.inOut' },
                0.4
            );
            t.fromTo(
                '#' + this.containerID + ' .color-move-2',
                { height: '40%' },
                { height: '130%', duration: 1.6, ease: 'power1.inOut' },
                0.4
            );

            t.fromTo(
                '#' + this.containerID + ' .color-move-3',
                { yPercent: -200 },
                { yPercent: 100, duration: 2, ease: 'power1.inOut' },
                0.5
            );
            t.fromTo(
                '#' + this.containerID + ' .color-move-3',
                { height: '40%' },
                { height: '150%', duration: 1.6, ease: 'power1.inOut' },
                0.5
            );

            return t;
        }
    }
};
</script>
