<template>
    <ui-voice-over-caption-selector :use-in-sequence="useInSequence" :seq-id="seqId" :read-only="readOnly" />

    <ui-voice-over-time-range-selector
        v-if="seqId"
        :use-in-sequence="useInSequence"
        :seq-id="seqId"
        :read-only="readOnly"
        :icon-only="true"
    />

    <ui-voice-over-volume-selector
        :use-in-sequence="useInSequence"
        :seq-id="seqId"
        :read-only="readOnly"
        :icon-only="true"
        menu-placement="bottom"
    />

    <ui-voice-over-speed-duration-selector :use-in-sequence="useInSequence" :seq-id="seqId" />
</template>

<script setup>
import UiVoiceOverCaptionSelector from '@/js/videos/components/ui/body/selectors/caption/UiVoiceOverCaptionSelector.vue';
import UiVoiceOverVolumeSelector from '@/js/videos/components/ui/body/selectors/volume/UiVoiceOverVolumeSelector.vue';
import UiVoiceOverTimeRangeSelector from '@/js/videos/components/ui/body/selectors/time-range/UiVoiceOverTimeRangeSelector.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import UiVoiceOverSpeedDurationSelector from '@/js/videos/components/ui/body/selectors/speed-duration/UiVoiceOverSpeedDurationSelector.vue';

const props = defineProps({
    seqId: String,
    cardElementId: String,
    useInSequence: Boolean
});

const { readOnly } = useVideo();
</script>
