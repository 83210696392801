<template>
    <template v-for="category in categories" :key="category">
        <ui-voice-over-caption-button
            v-if="category === voiceOverCategory"
            :category="category"
            :use-in-sequence="useInSequence"
            :seq-id="seqId"
            :read-only="readOnly"
        />
    </template>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';
import { TTS } from '@/js/video-studio/constants';
import UiVoiceOverCaptionButton from '@/js/videos/components/ui/body/selectors/caption/button/UiVoiceOverCaptionButton.vue';

const props = defineProps<{
    useInSequence: boolean;
    seqId?: string;
    readOnly: boolean;
}>();

const categories = TTS.CATEGORIES;

const store = useStore();

const voiceOverState = computed(() => {
    return props.useInSequence ? store.state.sequences[props.seqId]?.tts : store.state.settings.tts;
});

const voiceOverCategory = computed(() => voiceOverState.value.category);
</script>
