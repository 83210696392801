import type { Segment } from '../../../../types/segments';

interface TimeCalculationProps {
    segments: Segment[];
    playbackRate: number;
    videoEndTime: number;
}

/**
 * Calculate relative time based on absolute time and segments
 */
export function calculateRelativeTime(absoluteTime: number, { segments, playbackRate }: TimeCalculationProps): number {
    let elapsedTime = 0;
    let lastSegmentEnd = 0;

    for (const segment of segments) {
        if (absoluteTime < segment.start) {
            break;
        }

        if (absoluteTime >= segment.start && absoluteTime <= segment.end) {
            elapsedTime += absoluteTime - segment.start;
            break;
        } else {
            elapsedTime += segment.end - Math.max(segment.start, lastSegmentEnd);
        }

        lastSegmentEnd = segment.end;
    }

    return Math.round((elapsedTime / playbackRate) * 1000) / 1000;
}

/**
 * Calculate absolute time based on relative time and segments
 */
export function calculateAbsoluteTime(relativeTime: number, { segments, playbackRate }: TimeCalculationProps): number {
    let targetTime = 0;
    let accumulatedDuration = 0;

    for (const segment of segments) {
        const segmentDuration = (segment.end - segment.start) / playbackRate;
        if (relativeTime <= accumulatedDuration + segmentDuration) {
            const timeInSegment = relativeTime - accumulatedDuration;
            targetTime = segment.start + timeInSegment * playbackRate;
            break;
        }
        accumulatedDuration += segmentDuration;
    }

    // Si on dépasse la durée totale, on se positionne à la fin du dernier segment
    if (targetTime === 0 && segments.length > 0) {
        const lastSegment = segments[segments.length - 1];
        targetTime = lastSegment.end;
    }

    return targetTime;
}

/**
 * Calculate time position in pixels
 */
export function calculateTimePosition(time: number, { videoEndTime }: TimeCalculationProps, canvasWidth: number): number {
    return (time / videoEndTime) * canvasWidth;
} 