<template>
    <div class="ui-caption-translation-form">
        <div class="ui-content-row">
            <label>{{ $t('Language') }}</label>
            <dropdown v-model="selectedLanguageId" class="dropdown-small">
                <template #dropdown-selected-value>
                    <span v-if="selectedLanguage">{{ selectedLanguage.label }}</span>
                </template>
                <ui-scrollable>
                    <dropdown-item v-for="lang in translateOptions" :key="lang.id" :value="lang.id">
                        <span class="dropdown-list-item-label">{{ lang.label }}</span>
                    </dropdown-item>
                </ui-scrollable>
            </dropdown>
        </div>
        <div class="ui-content-row flex-end">
            <button class="ui-simple-button ui-simple-button-select" @click="startTranslating">
                {{ $t('captions.translation.translate') }}
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import Dropdown from '@/js/components/dropdown/Dropdown.vue';
import DropdownItem from '@/js/components/dropdown/DropdownItem.vue';
import { useTranslateOptions } from '@/js/composables/useTranslateOptions';
import CaptionService from '@/js/videos/application/services/caption/CaptionService.ts';
import UiScrollable from '@/js/components/UiScrollable.vue';
import { AxiosResponse } from 'axios';
import { CaptionUnit } from '@/js/videos/types/captions';
import { useCaptions } from '@/js/videos/composables/caption/useCaptions';
import { useNotification } from '@/js/composables/useNotification';
import { useI18n } from 'vue-i18n';
const props = defineProps<{
    mediaId: string;
    storeModulePath: string;
    type: string;
    parentStoreModulePath: string;
}>();

const store = useStore();
const { t } = useI18n();
const { baseCaptions, commitModulePath, resetCaptions, wordsNumber, manuallyUpdated } = useCaptions(
    props.storeModulePath,
    props.type,
    props.parentStoreModulePath
);
const { translateOptions, fetchTranslateOptions } = useTranslateOptions();
const { success, error } = useNotification('toast', false);

type lang = { id: number; label: string; db_video_language: string };

const defaultLanguageId: number = 0;
const selectedLanguageId = ref<number>(defaultLanguageId);

const selectedLanguage = computed<lang>(() => {
    return translateOptions.value.find((lang) => lang.id === selectedLanguageId.value);
});

const setTranslatingStatus = () => {
    store.commit(commitModulePath.value + '/updateStatus', 'translating');
};

const handleError = (): void => {
    store.commit(commitModulePath.value + '/updateStatus', null);
    error(t('captions.translation.error'));
};

const handleSuccess = (result: CaptionUnit[]): void => {
    resetCaptions();
    store.commit(commitModulePath.value + '/updateCaptions', [...result]);
    store.commit(commitModulePath.value + '/updateBaseCaptions', [...result]);
    store.commit(commitModulePath.value + '/updateStatus', null);

    manuallyUpdated.value = false;

    // Reset words number to recreate bunch of captions
    wordsNumber.value = wordsNumber.value;

    success(t('captions.translation.success'));
};

const checkResult = (response: AxiosResponse): void => {
    if (response.data?.data) {
        return handleSuccess(response.data.data);
    }

    return handleError();
};

const startTranslating = (): void => {
    setTranslatingStatus();

    new CaptionService(store)
        .createTranslationTask(baseCaptions.value, selectedLanguage.value.label)
        .then(checkResult)
        .catch(handleError);
};

defineExpose({
    startTranslating
});

onMounted((): void => {
    fetchTranslateOptions();
});
</script>
