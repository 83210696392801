<template>
    <ui-background-caption-selector
        v-if="hasVideoBackground || hasRecordingBackground"
        :seq-id="seqId"
        :card-element-id="cardElementId"
        :read-only="readOnly || isMediaConverting"
        :tooltip="isMediaConverting ? $t('media.preparation.warning') : ''"
        :icon-only="true"
    />

    <ui-background-video-time-range-selector
        v-if="hasVideoBackground || hasRecordingBackground || hasAnimatedBackground"
        :seq-id="seqId"
        :card-element-id="cardElementId"
        :read-only="readOnly || isMediaConverting"
        :tooltip="isMediaConverting ? $t('media.preparation.warning') : ''"
        :icon-only="true"
    />

    <ui-background-volume-selector
        v-if="hasVideoBackground || hasRecordingBackground || hasAnimatedBackground"
        :seq-id="seqId"
        :card-element-id="cardElementId"
        :readOnly="readOnly"
        :icon-only="true"
    />

    <ui-background-video-speed-selector
        v-if="hasVideoBackground || hasRecordingBackground || hasAnimatedBackground"
        :seq-id="seqId"
        :element-id="cardElementId"
        :read-only="readOnly || isMediaConverting"
        :tooltip="isMediaConverting ? $t('media.preparation.warning') : ''"
        :icon-only="true"
    />

    <ui-background-effect-selector
        v-if="!hasColorBackground"
        ref="effectSelector"
        :seq-id="seqId"
        :card-element-id="cardElementId"
    />
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import UiBackgroundCaptionSelector from '@/js/videos/components/ui/body/selectors/caption/UiBackgroundCaptionSelector.vue';
import UiBackgroundVideoTimeRangeSelector from '@/js/videos/components/ui/body/selectors/time-range/UiBackgroundVideoTimeRangeSelector.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { Background } from '@/js/video-studio/constants/index.js';
import { useSequence } from '@/js/videos/composables/useSequence.js';
import UiBackgroundVolumeSelector from '@/js/videos/components/ui/body/selectors/volume/UiBackgroundVolumeSelector.vue';
import UiBackgroundVideoSpeedSelector from '@/js/videos/components/ui/body/selectors/speed/UiBackgroundVideoSpeedSelector.vue';
import UiBackgroundEffectSelector from '@/js/videos/components/ui/body/selectors/effect/UiBackgroundEffectSelector.vue';

const props = defineProps({
    seqId: String,
    cardElementId: String
});

const store = useStore();
const { readOnly } = useVideo();
const { hasVideoBackground, hasRecordingBackground, hasColorBackground, hasAnimatedBackground, backgroundVideo } =
    useSequence(props.seqId);

const effectSelector = ref(null);
defineExpose({ effectSelector });

const isMediaConverting = computed(
    () => backgroundVideo.value.src && store.getters['loading/isConverting'](backgroundVideo.value.src)
);
</script>
