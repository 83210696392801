<!--
  -- Component name: ColorArrow
  -- Type: Transition
  -- Uses: color.start
  -- Tags: color
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <polygon
            :fill="state.color.start"
            class="color-arrow"
            points="0,200 640,0 1280,200 1280,1600 640,1400 0,1600"
        />
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            needsRefresh: true,
            isAbove: true,
            inElementsAutoAppear: false
        };
    },

    computed: {},

    watch: {},

    methods: {
        getTimeline() {
            let t = gsap.timeline();
            this._timeline = t;
            t.fromTo(
                '#' + this.containerID + ' .color-arrow',
                { yPercent: 100 },
                { yPercent: -33, duration: 0.5, ease: 'power2.in' }
            );
            t.fromTo(
                '#' + this.containerID + ' .color-arrow',
                { yPercent: -33 },
                { yPercent: -100, duration: 0.5, ease: 'power2.in' },
                0.6
            );
            t.set([this.backgroundInElement, this.sequenceInElement], { autoAlpha: 1 }, 0.5);
            t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 }, 0.5);

            return t;
        }
    }
};
</script>
