<!--
  -- Component name: SG9
  -- Type: Transition
  -- Uses: 
  -- Tags: sg
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        :viewBox="dataForView.viewBox"
        preserveAspectRatio="xMidYMid slice"
    >
        <defs>
            <clipPath
                :id="svgMaskID"
                clipPathUnits="objectBoundingBox"
                :style="clipPathTransform"
                v-if="isPortraitFormat"
            >
                <rect
                    class="sg-four"
                    :x="dataForView.width"
                    y="0"
                    :width="dataForView.width * 0.8"
                    :height="dataForView.height * dataForView.heightRatio"
                />
                <rect
                    class="sg-three"
                    :x="-dataForView.width"
                    :y="getYTwo"
                    :width="dataForView.width * 0.8"
                    :height="dataForView.height * dataForView.heightRatio"
                />
                <rect
                    class="sg-two"
                    :x="dataForView.width"
                    :y="getYThree"
                    :width="dataForView.width * 0.8"
                    :height="dataForView.height * dataForView.heightRatio"
                />
                <rect
                    class="sg-one"
                    :x="-dataForView.width"
                    :y="getYFour"
                    :width="dataForView.width * 0.8"
                    :height="dataForView.height * dataForView.heightRatio"
                />
            </clipPath>
            <clipPath :id="svgMaskID" clipPathUnits="objectBoundingBox" :style="clipPathTransform" v-else>
                <rect class="top" :x="-dataForView.width" :y="getTopY" :width="getWidth" height="40%" />
                <rect class="bottom" :x="dataForView.width" :y="getBottomY" :width="getWidth" height="40%" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            needsRefresh: true,
            keepActive: true
        };
    },

    computed: {
        svgMaskID() {
            return this.containerID + '-sg9';
        },

        svgMask() {
            return 'url(#' + this.svgMaskID + ')';
        },

        clipPathTransform() {
            return this.format && this.getClipPathTransform(this.dataForView.width, this.dataForView.height);
        },

        isLandscapeFormat() {
            return this.format.width > this.format.height;
        },

        isPortraitFormat() {
            return this.format.width < this.format.height;
        },

        isSquareFormat() {
            return this.format.width === this.format.height;
        },

        getWidth() {
            return this.isLandscapeFormat ? '60%' : '90%';
        },

        getBottomY() {
            return this.dataForView.height * 0.55;
        },

        getTopY() {
            return this.dataForView.height * 0.05;
        },

        getX() {
            return this.isLandscapeFormat ? this.dataForView.width * 0.2 : this.dataForView.width * 0.05;
        },

        getYTwo() {
            return this.dataForView.height * this.dataForView.heightRatio + this.dataForView.height * 0.04;
        },

        getYThree() {
            return (
                this.getYTwo + this.dataForView.height * this.dataForView.heightRatio + this.dataForView.height * 0.04
            );
        },

        getYFour() {
            return (
                this.getYThree + this.dataForView.height * this.dataForView.heightRatio + this.dataForView.height * 0.04
            );
        },

        dataForView() {
            if (this.isLandscapeFormat) {
                return {
                    viewBox: '0 0 1920 1080',
                    height: 1080,
                    width: 1920
                };
            }

            if (this.isSquareFormat) {
                return {
                    viewBox: '0 0 1000 1000',
                    height: 1000,
                    width: 1000
                };
            }

            if (this.isPortraitFormat) {
                return {
                    width: 1080,
                    height: 1920,
                    size: 960,
                    viewBox: '0 0 1080 1920',
                    heightRatio: 0.22
                };
            }
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            const t = gsap.timeline();
            this._timeline = t;

            t.set(this, { inClipPathProxy: this.svgMask }, 0.0001);

            // Enter in viewport
            if (this.isPortraitFormat) {
                t.to(
                    '#' + this.containerID + ' .sg-four',
                    { attr: { x: this.dataForView.width * 0.1 }, duration: 1, ease: 'power2.inOut', delay: 0.1 },
                    0
                );
                t.to(
                    '#' + this.containerID + ' .sg-two',
                    { attr: { x: this.dataForView.width * 0.1 }, duration: 1, ease: 'power2.inOut', delay: 0.1 },
                    0
                );
                t.to(
                    '#' + this.containerID + ' .sg-three',
                    { attr: { x: this.dataForView.width * 0.1 }, duration: 1, ease: 'power2.inOut' },
                    0
                );
                t.to(
                    '#' + this.containerID + ' .sg-one',
                    { attr: { x: this.dataForView.width * 0.1 }, duration: 1, ease: 'power2.inOut' },
                    0
                );
            } else {
                t.to(
                    '#' + this.containerID + ' .top',
                    { attr: { x: this.getX }, duration: 1, ease: 'power3.inOut' },
                    0
                );
                t.to(
                    '#' + this.containerID + ' .bottom',
                    { attr: { x: this.getX }, duration: 1, ease: 'power3.inOut', delay: 0.1 },
                    0
                );
            }

            // Scale to fill viewport
            if (this.isPortraitFormat) {
                t.to(
                    '#' + this.containerID + ' .sg-four',
                    { duration: 0.5, scaleY: 1.25, scaleX: 1.3, ease: 'power2.inOut', transformOrigin: '50% 50%' },
                    1
                );
                t.to(
                    '#' + this.containerID + ' .sg-two',
                    { duration: 0.5, scaleY: 1.25, scaleX: 1.3, ease: 'power2.inOut', transformOrigin: '50% 50%' },
                    1
                );
                t.to(
                    '#' + this.containerID + ' .sg-three',
                    { duration: 0.5, scaleY: 1.25, scaleX: 1.3, ease: 'power2.inOut', transformOrigin: '50% 50%' },
                    1
                );
                t.to(
                    '#' + this.containerID + ' .sg-one',
                    { duration: 0.5, scaleY: 1.25, scaleX: 1.3, ease: 'power2.inOut', transformOrigin: '50% 50%' },
                    1
                );
            } else {
                t.to(
                    '#' + this.containerID + ' .top',
                    {
                        duration: 0.5,
                        scaleY: 1.25,
                        scaleX: this.isLandscapeFormat ? 2 : 1.3,
                        ease: 'power2.inOut',
                        transformOrigin: '50% 50%'
                    },
                    1
                );
                t.to(
                    '#' + this.containerID + ' .bottom',
                    {
                        duration: 0.5,
                        scaleY: 1.25,
                        scaleX: this.isLandscapeFormat ? 2 : 1.3,
                        ease: 'power2.inOut',
                        transformOrigin: '50% 50%'
                    },
                    1
                );
            }

            t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 });

            return t;
        }
    }
};
</script>
