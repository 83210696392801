<!--
  -- Component name: Number9
  -- Type: Transition
  -- Uses: color.start
  -- Tags: number
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <defs>
            <mask :id="svgMaskID">
                <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
                <path
                    class="color-number"
                    d="M709.7,363.7c-10,2.1-18.9,4.4-28,5.9c-24.4,4-48.7,4.4-72.6-2.9c-24.7-7.6-40.3-23.8-45.6-49.4
				c-3.8-18.4-3.7-36.9,1.7-54.8c9.6-31.6,32.2-49,63.8-55.3c17.4-3.4,35-3.2,52.4,0.4c30.4,6.2,50,24.9,60.8,53.5
				c5.9,15.8,8.6,32.3,9.8,49.1c0.8,11.4,2.1,22.9,1.7,34.4c-0.8,26.2-3.2,52.2-13.2,76.9c-12.6,31.4-36.1,49.1-68.4,56.2
				c-19.7,4.3-39.7,3.9-59.6,2c-11.6-1.1-23-3.2-34.6-4.8c-3.1-0.4-4.4-1.6-3.8-5c1.3-8.3,2.1-16.7,3.2-25.1c0.5-3.5,2-4.6,5.9-3.7
				c19,4.6,38.3,7.6,57.8,6.9c17.8-0.7,35-4.2,48.4-17.6c7.1-7.1,11.9-15.6,14.2-25.2c2.7-11.4,4.2-23.1,6.1-34.8
				C709.9,368.2,709.7,366.2,709.7,363.7z M654.8,339.2c17.4,0,34-3.8,50.3-9.1c3.5-1.1,4.9-2.9,4.6-6.5
				c-1.1-11.9-1.6-23.8-3.3-35.6c-1.5-10.8-4.5-21.3-10.9-30.4c-12-16.8-29-21.4-48.5-19c-21.5,2.6-36.6,16.7-41.1,37.7
				c-2,9.1-2.4,18.3-0.9,27.5c2.6,16.7,9.3,28.7,28.5,32.8C640.5,338.1,647.7,338.3,654.8,339.2z"
                />
            </mask>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" :fill="state.color.start" :mask="svgMask" />
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
        };
    },

    computed: {
        svgMaskID() {
            return this.containerID + '-number9-mask';
        },

        svgMask() {
            return 'url(#' + this.svgMaskID + ')';
        }
    },

    watch: {
        svgMaskRadius: {
            handler() {
                this.updateTimeline();
            }
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            this._timeline = t;
            t.fromTo(
                '#' + this.svgMaskID + ' .color-number',
                { scale: 1 },
                { scale: 1.5, transformOrigin: '50% 50%', duration: 1 },
                0
            );
            t.fromTo(
                '#' + this.svgMaskID + ' .color-number',
                { scale: 1.5 },
                { scale: 50, translateX: '-100%', transformOrigin: '50% 50%', duration: 0.2 },
                1
            );
            return t;
        }
    }
};
</script>
