<template>
    <component :is="modalComponent" v-if="modalComponent" />
</template>

<script setup>
import { computed, defineAsyncComponent, h } from 'vue';
import { useStore } from 'vuex';
import VideoSavingErrorModal from '@/js/components/modals/VideoSavingErrorModal.vue';

const store = useStore();

const modalComponents = {
    VideoSavingErrorModal: VideoSavingErrorModal
};

const modalAsyncComponents = {
    ConfirmDeleteFolderModal: () => import('@/js/components/modals/ConfirmDeleteFolderModal.vue'),
    ConfirmDeleteVideosModal: () => import('@/js/components/modals/ConfirmDeleteVideosModal.vue'),
    ConfirmDuplicateVideosModal: () => import('@/js/components/modals/ConfirmDuplicateVideosModal.vue'),
    CopyVideoModal: () => import('@/js/components/modals/CopyVideoModal.vue'),
    CreateFolderModal: () => import('@/js/components/modals/CreateFolderModal.vue'),
    CreateVideoModal: () => import('@/js/components/modals/CreateVideoModal.vue'),
    TransformTemplateModal: () => import('@/js/components/modals/TransformTemplateModal.vue'),
    ShareFolderModal: () => import('@/js/components/modals/ShareFolderModal.vue'),
    UseTemplateModal: () => import('@/js/components/modals/UseTemplateModal.vue'),
    UseSequenceTemplateModal: () => import('@/js/components/modals/UseSequenceTemplateModal.vue'),
    EditProfileModal: () => import('@/js/components/modals/profile/EditProfileModal.vue'),
    ExportVideoModal: () => import('@/js/components/modals/export/ExportVideoModal.vue'),
    CGSModal: () => import('@/js/components/modals/CGSModal.vue'),
    EditUserModal: () => import('@/js/components/modals/user/EditUserModal.vue'),
    InviteUserModal: () => import('@/js/components/modals/user/InviteUserModal.vue'),
    WelcomeModal: () => import('@/js/components/modals/profile/WelcomeModal.vue'),
    TranslateModal: () => import('@/js/components/modals/TranslateModal.vue'),
    SubmitVideoValidationModal: () => import('@/js/components/modals/video/SubmitVideoValidation.vue'),
    ActionVideoValidationModal: () => import('@/js/components/modals/video/ActionVideoValidation.vue'),

    // Brands
    CreateBrandModal: () => import('@/js/components/modals/brand/CreateBrandModal.vue'),
    DuplicateBrandModal: () => import('@/js/components/modals/brand/DuplicateBrandModal.vue'),
    ApplyToFolderModal: () => import('@/js/components/modals/brand/ApplyBrandToFolderModal.vue'),
    CopyBrandToOrganizationModal: () => import('@/js/components/modals/brand/CopyBrandToOrganizationModal.vue'),
    DeleteBrandModal: () => import('@/js/components/modals/brand/DeleteBrandModal.vue'),

    // Captions
    MigrateVideoCaptionsModal: () => import('@/js/components/modals/video/MigrateVideoCaptionsModal.vue'),
    CaptionsWordsNumberChangeModal: () => import('@/js/components/modals/video/CaptionsWordsNumberChangeModal.vue')
};

const currentModal = computed(() => store.getters['modals/currentModal']);

const modalComponent = computed(() => {
    return (
        modalComponents[currentModal.value] ||
        defineAsyncComponent(() =>
            modalAsyncComponents[currentModal.value]()
                .then((module) => {
                    return module.default;
                })
                .catch((error) => {
                    console.error(`Unable to find modal component ${currentModal.value}`, error);
                    return h('div');
                })
        )
    );
});
</script>
