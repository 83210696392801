<template>
    <ui-dropdown
        :id="cardElementId + '-audio'"
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="bottom"
        menu-strategy="fixed"
        :disabled="readOnly || !videoState.src"
        :toggle-style="toggleStyle ? { color: 'var(--edit-mode-color)' } : {}"
        :icon-only="true"
        :tooltip="$t('Volume')"
    >
        <template #dropdown-toggle>
            <svg-icon icon="audio-icon" />
        </template>

        <ui-range-input
            :id="cardElementId + '-asset-audio-volume'"
            v-model="volume"
            :horizontal="false"
            :disabled="readOnly"
            :max="200"
            :label="$t('Video volume')"
        />
    </ui-dropdown>
</template>

<script setup>
import UiDropdown from '@/js/components/UiDropdown.vue';
import UiRangeInput from '@/js/components/UiRangeInput.vue';
import { useVisual } from '@/js/videos/composables/useVisual.js';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { computed } from 'vue';
import { useElement } from '@/js/videos/composables/useElement.js';

const props = defineProps({
    seqId: String,
    cardElementId: String,
    elementId: String
});

const { readOnly } = useVideo();
const { videoState } = useElement(props.seqId, props.elementId);
const { volume } = useVisual(props.seqId, props.elementId);

const toggleStyle = computed(() => volume.value !== 100);
</script>
