import { executeRequest } from '@/js/infrastructure/api/apiClient.js';

const EDITION_URL = import.meta.env.VITE_EDITION_API_URL;

export default class SequenceTemplateApiService {
    constructor(store) {
        this.store = store;
    }

    duplicate(sequence) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.post(`${EDITION_URL}/api/brands/${sequence.brandId}/sequences/${sequence.id}/copy`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'SequenceTemplateApiService.duplicate'
        });
    }

    fetchAll(brandId, options) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${EDITION_URL}/api/brands/${brandId}/sequence-templates`, { params: options });
            },
            onSuccess: async (response) => response.data,
            actionType: 'SequenceTemplateApiService.fetchAll'
        });
    }

    fetchSequenceTemplateThemes() {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${EDITION_URL}/api/sequences/themes`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'SequenceTemplateApiService.fetchSequenceTemplateThemes'
        });
    }
}
