import { calculateRelativeTime } from '../helpers/timeCalculations';

export function useCanvasInteraction({ sequenceProps, segments, currentTimePos, canvasWidth }) {
    const SEGMENT_EPSILON = 0.01; // 10 milliseconds

    const onCanvasClick = (event, containerElement) => {
        if (!containerElement) {
            return;
        }

        const rect = containerElement.getBoundingClientRect();
        const clickX = event.clientX - rect.left;
        let newTime = (clickX / containerElement.offsetWidth) * sequenceProps.endTime;

        // Check if the click is inside a segment
        let segmentIndex = segments.value.findIndex((segment) => newTime >= segment.start && newTime <= segment.end);

        // If not in a segment, find the closest segment
        if (segmentIndex === -1) {
            let closestSegmentIndex = -1;
            let minDistance = Infinity;
            let closestEdge = null;
            let isStartEdge = false;

            segments.value.forEach((segment, index) => {
                // Distance to segment start
                const distanceToStart = Math.abs(newTime - segment.start);
                if (distanceToStart < minDistance) {
                    minDistance = distanceToStart;
                    closestEdge = segment.start;
                    isStartEdge = true;
                    closestSegmentIndex = index;
                }

                // Distance to segment end
                const distanceToEnd = Math.abs(newTime - segment.end);
                if (distanceToEnd < minDistance) {
                    minDistance = distanceToEnd;
                    closestEdge = segment.end;
                    isStartEdge = false;
                    closestSegmentIndex = index;
                }
            });

            // If a close segment was found
            if (closestEdge !== null) {
                segmentIndex = closestSegmentIndex;

                // Adjust time to be just inside the segment
                if (isStartEdge) {
                    newTime = closestEdge + SEGMENT_EPSILON;
                } else {
                    newTime = closestEdge - SEGMENT_EPSILON;
                }
            }
        }

        // Update cursor position
        const containerWidth = canvasWidth.value;
        currentTimePos.value = (newTime / sequenceProps.endTime) * containerWidth;

        // Calculate relative time
        const timeProps = {
            segments: segments.value,
            playbackRate: sequenceProps.playbackRate,
            videoEndTime: sequenceProps.endTime
        };
        const relativeTime = calculateRelativeTime(newTime, timeProps);

        // Return time and segment index
        return {
            time: relativeTime,
            segmentIndex: segmentIndex
        };
    };

    return { onCanvasClick };
}
