<template>
    <div class="ui-library" :class="libraryClasses" @[transitionEndEvent]="emptyLibrary">
        <div class="ui-library-inner">
            <header class="ui-library-header">
                <h5 class="ui-library-title">{{ title }}</h5>

                <div v-if="libraryId === 'sequenceTemplates'" class="ui-library-search">
                    <ThemeSelector :showLabel="false" v-model="selectedThemes" class="ui-library-theme-selector" />
                </div>

                <button class="ui-library-close ui-action-button" @click.prevent="select">
                    <span class="visually-hidden">{{ $t('Close') }}</span>
                    <fa-icon class="icon" icon="fa-solid fa-xmark" />
                </button>
            </header>

            <ui-scrollable ref="$scrollable" :margin-bottom="-84" :margin-top="84">
                <div v-if="!isLibraryLoaded(libraryId)" class="ui-library-loader">
                    <svg-icon icon="dots-loader" />
                </div>
                <ul v-show="isLibraryLoaded(libraryId)" class="ui-library-tags">
                    <li
                        v-for="tag in libraryTags"
                        :key="'library-tag-' + tag"
                        :id="'ui-library-tag-' + tag"
                        class="ui-library-tag"
                        @transitionend="resizeScrollable"
                    >
                        <div v-if="hasTags" class="ui-library-tag-header">
                            <h4 v-if="librarySortedByTag[tag].length > 0">{{ getLibraryTagName(tag) }}</h4>
                            <div
                                v-if="librarySortedByTag[tag].length > libraryItemColumns"
                                class="ui-library-tag-toggle"
                            >
                                <input
                                    type="checkbox"
                                    :id="'library-tag-' + tag + '-toggle'"
                                    :checked="expandedTag == tag"
                                    @change="toggleTag(tag)"
                                />
                                <label :for="'library-tag-' + tag + '-toggle'">
                                    <span v-show="expandedTag != tag" class="expand">{{ $t('See more') }}</span>
                                    <span v-show="expandedTag == tag" class="reduce">{{ $t('Reduce') }}</span>
                                    <fa-icon class="icon" icon="fa-solid fa-chevron-right" />
                                </label>
                            </div>
                        </div>
                        <ul
                            class="ui-library-items"
                            :style="itemsStyles[tag]"
                            :class="{ 'ui-library-items-open': expandedTag === tag }"
                        >
                            <li v-for="item in librarySortedByTag[tag]" :key="'library-item-' + item.name">
                                <input
                                    type="checkbox"
                                    :id="'library-item-' + tag + '-' + item.name.toLowerCase()"
                                    class="ui-library-item"
                                    :style="item.styles"
                                    name="selected-item"
                                    :value="item.name"
                                    :checked="selectedItem == item.name && expandedTag === tag"
                                    :disabled="disabled"
                                    @click="handleItemClick(item.name, tag)"
                                />
                                <label :for="'library-item-' + tag + '-' + item.name.toLowerCase()">
                                    <svg
                                        v-if="item.createsNewItem"
                                        role="img"
                                        class="ui-library-item-border"
                                        aria-hidden="true"
                                    >
                                        <rect x="0" y="0" width="100%" height="100%" rx="10" fill="none" />
                                    </svg>
                                    <span class="ui-action-button ui-library-item-selected-button" aria-hidden="true">
                                        <fa-icon class="icon" icon="fa-solid fa-check" />
                                    </span>
                                    <span
                                        class="ui-library-item-name"
                                        :class="{ 'visually-hidden': !item.createsNewItem }"
                                        >{{ item.label || item.name }}</span
                                    >
                                </label>
                                <label
                                    v-if="!item.createsNewItem"
                                    :for="'library-item-' + tag + '-' + item.name.toLowerCase()"
                                    class="ui-library-item-index"
                                >
                                    {{ item.tagIndexes[tag] + 1 }}
                                </label>
                            </li>
                        </ul>
                    </li>
                </ul>
            </ui-scrollable>

            <footer v-if="isUniversalMusicLibrary" class="ui-library-universal-banner">
                <svg-icon icon="universal-music-logo" />
                <span>{{ $t('Access to the entire music repertoire') }}</span>
                <a :href="universalMusicDiscoverUrl" class="ui-library-universal-discover" target="_blank">{{
                    $t('Discover')
                }}</a>
            </footer>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import _isArray from 'lodash/isArray';
import _partition from 'lodash/partition';
import {
    CATEGORIES_INCLUDING_BRAND_NAME,
    LIBRARY_DEFAULT_TAG,
    LIBRARY_NEW_ITEM_ID,
    LIBRARY_SPECIFIC_TAG_CHAR,
    UNIVERSAL_MUSIC_CATEGORY
} from '../constants';
import { UsesTooltip } from '../mixins';
import { UI_LIBRARY_SELECTOR_HIDE } from './UiLibrarySelector.vue';
import UiScrollable from './UiScrollable.vue';
import { printf as sprintf } from 'fast-printf';
import ThemeSelector from '@/js/videos/components/ui/body/selectors/theme/ThemeSelector.vue';
import UiTextInput from '@/js/components/UiTextInput.vue';
import { useAuth } from '@/js/composables/useAuth';

const LIBRARY_ITEM_COLUMNS = 4;
const LIBRARY_NEW_TAG = 'new';

export default {
    mixins: [UsesTooltip],

    components: {
        UiScrollable,
        ThemeSelector,
        UiTextInput
    },

    props: {
        disabled: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            show: false,
            expandedTag: null,
            libraryItemColumns: LIBRARY_ITEM_COLUMNS,
            searchQuery: '',
            audioPreview: null
        };
    },

    computed: {
        ...mapState({
            shortLanguage: (state) => state.ui.shortLanguage,
            organizationName: (state) => state.ui.organizationName,
            libraries: (state) => state.branding.libraries,
            libraryId: (state) => state.ui.currentLibrary.id,
            libraryCategory: (state) => state.ui.currentLibrary.category,
            libraryTagOrder: (state) => state.ui.currentLibrary.tagOrder,
            selectedItemId: (state) => state.ui.currentLibrary.selectedItemId,
            selector: (state) => state.ui.currentLibrary.selector,
            universalMusicDiscoverUrl: (state) => state.ui.urls.universalMusicDiscover,
            allowedThemes: (state) => state.settings.allowedThemes
        }),

        ...mapGetters({
            assetAudioUrl: 'ui/assetAudioUrl',
            assetMusicUrl: 'ui/assetMusicUrl',
            isLibraryLoaded: 'branding/libraries/libraryIsLoaded',
            getLibrarySortedByTag: 'branding/libraries/librarySortedByTag',
            getLibraryTags: 'branding/libraries/libraryTags'
        }),

        library() {
            if (!this.libraryId) return [];
            return this.libraries[this.libraryId] || [];
        },

        librarySortedByTag() {
            let library = Object.entries(this.getLibrarySortedByTag(this.libraryId, this.libraryCategory)).reduce(
                (sorted, [tag, items]) => {
                    let filteredItems = items;

                    if (this.libraryId === 'sequenceTemplates' && this.selectedThemes.length > 0) {
                        filteredItems = items.filter((item) =>
                            item.themes?.some((theme) => this.selectedThemes.includes(theme))
                        );
                    }

                    if (this.libraryId === 'sequenceTemplates' && this.searchQuery) {
                        const query = this.searchQuery.toLowerCase();
                        filteredItems = filteredItems.filter(
                            (item) =>
                                item.name.toLowerCase().includes(query) ||
                                (item.label && item.label.toLowerCase().includes(query))
                        );
                    }

                    sorted[tag] = filteredItems
                        .reduce((modifiedItems, item, index) => {
                            item = { ...item };
                            item.isGenerating =
                                !!item.latest_produced_is_outdated ||
                                (!!item.thumbnails && !item.thumbnails.static_src);
                            item.styles = this.getItemStyles(item);

                            if (item.name == this._preselectedItemId) this.expandedTag = tag;

                            modifiedItems.push(item);

                            return modifiedItems;
                        }, [])
                        .sort((itemA, itemB) => {
                            return itemA.tagIndexes[tag] - itemB.tagIndexes[tag];
                        });

                    // musics must be shown in reverse order (last one at first place)
                    if (this.isMusicLibrary) sorted[tag].reverse();

                    return sorted;
                },
                {}
            );

            if (this.selector?.createsNewItem) {
                library = this.getLibraryWithCreateNewItem(library);
            }

            return library;
        },

        libraryTags() {
            let compareTagFunction =
                !this.libraryTagOrder || (_isArray(this.libraryTagOrder) && !this.libraryTagOrder.length)
                    ? this.compareTags
                    : !_isArray(this.libraryTagOrder)
                      ? this.libraryTagOrder
                      : (tagA, tagB) => this.libraryTagOrder.indexOf(tagA) - this.libraryTagOrder.indexOf(tagB);

            let [specificTags, tags] = _partition(this.getLibraryTags(this.libraryId, this.libraryCategory), (tag) =>
                this.$t(this.tagTranslationPath + tag).startsWith(LIBRARY_SPECIFIC_TAG_CHAR)
            );

            // filter specific tags alphabetically
            specificTags = specificTags.sort(this.compareTags);
            tags = tags.sort(compareTagFunction);

            if (this.selector?.createsNewItem) {
                tags.unshift(LIBRARY_DEFAULT_TAG);
            }

            return [...specificTags, ...tags];
        },

        hasTags() {
            return this.selector?.createsNewItem || !!this.getLibraryTags(this.libraryId, this.libraryCategory).length;
        },

        selectedItem: {
            get() {
                return this.selectedItemId;
            },
            set(value) {
                this.$store.commit('ui/setCurrentLibrarySelectedItem', value);
                clearTimeout(this._previewChangeTimeoutId);
                this._previewChangeTimeoutId = setTimeout(this.selector.previewChange, 200);
            }
        },

        libraryClasses() {
            return {
                show: this.show
            };
        },

        itemsStyles() {
            return Object.entries(this.librarySortedByTag).reduce((styles, [tag, items]) => {
                styles[tag] = {
                    '--item-rows-total': this.expandedTag == tag ? Math.ceil(items.length / LIBRARY_ITEM_COLUMNS) : null
                };
                return styles;
            }, {});
        },

        transitionEndEvent() {
            return !this.show ? 'transitionend' : null;
        },

        title() {
            switch (this.libraryId) {
                case 'messages':
                    return this.$t('Message animations');
                case 'backgroundAnimations':
                    return this.$t('Background animations');
                case 'captionAnimations':
                    return this.$t('Caption animations');
                case 'overlays':
                    return this.$t('Overlays');
                case 'panels':
                    return this.$t('Panels');
                case 'transitions':
                    return this.$t('Transitions');
                case 'visuals':
                    return this.$t('Visual animations');
                case 'zoomTargets':
                    return this.$t('Zoom target');
                case 'logoAnimations':
                    return this.$t('Logo animations');
                case 'musics':
                    return this.$t(`studio.music_categories.${this.libraryCategory}`);
                case 'voices':
                    return this.$t('AI Voices');
                case 'sequenceTemplates':
                    return this.selector?.createsNewSequence
                        ? this.$t('Add a sequence')
                        : this.$t('Change sequence with...');
                default:
                    return this.$t('Default Title');
            }
        },

        isMusicLibrary() {
            return this.libraryId === 'musics';
        },

        isVoiceLibrary() {
            return this.libraryId === 'voices';
        },

        tagTranslationPath() {
            return (this.selector?.tagTranslationPath || 'studio.tags') + '.';
        },

        libraryThumbnailExtension() {
            // music thumbnails are in PNG format
            return this.isMusicLibrary ? 'png' : 'gif';
        },

        musicPreviewSrc() {
            let previewSrc = '';

            if (this.isMusicLibrary && !!this.selectedItem) {
                previewSrc = this.assetMusicUrl(this.selectedItem);
            }

            if (this.isVoiceLibrary && !!this.selectedItem) {
                previewSrc = `/assets/audios/voices/${this.selectedItem}.mp3`;
            }

            return previewSrc;
        },

        isUniversalMusicLibrary() {
            return this.isMusicLibrary && this.libraryCategory === UNIVERSAL_MUSIC_CATEGORY;
        },

        selectedThemes: {
            get() {
                return this.allowedThemes || [];
            },
            set(value) {
                this.$store.commit('settings/setAllowedThemes', value);
                if (this.libraryId === 'sequenceTemplates') {
                    this.$nextTick(() => {
                        this.loadLibrary(this.libraryId);
                    });
                }
            }
        }
    },

    watch: {
        libraryId(newValue) {
            if (!!newValue) {
                this._preselectedItemId = this.selectedItemId;
                this.show = true;
                if (this.isLibraryLoaded(newValue)) {
                    this.$nextTick(() => {
                        if (!!this._preselectedItemId) {
                            this.$refs.$scrollable.handleResizeEvent();
                            this.$refs.$scrollable.scrollTo('#library-item-' + this._preselectedItemId.toLowerCase());
                        }
                    });
                } else {
                    console.log('Loading library:', newValue);
                    console.log('Library category:', this.libraryCategory);
                    this.loadLibrary(newValue);
                }
            }
        },

        selector(newValue) {
            if (!!newValue) this.selector.$el.addEventListener(UI_LIBRARY_SELECTOR_HIDE, this.close, { once: true });
        }
    },

    methods: {
        ...mapActions({
            loadLibrary: 'branding/libraries/loadLibrary'
        }),

        sprintf,

        select() {
            this.selector.select();
        },

        cancel() {
            this.selector.cancel();
        },

        close() {
            clearTimeout(this._previewChangeTimeoutId);
            this.destroyAudioPreview();
            this.show = false;
        },

        toggleTag(tag) {
            this.expandedTag = this.expandedTag != tag ? tag : null;
        },

        resizeScrollable(event) {
            if (event.propertyName == 'max-height') {
                this.$refs.$scrollable.refresh();
            }
        },

        emptyLibrary(event) {
            if (event.target == this.$el && event.propertyName == 'opacity') {
                this.$store.commit('ui/setCurrentLibrary', {
                    id: null,
                    category: null,
                    tagOrder: null,
                    selectedItemId: null,
                    selector: null
                });
                this._preselectedItemId = null;
                this.expandedTag = null;
            }
        },

        compareTags(tagA, tagB) {
            if (tagA == LIBRARY_NEW_TAG) return -1;
            if (tagB == LIBRARY_NEW_TAG) return 1;
            return this.$t(this.tagTranslationPath + tagA).localeCompare(
                this.$t(this.tagTranslationPath + tagB),
                this.shortLanguage
            );
        },

        handleItemClick(itemName, tag) {
            if (this.selectedItem == itemName && this.expandedTag === tag) {
                this.select();
            } else {
                this.selectedItem = itemName;
                this.destroyAudioPreview();
                this.playAudioPreview();

                if (this.expandedTag !== tag) {
                    this.toggleTag(tag);
                }
            }
        },

        getItemStyles(item) {
            let styles = {};

            if (!!item.thumbnails) {
                styles['--item-thumbnail'] = !!item.thumbnails.static_src
                    ? "url('" + item.thumbnails.static_src + "')"
                    : null;
                styles['--item-thumbnail-hover'] = !!item.thumbnails.preview_src
                    ? "url('" + item.thumbnails.preview_src + "')"
                    : null;
            } else {
                styles['--item-thumbnail'] =
                    `url(/assets/thumbnails/${this.selector.previewPath + item.name}.${this.libraryThumbnailExtension})`;
            }

            return styles;
        },

        getLibraryWithCreateNewItem(library) {
            library[LIBRARY_DEFAULT_TAG] = [
                {
                    name: LIBRARY_NEW_ITEM_ID,
                    label: this.$t('New'),
                    createsNewItem: true,
                    tags: [LIBRARY_DEFAULT_TAG],
                    tagIndexes: {
                        [LIBRARY_DEFAULT_TAG]: 0
                    }
                }
            ];

            if (this._preselectedItemId == LIBRARY_NEW_ITEM_ID) {
                this.expandedTag = LIBRARY_DEFAULT_TAG;
            }

            return library;
        },

        getLibraryTagName(tag) {
            const tagName = this.$t(this.tagTranslationPath + tag);
            return CATEGORIES_INCLUDING_BRAND_NAME.includes(tag) ? sprintf(tagName, this.organizationName) : tagName;
        },

        playAudioPreview() {
            if (!!this.musicPreviewSrc) {
                this.audioPreview = new Audio(this.musicPreviewSrc);
                this.audioPreview.addEventListener('ended', this.stopAudioPreview);
                this.audioPreview.play();
            }
        },

        destroyAudioPreview() {
            if (!!this.audioPreview) {
                this.audioPreview.removeEventListener('ended', this.stopAudioPreview);
                this.audioPreview.pause();
                this.audioPreview = null;
            }
        },

        stopAudioPreview() {
            if (!!this.audioPreview) {
                this.audioPreview.pause();
                this.audioPreview.currentTime = 0;
            }
        }
    },

    mounted() {
        this._preselectedItemId = null;
        this._previewChangeTimeoutId = -1;
    },

    beforeUnmount() {
        this.destroyAudioPreview();
    }
};
</script>

<style scoped lang="scss">
.ui-library-search {
    display: flex;
    align-items: center;
    gap: 10px;
}
.ui-library-theme-selector {
    margin-right: 10px;
}
</style>
