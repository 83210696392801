<template>
    <ui-caption-button
        :store-module-path="`sequences/${seqId}/audio`"
        type="audio"
        :parent-store-module-path="`sequences/${seqId}`"
        :has-old-captions="hasOldCaptions"
        :media-id="audioState.src__id"
        :media-src="audioState.src"
        :readonly="disabled"
        @open-editor="handleOpenEditor"
    />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';
import UiCaptionButton from '@/js/videos/components/ui/body/selectors/caption/button/UiCaptionButton.vue';
import { useCaptionsEditor } from '@/js/videos/composables/caption/useCaptionsEditor.ts';

const props = defineProps<{
    seqId: string;
    readOnly: boolean;
}>();

const store = useStore();
const { openEditor } = useCaptionsEditor();

const audioState = computed(() => store.state.sequences[props.seqId].audio.track);

const oldCaptions = computed(() => audioState.value?.captions || []);

const hasOldCaptions = computed(() => {
    return oldCaptions.value.length > 0 && oldCaptions.value[0].text.length > 0;
});

const disabled = computed(() => {
    return props.readOnly || !audioState.value?.src;
});

const handleOpenEditor = (): void => {
    openEditor(
        audioState.value?.src__id,
        `sequences/${props.seqId}/audio`,
        'audio',
        `sequences/${props.seqId}`,
        props.seqId
    );
};
</script>
