<template>
    <div :id="id" :class="`captions-animation ${props.animation}`"></div>
</template>

<script setup lang="ts">
import { onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import gsap from 'gsap';
import { randomID } from '@/js/video-studio/utils';
import { RelativeCaptionUnit } from '@/js/videos/types/captions.ts';
import CaptionsAnimationInterface from '@/js/video-studio/components/captions/animations/CaptionsAnimationInterface.ts';
import CaptionsBasicAnimation from '@/js/video-studio/components/captions/animations/CaptionsBasicAnimation.ts';

const store = useStore();

const id: string = 'captions-animation-' + randomID();

const selector: string = `#${id}`;

const props = defineProps<{
    captions: RelativeCaptionUnit[];
    animation: string;
    storeModulePath: string;
}>();

const emit = defineEmits<{
    (e: 'update'): void;
}>();

let animation: CaptionsAnimationInterface = CaptionsBasicAnimation;
let timeline: GSAPTimeline = gsap.timeline();

let timelineId: string = '';
let oldTimelineId: string = timelineId;

let oldIndex: number = -1;

let currentCaptionUnit: RelativeCaptionUnit | null;
let currentCaptionUnitTimeline: GSAPTimeline | null = gsap.timeline();

const animations: Record<string, () => Promise<{ default: CaptionsAnimationInterface }>> = {
    default: () => import('@/js/video-studio/components/captions/animations/CaptionsBasicAnimation.ts'),
    CaptionsBasicAnimation: () => import('@/js/video-studio/components/captions/animations/CaptionsBasicAnimation.ts'),
    CaptionsRetroAnimation: () => import('@/js/video-studio/components/captions/animations/CaptionsRetroAnimation.ts'),
    CaptionsBouncerAnimation: () =>
        import('@/js/video-studio/components/captions/animations/CaptionsBouncerAnimation.ts'),
    CaptionsSentenceBackgroundAnimation: () =>
        import('@/js/video-studio/components/captions/animations/CaptionsSentenceBackgroundAnimation.ts'),
    CaptionsOneWordAppearanceAnimation: () =>
        import('@/js/video-studio/components/captions/animations/CaptionsOneWordAppearanceAnimation.ts'),
    CaptionsWordBackgroundAnimation: () =>
        import('@/js/video-studio/components/captions/animations/CaptionsWordBackgroundAnimation.ts'),
    CaptionsWordHighlightAnimation: () =>
        import('@/js/video-studio/components/captions/animations/CaptionsWordHighlightAnimation.ts'),
    CaptionsWordHighlightBackgroundAnimation: () =>
        import('@/js/video-studio/components/captions/animations/CaptionsWordHighlightBackgroundAnimation.ts'),
    CaptionsWordBackgroundPulseAnimation: () =>
        import('@/js/video-studio/components/captions/animations/CaptionsWordBackgroundPulseAnimation.ts'),
    CaptionsSentenceBackgroundWordStraightAnimation: () =>
        import('@/js/video-studio/components/captions/animations/CaptionsSentenceBackgroundWordStraightAnimation.ts'),
    CaptionsSentenceBackgroundWordRoundedAnimation: () =>
        import('@/js/video-studio/components/captions/animations/CaptionsSentenceBackgroundWordRoundedAnimation.ts')
};

const loadAnimation = async (): Promise<void> => {
    animation = (await (animations[props.animation] || animations.default)()).default;
};

const setCurrentCaptionIndex = (index: number): void => {
    store.commit('ui/captionsEditor/setCurrentCaptionIndex', { storeModulePath: props.storeModulePath, index });
};

const clearCurrentCaptionTimeline = (): void => {
    if (currentCaptionUnitTimeline) {
        currentCaptionUnitTimeline.clear().kill();
        currentCaptionUnitTimeline = null;
    }
};

const buildTimeline = (): void => {
    timelineId = randomID();
    timeline.seek(0).clear().kill();
    timeline = gsap.timeline({ paused: true });

    const totalTime = props.captions.length ? props.captions[props.captions.length - 1].relativeEndTime : 0;

    timeline.to({}, { duration: totalTime, ease: 'none' });

    /**
     * In this specific context, it's more efficient to rely on an "onUpdate" callback
     * rather than creating a large single timeline for all subtitles. Using "onUpdate" here
     * lets us dynamically determine which caption block to display (and how to animate it)
     * without recalculating or replaying thousands of tweens. This approach is particularly
     * useful when you have many items to manage and want to avoid the performance overhead
     * of a huge timeline that needs to be recalculated on every seek or time jump.
     */
    timeline.eventCallback('onUpdate', () => {
        const timelineTime = timeline.time();

        let newIndex: number = props.captions.findIndex(
            (c) => timelineTime >= c.relativeStartTime && timelineTime < c.relativeEndTime
        );

        if (newIndex !== oldIndex || timelineId !== oldTimelineId) {
            oldIndex = newIndex;

            if (newIndex !== -1) {
                clearCurrentCaptionTimeline();
                gsap.set(selector, { innerText: '', visibility: 'hidden' });
                currentCaptionUnit = props.captions[newIndex];

                gsap.set(selector, { innerText: currentCaptionUnit.text, visibility: 'visible' });
                setCurrentCaptionIndex(currentCaptionUnit.initialIndex);

                currentCaptionUnitTimeline = animation.getTimeline(selector, currentCaptionUnit);
            } else {
                clearCurrentCaptionTimeline();
                gsap.set(selector, { innerText: '', visibility: 'hidden' });
            }
        }

        if (currentCaptionUnitTimeline && currentCaptionUnit) {
            currentCaptionUnitTimeline.seek(timelineTime - currentCaptionUnit.relativeStartTime);
        }

        oldTimelineId = timelineId;
    });
};

const updateTimeline = (): void => {
    buildTimeline();
    emit('update');
};

const getTimeline = (): GSAPTimeline => {
    buildTimeline();
    return timeline;
};

defineExpose<{
    getTimeline: () => GSAPTimeline;
}>({
    getTimeline
});

watch(
    () => props.animation,
    () => {
        loadAnimation().then(() => {
            updateTimeline();
        });
    }
);

onMounted(() => {
    loadAnimation().then(() => {
        updateTimeline();
    });
});
</script>
