import { BREAKPOINT_LG } from '../../../constants';

class StudioEditPreviewManager {
    constructor(store) {
        this.store = store;
        this.previewContainer = null;
        this.resizeObserver = null;
        this.initialized = false;
    }

    init() {
        if (this.initialized) return;

        this.previewContainer = document.querySelector('.preview-studio-wrapper');

        if (!this.previewContainer) {
            console.error('Preview container not found');
            return;
        }

        this.resizeObserver = new ResizeObserver(() => {
            this.resizePreview();
        });

        this.resizeObserver.observe(this.previewContainer);
        this.initialized = true;
        this.resizePreview();
    }

    resizePreview() {
        if (!this.initialized) {
            this.init();
            return;
        }

        if (!this.previewContainer) return;

        const isNarrowScreen = window.innerWidth < BREAKPOINT_LG;
        const { format } = this.store.state.display;
        const formatRatio = format.width / format.height;

        this.adjustContainerHeight(this.previewContainer, isNarrowScreen, formatRatio);
        this.adjustStudioScale(this.previewContainer, formatRatio);
    }

    adjustContainerHeight(container, isNarrowScreen, formatRatio) {
        if (isNarrowScreen) {
            const playerWidth = this.store.getters['display/formatRatioString'] !== '16x9' ? 0.5 : 1;
            container.style.height = `${(window.innerWidth * playerWidth) / formatRatio}px`;
        } else {
            container.style.removeProperty('height');
        }
    }

    adjustStudioScale(container, stageRatio) {
        const containerWidth = container.offsetWidth;
        const containerHeight = container.offsetHeight;
        const containerRatio = containerWidth / containerHeight;
        const studioScale =
            containerRatio <= stageRatio
                ? containerWidth / this.store.state.display.format.width
                : containerHeight / this.store.state.display.format.height;

        window.$studio.proxy.setContainerScale(studioScale, true);
        window.$studio.proxy.studio.$stage.scale = studioScale;
    }

    destroy() {
        if (this.resizeObserver && this.previewContainer) {
            this.resizeObserver.unobserve(this.previewContainer);
            this.resizeObserver.disconnect();
        }

        this.resizeObserver = null;
        this.previewContainer = null;
        this.initialized = false;
    }
}

export default StudioEditPreviewManager;
