<template>
    <ui-dropdown
        :id="cardElementId + '-audio'"
        class="audio-dropdown centered"
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="bottom-end"
        menu-strategy="fixed"
        :disabled="enableDropDown"
        :toggle-style="toggleStyle"
        :icon-only="iconOnly"
        :tooltip="$t('Volume')"
    >
        <template #dropdown-toggle>
            <svg-icon icon="audio-icon" />
            <span v-if="!iconOnly">{{ $t('Audio') }}</span>
        </template>
        <div>
            <ui-range-input
                :id="cardElementId + '-bg-audio-volume'"
                class="ui-content-row"
                v-model="backgroundVolumeModel"
                :horizontal="false"
                :disabled="readOnly"
                :max="200"
                :label="$t('Video volume')"
            />
            <ui-number-input
                :id="cardElementId + '-bg-audio-fade'"
                class="ui-content-row"
                v-model="backgroundVolumeFadeModel"
                :min="0"
                :label="$t('Audio fade (in s.)')"
                :disabled="readOnly"
            />
        </div>
    </ui-dropdown>
</template>
<script>
import { useStore } from 'vuex';
import { defineComponent, computed } from 'vue';
import UiRangeInput from '../../../../../../components/UiRangeInput.vue';
import UiNumberInput from '../../../../../../components/UiNumberInput.vue';
import UiDropdown from '../../../../../../components/UiDropdown.vue';
import { useHistory } from '../../../../../composables/useHistory';
import { useSequence } from '../../../../../composables/useSequence';

export default defineComponent({
    components: { UiRangeInput, UiNumberInput, UiDropdown },
    props: { seqId: String, cardElementId: String, readOnly: Boolean, iconOnly: Boolean },
    setup(props) {
        const store = useStore();
        const { saveHistoryStep } = useHistory();
        const {
            hasVideoBackground,
            hasRecordingBackground,
            hasAnimatedBackground,
            sequenceStoreModulePath,
            resolvedBackgroundVolume,
            backgroundVideo
        } = useSequence(props.seqId);

        const toggleStyle = computed(() => {
            return Math.round(100 * resolvedBackgroundVolume.value) !== 100 || backgroundVolumeFade.value !== 0
                ? { color: 'var(--edit-mode-color)' }
                : {};
        });

        const enableDropDown = computed(() => {
            return props.readOnly || !backgroundVideo.value.src;
        });

        const backgroundVolume = computed({
            get: () => {
                return (
                    (hasVideoBackground.value || hasRecordingBackground.value || hasAnimatedBackground.value) &&
                    Math.round(100 * resolvedBackgroundVolume.value)
                );
            },
            set: (value) => {
                saveHistoryStep(() => {
                    store.commit(sequenceStoreModulePath.value + '/video/setVolume', value / 100);
                });
            }
        });

        const backgroundVolumeFade = computed({
            get: () => {
                return (
                    (hasVideoBackground.value || hasRecordingBackground.value || hasAnimatedBackground.value) &&
                    store.getters[sequenceStoreModulePath.value + '/video/volumeFade']
                );
            },
            set: (value) => {
                if (value != store.getters[sequenceStoreModulePath.value + '/video/volumeFade']) {
                    saveHistoryStep(() => {
                        store.commit(sequenceStoreModulePath.value + '/video/setVolumeFade', { value });
                    });
                }
            }
        });

        return {
            toggleStyle,
            enableDropDown,
            backgroundVolumeModel: backgroundVolume,
            backgroundVolumeFadeModel: backgroundVolumeFade,
            hasVideoBackground,
            hasRecordingBackground
        };
    }
});
</script>

<style></style>
