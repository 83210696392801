<template>
    <div class="nav-child-items-list" :class="{ opened: opened }" ref="navList">
        <div class="nav-child-items-list-content" @click="closeNavList">
            <slot />
        </div>
    </div>
</template>

<script setup>
import { computed, inject, provide } from 'vue';

const props = defineProps({
    parentName: ''
});

const { openedNavItem, openNavItem } = inject('openedNavItem');

provide('navSubListName', props.parentName);

const opened = computed(() => {
    return openedNavItem.value === props.parentName;
});

const closeNavList = () => {
    if (opened.value) {
        openNavItem(null);
    }
};
</script>
