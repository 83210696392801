import { computed } from 'vue';
import { useStore } from 'vuex';
import { Background, Duration } from 'cte-video-studio';
import { useHistory } from './useHistory';
import { useBrand } from '@/js/videos/composables/useBrand.js';

// reference: to the mixin SequenceCard.js
export function useSequence(sequenceId, store = null) {
    if (!store) {
        store = useStore();
    }
    const { findLibraryItem } = useBrand();
    const { saveHistoryStep } = useHistory(store);

    const sequenceStoreModulePath = computed(() => `sequences/${sequenceId}`);
    const sequenceState = computed(() => store.state.sequences[sequenceId]);
    const isLastVisibleSequence = computed(() => store.getters[`${sequenceStoreModulePath.value}/isLastVisible`]);
    const isNextVisibleSequence = computed(() => store.getters[`${sequenceStoreModulePath.value}/nextVisible`]);

    const hasColorBackground = computed(() => store.getters[`sequences/${resolvedId.value}/hasColorBackground`]);
    const hasImageBackground = computed(() => store.getters[`sequences/${resolvedId.value}/hasImageBackground`]);
    const hasVideoBackground = computed(() => store.getters[`sequences/${resolvedId.value}/hasVideoBackground`]);
    const hasAnimatedBackground = computed(() => store.getters[`sequences/${resolvedId.value}/hasAnimatedBackground`]);
    const hasCartoonBackground = computed(() => store.getters[`sequences/${resolvedId.value}/hasCartoonBackground`]);
    const hasCollageBackground = computed(() => store.getters[`sequences/${resolvedId.value}/hasCollageBackground`]);
    const hasMapZoomBackground = computed(() => store.getters[`sequences/${resolvedId.value}/hasMapZoomBackground`]);
    const hasRecordingBackground = computed(
        () => store.getters[`sequences/${resolvedId.value}/hasRecordingBackground`]
    );
    const hasPreviousBackground = computed(
        () => store.getters[`${sequenceStoreModulePath.value}/hasPreviousBackground`]
    );
    // maybe review this, we need to get the duration of the video with the sequence duration
    const maxDuration = computed(() => resolvedBackgroundState.value.video.totalDuration());
    const maxDurationAudio = computed(() => sequenceState.value.audio.track.totalDuration());
    const resolvedId = computed(() => store.getters[`${sequenceStoreModulePath.value}/resolvedId`]);
    const resolvedBackgroundState = computed(() => store.state.sequences[sequenceId].background);
    const resolvedBackgroundVolume = computed(() => store.getters[`sequences/${resolvedId.value}/video/volume`]);
    const resolvedBackgroundPlaybackRate = computed(
        () => store.getters[`sequences/${resolvedId.value}/video/playbackRate`]
    );

    const playbackRate = computed({
        get: () => resolvedBackgroundPlaybackRate.value || Duration.PLAY_BACK_RATE_DEFAULT,
        set: (value) => {
            saveHistoryStep(() => {
                store.commit(`${sequenceStoreModulePath.value}/video/setPlaybackRate`, value);
            });
        }
    });
    const videoRange = computed(() => {
        const range = { start: 0, end: -1 };

        if (hasVideoBackground.value || hasRecordingBackground.value || hasAnimatedBackground.value) {
            range.start = resolvedBackgroundState.value.video.timerange.start;
            range.end = resolvedBackgroundState.value.video.timerange.end;
        }

        return range;
    });

    const currentTimeForced = computed({
        get: () => resolvedBackgroundState.value.video.currentTimeForced,
        set: (value) => {
            store.commit(`${sequenceStoreModulePath.value}/video/setCurrentTimeForced`, value);
        }
    });

    const videoTimerangeSegments = computed({
        get: () => resolvedBackgroundState.value.video.timerangeSegments ?? [],
        set: (value) => {
            saveHistoryStep(() => {
                store.commit(`${sequenceStoreModulePath.value}/video/setTimerangeSegments`, value);
            });
        }
    });

    const audioTimerangeSegments = computed({
        get: () => sequenceState.value.audio.track.timerangeSegments ?? [],
        set: (value) => {
            saveHistoryStep(() => {
                store.commit(`${sequenceStoreModulePath.value}/setTrackTimerangeSegments`, value);
            });
        }
    });

    const backgroundVideoCaptioningStatus = computed(() => {
        return !hasPreviousBackground.value && hasVideoBackground.value
            ? resolvedBackgroundState.value.video.captioningStatus
            : null;
    });

    const backgroundVideo = computed(() => {
        let ref = { src: '', id: '' };

        if (hasVideoBackground.value || hasRecordingBackground.value || hasAnimatedBackground.value) {
            ref.src = resolvedBackgroundState.value.video.src;
            ref.id = resolvedBackgroundState.value.video.src__id;
        }

        return ref;
    });

    const backgroundType = computed({
        get: () => sequenceState.value.background.type,
        set: (value) => saveHistoryStep(() => store.commit(`${sequenceStoreModulePath.value}/setBackground`, value))
    });

    const backgroundAnimationType = computed({
        get: () =>
            (backgroundType.value === Background.IMAGE_TYPE ||
                backgroundType.value === Background.VIDEO_TYPE ||
                backgroundType.value === Background.ANIMATED_TYPE ||
                backgroundType.value === Background.COLLAGE_TYPE ||
                backgroundType.value === Background.CARTOON_TYPE ||
                backgroundType.value === Background.RECORDING_TYPE) &&
            resolvedBackgroundState.value.animation.type,
        set: (type) => {
            saveHistoryStep(() => store.commit(`${sequenceStoreModulePath.value}/setBackgroundAnimation`, type));
        }
    });

    const backgroundAnimationTypeMetadata = computed(
        () => findLibraryItem.value('backgroundAnimations', '', backgroundAnimationType.value) || null
    );
    const backgroundAnimationTypeUses = computed(() => backgroundAnimationTypeMetadata.value?.uses || []);

    const audioSrc = computed({
        get: () => sequenceState.value.audio.track,
        set: (value) => {
            store.commit(`${elementStoreModulePath.value}/setTrack`, value);
        }
    });

    const sequenceDuration = computed(() => {
        return sequenceState.value.options.duration;
    });

    return {
        audioSrc,
        audioTimerangeSegments,
        backgroundVideo,
        backgroundVideoCaptioningStatus,
        hasAnimatedBackground,
        hasCartoonBackground,
        hasCollageBackground,
        hasColorBackground,
        hasImageBackground,
        hasMapZoomBackground,
        hasPreviousBackground,
        hasRecordingBackground,
        hasVideoBackground,
        isLastVisibleSequence,
        isNextVisibleSequence,
        maxDuration,
        maxDurationAudio,
        playbackRate,
        resolvedBackgroundState,
        resolvedBackgroundVolume,
        resolvedId,
        sequenceDuration,
        sequenceState,
        sequenceStoreModulePath,
        videoRange,
        videoTimerangeSegments,
        backgroundType,
        backgroundAnimationType,
        backgroundAnimationTypeUses,
        currentTimeForced
    };
}
