import { Duration, Sound } from '@/js/video-studio/constants';
import { conversions, types } from '@/js/video-studio/utils';
import _cloneDeep from 'lodash/cloneDeep';
import simpleMedia from '@/js/store/modules/commons/simple-media.js';
import oldCaptions from '@/js/store/modules/commons/oldCaptions.js';
import newCaptions from '@/js/store/modules/commons/newCaptions.ts';

const state = () => ({
    ...simpleMedia.state(),
    ...oldCaptions.state(),

    volume: Sound.VOLUME_DEFAULT,
    start: Duration.START_DEFAULT,
    playbackRate: Duration.PLAY_BACK_RATE_DEFAULT,
    timerange: {
        start: Duration.START_DEFAULT,
        end: Duration.END_DEFAULT
    },
    timerangeSegments: [],
    currentTimeForced: 0,
    totalDuration: () => 0,

    fade: {
        value: Sound.BACKGROUND_FADE_DEFAULT,
        custom: Sound.FADE_EMPTY
    },
    improved: false
});

const getters = {
    ...oldCaptions.getters,

    track: (state) => {
        return state.timerange &&
            state.timerange.start !== Duration.START_DEFAULT &&
            state.timerange.end !== Duration.END_DEFAULT
            ? conversions.mediaFragmentAssetSrc(state.src, state.totalDuration(), state.timerange)
            : state.src;
    },

    volume: (state) => {
        return state.volume;
    },

    start: (state) => {
        return state.start;
    },

    playbackRate: (state) => {
        return state.playbackRate;
    },

    timerange: (state) => {
        return state.timerange;
    },

    currentTimeForced: (state) => {
        return state.currentTimeForced;
    },

    volumeFade: (state) => conversions.optionValue(state.fade, [], Sound.BACKGROUND_FADE_DEFAULT),

    totalDuration: (state) => {
        return state.totalDuration();
    }
};

const mutations = {
    ...simpleMedia.mutations,
    ...oldCaptions.mutations,

    setVolume(state, volume) {
        state.volume = volume;
    },

    setStart(state, start) {
        state.start = start;
    },

    setPlaybackRate(state, playbackRate) {
        state.playbackRate = playbackRate;
    },

    setTimerange(state, timerange) {
        state.timerange = Object.assign({ start: 0, end: -1 }, timerange || {});
    },

    setTimerangeSegments(state, segments) {
        state.timerangeSegments = Array.isArray(segments) && !!segments.length ? _cloneDeep(segments) : [];
    },

    setCurrentTimeForced(state, currentTime) {
        state.currentTimeForced = currentTime;
    },

    setTotalDuration(state, totalDuration) {
        state.totalDuration = () => totalDuration;
    },

    setVolumeFade(state, fade) {
        if (types.isZeroPositive(fade.value)) {
            state.fade.value = parseFloat(fade.value);
            state.fade.custom = Sound.FADE_EMPTY;
        } else if (types.isZeroPositive(fade.custom)) {
            state.fade.value = Sound.FADE_CUSTOM;
            state.fade.custom = parseFloat(fade.custom);
        } else {
            state.fade.value = Sound.BACKGROUND_FADE_DEFAULT;
            state.fade.custom = Sound.FADE_EMPTY;
        }
    },

    setImproved(state, value) {
        state.improved = value;
    }
};

const actions = {
    ...oldCaptions.actions,

    init({ commit, dispatch }, data) {
        simpleMedia.actions.init({ commit }, data);

        if (data.volume !== Sound.VOLUME_DEFAULT) commit('setVolume', data.volume);
        if (data.start && data.start !== Duration.START_DEFAULT) commit('setStart', data.start);
        if (data.playbackRate && data.playbackRate !== Duration.PLAY_BACK_RATE_DEFAULT)
            commit('setPlaybackRate', data.playbackRate);
        if (data.timerange) commit('setTimerange', data.timerange);
        if (data.timerangeSegments) commit('setTimerangeSegments', data.timerangeSegments);
        if (data.fade) commit('setVolumeFade', data.fade);
        if (data.improved) commit('setImproved', data.improved);

        dispatch('initCaptions', data); // old captions

        if (data.newCaptions) {
            dispatch('newCaptions/init', data.newCaptions);
        }
    },

    update({ commit, state }, data) {
        simpleMedia.actions.update({ commit }, data);

        if (!data.src__id) {
            commit('setTotalDuration', 0);
        }
        if (state.src__id !== data.src__id) {
            commit('setTimerange', { start: 0, end: -1 });
        }

        if (data.hasOwnProperty('improved')) commit('setImproved', data.improved);
    }
};

export default {
    namespaced: true,

    state,
    modules: {
        newCaptions
    },
    getters,
    mutations,
    actions
};
