export interface Format {
    width: number;
    height: number;
}

export const FORMAT_RATIOS = {
    RATIO_16X9: 16 / 9,
    RATIO_1X1: 1,
    RATIO_9X16: 9 / 16
} as const;

export const PREDEFINED_FORMATS = {
    LANDSCAPE: { width: 1920, height: 1080 },
    SQUARE: { width: 1080, height: 1080 },
    PORTRAIT: { width: 1080, height: 1920 }
} as const;