<template>
    <div
        class="nav-item-create-video nav-dropdown-item sublist-container"
        ref="navItemContainer"
        @click="toggleSubList"
    >
        <div class="nav-item-create-video-content nav-item">
            <div class="nav-item-create-video-overlay" />
            <div class="nav-icon-container">
                <fa-icon class="icon" icon="fa-solid fa-plus" />
            </div>

            <div class="nav-item-name-label">
                <div class="nav-item-name-spacer"></div>
                {{ $t('Create a video') }}
            </div>
        </div>

        <ui-nav-sub-list parent-name="create" :class="{ opened: opened }">
            <ui-nav-item
                :disableAutomaticOpen="true"
                :link="false"
                :label="$t('From scratch')"
                icon="fa-square-dashed"
                @click="createVideo"
            />
            <ui-nav-item
                :route="{ name: 'Home', hash: '#templates-list' }"
                :label="$t('From a template')"
                icon="video-from-template"
                icon-type="svg"
                :disableAutomaticOpen="true"
            />
            <ui-nav-item
                v-bind:route="AIenabled ? { name: 'Companion' } : undefined"
                :label="$t('From a prompt')"
                :icon="AIenabled ? `sparkles-ai-${locale}-icon` : 'fa-lock'"
                v-bind:icon-type="AIenabled ? 'svg' : undefined"
                :class="[{ disabled: !AIenabled }]"
                :disableAutomaticOpen="true"
            />
        </ui-nav-sub-list>
    </div>
</template>

<script setup>
import { ref, computed, inject, onMounted, onBeforeUnmount } from 'vue';
import { useModal } from '@/js/composables/useModal.js';
import UiNavItem from '@/js/components/UiNavItem.vue';
import UiNavSubList from '@/js/components/UiNavSubList.vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';

const { locale, t } = useI18n();
const store = useStore();

const AIenabled = computed(() => {
    return store.state.auth.user.client.videoCompanionEnabled;
});

const { openModal } = useModal();

const { openedNavItem, openNavItem } = inject('openedNavItem', {
    openedNavItem: ref(null),
    openNavItem: () => {}
});

const opened = ref(false);

const navItemContainer = ref(null);

const toggleSubList = (event) => {
    opened.value = !opened.value;
};

const createVideo = () => {
    openModal('CreateVideoModal');
};

function handleClickOutside(event) {
    if (opened.value && navItemContainer.value && !navItemContainer.value.contains(event.target)) {
        opened.value = false;
    }
}
onMounted(() => {
    document.addEventListener('click', handleClickOutside);
});
onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
});
</script>
