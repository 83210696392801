<template>
    <div>
        <UiQuickCut
            v-if="isReady"
            :segments="segments"
            :media="media"
            :media-type="mediaType"
            :playback-rate="playbackRate"
            :sequence-id="sequenceId"
            :element-id="elementId"
            :start-time="startTime"
            :sequence-end-time="sequenceEndTime"
            :limit-type="limitType"
            :max-segment-duration="maxSegmentDuration"
            @update-segments="updateSegments"
            @force-current-time="forceCurrentTime"
            @close="close"
        />
    </div>
</template>

<script setup>
import { computed, ref, watch, nextTick, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useStoreManagement } from '../composables/useStoreManagement';
import { useSequence } from '@video-composables/useSequence';
import { useElement } from '@video-composables/useElement';
import { useVoiceOver } from '@video-composables/useVoiceOver';

import StudioEditPreviewManager from '@/js/videos/application/previewManager/StudioEditPreviewManager.js';

import UiQuickCut from '../UiQuickCut.vue';

// Add debug logging right after all imports
console.log('UiQuickCutWrapper loaded');

const store = useStore();
const { sequenceId, elementId, isOpenQuickCut, mediaType, mediaSrc, closeQuickCut } = useStoreManagement(store);

const segments = ref([]);
const media = ref(null);
const playbackRate = ref(1);
const startTime = ref(0);
const endTime = ref(0);
const sequenceEndTime = ref(0);
const needResetSegments = ref(false);
const previewManager = ref(null);

const limitType = ref('sequence');

const actualMediaDuration = ref(0);
const fullMediaDuration = ref(0);

const isAutoSequenceDuration = computed(() => {
    const isAutoMode = sequenceData.value?.sequenceState?.value?.options?.duration === null;
    return isAutoMode;
});

const actualSequenceDuration = computed(() => {
    return sequenceData.value?.sequenceState?.value?.options?.duration || 0;
});

const trackMediaDuration = (mediaObj) => {
    if (!mediaObj) return;

    if (mediaObj.duration && mediaObj.duration > 0) {
        fullMediaDuration.value = mediaObj.duration;
        recalculateEndTime();
        adjustSegmentsToFitSequenceDuration();
        return;
    }

    if (mediaObj instanceof HTMLVideoElement || mediaObj instanceof HTMLAudioElement) {
        const onLoadedMetadata = () => {
            fullMediaDuration.value = mediaObj.duration || 0;
            recalculateEndTime();
            adjustSegmentsToFitSequenceDuration();
            mediaObj.removeEventListener('loadedmetadata', onLoadedMetadata);
        };

        mediaObj.addEventListener('loadedmetadata', onLoadedMetadata);
    } else if (mediaObj.src) {
        const tempMedia = mediaObj.src.includes('audio') ? new Audio() : document.createElement('video');
        tempMedia.src = mediaObj.src;

        const onLoadedMetadata = () => {
            fullMediaDuration.value = tempMedia.duration || 0;
            recalculateEndTime();
            adjustSegmentsToFitSequenceDuration();
            tempMedia.removeEventListener('loadedmetadata', onLoadedMetadata);
        };

        tempMedia.addEventListener('loadedmetadata', onLoadedMetadata);
        tempMedia.load();
    }
};

const recalculateEndTime = () => {
    if (fullMediaDuration.value <= 0) return;

    switch (mediaType.value) {
        case 'backgroundVideo':
        case 'recording':
            endTime.value = fullMediaDuration.value;
            break;

        case 'visual':
        case 'recordingVisual':
            if (elementData.value?.elementState?.value?.animation?.end?.value !== -1) {
                endTime.value = Math.min(
                    elementData.value?.elementState?.value?.animation?.end?.value || Infinity,
                    fullMediaDuration.value
                );
            } else {
                endTime.value = fullMediaDuration.value;
            }
            break;

        case 'audio':
        case 'tts':
            endTime.value = fullMediaDuration.value;
            break;
    }
};

const sequenceData = computed(() => {
    if (!sequenceId.value) return null;

    const {
        backgroundVideo,
        audioSrc,
        playbackRate: seqPlaybackRate,
        videoTimerangeSegments: backgroundVideoSegments,
        videoRange,
        audioTimerangeSegments,
        sequenceState,
        sequenceDuration,
        maxDuration,
        maxDurationAudio,
        currentTimeForced
    } = useSequence(sequenceId.value, store);

    return {
        backgroundVideo,
        audioSrc,
        seqPlaybackRate,
        backgroundVideoSegments,
        videoRange,
        audioTimerangeSegments,
        sequenceState,
        sequenceDuration,
        maxDuration,
        maxDurationAudio,
        currentTimeForced
    };
});

const setMediaSrcInStore = (newMedia) => {
    if (newMedia && mediaSrc.value !== newMedia.src) {
        if (mediaSrc.value !== null) {
            needResetSegments.value = true;
        }
        store.dispatch('ui/quickcut/setMediaSrc', newMedia.src);
    }
};

const elementData = computed(() => {
    if (!sequenceId.value || !elementId.value) return null;

    const { sequenceDuration } = useSequence(sequenceId.value, store);
    const {
        elementState,
        playbackRate: elementPlaybackRate,
        videoTimerangeSegments: visualSegments,
        videoRange,
        videoSrc,
        audioSrc,
        startTime,
        endTime,
        maxDuration,
        currentTimeForced
    } = useElement(sequenceId.value, elementId.value, store);

    return {
        elementState,
        elementPlaybackRate,
        visualSegments,
        videoRange,
        videoSrc,
        audioSrc,
        sequenceDuration,
        startTime,
        endTime,
        maxDuration,
        currentTimeForced
    };
});

const voiceOverData = computed(() => {
    if (!sequenceId.value) return null;
    const useInSequence = ref(true);
    const { sequenceDuration, maxDuration } = useSequence(sequenceId.value, store);

    const { voiceOverMediaState, timerange, timerangeSegments } = useVoiceOver(useInSequence, sequenceId, store);
    return { voiceOverMediaState, timerange, timerangeSegments, sequenceDuration, maxDuration };
});

const videoTimerangeSegments = computed(() => {
    if (['backgroundVideo', 'recording'].includes(mediaType.value) && sequenceData.value) {
        return sequenceData.value.backgroundVideoSegments.value;
    }
    if (['visual', 'recordingVisual'].includes(mediaType.value) && elementData.value) {
        return elementData.value.visualSegments.value;
    }
    if (mediaType.value === 'audio' && sequenceData.value) {
        return sequenceData.value.audioTimerangeSegments.value;
    }
    if (mediaType.value === 'tts' && voiceOverData.value) {
        return voiceOverData.value.timerangeSegments.value;
    }
    return [];
});

const isReady = computed(() => sequenceId.value && media.value && isOpenQuickCut.value);

const setCommonMediaProperties = (
    mediaObj,
    playbackRateValue,
    segmentsValue,
    sequenceDurationValue,
    startValue,
    endValue
) => {
    media.value = mediaObj;
    playbackRate.value = playbackRateValue;
    segments.value = segmentsValue;

    // Set sequenceEndTime to null if we're in auto mode, otherwise use the actual duration
    // Use the computed property for consistency
    sequenceEndTime.value = isAutoSequenceDuration.value ? null : actualSequenceDuration.value || sequenceDurationValue;

    startTime.value = startValue;
    endTime.value = endValue;

    trackMediaDuration(mediaObj);

    if (fullMediaDuration.value > 0) {
        recalculateEndTime();
        adjustSegmentsToFitSequenceDuration();
    }

    setMediaSrcInStore(media.value);
    return true;
};

const initializeData = {
    backgroundVideo: () => {
        if (!sequenceData.value) return false;

        const { backgroundVideo, seqPlaybackRate, backgroundVideoSegments, sequenceDuration, maxDuration } =
            sequenceData.value;

        // Use computed property for consistency
        return setCommonMediaProperties(
            backgroundVideo.value,
            seqPlaybackRate.value,
            backgroundVideoSegments.value,
            sequenceDuration.value,
            0,
            // For auto duration, use the full media duration
            isAutoSequenceDuration.value
                ? backgroundVideo.value?.duration || maxDuration.value
                : sequenceDuration.value > 0
                  ? sequenceDuration.value
                  : maxDuration.value
        );
    },
    visual: () => {
        if (!elementData.value) return false;

        const { videoSrc, elementState, elementPlaybackRate, visualSegments, sequenceDuration, maxDuration } =
            elementData.value;

        const mediaDuration = videoSrc.value?.duration || 0;
        const animationEndValue = elementState.value.animation.end.value;

        media.value = videoSrc.value;
        playbackRate.value = elementPlaybackRate.value;
        segments.value = visualSegments.value;

        startTime.value = elementState.value.animation.start.value;

        let finalEndTime;

        if (animationEndValue !== -1) {
            if (sequenceDuration.value === 0 || sequenceDuration.value === null) {
                finalEndTime = Math.min(animationEndValue, mediaDuration || Infinity);
                limitType.value = 'element';
            } else {
                finalEndTime = Math.min(animationEndValue, sequenceDuration.value, mediaDuration || Infinity);
                limitType.value = 'element';
            }
        } else {
            limitType.value = 'sequence';
            finalEndTime = Math.min(sequenceDuration.value ?? maxDuration.value, mediaDuration || Infinity);
        }

        endTime.value = finalEndTime;

        setMediaSrcInStore(media.value);
        return true;
    },
    audio: () => {
        if (!sequenceData.value) return false;

        const { audioSrc, sequenceState, audioTimerangeSegments, sequenceDuration, maxDurationAudio } =
            sequenceData.value;

        const mediaDuration = audioSrc.value?.duration || 0;

        media.value = audioSrc.value;
        playbackRate.value = 1;
        segments.value = audioTimerangeSegments.value;

        // Set sequenceEndTime to null if in auto mode - use computed property
        sequenceEndTime.value = isAutoSequenceDuration.value ? null : sequenceDuration.value;

        startTime.value = sequenceState.value.audio.track.start.value;
        // For auto duration, use the full media duration
        endTime.value = isAutoSequenceDuration.value
            ? mediaDuration
            : Math.min(sequenceDuration.value ?? maxDurationAudio.value, mediaDuration || Infinity);

        setMediaSrcInStore(media.value);
        return true;
    },
    tts: () => {
        if (!voiceOverData.value) return false;

        const { voiceOverMediaState, timerangeSegments, sequenceDuration, maxDuration } = voiceOverData.value;

        media.value = voiceOverMediaState.value;
        playbackRate.value = 1;
        segments.value = timerangeSegments.value;

        // Set sequenceEndTime to null if in auto mode - use computed property
        sequenceEndTime.value = isAutoSequenceDuration.value ? null : sequenceDuration.value;

        startTime.value = voiceOverMediaState.value.start;
        // For auto duration, use the full media duration
        endTime.value = isAutoSequenceDuration.value
            ? voiceOverMediaState.value?.duration || maxDuration.value
            : sequenceDuration.value ?? maxDuration.value;

        setMediaSrcInStore(media.value);
        return true;
    },
    recording: () => {
        if (!sequenceData.value) return false;

        const { backgroundVideo, seqPlaybackRate, backgroundVideoSegments, sequenceDuration, maxDuration } =
            sequenceData.value;

        media.value = backgroundVideo.value;
        playbackRate.value = seqPlaybackRate.value;
        segments.value = backgroundVideoSegments.value;

        // Set sequenceEndTime to null if in auto mode - use computed property
        sequenceEndTime.value = isAutoSequenceDuration.value ? null : sequenceDuration.value;

        startTime.value = 0;
        // For auto duration, use the full media duration
        endTime.value = isAutoSequenceDuration.value
            ? backgroundVideo.value?.duration || maxDuration.value
            : sequenceDuration.value > 0
              ? sequenceDuration.value
              : maxDuration.value;

        setMediaSrcInStore(media.value);
        return true;
    },
    recordingVisual: () => {
        if (!elementData.value) return false;

        const { videoSrc, elementState, elementPlaybackRate, visualSegments, sequenceDuration, maxDuration } =
            elementData.value;

        const mediaDuration = videoSrc.value?.duration || 0;
        const animationEndValue = elementState.value.animation.end.value;

        media.value = videoSrc.value;
        playbackRate.value = elementPlaybackRate.value;
        segments.value = visualSegments.value;

        startTime.value = elementState.value.animation.start.value;

        let finalEndTime;

        if (animationEndValue !== -1) {
            if (sequenceDuration.value === 0 || sequenceDuration.value === null) {
                finalEndTime = Math.min(animationEndValue, mediaDuration || Infinity);
                limitType.value = 'element';
            } else {
                finalEndTime = Math.min(animationEndValue, sequenceDuration.value, mediaDuration || Infinity);
                limitType.value = 'element';
            }
        } else {
            limitType.value = 'sequence';
            finalEndTime = Math.min(sequenceDuration.value ?? maxDuration.value, mediaDuration || Infinity);
        }

        endTime.value = finalEndTime;

        // Set sequenceEndTime to null if in auto mode - use computed property
        sequenceEndTime.value = isAutoSequenceDuration.value ? null : sequenceDuration.value;

        setMediaSrcInStore(media.value);
        return true;
    }
};

const close = () => {
    segments.value = [];
    media.value = null;
    playbackRate.value = 1;
    startTime.value = 0;
    sequenceEndTime.value = 0;
    previewManager.value = null;
    closeQuickCut();
};

// Function to set editing element to null
const setEditingElementToNull = () => {
    //store.commit('edition/setEditingElement', null, { root: true });
};

watch(
    isOpenQuickCut,
    (isOpen) => {
        if (!isOpen) return;

        const initializeFunction = initializeData[mediaType.value];

        if (initializeFunction && !initializeFunction()) {
            console.warn('Failed to initialize data for QuickCut');
            close();
            return;
        }

        if (segments.value && segments.value.length > 0) {
            if (fullMediaDuration.value > 0) {
                // Éviter d'appeler adjustSegmentsToFitSequenceDuration au chargement initial
                // si nous sommes en mode auto et qu'il n'y a pas de contrainte de durée à imposer
                const isAutoMode = isAutoSequenceDuration.value;
                if (!isAutoMode) {
                    adjustSegmentsToFitSequenceDuration();
                }
            } else {
                const unwatchMediaDuration = watch(fullMediaDuration, (newDuration) => {
                    if (newDuration > 0) {
                        const isAutoMode = isAutoSequenceDuration.value;
                        if (!isAutoMode) {
                            adjustSegmentsToFitSequenceDuration();
                        }
                        unwatchMediaDuration();
                    }
                });
            }
        }

        // Set editing element to null after one second, refactor this and find a better way to do this
        nextTick(() => {
            setTimeout(() => {
                setEditingElementToNull();
            }, 1000);
        });
    },
    { immediate: true }
);

watch(
    isReady,
    (ready) => {
        if (ready) {
            previewManager.value = new StudioEditPreviewManager(store);
            previewManager.value.resizePreview();
        } else if (previewManager.value) {
            previewManager.value = null;
        }
    },
    { immediate: true }
);

watch(
    media,
    (newMedia) => {
        if (newMedia) {
            trackMediaDuration(newMedia);
        }
    },
    { immediate: true }
);

const maxSegmentDuration = computed(() => {
    // Pour les éléments visuels, vérifier les contraintes d'animation
    if (['visual', 'recordingVisual'].includes(mediaType.value) && elementData.value) {
        const animStart = elementData.value?.elementState?.value?.animation?.start?.value || 0;
        const animEnd = elementData.value?.elementState?.value?.animation?.end?.value || -1;

        if (animEnd !== -1) {
            // Si animation a une fin explicite, utiliser la différence comme limite stricte
            const animDuration = animEnd - animStart;
            return animDuration;
        }
    }

    // Pour les autres types de média ou en l'absence de contraintes d'animation
    if (!isAutoSequenceDuration.value && sequenceData.value?.sequenceDuration?.value) {
        const sequenceDuration = sequenceData.value?.sequenceDuration?.value;
        const startTimeOffset = startTime.value || 0;
        const availableTimeInSequence = sequenceDuration - startTimeOffset;

        if (availableTimeInSequence > 0) {
            return availableTimeInSequence;
        }
    }

    // Si pas de contraintes précises, utiliser la durée du média
    return fullMediaDuration.value;
});

const updateSegments = (newSegments) => {
    newSegments.forEach((segment) => {
        delete segment.gapStyle;
    });

    // Vérifier à nouveau l'état de la séquence à chaque mise à jour
    const isSequenceAutoMode = isAutoSequenceDuration.value;
    const currentSequenceDuration = actualSequenceDuration.value;

    // S'assurer que sequenceEndTime est correctement défini
    if (!isSequenceAutoMode && currentSequenceDuration > 0 && sequenceEndTime.value !== currentSequenceDuration) {
        sequenceEndTime.value = currentSequenceDuration;
    }

    // Get all the constraints
    const mediaDuration = fullMediaDuration.value;
    const sequenceDuration = sequenceData.value?.sequenceDuration?.value || Infinity;
    const startTimeOffset = startTime.value || 0;

    // Calculate effective maximum duration considering all constraints
    let effectiveMaxDuration = mediaDuration;

    // When in auto mode, use the full media duration without sequence duration constraint
    if (!isSequenceAutoMode && sequenceDuration !== Infinity) {
        const availableTimeInSequence = sequenceDuration - startTimeOffset;
        if (availableTimeInSequence > 0) {
            effectiveMaxDuration = Math.min(mediaDuration, availableTimeInSequence);
        } else {
            effectiveMaxDuration = 0;
        }
    }

    // Apply the animation constraints for visual elements
    if (['visual', 'recordingVisual'].includes(mediaType.value) && elementData.value) {
        const animStart = elementData.value?.elementState?.value?.animation?.start?.value || 0;
        const animEnd = elementData.value?.elementState?.value?.animation?.end?.value || -1;

        if (animEnd !== -1) {
            const animDuration = animEnd - animStart;
            effectiveMaxDuration = Math.min(effectiveMaxDuration, animDuration);
        }
    }

    // IMPORTANT: Only create new segments if none exist
    if (newSegments.length === 0) {
        // If no segments, create one spanning the available duration within the sequence
        // For a startTime of 10 in a 16s sequence with a 30s media, we need a segment [10, 16]
        // effectiveMaxDuration here is already calculated as min(mediaDuration, sequenceDuration - startTime)
        const segmentStart = startTimeOffset;
        const segmentEnd = isSequenceAutoMode
            ? mediaDuration
            : Math.min(sequenceDuration || mediaDuration, startTimeOffset + effectiveMaxDuration);

        newSegments = [{ start: segmentStart, end: segmentEnd }];
    } else {
        // Do not extend segments to full media duration - just check for media bounds
        // Ensure segments don't exceed media duration or effective max duration
        newSegments.forEach((segment) => {
            // First check against media duration
            if (segment.end > mediaDuration) {
                segment.end = mediaDuration;
            }

            // Then check against the maximum allowed segment duration
            const segmentDuration = segment.end - segment.start;
            if (!isSequenceAutoMode && segmentDuration > effectiveMaxDuration) {
                segment.end = segment.start + effectiveMaxDuration;
            }
        });

        // If not in auto mode, check if segments exceed effective maximum duration
        if (!isSequenceAutoMode) {
            // Calculate total segment duration
            const segmentsTotalDuration = newSegments.reduce((sum, segment) => {
                return sum + (segment.end - segment.start);
            }, 0);

            // If segments exceed effective maximum duration, trim them
            if (segmentsTotalDuration > effectiveMaxDuration) {
                let accumulatedDuration = 0;

                newSegments = newSegments.filter((segment, index) => {
                    const segmentDuration = segment.end - segment.start;

                    if (accumulatedDuration + segmentDuration <= effectiveMaxDuration) {
                        accumulatedDuration += segmentDuration;
                        return true;
                    }

                    if (accumulatedDuration < effectiveMaxDuration) {
                        // This segment partly fits - trim it
                        const remainingSpace = effectiveMaxDuration - accumulatedDuration;
                        segment.end = segment.start + remainingSpace;
                        accumulatedDuration += remainingSpace;
                        return true;
                    }

                    return false;
                });
            }
        }
    }

    // Update the segments in the appropriate store based on media type
    if (['backgroundVideo', 'recording'].includes(mediaType.value) && sequenceData.value) {
        sequenceData.value.backgroundVideoSegments.value = newSegments;
    } else if (['visual', 'recordingVisual'].includes(mediaType.value) && elementData.value) {
        elementData.value.visualSegments.value = newSegments;
    } else if (mediaType.value === 'audio' && sequenceData.value) {
        sequenceData.value.audioTimerangeSegments.value = newSegments;
    } else if (mediaType.value === 'tts' && voiceOverData.value) {
        voiceOverData.value.timerangeSegments.value = newSegments;
    }

    segments.value = newSegments;

    console.log('|||| updateSegments - new segments:', newSegments);
};

const sequenceDuration = computed(() => {
    return sequenceData.value?.sequenceDuration?.value ?? 0;
});

const elementState = computed(() => {
    return (
        elementData.value?.elementState?.value ?? {
            animation: {
                start: { value: 0 },
                end: { value: 0 }
            }
        }
    );
});

const forceCurrentTime = (time) => {
    if (typeof time !== 'number' || !isFinite(time)) {
        console.error('forceCurrentTime received non-finite value:', time);
        return;
    }

    if (['backgroundVideo', 'recording'].includes(mediaType.value)) {
        if (sequenceData.value && sequenceData.value.currentTimeForced) {
            sequenceData.value.currentTimeForced.value = time;
        } else {
            console.warn('sequenceData.currentTimeForced is not available', sequenceData.value);
        }
    } else if (['visual', 'recordingVisual'].includes(mediaType.value)) {
        if (elementData.value && elementData.value.currentTimeForced) {
            elementData.value.currentTimeForced.value = time;
        } else {
            console.warn('elementData.currentTimeForced is not available', elementData.value);
        }
    }
};

const adjustSegmentsToFitSequenceDuration = () => {
    if (!sequenceData.value) {
        return;
    }

    // Use computed property for consistency
    const isSequenceAutoMode = isAutoSequenceDuration.value;

    // Get the media duration
    const mediaDuration = fullMediaDuration.value;

    // For visual elements, get animation constraints
    let animStart = 0;
    let animEnd = -1;
    let hasAnimationConstraints = false;

    if (['visual', 'recordingVisual'].includes(mediaType.value) && elementData.value) {
        animStart = elementData.value?.elementState?.value?.animation?.start?.value || 0;
        animEnd = elementData.value?.elementState?.value?.animation?.end?.value || -1;
        hasAnimationConstraints = animEnd !== -1;
    }

    // CRITICAL: Only create segments if none exist
    if (!segments.value || segments.value.length === 0) {
        // For 'visual' and 'recordingVisual' with animation constraints
        if (hasAnimationConstraints) {
            // Create segment using calculated animation constraints
            const initialSegment = [
                {
                    start: 0,
                    end: Math.min(animEnd - animStart, mediaDuration)
                }
            ];
            updateSegments(initialSegment);
            return;
        }

        // For sequence mode (non-auto), use sequence duration
        if (!isSequenceAutoMode) {
            const sequenceDuration = sequenceData.value?.sequenceDuration?.value ?? 0;
            const initialSegment = [{ start: 0, end: Math.min(sequenceDuration, mediaDuration) }];
            updateSegments(initialSegment);
            return;
        }

        // For auto mode, use full media duration
        const initialSegment = [{ start: 0, end: mediaDuration }];
        updateSegments(initialSegment);
        return;
    }

    // Get the sequence duration
    const sequenceDuration = sequenceData.value?.sequenceDuration?.value ?? 0;

    // Get the start time offset
    const startTimeOffset = startTime.value || 0;

    // Calculate the effective maximum available duration by considering all constraints
    let effectiveMaxDuration = mediaDuration;

    // For visual elements with explicit animation constraints, they take precedence
    if (hasAnimationConstraints) {
        effectiveMaxDuration = Math.min(effectiveMaxDuration, animEnd - animStart);
    }
    // For non-auto sequence mode, apply sequence duration constraint
    else if (!isSequenceAutoMode && sequenceDuration > 0) {
        // The available time within the sequence is reduced by startTime
        const availableTimeInSequence = sequenceDuration - startTimeOffset;
        // Available time can't be negative
        if (availableTimeInSequence > 0) {
            // The effective max duration is the minimum of the current max and available sequence time
            effectiveMaxDuration = Math.min(effectiveMaxDuration, availableTimeInSequence);
        } else {
            // If startTime is beyond sequence duration, we have no available time
            effectiveMaxDuration = 0;
        }
    }

    // Calculate the total duration of all segments
    const totalSegmentDuration = segments.value.reduce((sum, segment) => {
        return sum + (segment.end - segment.start);
    }, 0);

    // Apply constraints if needed - either from animation or from sequence duration in non-auto mode
    const needToEnforceConstraints = hasAnimationConstraints || (!isSequenceAutoMode && sequenceDuration > 0);

    if (needToEnforceConstraints && totalSegmentDuration > effectiveMaxDuration) {
        if (segments.value.length === 1) {
            const segment = segments.value[0];
            const originalStart = segment.start;
            const originalEnd = segment.end;
            const newEnd = Math.min(originalStart + effectiveMaxDuration, mediaDuration, originalEnd);

            // Only adjust if it actually needs trimming
            if (newEnd < originalEnd) {
                const adjustedSegments = [
                    {
                        start: originalStart,
                        end: newEnd
                    }
                ];

                updateSegmentsStore(adjustedSegments);
                segments.value = adjustedSegments;
                updateSegments(adjustedSegments);
            }
        } else if (segments.value.length > 1) {
            let remainingDuration = effectiveMaxDuration;
            let adjustedSegments = [];
            let needsAdjustment = false;

            for (let i = 0; i < segments.value.length && remainingDuration > 0; i++) {
                const segment = segments.value[i];
                const segmentDuration = segment.end - segment.start;

                if (segmentDuration <= remainingDuration) {
                    // This entire segment fits
                    adjustedSegments.push({
                        start: segment.start,
                        end: segment.end
                    });
                    remainingDuration -= segmentDuration;
                } else {
                    // Only part of this segment fits - this needs trimming
                    needsAdjustment = true;
                    adjustedSegments.push({
                        start: segment.start,
                        end: Math.min(segment.start + remainingDuration, mediaDuration)
                    });
                    remainingDuration = 0;
                }
            }

            // If some segments didn't fit at all, we need adjustment
            if (adjustedSegments.length < segments.value.length) {
                needsAdjustment = true;
            }

            // Ensure we have at least one segment
            if (adjustedSegments.length === 0) {
                const firstSeg = segments.value[0];
                adjustedSegments = [
                    {
                        start: firstSeg.start,
                        end: Math.min(firstSeg.start + effectiveMaxDuration, mediaDuration)
                    }
                ];
                needsAdjustment = true;
            }

            // Only update if adjustments were needed
            if (needsAdjustment) {
                updateSegmentsStore(adjustedSegments);
                segments.value = adjustedSegments;
                updateSegments(adjustedSegments);
            }
        }
    }
};

const updateSegmentsStore = (newSegments) => {
    if (['backgroundVideo', 'recording'].includes(mediaType.value) && sequenceData.value) {
        sequenceData.value.backgroundVideoSegments.value = newSegments;
    } else if (['visual', 'recordingVisual'].includes(mediaType.value) && elementData.value) {
        elementData.value.visualSegments.value = newSegments;
    } else if (mediaType.value === 'audio' && sequenceData.value) {
        sequenceData.value.audioTimerangeSegments.value = newSegments;
    } else if (mediaType.value === 'tts' && voiceOverData.value) {
        voiceOverData.value.timerangeSegments.value = newSegments;
    }
};
</script>
