<template>
    <nav class="ui-layout-nav">
        <div class="nav-container">
            <div id="nav-logo">
                <RouterLink to="/">
                    <svg-id-randomized-icon icon="cte-logo" class="logo" />
                    <svg-id-randomized-icon icon="cte-logo-inline" class="hover-logo" />
                </RouterLink>
            </div>
            <div class="nav-item-list-spacer">
                <div class="nav-items-list">
                    <ui-nav-item :route="{ name: 'Home' }" :label="$t('Homepage')" icon="fa-house" />
                    <ui-nav-create-video />

                    <div class="nav-section-title">
                        <div class="title">{{ $t('Folders') }}</div>
                        <div class="title-bar-container">
                            <div class="title-bar"></div>
                        </div>
                    </div>
                    <ui-scrollable v-if="folders.length > 3" class="folders-augmented">
                        <ui-nav-folder-link-item v-for="folder in folders" :key="folder.id" :folder="folder" />
                    </ui-scrollable>
                    <ui-nav-folder-link-item v-else v-for="folder in folders" :key="folder.id" :folder="folder" />

                    <ui-nav-item :route="{ name: 'Folders' }" :label="$t('See more')" icon="fa-grid-2" />

                    <div class="nav-section-title">
                        <div class="title">{{ $t('Resources') }}</div>
                        <div class="title-bar-container">
                            <div class="title-bar"></div>
                        </div>
                    </div>
                    <ui-nav-item
                        name="medias"
                        :route="{ name: 'Medias' }"
                        :label="$t('Medias')"
                        icon="fa-folder-image"
                    />
                    <ui-nav-item
                        v-if="isCTE || isClientAdmin"
                        :route="{ name: 'Brands' }"
                        :label="$t('brand_center.title')"
                        icon="fa-swatchbook"
                    />
                    <ui-nav-item
                        :route="{ name: 'AiTools' }"
                        :label="$t('AI tools ')"
                        icon="fa-sparkles"
                        :name="'AiTools'"
                        @click="reloadAiTools"
                    />

                    <div class="nav-section-title">
                        <div class="title">{{ $t('Academy') }}</div>
                        <div class="title-bar-container">
                            <div class="title-bar"></div>
                        </div>
                    </div>
                    <ui-nav-item :route="{ name: 'Academy' }" :label="$t('Resources center')" icon="fa-book" />
                    <ui-nav-item
                        :route="{ name: 'Elearning' }"
                        :label="$t('E-learning')"
                        icon="fa-graduation-cap"
                        :name="'learn'"
                    />
                </div>
                <div class="nav-items-list settings-menu">
                    <ui-nav-beamer-item v-if="user.client.enableNotificationPanel" />
                    <ui-nav-item
                        v-if="isCTE || isClientAdmin"
                        name="params"
                        :route="{ name: 'EditClient' }"
                        :label="$t('Parameters')"
                        icon="fa-gear"
                    />
                    <div class="nav-item sublist-container" ref="containerRef" @click="toggleSubList">
                        <div class="profile-icon-container">
                            <fa-icon icon="fa-regular fa-user" class="icon" />
                        </div>
                        <div class="nav-item-route-name">
                            <div class="nav-item-name-label">{{ userFullName }}</div>
                        </div>
                        <ui-nav-sub-list :class="{ opened: opened }" class="settings-sub-list">
                            <ui-nav-item
                                v-if="user"
                                name="profile"
                                :link="false"
                                :label="'Mon profil'"
                                icon="fa-user"
                                @click="editProfile"
                            />

                            <ui-nav-item
                                v-if="user"
                                name="logout"
                                :link="false"
                                :label="$t('Logout')"
                                icon="fa-right-from-bracket"
                                @click="logout"
                            />
                        </ui-nav-sub-list>
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>
<script setup>
import { computed, onBeforeUnmount, onMounted, provide, ref } from 'vue';
import UiScrollable from '@/js/components/UiScrollable.vue';
import UiNavItem from '@/js/components/UiNavItem.vue';
import { useModal } from '@/js/composables/useModal.js';
import { useAuth } from '@/js/composables/useAuth.js';
import { useBrands } from '@/js/composables/useBrands.js';
import { useFolders } from '@/js/composables/useFolders.js';
import UiNavFolderLinkItem from '@/js/components/UiNavFolderLinkItem.vue';
import UiNavBeamerItem from '@/js/components/UiNavBeamerItem.vue';
import UiNavCreateVideo from '@/js/components/UiNavCreateVideo.vue';
import UiNavSubList from '@/js/components/UiNavSubList.vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const { openModal } = useModal();
const { user, isCTE, isClientAdmin, logout } = useAuth();
const opened = ref(false);

const openedNavItem = ref(null);
const openNavItem = (item) => {
    openedNavItem.value = item;
};
provide('openedNavItem', { openedNavItem, openNavItem });

const userFullName = computed(() => {
    return user.value ? `${user.value.firstName} ${user.value.lastName}` : '';
});
const containerRef = ref(null);

const toggleSubList = (event) => {
    opened.value = !opened.value;
};

const reloadAiTools = () => {
    if (route.name === 'AiTools') {
        router.replace({ name: 'Home' }).then(() => {
            router.replace({ name: 'AiTools' });
        });
    } else {
        router.push({ name: 'AiTools' });
    }
};

function handleClickOutside(event) {
    if (opened.value && containerRef.value && !containerRef.value.contains(event.target)) {
        opened.value = false;
    }
}

const editProfile = () => {
    openModal('EditProfileModal');
};

const { brands, fetchBrands } = useBrands();
const { folders, fetchFolders } = useFolders();

onMounted(() => {
    document.addEventListener('click', handleClickOutside);
    fetchFolders(user.value.client.id, { count: 3, menu: true });
    fetchBrands({ count: 3 });
});

onBeforeUnmount(() => {
    document.removeEventListener('click', handleClickOutside);
});
</script>
