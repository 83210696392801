const originalState = () => ({
    favourites: new Set()
});

const state = () => ({
    ...originalState()
});

const getters = {
    isFavourite: (state) => (folderUuid) => state.favourites.has(folderUuid)
};

const mutations = {
    addFavourite(state, folderUuid) {
        state.favourites.add(folderUuid);
    },
    removeFavourite(state, folderUuid) {
        state.favourites.delete(folderUuid);
    },
    setFavourites(state, folderList) {
        for (const folder of folderList) {
            state.favourites.add(folder.shortname);
        }
    }
};

const actions = {
    toggleFavourite({ commit, state }, folderUuid) {
        if (state.favourites.has(folderUuid)) {
            commit('removeFavourite', folderUuid);
        } else {
            commit('addFavourite', folderUuid);
        }
    },
    initializeFavourites({ commit }, folders) {
        commit('setFavourites', folders);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
