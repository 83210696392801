import { Store } from 'vuex';
import { AxiosInstance, AxiosResponse } from 'axios';
import { executeRequest } from '../apiClient.js';
import { CaptionUnit } from '@/js/videos/types/captions.js';

const BASE_URL = import.meta.env.VITE_LEGACY_APP_URL;
const AI_APP_KEY = import.meta.env.VITE_AI_APP_KEY;

export default class CaptionApiService {
    private readonly store: Store<any>;

    constructor(store: Store<any>) {
        this.store = store;
    }

    createTask(mediaId: string, lang: string): Promise<AxiosResponse> {
        return executeRequest(this.store, {
            request: async (client: AxiosInstance): Promise<AxiosResponse> => {
                return await client.post(`${BASE_URL}/media/${mediaId}/captions`, { language_src: lang });
            }
        });
    }

    getMediaTaskResult(mediaId: string): Promise<AxiosResponse> {
        return executeRequest(this.store, {
            request: async (client: AxiosInstance): Promise<AxiosResponse> => {
                return await client.post(`${BASE_URL}/media/${mediaId}/captions/status`);
            }
        });
    }

    createTranslationTask(captions: CaptionUnit[], lang: string): Promise<AxiosResponse> {
        return executeRequest(this.store, {
            request: async (client: AxiosInstance): Promise<AxiosResponse> => {
                return await client.post(
                    `/api/v1/translate-subtitle`,
                    {
                        data: captions,
                        targetLanguage: lang
                    },
                    {
                        headers: {
                            Authorization: AI_APP_KEY
                        }
                    }
                );
            }
        });
    }
}
