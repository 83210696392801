<template>
    <ui-dropdown
        id="settings-music-volume-dropdown"
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="bottom"
        menu-strategy="fixed"
        :icon-only="true"
        :toggle-style="toggleStyle"
        :disabled="readOnly || (!music.src && !libraryMusic.src)"
        :tooltip="$t('Volume')"
    >
        <template #dropdown-toggle>
            <svg-icon icon="audio-icon" />
        </template>

        <ui-range-input
            id="settings-music-volume"
            :horizontal="false"
            v-model="musicVolume"
            :label="$t('Volume')"
            :max="200"
            :disabled="readOnly"
        />
    </ui-dropdown>
</template>

<script setup>
import { computed } from 'vue';
import UiRangeInput from '../../../../../../components/UiRangeInput.vue';
import UiDropdown from '../../../../../../components/UiDropdown.vue';
import { useMusic } from '@/js/videos/composables/useMusic.js';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import Sound from '@/js/video-studio/constants/sound.js';

const { readOnly } = useVideo();
const { music, libraryMusic, musicVolume } = useMusic();

const toggleStyle = computed(() =>
    musicVolume.value !== Sound.VOLUME_DEFAULT * 100 ? { color: 'var(--edit-mode-color)' } : {}
);
</script>
