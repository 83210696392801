import axios from 'axios';
import { STATUS_REFRESHING_TOKEN } from '../../constants/index.js';
import * as Sentry from '@sentry/browser';
import i18n from '@/libs/i18n.js';

const LOGIN_URL = import.meta.env.VITE_IDENTITY_URL;
const REFRESH_TOKEN_URL = `${LOGIN_URL}/service-tokens/refresh`;
const { t } = i18n.global;

// Création d'une instance axios avec gestion de la langue
const createAxiosInstance = (language = 'fr_FR') =>
    axios.create({
        withCredentials: true,
        headers: { 'x-lang': language }
    });

// Récupère la langue de l'utilisateur depuis le store
const getUserLanguage = (store) => store?.getters['auth/user']?.language || 'fr_FR';

// Classes d'erreurs personnalisées
class ValidationError extends Error {
    constructor(errors) {
        super('Validation failed');
        this.name = 'ValidationError';
        this.errors = errors;
    }
}

class BadRequestError extends Error {
    constructor(message, errorCode, error) {
        super(message);
        this.name = 'BadRequestError';
        this.errorCode = errorCode;
        this.error = error;
    }
}

class SafeRequestError extends Error {
    constructor(message, errorCode, error) {
        if (import.meta.env.VITE_DEBUG_MODE === 'true') {
            console.error('SafeRequestError:', message, errorCode, error);
            super(message);
        } else {
            super(t('requests.error'));
        }
        this.name = 'SafeRequestError';
        this.errorCode = errorCode;
        this.error = error;
    }
}

// Redirige vers la page de login si l'utilisateur n'est pas connecté
const handleUserNotConnected = () => {
    window.location.assign(LOGIN_URL);
};

// Gestion centralisée des erreurs d'API
const handleApiError = (error) => {
    // Gestion des erreurs de validation Laravel
    if (error.response?.status === 422 && error.response?.data?.errors) {
        throw new ValidationError(error.response.data.errors);
    }
    // Gestion des erreurs d'API Laravel (ex: mauvaise requête)
    if (error.response?.status === 400 && error.response?.data?.message) {
        throw new BadRequestError(error.response.data.message, error.response.data.error, error.response.data.details);
    }
    // Pour toutes les autres erreurs, on capture dans Sentry puis on renvoie une erreur "safe"
    Sentry.captureException(error);
    const message = error.response?.data?.message || error.message;
    const errorCode = error.response?.data?.error;
    const details = error.response?.data?.details;
    throw new SafeRequestError(message, errorCode, details);
};

// Logique de rafraîchissement du token :
// Essaye d'exécuter la requête, et si l'erreur indique un problème d'authentification (401, 409 ou 419),
// on tente de rafraîchir le token puis on ré-exécute la requête.
// En cas d'échec du rafraîchissement, on redirige (sauf si disableRedirection est true).
const handleTokenRefresh = async (request, store, disableRedirection = false) => {
    try {
        return await request();
    } catch (error) {
        if ([401, 409, 419].includes(error.response?.status)) {
            try {
                await refreshServiceToken(store);
                // Refaire la requête après rafraîchissement
                return await request();
            } catch (refreshError) {
                if (!disableRedirection) handleUserNotConnected();
                throw refreshError;
            }
        }
        throw error;
    }
};

// Fonction d'exécution d'une requête avec gestion de rafraîchissement et erreurs
const executeRequest = async (store, { request, onSuccess, onError, actionType }, disableRedirection = false) => {
    const axiosInstance = createAxiosInstance(getUserLanguage(store));
    try {
        const response = await handleTokenRefresh(() => request(axiosInstance), store, disableRedirection);
        if (onSuccess) onSuccess(response, store);
        return response;
    } catch (error) {
        // Transformation et gestion centralisée de l'erreur via handleApiError
        throw handleApiError(error);
    }
};

// Rafraîchit le token en appelant l'endpoint dédié
const refreshServiceToken = async (store) => {
    await axios.get(REFRESH_TOKEN_URL, { withCredentials: true });
    if (store) store.commit('ui/setSaveStatus', STATUS_REFRESHING_TOKEN, { root: true });
};

export { refreshServiceToken, executeRequest, ValidationError, BadRequestError };