import { executeRequest } from '@/js/infrastructure/api/apiClient.js';

const LEGACY_URL = import.meta.env.VITE_LEGACY_APP_URL;
const EDITION_URL = import.meta.env.VITE_EDITION_API_URL;

export default class FoldersApiService {
    constructor(store) {
        this.store = store;
    }

    fetchFoldersData(clientId, options = {}) {
        const { page = 1, search = '', count = 10, menu = false } = options;

        const queryParams = new URLSearchParams({
            page: page.toString(),
            count: count.toString()
        });
        if (menu === true) {
            queryParams.append('menu', 'true');
        }

        if (search) queryParams.append('search', search);

        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${EDITION_URL}/api/clients/${clientId}/folders?${queryParams.toString()}`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'fetchFoldersData'
        });
    }

    fetchByShortname(shortname) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${EDITION_URL}/api/folders/shortname/${shortname}`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'fetchByShortname'
        });
    }

    fetchEditionByShortname(shortname) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(`${EDITION_URL}/api/folders/${shortname}?expand[]=brand.logo`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'fetchEditionByShortname'
        });
    }

    fetchFolderUsers(folderShortname, options = {}) {
        const { page = 1, search = '', count = 10 } = options;

        const queryParams = new URLSearchParams({
            page: page.toString(),
            count: count.toString()
        });

        if (search) queryParams.append('search', search);

        return executeRequest(this.store, {
            request: async (client) => {
                return await client.get(
                    `${LEGACY_URL}/front/folders/${folderShortname}/grantUsers?${queryParams.toString()}`
                );
            },
            onSuccess: async (response) => response.data,
            actionType: 'fetchFolderUsers'
        });
    }

    updateFolderName(folderShortname, name) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.patch(`${LEGACY_URL}/front/folders/${folderShortname}/rename`, { name });
            },
            onSuccess: async (response) => response.data,
            actionType: 'updateFolderName'
        });
    }

    // Favourites
    addFavourite(folderUuid = {}) {
        const queryParams = new URLSearchParams({
            folderUuid: folderUuid
        });

        return executeRequest(this.store, {
            request: async (client) => {
                return await client.post(`${EDITION_URL}/api/folders/favourites?${queryParams.toString()}`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'addFavourite'
        });
    }

    removeFavourite(folderUuid = {}) {
        const queryParams = new URLSearchParams({
            folderUuid: folderUuid
        });

        return executeRequest(this.store, {
            request: async (client) => {
                return await client.delete(`${EDITION_URL}/api/folders/favourites?${queryParams.toString()}`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'addFavourite'
        });
    }
}
