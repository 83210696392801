import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';

import { CUSTOM_MUSIC_CATEGORY, MUSIC_CATEGORIES } from '@/js/constants/index.js';
import { useBrand } from '@/js/videos/composables/useBrand.js';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import { getPrefixedUuid } from '@/js/utils.js';

export const useMusic = () => {
    const store = useStore();
    const { isBrandLibraryEmpty } = useBrand();
    const { saveVideo } = useVideo();
    const { startHistoryStep } = useHistory();

    const settingsState = computed(() => store.state.settings);
    const prefixes = computed(() => store.state.ui.prefixes);
    const triggerSelection = ref(false);

    const musicLibraryCategories = computed(() =>
        [...MUSIC_CATEGORIES].filter((category) => !isBrandLibraryEmpty.value('musics', category))
    );

    const musicTypes = computed(() => [...musicLibraryCategories.value, CUSTOM_MUSIC_CATEGORY]);
    const musicType = computed({
        get: () => settingsState.value.music.type,
        set: (type) => {
            startHistoryStep();
            store.commit('settings/setMusicType', type);
            store.dispatch('settings/updateLibraryMusic', { src: '', src__id: '' });
            saveVideo();
        }
    });
    watch(musicType, () => (triggerSelection.value = true));

    const music = computed({
        get: () => ({ src: settingsState.value.music.src, id: settingsState.value.music.src__id }),
        set: ({ src, id }) => {
            startHistoryStep();
            if (!settingsState.value.music.src__ref) {
                store.commit('settings/setMusicReference', getPrefixedUuid(prefixes.value.mediaReference));
            }

            store.dispatch('settings/updateMusic', { src, src__id: id });
            if (musicTypes.value.length === 1 && musicType.value !== CUSTOM_MUSIC_CATEGORY) {
                store.commit('settings/setMusicType', CUSTOM_MUSIC_CATEGORY);
            }
            saveVideo();
        }
    });

    const libraryMusic = computed({
        get: () => ({ src: settingsState.value.music.library_src, id: settingsState.value.music.library_src__id }),
        set: ({ src, id }) => {
            startHistoryStep();
            if (!settingsState.value.music.library_src__ref) {
                store.commit('settings/setLibraryMusicReference', getPrefixedUuid(prefixes.value.musicReference));
            }
            store.dispatch('settings/updateLibraryMusic', { src, src__id: id });
            saveVideo();
        }
    });

    const musicVolume = computed({
        get: () => Math.round(100 * store.getters['settings/musicVolume']),
        set: (value) => {
            startHistoryStep();
            store.commit('settings/setMusicVolume', { value: (value / 100).toFixed(2), custom: null });
            saveVideo();
        }
    });

    const musicStart = computed({
        get: () => settingsState.value.music.start,
        set: (value) => {
            if (value !== musicStart.value) {
                startHistoryStep();
                store.commit('settings/setMusicStart', value);
                saveVideo();
            }
        }
    });

    const enabledEndFade = computed({
        get: () => settingsState.value.fade.end.enabled,
        set: (value) => {
            startHistoryStep();
            store.commit('settings/enableEndFade', value);
            saveVideo();
        }
    });

    const musicRange = computed({
        get: () => ({
            start: settingsState.value.music.timerange?.start || 0,
            end: settingsState.value.music.timerange?.end || -1
        }),
        set: ({ start, end }) => {
            if (start !== musicRange.value.start || end !== musicRange.value.end) {
                startHistoryStep();
                store.commit('settings/setMusicTimerange', { start, end });
                saveVideo();
            }
        }
    });

    const activeTimerange = computed(() => {
        const musicTotalDurationRounded =
            Math.floor((settingsState.value.music.totalDuration() + Number.EPSILON) * 100) / 100;
        return (
            musicType.value &&
            (settingsState.value.music.src || settingsState.value.music.library_src) &&
            (musicRange.value.start > 0 ||
                (musicRange.value.end < musicTotalDurationRounded && musicRange.value.end > -1))
        );
    });

    return {
        triggerSelection,
        musicLibraryCategories,

        settingsState,
        musicTypes,
        musicType,
        music,
        libraryMusic,

        musicVolume,
        musicStart,
        enabledEndFade,
        musicRange,
        activeTimerange
    };
};
