<template>
    <div class="ui-text-input" :class="containerClasses">
        <label class="ui-text-label" :class="labelClasses" :for="inputId" :aria-hidden="!showLabel || null">
            <slot>{{ label }}</slot>
        </label>
        <input
            v-if="!textarea"
            :type="type"
            ref="$input"
            :id="inputId"
            v-model="inputValue"
            :placeholder="placeholder"
            :disabled="disabled"
            :class="inputClass"
            @focus="handleFocus"
            @blur="handleBlur"
            @input="handleInput"
            @change="handleChange"
            @keyup.enter="$event.target.blur()"
        />
        <textarea
            v-else
            ref="$input"
            :id="inputId"
            v-model="inputValue"
            :placeholder="placeholder"
            :disabled="disabled"
            :maxlength="maxlength"
            :class="textareaClass"
            @focus="handleFocus"
            @blur="handleBlur"
            @input="handleInput"
            @change="handleChange"
        />
        <svg-icon v-if="!textarea && !!icon" :icon="icon" class="ui-text-input-icon" />
        <span v-if="hint" class="ui-text-input-hint">{{ hint }}</span>
        <TextError :errors="errors" />
    </div>
</template>

<script>
import TextError from '@/js/components/TextError.vue';

export const UI_TEXT_INPUT_INPUT = 'ui-text-input-input';
export const UI_TEXT_INPUT_CHANGE = 'ui-text-input-change';

export default {
    components: { TextError },
    emits: [
        'update:modelValue',
        'focus',
        'blur',
        'input',
        'change',
        'enter',
        UI_TEXT_INPUT_INPUT,
        UI_TEXT_INPUT_CHANGE
    ],

    props: {
        id: {
            type: String
        },
        modelValue: {
            type: [String, Number],
            default: ''
        },
        defaultValue: {
            type: [String, Number],
            default: ''
        },
        textarea: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'text',
            validator: (value) => ['text', 'search', 'password'].includes(value)
        },
        horizontal: {
            type: Boolean,
            default: true
        },
        placeholder: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        maxlength: {
            type: Number,
            default: null
        },
        showError: {
            type: Boolean,
            default: false
        },
        noStyle: {
            type: Boolean,
            default: false
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        hint: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        errors: {
            type: Array,
            default: null
        }
    },

    data() {
        return {
            inputValue: /\S/.test(this.modelValue) ? this.modelValue : this.defaultValue
        };
    },

    computed: {
        containerClasses() {
            return {
                horizontal: this.horizontal,
                disabled: this.disabled,
                'with-icon': !this.textarea && !!this.icon,
                'no-style': this.noStyle
            };
        },

        inputId() {
            return this.id + '-text-input';
        },

        labelClasses() {
            return {
                'visually-hidden': !this.showLabel,
                required: this.required
            };
        },

        textareaClass() {
            return {
                'text-error-color': this.showError
            };
        },

        inputClass() {
            return {
                'text-error-color': this.showError
            };
        }
    },

    watch: {
        modelValue() {
            this.inputValue = /\S/.test(this.modelValue) ? this.modelValue : this.defaultValue;
        }
    },

    methods: {
        handleFocus(event) {
            this.$emit('focus', event);
        },

        handleBlur(event) {
            this.$emit('blur', event);
        },

        handleInput(event) {
            this.$emit('input', event);
            this.$emit(UI_TEXT_INPUT_INPUT, this.inputValue, event);
            this.$emit('update:modelValue', this.inputValue);
        },

        handleChange(event) {
            if (!/\S/.test(this.inputValue)) this.inputValue = this.defaultValue;
            this.$emit('change', event);
            this.$emit(UI_TEXT_INPUT_CHANGE, this.inputValue, event);
            this.$emit('update:modelValue', this.inputValue);
        }
    },

    mounted() {
        if (this.autofocus) this.$refs.$input.select();
    }
};
</script>