<template>
    <div ref="containerRef" class="ui-video-preview" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave">
        <img
            :src="currentThumbnail"
            class="static-image-preview"
            :class="{ 'default-preview': isDefaultThumbnail, hidden: isVideoPlaying }"
            alt="Video thumbnail"
            loading="lazy"
            @error="handleStaticImageError"
        />
        <video
            v-if="isInViewport"
            ref="videoElement"
            :src="media.thumbnails.videoSrc"
            class="video-preview"
            :class="{ hidden: !isVideoPlaying }"
            loop
            muted
            preload="none"
            @loadedmetadata="onVideoLoaded"
        ></video>
    </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch, onUnmounted } from 'vue';
import type { VideoPreviewable } from '@/js/types/VideoPreviewable';
import { FORMAT_RATIOS } from '@/js/types/Format';
import { useIntersectionObserver } from '@vueuse/core';
import previewLandscape from '@/assets/images/preview-landscape.png';
import previewSquare from '@/assets/images/preview-square.png';
import previewVertical from '@/assets/images/preview-vertical.png';

interface Props {
    media: VideoPreviewable;
    debugMode?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    debugMode: false
});

const containerRef = ref<HTMLElement | null>(null);
const videoElement = ref<HTMLVideoElement | null>(null);
const isVideoPlaying = ref(false);
const videoLoaded = ref(false);
const useDefaultThumbnail = ref(false);

const isInViewport = ref(false);
useIntersectionObserver(containerRef, ([entry]) => {
    isInViewport.value = entry.isIntersecting;
}, { threshold: 0.1 });

let playTimeout: ReturnType<typeof setTimeout> | undefined;
let stopTimeout: ReturnType<typeof setTimeout> | undefined;

const getDefaultThumbnail = computed(() => {
    const ratio = props.media.format.width / props.media.format.height;
    const previewMap = {
        [FORMAT_RATIOS.RATIO_16X9]: previewLandscape,
        [FORMAT_RATIOS.RATIO_1X1]: previewSquare,
        [FORMAT_RATIOS.RATIO_9X16]: previewVertical
    };
    
    return previewMap[ratio] ?? previewSquare;
});

const currentThumbnail = computed(() => {
    return useDefaultThumbnail.value
        ? getDefaultThumbnail.value
        : props.media.thumbnails.staticSrc || getDefaultThumbnail.value;
});

const isDefaultThumbnail = computed(() => {
    return useDefaultThumbnail.value || !props.media.thumbnails.staticSrc;
});

const loadAndPlayVideo = async () => {
    if (!isInViewport.value || !props.media.thumbnails.videoSrc) {
        if (props.debugMode) console.log('Video hors du viewport ou pas de source vidéo - abandon du chargement');
        return;
    }

    if (props.debugMode) {
        console.log('Début loadAndPlayVideo avec:', {
            videoSrc: props.media.thumbnails.videoSrc,
            videoElement: !!videoElement.value,
            videoLoaded: videoLoaded.value,
            isInViewport: isInViewport.value
        });
    }

    if (videoElement.value && !videoLoaded.value) {
        try {
            if (props.debugMode) console.log('Tentative de chargement de la vidéo...');
            videoElement.value.preload = 'auto';
            
            // Vérification du type MIME
            if (props.debugMode) {
                const videoType = videoElement.value.canPlayType(props.media.thumbnails.videoSrc.split(';')[0]);
                console.log('Support du format vidéo:', {
                    src: props.media.thumbnails.videoSrc,
                    canPlay: videoType
                });
            }

            await videoElement.value.load();
            videoLoaded.value = true;
            if (props.debugMode) console.log('Vidéo chargée avec succès');
        } catch (error) {
            console.error('Erreur détaillée lors du chargement:', {
                error,
                videoSrc: props.media.thumbnails.videoSrc,
                readyState: videoElement.value?.readyState,
                networkState: videoElement.value?.networkState
            });
            return;
        }
    }

    if (videoElement.value && videoLoaded.value) {
        try {
            if (props.debugMode) console.log('Tentative de lecture...');
            await videoElement.value.play();
            isVideoPlaying.value = true;
            if (props.debugMode) console.log('Lecture démarrée avec succès');
        } catch (error) {
            if (error instanceof Error) {
                console.error('Erreur détaillée lors de la lecture:', {
                    src: props.media.thumbnails.videoSrc,
                    name: error.name,
                    message: error.message,
                    readyState: videoElement.value.readyState,
                    networkState: videoElement.value.networkState,
                    error: videoElement.value.error
                });
            }
            isVideoPlaying.value = false;
        }
    }
};

const stopVideo = () => {
    if (videoElement.value) {
        videoElement.value.pause();
        isVideoPlaying.value = false;
        if (props.debugMode) console.log('Vidéo mise en pause');
    }
};

const handleMouseEnter = () => {
    if (playTimeout) clearTimeout(playTimeout);
    if (stopTimeout) clearTimeout(stopTimeout);
    playTimeout = setTimeout(loadAndPlayVideo, 100);
};

const handleMouseLeave = () => {
    if (playTimeout) clearTimeout(playTimeout);
    if (stopTimeout) clearTimeout(stopTimeout);
    stopTimeout = setTimeout(stopVideo, 100);
};

const onVideoLoaded = () => {
    if (props.debugMode) console.log('Métadonnées de la vidéo chargées');
    videoLoaded.value = true;
};

const handleStaticImageError = () => {
    useDefaultThumbnail.value = true;
    if (props.debugMode) console.log("Erreur de chargement de l'image statique, utilisation de l'image par défaut");
};

onMounted(() => {
    if (props.debugMode) console.log('Composant monté, URL de la vidéo:', props.media.thumbnails.videoSrc);
});

watch(
    () => props.media.thumbnails.videoSrc,
    (newSrc) => {
        if (props.debugMode) console.log('URL de la vidéo mise à jour:', newSrc);
        videoLoaded.value = false;
        useDefaultThumbnail.value = !newSrc;
        if (isVideoPlaying.value && !newSrc) {
            stopVideo();
        }
    }
);

onUnmounted(() => {
    if (playTimeout) clearTimeout(playTimeout);
    if (stopTimeout) clearTimeout(stopTimeout);
});
</script>

<style scoped>
.ui-video-preview {
    position: relative;
    width: 100%;
    height: 100%;
}

.static-image-preview,
.video-preview {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
}

.video-preview {
    position: absolute;
    top: 0;
    left: 0;
}

.hidden {
    display: none;
}
</style>
    