<template>
    <component
        :is="containerTag"
        :role="dropdown && dropdown.select ? 'option' : 'menuitem'"
        :aria-disabled="disabled || null"
    >
        <component
            :is="itemTag"
            v-bind="itemAttributes"
            :class="itemClasses"
            role="none"
            @click.prevent="emitItemClickEvent"
            @keydown.space="emitItemSelectEvent"
        >
            <ui-wrapper :tag="labelTag" class="ui-dropdown-item-label">
                <svg-icon v-if="customIcon" class="dropdown-item-icon" :icon="customIcon"></svg-icon>
                <fa-icon v-if="icon" class="dropdown-item-icon" :icon="icon" />
                <slot></slot>
                <slot name="dropdown-menu-item-label" :instance="instanceForSlots">
                    {{ label || value }}
                </slot>
            </ui-wrapper>
            <span v-show="showSelectedIcon" class="selected-icon">
                <fa-icon class="icon" icon="fa-solid fa-check" />
            </span>
            <span v-show="showDisabledIcon" class="selected-icon">
                <fa-icon class="icon" icon="fa-solid fa-lock" />
            </span>
        </component>
    </component>
</template>

<script>
import UiIcon from './UiIcon.vue';
import UiWrapper from './UiWrapper.vue';

export default {
    name: 'ui-dropdown-item',

    // prevent listeners to fire twice due to the binding of "itemAttributes"
    inheritAttrs: false,

    components: {
        UiIcon,
        UiWrapper
    },

    inject: ['dropdown'],

    props: {
        label: {
            type: String,
            default: ''
        },
        value: {
            type: [String, Number],
            default: ''
        },
        metadata: {
            type: Object,
            default: () => ({})
        },
        classes: {
            type: [String, Array, Object],
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        containerTag: {
            type: String,
            default: 'li'
        },
        labelTag: {
            type: [Boolean, String],
            default: false
        },
        tooltip: {
            type: Boolean,
            default: false
        },
        showIconOnDisabled: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: ''
        },
        customIcon: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            selected: false
        };
    },

    computed: {
        itemTag() {
            return !this.disabled ? 'a' : 'span';
        },

        itemClasses() {
            return [
                'ui-dropdown-item',
                'dropdown-item',
                {
                    disabled: this.disabled,
                    selected: this.selected && (!this.dropdown || !this.dropdown.hasValidEditableValue)
                },
                this.classes
            ];
        },

        itemAttributes() {
            let attrs = { ...this.$attrs };

            if (!this.disabled) attrs.href = attrs.href || '#';
            else delete attrs.href;

            if (this.tooltip) attrs.title = this.label || this.value;

            return attrs;
        },

        showSelectedIcon() {
            return this.dropdown && this.dropdown.select && this.selected && !this.dropdown.hasValidEditableValue;
        },

        showDisabledIcon() {
            return this.dropdown?.select && this.disabled && this.showIconOnDisabled;
        },

        instanceForSlots() {
            return this;
        }
    },

    watch: {
        //
    },

    methods: {
        emitItemClickEvent(event) {
            if (!this.disabled) this.dropdown.handleItemClick(event, this);
            if (this.dropdown && this.dropdown.select && this.dropdown.multiple && this.selected) {
                event.target.blur();
            }
        },

        emitItemSelectEvent(event) {
            if (!this.disabled) {
                event.preventDefault();

                if (this.dropdown && this.dropdown.select) {
                    this.dropdown.handleItemClick(event, this);
                    if (!this.dropdown.multiple) this.$nextTick(this.dropdown.hide);
                }
            }
        },

        handleSelectChange(value) {
            this.selected = !this.dropdown.multiple ? this.value == value : value.includes(this.value);
        }
    },

    mounted() {
        if (this.dropdown) {
            if (this.dropdown.select) {
                this.dropdown.$watch('modelValue', this.handleSelectChange, { immediate: true, deep: true });
            }
        }
    }
};
</script>

<style scoped lang="scss">
.dropdown-item-icon {
    margin-right: 10px;
    width: 16px;
    height: 16px;
}
</style>
