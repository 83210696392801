<template>
    <captions
        v-if="hasAudio"
        ref="audioCaptionsRef"
        :store-module-path="`sequences/${seqId}/audio`"
        type="audio"
        :parent-store-module-path="`sequences/${seqId}`"
        :sequence-id="seqId"
        @update="updateTimeline"
    />
</template>

<script setup lang="ts">
import { computed, defineProps, ref } from 'vue';
import { useStore } from 'vuex';
import gsap from 'gsap';
import Captions from '@/js/video-studio/components/captions/Captions.vue';

const { seqId } = defineProps<{
    seqId: string;
}>();

const emit = defineEmits<{
    (e: 'update'): void;
}>();

const store = useStore();

const hasAudio = computed(() => {
    return (
        store.getters[`sequences/${seqId}/hasAudio`] &&
        store.state.sequences[seqId]?.audio?.track?.src &&
        store.state.sequences[seqId]?.audio?.newCaptions
    );
});

const audioCaptionsRef = ref<typeof Captions | null>(null);

let timeline: GSAPTimeline = gsap.timeline();

const buildTimeline = (): void => {
    timeline.seek(0);
    timeline.clear();
    timeline.kill();
    timeline = gsap.timeline({ paused: true });

    if (audioCaptionsRef.value) {
        timeline.add(audioCaptionsRef.value.getTimeline().paused(false), 0);
    }
};

const updateTimeline = (): void => {
    buildTimeline();
    emit('update');
};

const getTimeline = (): GSAPTimeline => {
    buildTimeline();
    return timeline;
};

defineExpose<{
    getTimeline: () => GSAPTimeline;
}>({
    getTimeline
});
</script>
