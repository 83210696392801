<template>
    <div class="ui-content-row">
        <label>{{ $t('Font size') }}</label>
        <ui-number-input
            id="captions-editor-font-size"
            class="captions-editor-font-size-input"
            v-model="fontSize"
            :default-value="''"
            :min="16"
            :max="160"
            :show-label="false"
        />
    </div>
</template>

<script setup lang="ts">
import { useCaptions } from '@/js/videos/composables/caption/useCaptions.ts';
import { useCaptionsEditor } from '@/js/videos/composables/caption/useCaptionsEditor.ts';
import UiNumberInput from '@/js/components/UiNumberInput.vue';

const { editorState } = useCaptionsEditor();
const { fontSize } = useCaptions(
    editorState.value.storeModulePath,
    editorState.value.type,
    editorState.value.parentStoreModulePath
);
</script>
