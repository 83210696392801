<template>
    <div class="dropdown" ref="dropdownRef">
        <div
            class="dropdown-selected-value-container"
            :data-bs-toggle="!disabled ? 'dropdown' : null"
            aria-expanded="false"
            :data-bs-auto-close="autoClose"
        >
            <div class="dropdown-selected-value">
                <slot name="dropdown-selected-value" />
            </div>
            <fa-icon class="icon dropdown-chevron-icon" icon="fa-solid fa-chevron-right" />
        </div>
        <div class="dropdown-menu dropdown-list">
            <div class="dropdown-list-container">
                <slot />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, provide, ref } from 'vue';
import Dropdown from 'bootstrap/js/dist/dropdown';

const model = defineModel<any>();

const props = defineProps<{
    autoClose?: 'true' | 'inside' | 'outside' | 'false';
    disabled?: boolean;
}>();

provide('model', model);

const dropdownRef = ref<HTMLElement | null>(null);

let dropdown: any = null;

const close = (): void => {
    dropdown?.hide();
};

defineExpose<{
    close: () => void;
}>({
    close
});

onMounted(() => {
    if (dropdownRef.value && !props.disabled) {
        dropdown = new Dropdown(dropdownRef.value.querySelector('[data-bs-toggle="dropdown"]'));
    }
});
</script>
