<template>
    <div>
        <div class="home-view-templates-banner-header">
            <div class="home-view-templates-banner-header-tabs">
                <h2
                    :class="['header-title', { selected: selectedTab === 'templates' }]"
                    @click="selectTab('templates')"
                >
                    {{ $t('Templates') }}
                </h2>
                <h2
                    v-if="themes.length > 0"
                    :class="['header-title', { selected: selectedTab === 'themes' }]"
                    @click="selectTab('themes')"
                >
                    {{ $t('Graphical Theme') }}
                </h2>
            </div>
            <div class="home-view-templates-filters">
                <LanguageSelector
                    v-show="languageOptions.length > 1"
                    v-model="selectedLanguage"
                    class="dropdown-center"
                />
                <BrandSelector :modelValue="localSelectedBrandId" @update:modelValue="updateSelectedBrand" />
                <UiTextInput
                    id="home-templates-search"
                    type="search"
                    :placeholder="$t('Search')"
                    :show-label="false"
                    icon="search-icon"
                    v-model="localTemplateFilterText"
                />
            </div>
        </div>
        <div class="home-view-templates-banner-categories">
            <div class="template-format-filter-list">
                <TemplateFormatItem
                    v-for="format in formats"
                    :key="format"
                    :format="format"
                    :is-selected="selectedTemplateFormat === format"
                    @select="selectFormat"
                />
            </div>
            <template v-if="selectedTab === 'templates'">
                <div class="template-filter-separator" />
                <div class="template-category-filter-list">
                    <UiScrollableHorizontal class="template-category-filter-list">
                        <TemplateCategoryItem
                            v-for="category in templateCategories"
                            :key="category"
                            :category="category"
                            :is-selected="selectedTemplateCategory === category"
                            @select="selectCategory"
                        />
                    </UiScrollableHorizontal>
                </div>
            </template>

            <template v-else-if="selectedTab === 'themes'">
                <div class="template-filter-separator" />
                <div class="template-category-filter-list">
                    <UiScrollableHorizontal class="template-theme-filter-list template-category-filter-list-scrollable">
                        <SequenceTemplateThemeItem
                            v-for="theme in themes"
                            :key="theme"
                            :theme="theme.name"
                            :icon="theme.icon"
                            :is-selected="selectedTheme === Number(theme.id)"
                            @select="selectTheme(Number(theme.id))"
                        />
                    </UiScrollableHorizontal>
                </div>
            </template>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import BrandSelector from './BrandSelector.vue?inline';
// @ts-ignore
import UiScrollableHorizontal from '@/js/components/UiScrollableHorizontal.vue';
import TemplateCategoryItem from './TemplateCategoryItem.vue?inline';
import SequenceTemplateThemeItem from './SequenceTemplateThemeItem.vue?inline';
import TemplateFormatItem from './TemplateFormatItem.vue?inline';
// @ts-ignore
import UiDropdown from '@/js/components/UiDropdown.vue';
// @ts-ignore
import UiTextInput from '@/js/components/UiTextInput.vue';
// @ts-ignore
import LocalStorageManager from '@/js/infrastructure/local_storage/LocalStorageManager';
// @ts-ignore
import i18n from '@/libs/i18n.js';
import LanguageSelector from './LanguageSelector.vue?inline';

interface Theme {
    id: string | number;
    name: string;
    icon: string;
}

const props = defineProps({
    templateCategories: {
        type: Array,
        required: true
    },
    formats: {
        type: Array,
        required: true
    },
    selectedBrandId: {
        type: [String, null],
        default: null
    },
    templateFilterText: {
        type: String,
        default: ''
    },
    selectedTemplateCategory: {
        type: String,
        default: 'all'
    },
    selectedTemplateFormat: {
        type: String,
        default: 'landscape'
    },
    selectedLanguage: {
        type: String,
        default: 'fr'
    },
    themes: {
        type: Array as () => Theme[],
        default: () => []
    },
    selectedTheme: {
        type: Number,
        default: 0
    }
});

const emit = defineEmits([
    'update:selectedBrandId',
    'update:templateFilterText',
    'update:selectedTemplateCategory',
    'update:selectedTemplateFormat',
    'update:selectedLanguage',
    'update:selectedTheme',
    'update:selectedTab'
]);

const store = useStore();

const LANGUAGE_STORAGE_KEY = 'selectedLanguage';

const selectedTab = ref('templates');

// Ensure a default theme is selected when themes are available
watch(
    () => props.themes,
    (newThemes) => {
        if (newThemes.length > 0 && props.selectedTheme === 0) {
            emit('update:selectedTheme', Number(newThemes[0].id));
        }
    },
    { immediate: true }
);

const selectTab = (tab: string) => {
    selectedTab.value = tab;
    emit('update:selectedTab', tab);

    // If switching to themes tab and no theme is selected, select the first one
    if (tab === 'themes' && props.themes.length > 0 && props.selectedTheme === 0) {
        emit('update:selectedTheme', Number(props.themes[0].id));
    }
};

const selectTheme = (themeId: number) => {
    emit('update:selectedTheme', themeId);
};

const localSelectedBrandId = computed({
    get: () => props.selectedBrandId,
    set: (value) => emit('update:selectedBrandId', value)
});

const updateSelectedBrand = (newValue: string) => {
    localSelectedBrandId.value = newValue;
};

const localTemplateFilterText = computed({
    get: () => props.templateFilterText,
    set: (value) => emit('update:templateFilterText', value)
});

const localSelectedTemplateCategory = computed({
    get: () => props.selectedTemplateCategory,
    set: (value) => emit('update:selectedTemplateCategory', value)
});

const localSelectedTemplateFormat = computed({
    get: () => props.selectedTemplateFormat,
    set: (value) => emit('update:selectedTemplateFormat', value)
});

const selectCategory = (category: string) => {
    localSelectedTemplateCategory.value = category;
};

const selectFormat = (format: string) => {
    localSelectedTemplateFormat.value = format;
};

const enabledTemplateLanguages = computed(() => store.state.branding.enabledTemplateLanguages);

const initializeLanguage = () => {
    if (!store.state.branding.enabledTemplateLanguages?.length) {
        return;
    }

    const savedLanguage = LocalStorageManager.load(LANGUAGE_STORAGE_KEY);

    // If a language is saved and available, use it
    if (savedLanguage && store.state.branding.enabledTemplateLanguages.includes(savedLanguage)) {
        emit('update:selectedLanguage', savedLanguage);
        return;
    }

    // Prioritize French if available
    if (store.state.branding.enabledTemplateLanguages.includes('fr')) {
        emit('update:selectedLanguage', 'fr');
        return;
    }

    // Otherwise, use the first available language
    const firstEnabledLanguage = store.state.branding.enabledTemplateLanguages[0];
    if (firstEnabledLanguage) {
        emit('update:selectedLanguage', firstEnabledLanguage);
    }
};

const resetLanguageAndInitialize = () => {
    initializeLanguage();
};

watch(
    [enabledTemplateLanguages, localSelectedBrandId],
    ([newLanguages, newBrandId]) => {
        if (newLanguages?.length || newBrandId) {
            resetLanguageAndInitialize();
        }
    },
    { immediate: true }
);

const languageOptions = computed(() => {
    const enabledLanguages = store.state.branding.enabledTemplateLanguages;
    return enabledLanguages.map((lang: string) => ({
        value: lang,
        label: i18n.global.t(`languages.${lang}`) || lang
    }));
});

const selectedLanguage = computed({
    get: () => props.selectedLanguage,
    set: (value) => {
        emit('update:selectedLanguage', value);
        LocalStorageManager.save(LANGUAGE_STORAGE_KEY, value);
    }
});
</script>

<style scoped>
.home-view-templates-filters {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.dropdown-center {
    display: flex;
    align-items: center;
}
</style>
