<template>
    <div class="ui-content-row">
        <label>{{ $t('captions.editor.font.label') }}</label>
        <dropdown v-model="font" class="captions-editor-fonts-dropdown">
            <template #dropdown-selected-value>
                <div :style="getMessageFontStyles(font)" />
            </template>
            <ui-scrollable>
                <dropdown-item
                    v-for="parsedFont in parsedFontLibrary"
                    :key="parsedFont.name"
                    :value="parsedFont.name"
                    :style="getMessageFontStyles(parsedFont.name)"
                />
            </ui-scrollable>
        </dropdown>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useCaptions } from '@/js/videos/composables/caption/useCaptions.ts';
import { useCaptionsEditor } from '@/js/videos/composables/caption/useCaptionsEditor.ts';
import DropdownItem from '@/js/components/dropdown/DropdownItem.vue';
import Dropdown from '@/js/components/dropdown/Dropdown.vue';
import UiScrollable from '@/js/components/UiScrollable.vue';

const store = useStore();
const { editorState } = useCaptionsEditor();
const { font } = useCaptions(
    editorState.value.storeModulePath,
    editorState.value.type,
    editorState.value.parentStoreModulePath
);

const fontLibrary = computed(() => store.state.branding.libraries.fonts);
const shortLanguage = computed(() => store.state.ui.shortLanguage);

const parsedFontLibrary = computed(() => {
    return fontLibrary.value
        .slice()
        .sort((fontA: { name: string }, fontB: { name: string }) =>
            fontA.name.localeCompare(fontB.name, shortLanguage.value)
        );
});

const getMessageFontStyles = (font: string): object => {
    return {
        '--font-thumbnail': `url(/assets/thumbnails/fonts/${font}.gif)`
    };
};
</script>
