import { ref } from 'vue';

export function useWaveformDrawing() {
    const amplitudeScale = ref(0.7);

    const normalizeAudioData = (audioData, isMP3 = false) => {
        if (!audioData || audioData.length === 0) {
            return [];
        }

        const sortedValues = audioData.map(Math.abs).sort((a, b) => b - a);

        // Ajuster le pourcentage de valeurs à ignorer en fonction du type de média
        // Pour les MP3, on ignore moins de valeurs extrêmes pour conserver plus de détails
        const pourcentRemove = Math.floor(sortedValues.length * (isMP3 ? 0.005 : 0.01));

        const maxAmplitude = sortedValues[pourcentRemove] || sortedValues[sortedValues.length - 1];

        // Facteur d'échelle minimum pour éviter une amplification excessive du bruit de fond
        const minScaleFactor = isMP3 ? 0.2 : 0.1;

        // Calculer le facteur d'échelle avec une limite minimale
        const scaleFactor = maxAmplitude > 0 ? Math.max(minScaleFactor, 1 / maxAmplitude) : 1;

        // Normaliser les données audio
        const normalizedData = audioData.map((value) => value * scaleFactor);

        // Appliquer un traitement spécial pour les MP3 ou les fichiers avec des trous
        if (isMP3) {
            // Étape 1: Appliquer un seuil minimum pour éviter les valeurs trop faibles
            const minThreshold = 0.001;
            let filteredData = normalizedData.map((value) => (Math.abs(value) < minThreshold ? 0 : value));

            // Compter les valeurs filtrées
            const filteredCount = filteredData.filter((v) => v === 0).length;

            // Étape 2: Combler les trous (séquences de zéros) par interpolation
            const interpolatedData = [...filteredData];
            let filledGaps = 0;

            // Détecter et combler les petits trous (jusqu'à 5 échantillons consécutifs)
            for (let i = 1; i < interpolatedData.length - 1; i++) {
                // Si on trouve une valeur nulle
                if (Math.abs(interpolatedData[i]) < minThreshold) {
                    // Chercher la fin du trou
                    let holeEnd = i;
                    while (holeEnd < interpolatedData.length && Math.abs(interpolatedData[holeEnd]) < minThreshold) {
                        holeEnd++;
                    }

                    // Si le trou n'est pas trop grand et qu'on a des valeurs non nulles avant et après
                    if (holeEnd - i <= 5 && i > 0 && holeEnd < interpolatedData.length) {
                        const startValue = interpolatedData[i - 1];
                        const endValue = interpolatedData[holeEnd];

                        // Interpolation linéaire
                        for (let j = i; j < holeEnd; j++) {
                            const ratio = (j - i + 1) / (holeEnd - i + 1);
                            interpolatedData[j] = startValue + ratio * (endValue - startValue);
                            filledGaps++;
                        }
                    }

                    // Avancer à la fin du trou
                    i = holeEnd;
                }
            }

            // Étape 3: Appliquer un lissage pour adoucir les transitions
            const smoothedData = [...interpolatedData];
            const smoothingWindow = 3; // Taille de la fenêtre de lissage

            for (let i = smoothingWindow; i < smoothedData.length - smoothingWindow; i++) {
                // Appliquer un lissage uniquement si la valeur actuelle est faible
                if (Math.abs(smoothedData[i]) < 0.05) {
                    let sum = 0;
                    for (let j = i - smoothingWindow; j <= i + smoothingWindow; j++) {
                        sum += interpolatedData[j];
                    }
                    smoothedData[i] = sum / (2 * smoothingWindow + 1);
                }
            }

            return smoothedData;
        }

        return normalizedData;
    };

    const drawBarWaveform = (ctx, width, height, color, audioData, isMP3 = false) => {
        const normalizedData = normalizeAudioData(audioData, isMP3);
        const barWidth = 1;
        const barGap = 1;
        const barCount = Math.floor(width / (barWidth + barGap));
        const dataStep = normalizedData.length / barCount;

        ctx.fillStyle = color;

        // Compter les barres dessinées
        let drawnBars = 0;
        let emptyBars = 0;

        for (let i = 0; i < barCount; i++) {
            const dataIndex = Math.floor(i * dataStep);
            const value = normalizedData[dataIndex] || 0;
            const barHeight = Math.max(0, value * height * amplitudeScale.value);
            const x = i * (barWidth + barGap);
            const y = height - barHeight;

            ctx.fillRect(x, y, barWidth, barHeight);

            if (barHeight > 0) {
                drawnBars++;
            } else {
                emptyBars++;
            }
        }
    };

    const drawWaveWaveform = (ctx, width, height, color, audioData, isMP3 = false) => {
        const normalizedData = normalizeAudioData(audioData, isMP3);
        ctx.clearRect(0, 0, width, height);

        const gradient = ctx.createLinearGradient(0, 0, 0, height);
        gradient.addColorStop(0, color);

        let colorWithOpacity;
        if (color.startsWith('rgba')) {
            colorWithOpacity = color.replace(/rgba\((\d+),\s*(\d+),\s*(\d+),\s*[\d.]+\)/, 'rgba($1, $2, $3, 0.7)');
        } else if (color.startsWith('rgb')) {
            colorWithOpacity = color.replace(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/, 'rgba($1, $2, $3, 0.7)');
        } else {
            colorWithOpacity = color;
        }

        gradient.addColorStop(1, colorWithOpacity);
        ctx.fillStyle = gradient;

        ctx.beginPath();

        // Augmenter la densité des points pour les MP3
        const densityFactor = isMP3 ? 3 : 2;
        const dataStep = normalizedData.length / (width * densityFactor);

        let previous = {
            x: 0,
            y: height - Math.max(0, normalizedData[0] * height * amplitudeScale.value)
        };
        ctx.moveTo(previous.x, previous.y);

        // Compter les points dessinés et les points avec valeur zéro
        let pointsDrawn = 1;
        let zeroPoints = normalizedData[0] === 0 ? 1 : 0;

        for (let i = 1; i < width * densityFactor; i++) {
            const dataIndex = Math.floor(i * dataStep);
            if (dataIndex >= normalizedData.length) break;

            const value = normalizedData[dataIndex] || 0;
            const x = i / densityFactor;
            const y = height - Math.max(0, value * height * amplitudeScale.value);
            const cx = (previous.x + x) / 2;
            const cy = (previous.y + y) / 2;

            ctx.quadraticCurveTo(previous.x, previous.y, cx, cy);

            pointsDrawn++;
            if (value === 0) zeroPoints++;

            previous = { x, y };
        }

        ctx.lineTo(width, height);
        ctx.lineTo(0, height);
        ctx.closePath();
        ctx.fill();

        ctx.strokeStyle = color;
        ctx.lineWidth = 0.5;
        ctx.stroke();
    };

    const drawWaveWaveformMiddle = (ctx, width, height, color, audioData, isMP3 = false) => {
        const normalizedData = normalizeAudioData(audioData, isMP3);
        amplitudeScale.value = 0.9;
        const centerY = height / 2;

        // Créer un dégradé plus sophistiqué pour un rendu professionnel
        const gradient = ctx.createLinearGradient(0, 0, 0, height);
        gradient.addColorStop(0, '#ff007f');
        gradient.addColorStop(0.3, '#ff006f');
        gradient.addColorStop(0.5, '#ff005f');
        gradient.addColorStop(0.7, '#ff004f');
        gradient.addColorStop(1, '#ff0049');

        // Effacer le canvas avant de dessiner
        ctx.clearRect(0, 0, width, height);

        // Algorithme amélioré pour un rendu haute définition
        // Inspiré des techniques utilisées dans les DAW professionnels
        const dataLength = normalizedData.length;

        // Calculer le nombre optimal de points à dessiner
        // Pour un rendu HD, on vise au moins 2-3 points par pixel
        const pointsPerPixel = isMP3 ? 3 : 2;
        const targetPoints = width * pointsPerPixel;

        // Adapter la stratégie d'échantillonnage en fonction de la quantité de données
        let renderStrategy;
        if (dataLength <= targetPoints) {
            // Si nous avons moins de points que nécessaire, utiliser tous les points
            renderStrategy = 'all-points';
        } else if (dataLength <= targetPoints * 5) {
            // Si nous avons un nombre raisonnable de points, utiliser le sous-échantillonnage
            renderStrategy = 'subsample';
        } else {
            // Si nous avons beaucoup de points, utiliser la technique RMS + Peak
            renderStrategy = 'rms-peak';
        }

        // Préparer les tableaux pour stocker les points
        const upperPoints = [];
        const lowerPoints = [];
        let significantPoints = 0;

        if (renderStrategy === 'all-points' || renderStrategy === 'subsample') {
            // Calculer le pas d'échantillonnage
            const sampleStep = dataLength / targetPoints;

            // Générer les points
            for (let i = 0; i < targetPoints; i++) {
                const dataIndex = Math.min(dataLength - 1, Math.floor(i * sampleStep));
                const value = normalizedData[dataIndex] || 0;

                // Calculer la position x
                const x = (i / targetPoints) * width;

                // Calculer les décalages y
                const yOffset = (value * height * amplitudeScale.value) / 2;

                // Stocker les points
                upperPoints.push({ x, y: centerY - yOffset });
                lowerPoints.push({ x, y: centerY + yOffset });

                if (Math.abs(yOffset) > 1) significantPoints++;
            }
        } else {
            // Technique RMS + Peak pour un rendu professionnel
            // Cette approche combine les valeurs RMS (moyenne quadratique) et les valeurs de pic
            // pour représenter à la fois l'énergie moyenne et les transitoires

            // Diviser les données en segments
            const segmentSize = Math.ceil(dataLength / targetPoints);

            for (let i = 0; i < targetPoints; i++) {
                const startIdx = Math.min(dataLength - 1, i * segmentSize);
                const endIdx = Math.min(dataLength, (i + 1) * segmentSize);

                if (startIdx >= endIdx) continue;

                // Calculer la valeur RMS et la valeur de pic pour ce segment
                let sumSquares = 0;
                let peakValue = 0;
                let validSamples = 0;

                for (let j = startIdx; j < endIdx; j++) {
                    const value = Math.abs(normalizedData[j] || 0);
                    sumSquares += value * value;
                    peakValue = Math.max(peakValue, value);
                    validSamples++;
                }

                // Calculer la valeur RMS
                const rmsValue = Math.sqrt(sumSquares / validSamples);

                // Combiner RMS et valeur de pic (70% RMS, 30% pic)
                // Cette combinaison donne un bon équilibre entre l'énergie moyenne et les transitoires
                const combinedValue = rmsValue * 0.7 + peakValue * 0.3;

                // Calculer la position x
                const x = (i / targetPoints) * width;

                // Calculer les décalages y
                const yOffset = (combinedValue * height * amplitudeScale.value) / 2;

                // Stocker les points
                upperPoints.push({ x, y: centerY - yOffset });
                lowerPoints.push({ x, y: centerY + yOffset });

                if (Math.abs(yOffset) > 1) significantPoints++;
            }
        }

        // Dessiner la forme d'onde avec un rendu amélioré

        // 1. Dessiner le remplissage principal avec dégradé
        ctx.fillStyle = gradient;
        ctx.beginPath();

        // Dessiner la partie supérieure
        if (upperPoints.length > 0) {
            ctx.moveTo(0, centerY);
            for (let i = 0; i < upperPoints.length; i++) {
                ctx.lineTo(upperPoints[i].x, upperPoints[i].y);
            }
        }

        // Dessiner la partie inférieure (en ordre inverse)
        if (lowerPoints.length > 0) {
            ctx.lineTo(width, centerY);
            for (let i = lowerPoints.length - 1; i >= 0; i--) {
                ctx.lineTo(lowerPoints[i].x, lowerPoints[i].y);
            }
        }

        // Fermer le chemin
        ctx.lineTo(0, centerY);
        ctx.closePath();
        ctx.fill();

        // 2. Ajouter un contour fin pour plus de définition
        ctx.strokeStyle = color;
        ctx.lineWidth = 0.5;
        ctx.beginPath();

        // Dessiner le contour supérieur
        if (upperPoints.length > 0) {
            ctx.moveTo(upperPoints[0].x, upperPoints[0].y);
            for (let i = 1; i < upperPoints.length; i++) {
                ctx.lineTo(upperPoints[i].x, upperPoints[i].y);
            }
        }

        // Dessiner le contour inférieur
        if (lowerPoints.length > 0) {
            ctx.moveTo(lowerPoints[0].x, lowerPoints[0].y);
            for (let i = 1; i < lowerPoints.length; i++) {
                ctx.lineTo(lowerPoints[i].x, lowerPoints[i].y);
            }
        }

        ctx.stroke();

        // 3. Ajouter des lignes de grille subtiles pour améliorer la lisibilité
        ctx.strokeStyle = 'rgba(255, 255, 255, 0.1)';
        ctx.lineWidth = 0.5;

        // Ligne centrale
        ctx.beginPath();
        ctx.moveTo(0, centerY);
        ctx.lineTo(width, centerY);
        ctx.stroke();

        // Lignes horizontales à 25% et 75% de la hauteur
        ctx.beginPath();
        ctx.moveTo(0, height * 0.25);
        ctx.lineTo(width, height * 0.25);
        ctx.moveTo(0, height * 0.75);
        ctx.lineTo(width, height * 0.75);
        ctx.stroke();
    };

    const drawWaveform = (ctx, width, height, color, audioData, type, isMP3 = false) => {
        if (!audioData || audioData.length === 0) {
            console.warn('[useWaveformDrawing] Pas de données audio à dessiner');
            return;
        }

        // Analyser les données avant le dessin
        let zeroCount = 0;
        let totalCount = audioData.length;
        for (let i = 0; i < totalCount; i++) {
            if (Math.abs(audioData[i]) < 0.001) zeroCount++;
        }

        ctx.clearRect(0, 0, width, height);
        if (type === 'bar') {
            drawBarWaveform(ctx, width, height, color, audioData, isMP3);
        }

        if (type === 'wave') {
            drawWaveWaveform(ctx, width, height, color, audioData, isMP3);
        }

        if (type === 'wave-middle') {
            drawWaveWaveformMiddle(ctx, width, height, color, audioData, isMP3);
        }
    };

    return {
        drawWaveform,
        amplitudeScale
    };
}
