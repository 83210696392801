<!--
  -- Component name: ColorWater
  -- Type: Transition
  -- Uses: color.start
  -- Tags: color
  -->
<template>
    <svg class="studio-sequence-transition studio-container" :class="classes" :id="this.containerID">
        <circle class="color-water circle-1" cx="10%" cy="20%" r="0" :fill="state.color.start" />
        <circle class="color-water circle-2" cx="25%" cy="15%" r="0" :fill="state.color.start" />
        <circle class="color-water circle-3" cx="60%" cy="2%" r="0" :fill="state.color.start" />
        <circle class="color-water circle-4" cx="85%" cy="93%" r="0" :fill="state.color.start" />
        <circle class="color-water circle-5" cx="73%" cy="85%" r="0" :fill="state.color.start" />
        <circle class="color-water circle-6" cx="85%" cy="50%" r="0" :fill="state.color.start" />
        <circle class="color-water circle-7" cx="46%" cy="35%" r="0" :fill="state.color.start" />
        <circle class="color-water circle-8" cx="73%" cy="65%" r="0" :fill="state.color.start" />
        <circle class="color-water circle-9" cx="86%" cy="23%" r="0" :fill="state.color.start" />
        <circle class="color-water circle-10" cx="60%" cy="43%" r="0" :fill="state.color.start" />
        <circle class="color-water circle-11" cx="25%" cy="47%" r="0" :fill="state.color.start" />
        <circle class="color-water circle-12" cx="65%" cy="75%" r="0" :fill="state.color.start" />
        <circle class="color-water circle-13" cx="12%" cy="70%" r="0" :fill="state.color.start" />
        <circle class="color-water circle-14" cx="33%" cy="66%" r="0" :fill="state.color.start" />
        <circle class="color-water circle-15" cx="40%" cy="88%" r="0" :fill="state.color.start" />
        <circle class="color-water circle-16" cx="22%" cy="86%" r="0" :fill="state.color.start" />
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            //needsRefresh: true, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true, // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            inElementsAutoAppear: false // The in elements are automatically added to the timeline and appear from the start
        };
    },

    computed: {
        svgRadius() {
            return Math.ceil(Math.sqrt(Math.pow(this.format.width, 2) + Math.pow(this.format.height, 2))) / 2;
        }
    },

    watch: {
        svgMaskRadius: {
            handler() {
                this.updateTimeline();
            }
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline(),
                radius = this.svgRadius / 2 + 25;
            t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 }, 0.9);
            t.fromTo(
                '#' + this.containerID + ' .color-water',
                { attr: { r: 0 } },
                { duration: 0.7, stagger: 0.02, attr: { r: radius }, ease: 'power3.out' },
                0
            );
            t.set([this.backgroundInElement, this.sequenceInElement], { autoAlpha: 1 }, 0.9);
            t.to(
                '#' + this.containerID + ' .color-water',
                { duration: 0.6, stagger: 0.025, attr: { r: 0 }, ease: 'power3.in' },
                0.4
            );

            this._timeline = t;
            return t;
        }
    }
};
</script>
