<template>
    <div class="segment-container">
        <template v-if="isSmallSegment">
            <div
                @click.stop="selectSegment"
                class="small-segment-handle"
                :style="{
                    left: `${getCenteredHandlePos()}px`,
                    backgroundColor: isDragging && dragSegmentIndex === index ? 'transparent' : undefined
                }"
                :class="{ selected: isSelected, 'no-cursor': isDragging && dragSegmentIndex === index }"
            >
                <div class="handle-indicator vertical-bar" :class="{ hidden: isDraggingStart }"></div>
            </div>
            <div
                class="small-segment-highlight"
                :class="{ active: isSelected }"
                :style="{
                    left: getCenteredHandlePos() + 'px',
                    width: SMALL_SEGMENT_WIDTH + 'px'
                }"
                @click.stop="selectSegment"
            ></div>
        </template>
        <template v-else>
            <div
                class="handle start-handle"
                @mousedown.stop="startDrag('start', index)"
                :style="{
                    left: `${getHandlePos(segment.start)}px`,
                    borderTopLeftRadius: isDraggingStart ? '0px' : '3px',
                    borderBottomLeftRadius: isDraggingStart ? '0px' : '3px',
                    backgroundColor: isDraggingStart ? 'transparent' : undefined
                }"
                ref="startHandle"
                :class="{ selected: isSelected, 'no-cursor': isDragging && dragSegmentIndex === index }"
            >
                <div class="handle-indicator vertical-bar" :class="{ hidden: isDraggingStart }"></div>
            </div>
            <div
                class="handle end-handle"
                @mousedown.stop="startDrag('end', index)"
                :style="{
                    left: `${getHandlePos(segment.end, true)}px`,
                    borderTopRightRadius: isDraggingEnd ? '0px' : '3px',
                    borderBottomRightRadius: isDraggingEnd ? '0px' : '3px',
                    backgroundColor: isDraggingEnd ? 'transparent' : undefined
                }"
                ref="endHandle"
                :class="{ selected: isSelected, 'no-cursor': isDragging && dragSegmentIndex === index }"
            >
                <div class="handle-indicator vertical-bar" :class="{ hidden: isDraggingEnd }"></div>
            </div>
            <div
                class="segment-highlight"
                :class="{ active: isSelected }"
                :style="{
                    left: getHandlePos(segment.start) + 'px',
                    width: getHandlePos(segment.end) - getHandlePos(segment.start) + 'px'
                }"
                @click.stop="selectSegment"
            ></div>
        </template>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
// Import the Segment interface from types file
import { Segment } from '@/js/videos/types/segments';

// Remove local Segment interface and use the imported one
interface Props {
    segment: Segment;
    index: number;
    getHandlePos: (pos: number, isEnd?: boolean) => number;
    isDragging: boolean;
    dragSegmentIndex: number;
    selectedSegmentIndex: number;
    dragHandle?: string;
}

// Constants
const SMALL_SEGMENT_THRESHOLD = 28; // pixels
const SMALL_SEGMENT_WIDTH = 14; // pixels

// Props
const props = defineProps<Props>();

// Emits
const emit = defineEmits<{
    (e: 'startDrag', handle: 'start' | 'end' | 'both', index: number): void;
    (e: 'selectSegment', index: number, event: MouseEvent): void;
}>();

// Computed
const isSelected = computed(() => props.selectedSegmentIndex === props.index);

const isSmallSegment = computed(() => {
    const startPos = props.getHandlePos(props.segment.start);
    const endPos = props.getHandlePos(props.segment.end);
    return endPos - startPos < SMALL_SEGMENT_THRESHOLD;
});

const isDraggingStart = computed(
    () => props.isDragging && props.dragSegmentIndex === props.index && props.dragHandle === 'start'
);

const isDraggingEnd = computed(
    () => props.isDragging && props.dragSegmentIndex === props.index && props.dragHandle === 'end'
);

// Methods
function getCenteredHandlePos(): number {
    const startPos = props.getHandlePos(props.segment.start);
    const endPos = props.getHandlePos(props.segment.end);
    return startPos + (endPos - startPos) / 2 - SMALL_SEGMENT_WIDTH / 2;
}

function selectSegment(event: MouseEvent): void {
    emit('selectSegment', props.index, event);
}

function startDrag(handle: 'start' | 'end' | 'both', index: number): void {
    emit('startDrag', handle, index);
}
</script>

<style scoped>
.segment-container {
    position: relative;
}

.small-segment-handle {
    background-color: var(--edit-mode-color);
}

.handle,
.small-segment-handle {
    position: absolute;
    top: -2px;
    height: 62px;
    z-index: 10;
    transition:
        background-color 0.2s ease,
        border-radius 0.2s ease,
        opacity 0.2s ease;
    cursor: ew-resize;
}

.handle {
    width: 14px;
    opacity: 0.5;
    z-index: 50;
}

.small-segment-handle {
    position: absolute;
    top: -2px;
    width: 14px;
    height: 62px;
    opacity: 0.5;
    border-radius: 3px;
    z-index: 50;
    background-color: var(--edit-mode-color);
    cursor: pointer;
}

.handle.selected,
.small-segment-handle.selected {
    opacity: 1;
}

.handle.no-cursor,
.small-segment-handle.no-cursor {
    cursor: none;
}

.handle-indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: opacity 0.2s ease;
}

.vertical-bar {
    width: 2px;
    height: 20px;
    background-color: white;
}

.segment-highlight,
.small-segment-highlight {
    top: -2px;
    position: absolute;
    height: 62px;
    pointer-events: auto;
    cursor: pointer;
    z-index: 5;
    transition: background-color 0.2s ease;
    border-radius: 3px;
    border: 2px solid var(--edit-mode-color);
    opacity: 0.5;
}

.segment-highlight.active,
.small-segment-highlight.active {
    opacity: 1;
}

.small-segment-highlight {
    z-index: 9;
    cursor: pointer;
}

.start-handle.selected {
    border-left: none;
}

.end-handle.selected {
    border-right: none;
}

.start-handle[style*='border-top-left-radius: 0px'] {
    border-left: 2px solid white;
    opacity: 1;
}

.end-handle[style*='border-top-right-radius: 0px'] {
    border-right: 2px solid white;
    opacity: 1;
}

.handle-indicator.hidden {
    opacity: 0;
}
</style>
