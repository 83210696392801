<template>
    <captions
        v-if="hasBackgroundCaptions"
        ref="backgroundCaptionRef"
        :store-module-path="`sequences/${seqId}/background/video`"
        type="background"
        :parent-store-module-path="`sequences/${seqId}/video`"
        :sequence-id="seqId"
        @update="updateTimeline"
    />
</template>

<script setup lang="ts">
import { computed, defineProps, ref } from 'vue';
import { useStore } from 'vuex';
import gsap from 'gsap';
import Captions from '@/js/video-studio/components/captions/Captions.vue';
import { useSequence } from '@/js/videos/composables/useSequence';

const { seqId } = defineProps<{
    seqId: string;
}>();

const emit = defineEmits<{
    (e: 'update'): void;
}>();

const store = useStore();
const { hasVideoBackground, hasRecordingBackground } = useSequence(seqId);

const hasBackgroundCaptions = computed(() => {
    return (
        (hasVideoBackground.value || hasRecordingBackground.value) &&
        store.state.sequences[seqId]?.background?.video?.src
    );
});

const backgroundCaptionRef = ref<typeof Captions | null>(null);

let timeline: GSAPTimeline = gsap.timeline();

const buildTimeline = (): void => {
    timeline.seek(0);
    timeline.clear();
    timeline.kill();
    timeline = gsap.timeline({ paused: true });

    if (backgroundCaptionRef.value) {
        timeline.add(backgroundCaptionRef.value.getTimeline().paused(false), 0);
    }
};

const updateTimeline = (): void => {
    buildTimeline();
    emit('update');
};

const getTimeline = (): GSAPTimeline => {
    buildTimeline();
    return timeline;
};

defineExpose<{
    getTimeline: () => GSAPTimeline;
}>({
    getTimeline
});
</script>
