<template>
    <div class="ui-scrollable" :class="scrollableClasses" role="none" @wheel="onWheel">
        <div
            ref="containerRef"
            class="ui-scrollable-container"
            @scroll="onContainerScroll"
            @wheel.stop=""
            role="none"
            tabindex="-1"
        >
            <!-- masse totale -->
            <div
                :style="{
                    height: items.length * itemHeight + 'px',
                    position: 'relative',
                    width: '100%'
                }"
            >
                <div
                    v-for="(item, localIndex) in visibleItems"
                    :key="startIndex + localIndex"
                    :style="{
                        position: 'absolute',
                        top: (startIndex + localIndex) * itemHeight + 'px',
                        left: 0,
                        right: 0,
                        height: itemHeight + 'px'
                    }"
                    class="virtual-scroll-item"
                >
                    <slot :item="item" :index="startIndex + localIndex" />
                </div>
            </div>
        </div>

        <!-- Scrollbar personnalisée -->
        <div
            v-show="!disabled && !!scrollMax"
            class="ui-scrollable-handle"
            ref="scrollHandle"
            :style="handleStyles"
            role="none"
            @mousedown.left="startHandleMove"
            @wheel="onWheel"
        ></div>
    </div>
</template>

<script setup lang="ts">
import { computed, defineExpose, onMounted, onBeforeUnmount, ref, withDefaults, watch, nextTick } from 'vue';
import _throttle from 'lodash/throttle';

interface Props {
    /** Liste complète d'éléments à afficher */
    items: any[];
    /** Hauteur fixe (en px) d'un élément */
    itemHeight: number;
    disabled?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
    items: () => [],
    itemHeight: 40,
    disabled: false
});

const containerRef = ref<HTMLElement | null>(null);
const scrollHandle = ref<HTMLElement | null>(null);
const scrollTop = ref(0);
const handleMoving = ref(false);
const handleHeight = ref(1);
const handleTop = ref(0);
const scrollMax = ref(0);
const scrollableHeight = ref(0);

const HANDLE_MIN_HEIGHT = 20;

// Classes pour le conteneur principal
const scrollableClasses = computed(() => ({
    'ui-scrollable-moving': handleMoving.value,
    disabled: props.disabled
}));

// Style de la scrollbar
const handleStyles = computed(() => ({
    height: handleHeight.value * scrollableHeight.value + 'px',
    transform: `translate(0, ${handleTop.value}px)`
}));

// Calcul des éléments visibles
const startIndex = computed(() => Math.floor(scrollTop.value / props.itemHeight));
const itemsPerScreen = ref(0);
const endIndex = computed(() => startIndex.value + itemsPerScreen.value);
const visibleItems = computed(() => props.items.slice(startIndex.value, endIndex.value));

// Gestion du scroll
const onContainerScroll = _throttle(() => {
    if (!containerRef.value) return;
    scrollTop.value = containerRef.value.scrollTop;
    updateHandlePosition();
}, 25);

// Mise à jour de la position de la scrollbar
function updateHandlePosition() {
    if (!containerRef.value) return;
    handleTop.value =
        scrollMax.value > 0
            ? (scrollableHeight.value * (1 - handleHeight.value) * scrollTop.value) / scrollMax.value
            : 0;
}

// Gestion de la molette
function onWheel(event: WheelEvent) {
    if (props.disabled || !scrollMax.value) return;
    event.preventDefault();
    event.stopImmediatePropagation();
    if (containerRef.value) {
        containerRef.value.scrollTop += event.deltaY;
    }
    showHandle();
}

// Gestion du drag de la scrollbar
let referenceHandleTop = 0;
let referenceClientY = 0;

function startHandleMove(event: MouseEvent) {
    event.preventDefault();
    handleMoving.value = true;
    referenceHandleTop = handleTop.value;
    referenceClientY = event.clientY;
    document.addEventListener('mouseup', stopHandleMove);
    document.addEventListener('mousemove', doHandleMove);
}

function doHandleMove(event: MouseEvent) {
    event.preventDefault();
    if (!containerRef.value) return;

    const newHandleTop = Math.max(
        0,
        Math.min(
            referenceHandleTop + event.clientY - referenceClientY,
            scrollableHeight.value * (1 - handleHeight.value)
        )
    );

    handleTop.value = newHandleTop;
    containerRef.value.scrollTop =
        (newHandleTop * scrollMax.value) / (scrollableHeight.value * (1 - handleHeight.value));
}

function stopHandleMove() {
    handleMoving.value = false;
    document.removeEventListener('mouseup', stopHandleMove);
    document.removeEventListener('mousemove', doHandleMove);
}

// Affichage/masquage de la scrollbar
let handleMovingTimeoutId = -1;

function showHandle() {
    clearTimeout(handleMovingTimeoutId);
    handleMoving.value = true;
    handleMovingTimeoutId = setTimeout(hideHandle);
}

function hideHandle() {
    clearTimeout(handleMovingTimeoutId);
    handleMoving.value = false;
}

// Calcul des dimensions
function updateDimensions() {
    if (!containerRef.value) return;

    const containerHeight = containerRef.value.clientHeight;
    scrollableHeight.value = containerHeight;
    itemsPerScreen.value = Math.ceil(containerHeight / props.itemHeight);

    const totalHeight = props.items.length * props.itemHeight;
    scrollMax.value = Math.max(0, totalHeight - containerHeight);

    handleHeight.value = Math.min(
        1,
        Math.max(HANDLE_MIN_HEIGHT / scrollableHeight.value, scrollableHeight.value / totalHeight)
    );
}

// Scroll vers un élément spécifique
function scrollToIndex(index: number, position: 'top' | 'center' | 'bottom' = 'top', smooth = false) {
    if (!containerRef.value) return;

    const clampedIndex = Math.max(0, Math.min(index, props.items.length - 1));
    const itemOffset = clampedIndex * props.itemHeight;
    const containerHeight = containerRef.value.clientHeight;
    const totalHeight = props.items.length * props.itemHeight;

    let targetScrollTop = itemOffset;
    if (position === 'center') {
        targetScrollTop = itemOffset - containerHeight / 2 + props.itemHeight / 2;
    } else if (position === 'bottom') {
        targetScrollTop = itemOffset - containerHeight + props.itemHeight;
    }

    const maxScrollTop = Math.max(0, totalHeight - containerHeight);
    targetScrollTop = Math.max(0, Math.min(targetScrollTop, maxScrollTop));

    containerRef.value.scrollTo({
        top: targetScrollTop,
        behavior: smooth ? 'smooth' : 'auto'
    });
}

// Surveiller les changements dans le nombre d'items
watch(
    () => props.items.length,
    () => {
        // On attend le prochain tick pour que le DOM soit mis à jour
        nextTick(() => {
            updateDimensions();
            updateHandlePosition();
        });
    }
);

// Lifecycle hooks
onMounted(() => {
    updateDimensions();
    window.addEventListener('resize', updateDimensions);
});

onBeforeUnmount(() => {
    window.removeEventListener('resize', updateDimensions);
});

defineExpose({
    scrollToIndex
});
</script>

<style scoped>
.ui-scrollable {
    position: relative;
    overflow: hidden;
    height: 100%;
}

.ui-scrollable-container {
    width: calc(100% + 20px);
    height: 100%;
    padding-right: 20px;
    overflow: hidden scroll;
    scrollbar-width: none;
}

.ui-scrollable-container::-webkit-scrollbar {
    appearance: none;
    width: 0;
}

.virtual-scroll-item {
    box-sizing: border-box;
}
</style>
