<template>
    <div class="studio-captions-container studio-container studio-container-align" :style="settingsStyle">
        <settings-voice-over-captions ref="settingsVoiceOverCaptionsRef" @update="updateTimeline" />
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import gsap from 'gsap';
import { Dimension } from '@/js/video-studio/constants';
import SettingsVoiceOverCaptions from '@/js/video-studio/components/captions/settings-captions/SettingsVoiceOverCaptions.vue';

const emit = defineEmits<{
    (e: 'update'): void;
}>();

const store = useStore();

const settingsVoiceOverCaptionsRef = ref<typeof SettingsVoiceOverCaptions | null>(null);

const formatFontSize = computed(() => store.getters['display/formatFontSize']);

const settingsStyle = computed(() => {
    return {
        fontSize: formatFontSize.value + Dimension.PIXEL_UNIT
    };
});

let timeline: GSAPTimeline = gsap.timeline();

const buildTimeline = (): void => {
    timeline.seek(0);
    timeline.clear();
    timeline.kill();
    timeline = gsap.timeline({ paused: true });

    if (settingsVoiceOverCaptionsRef.value) {
        timeline.add(settingsVoiceOverCaptionsRef.value.getTimeline().paused(false), 0);
    }
};

const updateTimeline = (): void => {
    buildTimeline();
    emit('update');
};

const getTimeline = (): GSAPTimeline => {
    buildTimeline();
    return timeline;
};

defineExpose<{
    getTimeline: () => GSAPTimeline;
}>({
    getTimeline
});
</script>
