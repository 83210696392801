<template>
    <div v-if="media" ref="quickCutContainer" id="quick-cut-container" :style="sliderWrapperStyle">
        <template v-if="media.src">
            <DurationLimit
                v-if="showLimitDuration"
                :sequence-end-time="sequenceProps.sequenceEndTime"
                :is-closing="isClosing"
                :limit-type="limitType"
            />
            <ControlButtons
                :can-split="canSplit"
                :can-zoom-in="canZoomIn"
                :can-zoom-out="canZoomOut"
                @split="splitVideoHandler"
                @zoomIn="zoomIn"
                @zoomOut="zoomOut"
                @close="close"
            />
            <div class="timeline-container" @click.self="handleCanvasClick">
                <PlayButton
                    :current-time="sequenceElapsedTime"
                    :video-duration="mediaDuration"
                    :startTime="startTime"
                    :is-dragging="isDragging || dragSegmentIsDragging"
                />
                <TimelineSlider
                    :canvas-width="canvasWidth"
                    :segments="segments"
                    :current-time-pos="currentTimePos"
                    :get-initial-gap-style="getInitialGapStyle"
                    :get-gap-style="getGapStyle"
                    :get-final-gap-style="getFinalGapStyle"
                    :is-dragging="isDragging"
                    :segment-is-dragging="dragSegmentIsDragging"
                    :current-time-is-dragging="currentTimeDrag"
                    :endTime="sequenceProps.endTime"
                    :rate="sequenceProps.rate"
                    :playback-rate="playbackRate"
                    :is-locked="timeBarLocked"
                    @update:is-locked="updateTimeBarLocked"
                    @start-segment-drag="startSegmentDrag"
                    @start-slider-drag="handleStartSliderDrag"
                    @start-current-time-drag="startCurrentTimeDrag"
                    @elements-mount="handleElementsMount"
                    @click="handleCanvasClick"
                >
                    <template #segments>
                        <SegmentHandler
                            v-for="(segment, index) in segments"
                            :key="index"
                            :segment="segment"
                            :index="index"
                            :get-handle-pos="getHandlePos"
                            :is-dragging="isDragging"
                            :drag-segment-index="dragSegmentIndex"
                            :selected-segment-index="selectedSegmentIndex"
                            :dragHandle="dragHandle"
                            :is-handle-disabled="true"
                            @start-drag="handleStartDrag"
                            @select-segment="selectSegment"
                        />
                    </template>
                    <template #canvas>
                        <div
                            ref="canvas"
                            class="canvas-container"
                            style="position: relative; width: 100%; height: 58px; z-index: 1"
                        ></div>
                    </template>
                    <template #audio-waveform>
                        <div class="audio-waveform-wrapper">
                            <AudioLoading v-if="isLoading" :is-loading="isLoading" :width="canvasWidth" :height="58" />
                            <AudioWaveform
                                v-else
                                :audio-data="audioData"
                                :canvas-width="canvasWidth"
                                :canvas-height="58"
                                :zoom-level="zoomLevel"
                                :waveform-type="waveformType"
                            />
                        </div>
                    </template>
                </TimelineSlider>
                <TimeDisplay :elapsed-time="sequenceElapsedTime" />
            </div>
            <SegmentEditor
                :segments="segments"
                :rate="sequenceProps.rate"
                :selected-segment-index="selectedSegmentIndex"
                :media-duration="mediaDuration"
                :media-max-duration="sequenceProps.endTime"
                @update-segment="handleUpdateSegment"
                @remove-segment="removeSegment"
            />
        </template>
    </div>
</template>

<script>
import { throttle, debounce } from 'lodash';
import {
    computed,
    defineComponent,
    nextTick,
    onMounted,
    reactive,
    ref,
    toRef,
    toRefs,
    watch,
    watchEffect,
    onBeforeUnmount,
    inject
} from 'vue';
import { useDisplay } from '@video-composables/useDisplay';
import { useVideoSegments } from './composables/useVideoSegments';
import { useMediaPlayer } from './composables/useMediaPlayer';
import { useTimelineControls } from './composables/useTimelineControls';
import { useSegmentStyles } from './composables/useSegmentStyles';
import { useDragInteractions } from './composables/useDragInteractions';
import { useZoom } from './composables/useZoom';
import { useEventHandlers } from './composables/useEventHandlers';
import { useCanvasInteraction } from './composables/useCanvasInteraction';
import { useQuickCutLayout } from './composables/useQuickCutLayout';
import { useAudioWaveform } from './composables/useAudioWaveform';
import { useScrolling } from './composables/useScrolling';
import { gsap } from 'gsap';

import { usePreview } from '@video-composables/usePreview';
import { useTimeline } from '@video-composables/useTimeline';
import { STAGE_TIMELINE_UPDATED_EVENT } from '@video-studio/utils/events';

import ControlButtons from './subcomponents/ControlButtons.vue';
import TimelineSlider from './subcomponents/TimelineSlider.vue';
import SegmentHandler from './subcomponents/SegmentHandler.vue';
import PlayButton from './subcomponents/PlayButton.vue';
import TimeDisplay from './subcomponents/TimeDisplay.vue';
import SegmentEditor from './subcomponents/SegmentEditor.vue';
import AudioWaveform from './subcomponents/AudioWaveform.vue';
import AudioLoading from './subcomponents/AudioLoading.vue';
import DurationLimit from './subcomponents/DurationLimit.vue';

// Delete find FRAME_WIDTH in extractFrames
const FRAME_WIDTH = 103;

const FRAME_RATE = 1;
const EPSILON = 0.01;

const TIMELINE_UPDATED_EVENT = 'timeline-updated-event';

export default defineComponent({
    name: 'UiQuickCut',
    components: {
        ControlButtons,
        TimelineSlider,
        SegmentHandler,
        PlayButton,
        TimeDisplay,
        SegmentEditor,
        AudioWaveform,
        AudioLoading,
        DurationLimit
    },
    props: {
        segments: {
            type: Array,
            default: () => []
        },
        media: {
            type: Object,
            default: () => ({ src: '' })
        },
        mediaType: {
            type: String,
            validator: (value) =>
                ['visual', 'audio', 'tts', 'backgroundVideo', 'recording', 'recordingVisual'].includes(value)
        },
        playbackRate: {
            type: Number,
            default: 1
        },
        startTime: {
            type: Number,
            default: 0.001
        },
        sequenceEndTime: {
            type: Number
        },
        sequenceId: {
            type: String,
            required: true
        },
        elementId: {
            type: String
        },
        limitType: {
            type: String,
            default: 'sequence'
        },
        maxSegmentDuration: {
            type: Number,
            default: null
        }
    },
    emits: ['update-segments', 'close', 'force-current-time'],
    setup(props, { emit }) {
        const canvas = ref(null);
        const sliderContainer = ref(null);
        const sliderWrapper = ref(null);
        const canvasWidth = ref(0);
        const canvasHeight = ref(58);
        const segments = ref(props.segments);
        const selectedSegmentIndex = ref(0);
        const isUpdating = ref(false);
        const showLimitDuration = ref(false);
        const isClosing = ref(false);
        const sharedCurrentTimePos = ref(0);
        const timelineUpdateListener = ref(null);
        const sliderOuterContainer = ref(null);

        const timeBarLocked = ref(false);

        const updateTimeBarLocked = (value) => {
            timeBarLocked.value = value;
        };

        const canSplit = computed(() => {
            const currentTime = (currentTimePos.value / canvasWidth.value) * sequenceProps.endTime;

            return segments.value.some((segment) => currentTime >= segment.start && currentTime <= segment.end);
        });

        const selectSegment = (index, event) => {
            console.trace('selectSegment', index, event);
            isUpdating.value = true;
            timeBarLocked.value = false;

            selectedSegmentIndex.value = index;

            const originalHandleCanvasClick = handleCanvasClick;
            handleCanvasClick = (e) => {
                if (isDragging.value || dragSegmentIsDragging.value) {
                    return;
                }

                const result = onCanvasClick(e, sliderContainer.value);

                if (result && result.time !== undefined) {
                    nextTick(() => {
                        simpleSeek(result.time);
                    });
                }
            };

            handleCanvasClick(event);

            handleCanvasClick = originalHandleCanvasClick;

            setTimeout(() => {
                isUpdating.value = false;
            }, 200);
        };

        watch(
            () => props.segments,
            () => {
                segments.value = props.segments;

                if (segments.value.length == 0) {
                    resetSegments();
                }
                recalculateGapStyles();
            }
        );

        watch(
            () => props.sequenceEndTime,
            (newValue) => {
                if (sequenceProps) {
                    sequenceProps.sequenceEndTime = newValue;
                }
            }
        );

        const handleUpdateSegment = (index, updatedSegment) => {
            segments.value[index] = updatedSegment;
            mergeSegments();
            mergeAdjacentSegments(index);
            updateSegmentsInStore(segments.value);
        };

        const updateSelectedSegment = (currentTime) => {
            if (!canSplit.value) {
                // Check if currentTime is in a segment
                const segment = segments.value.find(
                    (segment) => currentTime >= segment.start && currentTime <= segment.end
                );
                if (segment) {
                    selectedSegmentIndex.value = segments.value.indexOf(segment);
                } else {
                    // If we are at the end of the last segment or just after, select the last segment
                    if (
                        segments.value.length > 0 &&
                        currentTime >= segments.value[segments.value.length - 1].end - EPSILON
                    ) {
                        selectedSegmentIndex.value = segments.value.length - 1;
                    } else if (segments.value.length > 0) {
                        selectedSegmentIndex.value = 0;
                    } else {
                        selectedSegmentIndex.value = -1;
                    }
                }

                return;
            }

            let newSelectedIndex = -1;
            let accumulatedTime = 0;

            for (let i = 0; i < segments.value.length; i++) {
                const segment = segments.value[i];
                const segmentDuration = (segment.end - segment.start) / sequenceProps.playbackRate;

                if (currentTime >= accumulatedTime && currentTime < accumulatedTime + segmentDuration) {
                    newSelectedIndex = i;
                    break;
                }

                accumulatedTime += segmentDuration;
            }

            // If we are at the end of the last segment or just after, select the last segment
            if (newSelectedIndex === -1 && segments.value.length > 0 && currentTime >= accumulatedTime - EPSILON) {
                newSelectedIndex = segments.value.length - 1;
            }

            if (newSelectedIndex !== selectedSegmentIndex.value) {
                selectedSegmentIndex.value = newSelectedIndex;
            }
        };

        const debouncedUpdateSelectedSegment = debounce(updateSelectedSegment, 100);
        const throttledUpdateSelectedSegment = throttle(updateSelectedSegment, 100);

        const sequenceProps = reactive({
            media: { src: '' },
            mediaType: props.mediaType,
            startTime: props.startTime,
            endTime: 0,
            playbackRate: 1,
            rate: 100,
            duration: props.segments.reduce((acc, segment) => acc + (segment.end - segment.start), 0),
            sequenceEndTime: props.sequenceEndTime,
            zoomLevel: 1,
            limitType: props.limitType,
            elementAnimationEnd:
                props.limitType === 'element' && props.elementId
                    ? props.sequenceEndTime || props.media?.duration || 0
                    : null,
            maxSegmentDuration: props.maxSegmentDuration
        });

        // Add a debug message to verify the value
        console.log(
            'UiQuickCut initialized with sequenceEndTime:',
            props.sequenceEndTime,
            'isAutoMode:',
            props.sequenceEndTime === null
        );

        const updateSegmentsInStore = (newSegments) => {
            const cleanNewSegments = JSON.parse(JSON.stringify(newSegments)).map((segment) => {
                delete segment.gapStyle;
                return segment;
            });
            emit('update-segments', newSegments);
        };

        const close = () => {
            emit('close');
        };

        const { elapsedTime, sequenceElapsedTime, sequenceTotalTime, labels, playing } = useDisplay();
        const { currentTimelineId } = usePreview();
        const { isEndSequencePause, setEndSequencePause, toggleTimeline } = useTimeline(currentTimelineId, labels);

        const adjustedSequenceElapsedTime = computed(() => {
            const adjusted = sequenceElapsedTime.value - sequenceProps.startTime;
            return Math.max(0, Math.min(adjusted, sequenceProps.endTime + sequenceProps.startTime));
        });

        const adjustedElapsedTime = computed(() => {
            const adjusted = elapsedTime.value - sequenceProps.startTime;
            return Math.max(0, Math.min(adjusted, sequenceProps.endTime + sequenceProps.startTime));
        });

        onMounted(() => {
            initializeTimeline();

            nextTick(() => {
                if (canvas.value && canvasWidth.value > 0 && sequenceProps.endTime > 0) {
                    extractFramesHandler();
                }
            });
        });

        const timeoutIds = ref({ show: null, hide: null });

        const displayLimitDuration = () => {
            console.log('displayLimitDuration called from UiQuickCut.vue');

            if (timeoutIds.value.show) clearTimeout(timeoutIds.value.show);
            if (timeoutIds.value.hide) clearTimeout(timeoutIds.value.hide);

            showLimitDuration.value = true;
            isClosing.value = false;

            timeoutIds.value.show = setTimeout(() => {
                isClosing.value = true;
                timeoutIds.value.hide = setTimeout(() => {
                    showLimitDuration.value = false;
                }, 300);
            }, 2700);

            if (typeof updateAllSegments === 'function') {
                updateAllSegments();
            }
        };

        onBeforeUnmount(() => {
            if (timeoutIds.value.show) clearTimeout(timeoutIds.value.show);
            if (timeoutIds.value.hide) clearTimeout(timeoutIds.value.hide);

            if (timelineUpdateListener.value) {
                window.removeEventListener(TIMELINE_UPDATED_EVENT, timelineUpdateListener.value);
                if (timelineUpdateListener.value.timeoutId) {
                    clearTimeout(timelineUpdateListener.value.timeoutId);
                }
                timelineUpdateListener.value = null;
            }

            // cleanup drag interactions
            if (dragInteractions.value && dragInteractions.value.cleanup) {
                dragInteractions.value.cleanup();
            }
        });

        const {
            isDragging,
            updateSegmentEnd,
            splitVideo,
            removeSegment: originalRemoveSegment,
            mergeSegments,
            mergeAdjacentSegments,
            resetSegments,
            updateSegmentPosition,
            startDrag,
            stopDrag
        } = useVideoSegments(segments, sequenceProps, displayLimitDuration, () => {
            updateSelectedSegment(adjustedSequenceElapsedTime.value);
            updateSegmentsInStore(segments.value);
        });

        // Update props.sequenceEndTime to sequenceProps manually instead of using a watch with immediate: true
        // This prevents the ReferenceError
        if (props.sequenceEndTime !== undefined) {
            sequenceProps.sequenceEndTime = props.sequenceEndTime;
        }

        const removeSegment = (index) => {
            originalRemoveSegment(index);
            updateSegmentsInStore(segments.value);
        };

        const { audioData, loadAudioData, isLoading } = useAudioWaveform(canvasWidth);

        const { handleEdgeScroll, stopEdgeScroll } = useScrolling(
            sliderOuterContainer,
            sharedCurrentTimePos,
            ref(false)
        );

        const dragInteractions = ref(null);

        dragInteractions.value = useDragInteractions({
            segments,
            isDragging,
            updateSegmentPosition,
            mergeAdjacentSegments,
            mergeSegments,
            updateCurrentTimeAndPos,
            startDrag,
            stopDrag,
            sliderWrapper,
            sliderContainer,
            sequenceProps,
            updateSegmentsInStore,
            emit,
            playing,
            toggleTimeline,
            onHandleStopDrag: (newTime, direction) => {
                isUpdating.value = true;
                timeBarLocked.value = true;

                simpleSeek(newTime);
                const currentSegment = segments.value[selectedSegmentIndex.value];
                if (currentSegment) {
                    const absoluteTime = direction === 'start' ? currentSegment.start : currentSegment.end;
                    debouncedUpdateSelectedSegment(absoluteTime);

                    const handleTimelineUpdated = () => {
                        if (timelineUpdateListener.value?.timeoutId) {
                            clearTimeout(timelineUpdateListener.value.timeoutId);
                        }

                        window.removeEventListener(TIMELINE_UPDATED_EVENT, handleTimelineUpdated);
                        timelineUpdateListener.value = null;

                        nextTick(() => {
                            const currentSegment = segments.value[selectedSegmentIndex.value];
                            if (currentSegment) {
                                const absoluteTime = direction === 'start' ? currentSegment.start : currentSegment.end;
                                simpleSeek(newTime);
                                nextTick(() => {
                                    isUpdating.value = false;
                                    updateCurrentTimeAndPosition(newTime);
                                });
                            }
                        });
                    };
                    timelineUpdateListener.value = handleTimelineUpdated;
                    window.addEventListener(TIMELINE_UPDATED_EVENT, handleTimelineUpdated);

                    updateSegmentsInStore(segments.value);
                    setTimeout(() => {
                        window.dispatchEvent(new CustomEvent(TIMELINE_UPDATED_EVENT));
                    }, 500);

                    timelineUpdateListener.value.timeoutId = setTimeout(() => {
                        window.removeEventListener(TIMELINE_UPDATED_EVENT, handleTimelineUpdated);
                        timelineUpdateListener.value = null;

                        simpleSeek(newTime);
                        nextTick(() => {
                            const currentSegment = segments.value[selectedSegmentIndex.value];
                            if (currentSegment) {
                                const absoluteTime = direction === 'start' ? currentSegment.start : currentSegment.end;
                                isUpdating.value = false;

                                updateCurrentTimeAndPosition(absoluteTime);
                            }
                        });
                    }, 1000);
                }
            },
            handleEdgeScroll,
            stopEdgeScroll
        });

        const {
            extractFrames,
            videoEndTime,
            performSeekOperation,
            initializeTimeline,
            simpleSeek,
            seekOnCurrentTimeWhenReady
        } = useMediaPlayer(
            toRef(sequenceProps, 'media'),
            toRef(sequenceProps, 'mediaType'),
            handlemediaDurationChange,
            loadAudioData,
            sequenceProps,
            dragInteractions.value.dragHandle,
            sequenceTotalTime,
            props.sequenceId,
            props.elementId
        );

        const {
            handleStartDrag,
            onDrag,
            handleStopDrag,
            startSliderDrag,
            onSliderDrag,
            stopSliderDrag,
            dragSegmentIndex,
            dragHandle,
            startSegmentDrag,
            dragSegmentIsDragging,
            isHandleDisabled
        } = dragInteractions.value;

        const { zoomLevel, adjustZoomLevel, handleZoom, canZoomOut, canZoomIn } = useZoom(
            FRAME_WIDTH,
            videoEndTime,
            sliderContainer,
            canvasWidth,
            segments
        );

        const { sliderWrapperStyle, quickCutContainer, handleResize } = useQuickCutLayout(
            canvasWidth,
            adjustZoomLevel,
            extractFramesHandler
        );

        const {
            currentTimePos,
            updateCurrentTimeAndPosition,
            startCurrentTimeDrag,
            onCurrentTimeDrag,
            stopCurrentTimeDrag,
            getCurrentRealTime,
            currentTimeDrag
        } = useTimelineControls(
            { FRAME_RATE, FRAME_WIDTH },
            {
                segments,
                videoEndTime: sequenceTotalTime,
                updateCurrentTimeAndPos,
                zoomLevel,
                isUpdating,
                sequenceProps
            },
            simpleSeek,
            emit
        );

        const { getHandlePos, getGapStyle, getInitialGapStyle, getFinalGapStyle, recalculateGapStyles } =
            useSegmentStyles({
                sequenceProps,
                segments,
                canvasWidth,
                isDragging,
                dragSegmentIndex,
                dragHandle
            });

        const { addEventListeners } = useEventHandlers({
            sliderWrapper,
            sliderContainer,
            handleCurrentTimeDrag,
            stopCurrentTimeDrag,
            onDrag,
            handleStopDrag,
            onSliderDrag,
            stopSliderDrag,
            handleResize,
            close
        });

        const { onCanvasClick } = useCanvasInteraction({
            sequenceProps,
            segments,
            currentTimePos,
            canvasWidth
        });

        const rawMediaDuration = computed(() =>
            segments.value.reduce((acc, segment) => acc + (segment.end - segment.start), 0)
        );

        const mediaDuration = computed(() => rawMediaDuration.value / sequenceProps.playbackRate);

        const waveformType = computed(() => {
            if (props.mediaType === 'audio' || props.mediaType === 'tts') {
                return 'wave-middle';
            }
            return 'wave';
        });

        function handlemediaDurationChange(duration) {
            sequenceProps.endTime = duration;
            updateSegmentEnd(duration);
            nextTick(() => {
                adjustZoomLevel();
                // Calculer la largeur du canvas en fonction du niveau de zoom
                canvasWidth.value = sequenceProps.endTime * FRAME_WIDTH * zoomLevel.value;
                extractFramesHandler();
                quickCutContainer.value = document.getElementById('quick-cut-container');
                sliderWrapperStyle.value = `max-width: ${quickCutContainer.value.offsetWidth}px;`;

                document.addEventListener('mousedown', (event) => {
                    if (quickCutContainer.value && !quickCutContainer.value.contains(event.target)) {
                        close();
                    }
                });
            });
        }

        async function extractFramesHandler() {
            if (canvas.value && canvasWidth.value > 0) {
                gsap.set(canvas.value, { opacity: 0, scale: 0.95 });

                await extractFrames(canvas.value, canvasWidth.value, canvasHeight.value);

                gsap.to(canvas.value, {
                    duration: 0.5,
                    opacity: 1,
                    scale: 1,
                    ease: 'power2.out'
                });
            }
        }

        function updateCurrentTimeAndPos(newTime) {
            const { elT, segmentStart, found } = calculateElapsedTime(newTime);

            if (!found && segments.value.length > 0) {
                return;
            }

            performSeekOperation(elT);
            updateCurrentPosition(newTime, segmentStart);
        }

        function calculateElapsedTime(newTime) {
            let elT = sequenceProps.startTime;
            let segmentStart = 0;
            let found = false;
            let totalDuration = 0;

            for (const segment of segments.value) {
                const segmentDuration = segment.end - segment.start;
                totalDuration += segmentDuration;

                if (isTimeWithinSegment(newTime, segment)) {
                    elT += newTime - segment.start;
                    found = true;
                    break;
                } else if (newTime > segment.end) {
                    elT += segmentDuration;
                    segmentStart = segment.end;
                } else {
                    break;
                }

                if (elT > totalDuration + sequenceProps.startTime) {
                    elT = totalDuration + sequenceProps.startTime;
                    found = true;
                    break;
                }
            }

            if (!found) {
                elT = sequenceProps.startTime;
                segmentStart = 0;
            }

            if (elT > mediaDuration.value - EPSILON) {
                elT = mediaDuration.value - EPSILON;
            }

            return { elT, segmentStart, found };
        }

        function isTimeWithinSegment(time, segment) {
            return time >= segment.start - EPSILON && time <= segment.end + EPSILON;
        }

        function updateCurrentPosition(newTime, segmentStart) {
            const containerWidth = sliderContainer.value.getBoundingClientRect().width;
            const segmentPosition = calculatePosition(segmentStart, containerWidth);
            const positionInSegment = calculatePosition(newTime - segmentStart, containerWidth);
            const newPosition = Math.min(segmentPosition + positionInSegment, containerWidth);

            currentTimePos.value = newPosition;
        }

        function calculatePosition(time, containerWidth) {
            return (time / sequenceProps.endTime) * containerWidth;
        }

        function splitVideoHandler() {
            const realTime = getCurrentRealTime();
            splitVideo(realTime);
            updateSegmentsInStore(segments.value);
        }

        function handleCurrentTimeDrag(event) {
            onCurrentTimeDrag(event, sliderContainer.value, canSplit.value);
        }

        function handleStartSliderDrag(event, wrapperEl) {
            startSliderDrag(event, wrapperEl);
        }

        function handleElementsMount({ sliderWrapper: wrapperEl, sliderContainer: containerEl }) {
            sliderWrapper.value = wrapperEl;
            sliderContainer.value = containerEl;
            sliderOuterContainer.value = containerEl.parentElement.parentElement;
            addEventListeners();
        }

        function handleCanvasClick(event) {
            if (isDragging.value || dragSegmentIsDragging.value) {
                return;
            }

            const result = onCanvasClick(event, sliderContainer.value);

            if (result && result.time !== undefined) {
                // isUpdating.value = true;
                simpleSeek(result.time);
            }
        }

        watchEffect(() => {
            sequenceProps.media = props.media;
            sequenceProps.playbackRate = props.playbackRate;
            sequenceProps.zoomLevel = zoomLevel.value;
        });

        watchEffect(() => {
            sharedCurrentTimePos.value = currentTimePos.value;
        });

        watch(zoomLevel, () => {
            if (isUpdating.value) return;

            try {
                isUpdating.value = true;
                canvasWidth.value = sequenceProps.endTime * FRAME_WIDTH * zoomLevel.value;
                recalculateGapStyles();
                extractFramesHandler();

                nextTick(() => {
                    updateCurrentTimeAndPosition(adjustedSequenceElapsedTime.value - sequenceProps.startTime);
                    debouncedUpdateSelectedSegment(adjustedSequenceElapsedTime.value);
                });
            } finally {
                isUpdating.value = false;
            }
        });

        watch(sequenceElapsedTime, (newTime) => {
            if (!isDragging.value && !currentTimeDrag.value) {
                nextTick(() => {
                    updateCurrentTimeAndPosition(newTime - sequenceProps.startTime);
                    debouncedUpdateSelectedSegment(newTime - sequenceProps.startTime);
                });
            }
        });

        watch(adjustedSequenceElapsedTime, (newTime) => {
            if (isUpdating.value) return;

            try {
                isUpdating.value = true;

                throttledUpdateSelectedSegment(newTime);
            } finally {
                isUpdating.value = false;
            }
        });

        watch(
            () => sequenceProps.endTime,
            (newEndTime) => {
                if (newEndTime > 0 && canvas.value) {
                    nextTick(() => {
                        canvasWidth.value = newEndTime * FRAME_WIDTH * zoomLevel.value;
                        extractFramesHandler();
                    });
                }
            }
        );

        watch(
            () => props.maxSegmentDuration,
            (newValue) => {
                if (newValue !== null && newValue !== undefined) {
                    sequenceProps.maxSegmentDuration = newValue;
                }
            },
            { immediate: true }
        );

        return {
            ...toRefs(sequenceProps),
            audioData,
            canSplit,
            canZoomIn,
            canZoomOut,
            canvas,
            canvasWidth,
            close,
            currentTimeDrag,
            currentTimePos,
            dragHandle,
            dragSegmentIndex,
            dragSegmentIsDragging,
            elapsedTime: adjustedElapsedTime,
            getCurrentRealTime,
            getFinalGapStyle,
            getGapStyle,
            getHandlePos,
            getInitialGapStyle,
            handleCanvasClick,
            handleCurrentTimeDrag,
            handleElementsMount,
            handleStartDrag,
            handleStartSliderDrag,
            handleUpdateSegment,
            isDragging,
            isHandleDisabled,
            isLoading,
            mediaDuration,
            removeSegment,
            segments,
            selectSegment,
            selectedSegmentIndex,
            sequenceElapsedTime: adjustedSequenceElapsedTime,
            sequenceProps,
            sequenceTotalTime,
            showLimitDuration,
            sliderContainer,
            sliderWrapper,
            sliderWrapperStyle,
            splitVideoHandler,
            startCurrentTimeDrag,
            startSegmentDrag,
            startSliderDrag,
            waveformType,
            zoomIn: () => handleZoom(1.8),
            zoomLevel,
            zoomOut: () => handleZoom(1 / 1.8),
            isClosing,
            limitType: toRef(props, 'limitType'),
            timeBarLocked,
            updateTimeBarLocked
        };
    }
});
</script>

<style scoped>
canvas {
    background-color: #3a4452;
    transform-origin: center center; /* Point d'origine pour l'animation scale */
}
.timeline-container {
    display: flex;
    margin-bottom: 16px;
}

.time-container-content {
    display: flex;
    justify-content: center;
    width: 64px;
}

.time-container {
    padding-top: 36px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#quick-cut-container {
    position: relative;
}
</style>
