<template>
    <div class="ui-video-list-item-container" :class="{ 'is-loading': loading }">
        <div class="ui-video-list-item" @click="handleClick">
            <div class="video-wrapper" :style="videoWrapperStyle">
                <UiVideoPreview :media="sequenceTemplateToVideo" />
                <div v-if="loading" class="loading-overlay"></div>
            </div>
            <div class="ui-video-list-item-name">{{ name || sequenceTemplate.name }}</div>
            <div class="ui-video-list-item-description" v-if="showMeta">
                <span v-for="(theme, index) in sequenceTemplate.themes" :key="index">
                    {{ theme.name }}
                    <span v-if="index < sequenceTemplate.themes.length - 1">- </span>
                </span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { SequenceTemplate } from '@/js/types/SequenceTemplate';
import UiVideoPreview from '@/js/components/UiVideoPreview.vue?inline';
import type { VideoPreviewable } from '@/js/types/VideoPreviewable';
import { PREDEFINED_FORMATS } from '@/js/types/Format';

const DEFAULT_FORMAT = 'LANDSCAPE';

interface Props {
    sequenceTemplate: SequenceTemplate;
    loading?: boolean;
    showMeta?: boolean;
    name?: string;
    selectedFormat: keyof typeof PREDEFINED_FORMATS;
}

const props = withDefaults(defineProps<Props>(), {
    loading: false,
    showMeta: true,
    name: '',
    selectedFormat: DEFAULT_FORMAT
});

const emit = defineEmits<{
    (e: 'click', template: SequenceTemplate): void;
}>();

const currentFormat = computed(() => {
    const formatKey = (props.selectedFormat || DEFAULT_FORMAT).toUpperCase() as keyof typeof PREDEFINED_FORMATS;
    return PREDEFINED_FORMATS[formatKey] || PREDEFINED_FORMATS[DEFAULT_FORMAT];
});

const sequenceTemplateToVideo = computed<VideoPreviewable>(() => {
    return {
        format: {
            width: currentFormat.value.width,
            height: currentFormat.value.height
        },
        thumbnails: {
            staticSrc: props.sequenceTemplate.thumbnails.static_src,
            videoSrc: props.sequenceTemplate.thumbnails.video_src
        }
    };
});

const aspectRatio = computed(() => {
    const { width, height } = currentFormat.value;
    if (!width || !height) return 56.25; // Ratio 16:9 par défaut
    return (height / width) * 100;
});

const videoWrapperStyle = computed(() => ({
    paddingBottom: `${aspectRatio.value}%`
}));

function handleClick() {
    if (!props.loading) {
        emit('click', props.sequenceTemplate);
    }
}
</script>

<style lang="scss" scoped>
</style>
