<template>
    <splash-screen v-if="loading" />
    <div v-else-if="!errorCode" class="edit-video" :class="`edit-mode-${editionMode}`">
        <div class="container-fluid h-100">
            <div class="row h-100">
                <main class="content col-12 p-0" :class="mainClass">
                    <div class="edit-ui">
                        <div id="studio-ui">
                            <ui-onboarding-tour v-if="studioReady" context="video"></ui-onboarding-tour>
                            <div class="ui-layout">
                                <div class="ui-header skeleton-element" v-if="!studioReady">
                                    <div class="ui-header-brand">
                                        <span class="ui-header-brand-logo">
                                            <svg-id-randomized-icon icon="cte-logo" />
                                        </span>
                                    </div>
                                </div>
                                <div class="ui-sidebar skeleton-element" v-if="!studioReady">
                                    <div class="ui-settings-item ui-sidebar-item">
                                        <span class="ui-sidebar-item-handle">
                                            <svg-icon icon="video-settings-icon" />
                                        </span>
                                    </div>
                                    <div class="ui-sequence-list">
                                        <ul class="ui-sequence-list-items">
                                            <li
                                                v-for="index in 8"
                                                :key="index"
                                                class="ui-sequence-list-item ui-sidebar-item"
                                            >
                                                <span class="ui-sidebar-item-handle">
                                                    <div class="ui-sequence-list-item-preview"></div>
                                                    <span class="ui-sequence-list-item-name">&nbsp;</span>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="ui-body skeleton-element" v-if="!studioReady"></div>
                                <ui-tts-editor v-if="studioReady"></ui-tts-editor>
                                <ui-captions-editor v-if="studioReady && captionsEditorActive" />
                                <ui-library v-if="studioReady"></ui-library>
                                <ui-media-library
                                    :show-header="true"
                                    :show-cancel-button="false"
                                    v-if="studioReady"
                                ></ui-media-library>
                                <!--                                <ui-caption-editor v-if="studioReady"></ui-caption-editor>-->
                                <ui-sidebar v-if="studioReady"></ui-sidebar>
                                <ui-body v-if="studioReady" ref="uiBodyRef"></ui-body>
                                <ui-header v-if="studioReady"></ui-header>
                            </div>
                            <ui-modal-container v-if="studioReady"></ui-modal-container>
                        </div>
                    </div>

                    <aside class="edit-preview">
                        <div class="preview-layout">
                            <div class="preview-studio-wrapper">
                                <video-studio v-if="studioReady" ref="videoStudioRef" id="studio"></video-studio>
                            </div>
                            <studio-controls
                                :skeleton="!studioReady"
                                :is-template="isTemplate || isSequenceTemplate"
                            ></studio-controls>
                        </div>
                    </aside>
                </main>
            </div>
        </div>
    </div>
    <error-screen v-else :error-code="errorCode" />
</template>

<script setup>
import { computed, nextTick, onUnmounted, provide, ref } from 'vue';
import { UiLibrary, UiMediaLibrary, UiModalContainer, UiOnboardingTour, UiTtsEditor } from '@/js/components/index.js';
import VideoStudioService from '@/js/infrastructure/api/video/VideoStudioService.js';
import { useStore } from 'vuex';
import StudioEditInitializer from '@/js/videos/application/StudioEditInitializer.js';
import { UiBody, UiHeader, UiSidebar } from '@/js/videos/components/ui/index.js';
import {
    MEDIA_VARIATION_OPTIMIZED_READY,
    MEDIA_VARIATION_THUMBNAIL_READY,
    STATUS_NO_CHANGE,
    STATUS_SAVED,
    USER_CHANNEL
} from '@/js/constants/index.js';
import { subscribeToChannel } from '@/js/utils.js';
import VideoStudio from '@/js/video-studio/VideoStudio.vue';
import '@/sass/video-studio/studio.scss';
import StudioControls from '@/js/videos/components/controls/StudioControls.vue';
import loader from '@/js/video-studio/loader.js';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import SplashScreen from '@/js/components/SplashScreen.vue';
import ErrorScreen from '@/js/components/ErrorScreen.vue';
import { useMediaConversions } from '@/js/videos/composables/useMediaConversions.js';
import UiCaptionsEditor from '@/js/videos/components/ui/body/editors/captions/UiCaptionsEditor.vue';

const store = useStore();
const route = useRoute();
const { t } = useI18n();
const { removeFromPath } = useMediaConversions();
const studioReady = ref(false);
const confirmationMessage = t('You have unsaved changes, are you sure you want to leave this page?');
const uiBodyRef = ref(null);
const videoStudioRef = ref(null);
const videoStudioService = new VideoStudioService(store);
const studioEditInitializer = new StudioEditInitializer(store, videoStudioService);

const editionMode = computed(() => store.state.ui.editionMode);
const brandId = computed(() => route.params.brandId);
const isSequenceTemplate = computed(() => route.meta.isSequenceTemplate);
const isTemplate = computed(() => route.meta.isTemplate);

// component state with API call
const loading = ref(true);
const errorCode = ref(null);

const avoidTimelineReflow = (value) => {
    store.commit('display/setAvoidTimelineReflow', value);
};

avoidTimelineReflow(false);

const ready = () => {
    studioReady.value = true;

    store.dispatch('ui/history/startRecording');

    initWebSockets();

    window.removeEventListener('beforeunload', preventUnsavedChangesLoss);
    window.addEventListener('beforeunload', preventUnsavedChangesLoss);
};

provide('$videoStudio', videoStudioRef);
provide('$uiBody', uiBodyRef);

const clearStore = () => {
    console.log('clearStore');
    store.dispatch('sequences/clearState');
    store.dispatch('settings/clearState');
    store.dispatch('preview/clearState');
    store.dispatch('branding/clearState');
    store.dispatch('display/clearState');
    store.dispatch('loading/clearState');
    store.dispatch('edition/clearState');
    store.dispatch('ui/clearState');
};

clearStore();

studioEditInitializer
    .initVideoStudio(route.params.id, isSequenceTemplate.value, isTemplate.value, brandId.value ?? null)
    .then((response) => {
        loading.value = false;

        if (response?.errorCode) errorCode.value = response.errorCode;
        else nextTick(ready);
    });

const saveStatus = computed(() => store.state.ui.saveStatus);
const userId = computed(() => store.state.ui.userId);

const preventUnsavedChangesLossCondition = computed(() => {
    return saveStatus.value !== STATUS_NO_CHANGE && saveStatus.value !== STATUS_SAVED;
});

const mainClass = computed(() => {
    return {
        skeleton: !studioReady.value
    };
});

const captionsEditorActive = computed(() => store.state.ui.captionsEditor.active);

const exists = (path) => {
    return store.getters['loading/exists'](path);
};

const initWebSockets = () => {
    subscribeToChannel(USER_CHANNEL, { id: userId.value }, 'private', {
        [MEDIA_VARIATION_OPTIMIZED_READY]: (event) => {
            if (exists(event.path)) {
                loader.refresh(event.path);
                removeFromPath(event.path);
            }
        },
        [MEDIA_VARIATION_THUMBNAIL_READY]: (event) => {
            if (exists(event.path)) {
                loader.refresh(event.path);
                removeFromPath(event.path);
            }
        }
    });
};

const preventUnsavedChangesLoss = (e) => {
    if (preventUnsavedChangesLossCondition.value) {
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
    }
};

onBeforeRouteLeave((to, from, next) => {
    avoidTimelineReflow(true);
    if (preventUnsavedChangesLossCondition.value) {
        const answer = window.confirm(confirmationMessage);
        if (answer) {
            next();
        } else {
            next(false);
        }
    } else {
        next();
    }
});

onUnmounted(() => {
    clearStore();
    window.removeEventListener('beforeunload', preventUnsavedChangesLoss);
});
</script>
