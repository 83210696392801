<template>
    <ui-caption-button
        :store-module-path="`sequences/${props.seqId}/background/video`"
        type="background"
        :parent-store-module-path="`sequences/${props.seqId}/video`"
        :has-old-captions="hasOldCaptions"
        :media-id="videoState.src__id"
        :media-src="videoState.src"
        :readonly="disabled"
        @open-editor="handleOpenEditor"
    />
</template>

<script setup lang="ts">
import { computed } from 'vue';
import UiCaptionButton from '@/js/videos/components/ui/body/selectors/caption/button/UiCaptionButton.vue';
import { useSequence } from '@/js/videos/composables/useSequence';
import { useCaptionsEditor } from '@/js/videos/composables/caption/useCaptionsEditor.ts';

const props = defineProps<{
    seqId: string;
    readOnly: boolean;
}>();

const { resolvedBackgroundState } = useSequence(props.seqId);
const { openEditor } = useCaptionsEditor();

const videoState = computed(() => resolvedBackgroundState.value.video);

const oldCaptions = computed(() => videoState.value?.captions || []);

const hasOldCaptions = computed(() => {
    return oldCaptions.value.length > 0 && oldCaptions.value[0].text.length > 0;
});

const disabled = computed(() => {
    return props.readOnly || !videoState.value?.src;
});

const handleOpenEditor = (): void => {
    openEditor(
        videoState.value?.src__id,
        `sequences/${props.seqId}/background/video`,
        'background',
        `sequences/${props.seqId}/video`,
        props.seqId
    );
};
</script>
