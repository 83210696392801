<template>
    <ui-asset-caption-selector
        v-if="isVideoCategory || isRecordingCategory"
        :seq-id="seqId"
        :element-id="elementId"
        :card-element-id="cardElementId"
        :read-only="readOnly || isMediaConverting"
        :tooltip="isMediaConverting ? $t('media.preparation.warning') : $t('Caption')"
        :icon-only="true"
    />

    <ui-asset-video-time-range-selector
        v-if="isVideoCategory || isRecordingCategory"
        :seq-id="seqId"
        :element-id="elementId"
        :card-element-id="cardElementId"
        :read-only="readOnly || isMediaConverting"
        :tooltip="isMediaConverting ? $t('media.preparation.warning') : $t('Cut')"
        :icon-only="true"
    />

    <ui-visual-volume-selector
        v-if="isVideoCategory || isRecordingCategory"
        :seq-id="seqId"
        :element-id="elementId"
        :card-element-id="cardElementId"
    />

    <ui-visual-speed-duration-selector :seq-id="seqId" :element-id="elementId" :card-element-id="cardElementId" />

    <ui-visual-effect-selector
        ref="effectSelector"
        :seq-id="seqId"
        :element-id="elementId"
        :card-element-id="cardElementId"
    />
</template>

<script setup>
import { ref } from 'vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import UiVisualEffectSelector from '@/js/videos/components/ui/body/selectors/effect/UiVisualEffectSelector.vue';
import UiVisualSpeedDurationSelector from '@/js/videos/components/ui/body/selectors/speed-duration/UiVisualSpeedDurationSelector.vue';
import UiVisualVolumeSelector from '@/js/videos/components/ui/body/selectors/volume/UiVisualVolumeSelector.vue';
import UiAssetVideoTimeRangeSelector from '@/js/videos/components/ui/body/selectors/time-range/UiAssetVideoTimeRangeSelector.vue';
import { useVisual } from '@/js/videos/composables/useVisual.js';
import UiAssetCaptionSelector from '@/js/videos/components/ui/body/selectors/caption/UiAssetCaptionSelector.vue';

const props = defineProps({
    seqId: String,
    cardElementId: String,
    elementId: String
});

const { isEmotionMode, readOnly } = useVideo();
const { isMediaConverting, isVideoCategory, isRecordingCategory } = useVisual(props.seqId, props.elementId);
const effectSelector = ref(null);

defineExpose({ effectSelector });
</script>
