import { computed } from 'vue';
import { useStore } from 'vuex';
import { getPrefixedUuid } from '../../utils';
import { useHistory } from './useHistory';
import { Duration } from '@/js/video-studio/constants/index.js';

export function useMedia(storeModulePath) {
    const store = useStore();
    const { saveHistoryStep } = useHistory(store);

    /*
    Vuex doesn't allow to access property like that : state['sequences/id/nested/value']
    So we need to access each nested value one by one
    */
    const mediaState = computed(() => {
        const keys = storeModulePath.split('/');
        let currentValue = store.state;

        for (const key of keys) {
            if (!currentValue[key]) {
                return undefined;
            }
            currentValue = currentValue[key];
        }

        return currentValue;
    });

    const prefixes = computed(() => store.state.ui.prefixes);

    const sourceRef = computed(() => store.getters[storeModulePath + '/sourceRef']);

    const mediaModel = computed({
        get() {
            return {
                src: mediaState.value.src,
                id: mediaState.value.src__id
            };
        },
        set({ src, id }) {
            saveHistoryStep(() => {
                setSource(src, id);
                if (store._mutations[storeModulePath + '/setImproved'])
                    store.commit(storeModulePath + '/setImproved', false);
            });
        }
    });

    const startTime = computed(() => mediaState.value.start);
    const endTime = computed(() => mediaState.value.end);

    const segments = computed({
        get: () => mediaState.value.timerangeSegments,
        set: (value) => {
            saveHistoryStep(() => {
                store.commit(storeModulePath + '/setTimerangeSegments', value);
            });
        }
    });

    const playbackRate = computed({
        get: () => mediaState.value.playbackRate || Duration.PLAY_BACK_RATE_DEFAULT,
        set: (value) => {
            saveHistoryStep(() => {
                store.commit(storeModulePath + '/setPlaybackRate', value);
            });
        }
    });

    const setSource = (src, id) => {
        store.commit(storeModulePath + '/setSource', src);
        store.commit(storeModulePath + '/setSourceId', id);
        if (!sourceRef.value) {
            store.commit(storeModulePath + '/setSourceRef', getPrefixedUuid(prefixes.value.mediaReference));
        }
    };

    const deleteSource = () => {
        store.commit(storeModulePath + '/setSource', '');
        store.commit(storeModulePath + '/setSourceId', '');
    };

    return {
        mediaState,
        mediaModel,
        startTime,
        endTime,
        segments,
        playbackRate,
        setSource,
        deleteSource
    };
}
