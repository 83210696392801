import { Store } from 'vuex';
import CaptionApiService from '@/js/infrastructure/api/caption/CaptionApiService';
import { CaptionUnit } from '@/js/videos/types/captions';

export default class CaptionService {
    private apiService: CaptionApiService;

    constructor(store: Store<any>) {
        this.apiService = new CaptionApiService(store);
    }

    createTask(mediaId: string, lang: string): Promise<any> {
        return this.apiService.createTask(mediaId, lang);
    }

    getMediaTaskResult(mediaId: string): Promise<any> {
        return this.apiService.getMediaTaskResult(mediaId);
    }

    createTranslationTask(captions: CaptionUnit[], lang: string): Promise<any> {
        return this.apiService.createTranslationTask(captions, lang);
    }
}
