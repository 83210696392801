<template>
    <ui-dropdown
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="bottom"
        menu-strategy="fixed"
        :disabled="disabled"
        :toggle-style="toggleStyle"
        :icon-only="true"
        :tooltip="$t('Duration and speed')"
    >
        <template #dropdown-toggle>
            <fa-icon class="icon" icon="fa-regular fa-clock" />
        </template>

        <ui-number-input
            v-model="voiceOverStart"
            :default-value="0"
            :min="0"
            :label="$t('Starting time')"
            :disabled="readOnly"
        />

        <ui-number-input
            v-model="playbackRate"
            :default-value="playbackRateDefault"
            :min="0.25"
            :max="4"
            :step="0.25"
            :label="$t('Speed')"
            :disabled="readOnly"
        />
    </ui-dropdown>
</template>

<script setup>
import UiDropdown from '@/js/components/UiDropdown.vue';
import UiNumberInput from '@/js/components/UiNumberInput.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { computed, ref } from 'vue';
import { useVoiceOver } from '@/js/videos/composables/useVoiceOver.js';
import { Duration } from '@/js/video-studio/constants/index.js';

const props = defineProps({
    seqId: String,
    useInSequence: Boolean
});

const { readOnly } = useVideo();

const useInSequence = ref(props.useInSequence);
const seqId = ref(props.seqId);
const { playbackRate, voiceOverMediaState, voiceOverStart } = useVoiceOver(useInSequence, seqId);

const playbackRateDefault = Duration.PLAY_BACK_RATE_DEFAULT;

const disabled = computed(() => readOnly.value || !voiceOverMediaState?.value?.src);

const toggleStyle = computed(() => {
    return voiceOverMediaState?.value?.src &&
        (playbackRate.value !== playbackRateDefault || voiceOverStart.value !== Duration.START_DEFAULT)
        ? { color: 'var(--edit-mode-color)' }
        : {};
});
</script>
