<!--
  -- Component name: ALD5BottomLeftLarge
  -- Type: Transition
  -- Uses: color.start
  -- Tags: ald
  -->
<template>
    <div class="studio-sequence-transition studio-container" :class="classes" :id="this.containerID">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMinYMin meet"
            class="ald-line-bottom"
        >
            <rect
                class="ald-rect-1"
                :x="this.rectWidth * -0.25"
                :y="this.rectHeight * 0.75"
                :width="rectWidth"
                :height="rectHeight"
                :rx="rx"
                fill="transparent"
                :stroke="state.color.start"
                stroke-width="1"
                :stroke-dasharray="lineSize + ' ' + lineSize * (lineRatio - 1)"
            />
        </svg>
    </div>
</template>

<style scoped>
svg {
    position: absolute;
    width: 100%;
    height: 100%;
}
</style>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true, // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
            lineRatio: 6,
            rx: 10
        };
    },

    computed: {
        ratio() {
            return this.format.width / this.format.height;
        },

        curveSize() {
            return (2 * Math.PI * this.rx) / 4;
        },

        rectWidth() {
            return this.ratio > 1 ? 100 * this.ratio : 100;
        },

        rectHeight() {
            return this.ratio < 1 ? 100 / this.ratio : 100;
        },

        rectPerimeter() {
            return ((this.rectWidth + this.rectHeight - 4 * this.rx) / 2 + this.curveSize) * 4;
        },

        lineSize() {
            return this.rectPerimeter / this.lineRatio;
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline(),
                from1 = -(this.rectWidth * 0.25 - this.rx) + this.lineSize,
                to1 = -(this.rectWidth - 2 * this.rx + this.curveSize) - (this.rectHeight * 0.25 - this.rx);

            t.fromTo(
                '#' + this.containerID + ' .ald-rect-1',
                { attr: { 'stroke-dashoffset': from1 } },
                { duration: 2, attr: { 'stroke-dashoffset': to1 }, ease: 'power1.inOut' },
                0
            );

            this._timeline = t;

            return t;
        }
    }
};
</script>
