<template>
    <div class="nav-item-wrapper">
        <component :is="component" class="nav-item" :class="classes" v-on="events" v-bind="attributes">
            <div class="nav-icon-container" :class="`nav-icon-container-${name}`">
                <fa-icon v-if="iconType === 'fa'" class="icon" :icon="iconClass" />
                <svg-icon v-if="iconType === 'svg'" class="icon" :icon="icon" />
            </div>
            <div class="nav-item-route-name">
                <div class="nav-item-name-label" :class="{ folder: folder }">{{ label }}</div>
                <div v-if="folder" class="nav-item-name-right-label">
                    <FavouriteButton :folder="folder" :tooltip="false"></FavouriteButton>
                    <span class="right-label-data">{{ rightLabelData ? rightLabelData : 0 }}</span>
                </div>
            </div>
            <fa-icon v-if="dropdown" icon="fa-solid fa-chevron-right" class="icon nav-dropdown-item-arrow-icon" />
        </component>
        <slot :opened="opened"></slot>
    </div>
</template>

<script setup>
import { computed, inject, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import _isEqual from 'lodash/isEqual.js';
import { useAuth } from '@/js/composables/useAuth.js';
import FolderDTO from '@/js/dto/FolderDTO.js';
import FavouriteButton from '@/js/components/folders/FavouriteButton.vue';

const props = defineProps({
    name: '',
    link: {
        type: Boolean,
        default: true
    },
    route: {
        type: Object,
        default: { name: 'Home' }
    },
    dropdown: {
        type: Boolean,
        default: false
    },
    label: {
        type: String,
        default: ''
    },
    rightLabelData: {
        type: String,
        default: ''
    },
    icon: {
        type: String,
        default: ''
    },
    iconType: {
        type: String,
        default: 'fa'
    },
    hideActivation: {
        type: Boolean,
        default: false
    },
    disableAutomaticOpen: {
        type: Boolean,
        default: false
    },
    folder: {
        type: FolderDTO,
        required: true
    }
});

const { user } = useAuth();
const route = useRoute();

const { openedNavItem, openNavItem } = inject('openedNavItem');
const navSubListName = inject('navSubListName', '');

const opened = computed(() => {
    return openedNavItem.value === props.name;
});

const component = computed(() => {
    return props.link ? 'router-link' : 'div';
});

const attributes = computed(() => {
    return props.link ? { to: props.route } : {};
});

const active = computed(() => {
    return (
        props.link &&
        route.name === props.route.name &&
        (!props.route.params || _isEqual(route.params, props.route.params)) &&
        ((!route.hash && !props.route.hash) || route.hash === props.route.hash)
    );
});

const classes = computed(() => {
    return {
        active: !props.hideActivation && active.value,
        opened: opened.value,
        'nav-dropdown-item': props.dropdown
    };
});

const iconClass = computed(() => {
    return active.value ? `fa-solid ${props.icon}` : `fa-regular ${props.icon}`;
});

const events = computed(() => {
    return props.dropdown
        ? {
              click: open
          }
        : {};
});

const open = () => {
    if (props.name && props.name === openedNavItem.value) {
        openNavItem(null);
    } else if (props.name) {
        openNavItem(props.name);
    }
};

const openParentList = () => {
    if (navSubListName && openedNavItem.value !== navSubListName) {
        openNavItem(navSubListName);
    }
};

watch(active, (newValue) => {
    if (newValue && !props.disableAutomaticOpen) {
        openParentList();
    }
});

onMounted(() => {
    if (active.value && !props.disableAutomaticOpen) {
        openParentList();
    }
});
</script>
