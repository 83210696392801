<!--
  -- Component name: SG8
  -- Type: Transition
  -- Uses: color.start
  -- Tags: sg
  -->
<template>
    <svg
        ref="$sgLogo"
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        :viewBox="dataForView.viewBox"
        preserveAspectRatio="xMidYMid slice"
    >
        <g class="sg-logo">
            <g class="sg-group">
                <rect
                    class="sg-four"
                    :x="dataForView.width * 0.1"
                    y="0"
                    :width="dataForView.width * 0.8"
                    :height="dataForView.height * dataForView.heightRatio"
                    :fill="state.color.start"
                />
                <rect
                    class="sg-three"
                    :x="dataForView.width * 0.1"
                    y="0"
                    :width="dataForView.width * 0.8"
                    :height="dataForView.height * dataForView.heightRatio"
                    fill="#000"
                />
                <rect
                    class="sg-two"
                    :x="dataForView.width * 0.1"
                    y="0"
                    :width="dataForView.width * 0.8"
                    :height="dataForView.height * dataForView.heightRatio"
                    fill="#000"
                />
                <rect
                    class="sg-one"
                    :x="dataForView.width * 0.1"
                    y="-0"
                    :width="dataForView.width * 0.8"
                    :height="dataForView.height * dataForView.heightRatio"
                    fill="#000"
                />
            </g>
            <rect class="sg-container" x="0" y="0" width="100%" height="70%" fill="#fff" />
        </g>
    </svg>
</template>

<style scoped>
.sg-group {
    will-change: transform;
}
</style>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            isAbove: true,
            borderSize: 10,
            logoScaleDown: 0.9
        };
    },

    computed: {
        isLandscapeFormat() {
            return this.format.width > this.format.height;
        },

        isPortraitFormat() {
            return this.format.width < this.format.height;
        },

        isSquareFormat() {
            return this.format.width === this.format.height;
        },

        getRectOne() {
            if (this.isLandscapeFormat || this.isSquareFormat) {
                const value = this.dataForView.height * 0.04;
                const rectangleHeight = this.dataForView.height * this.dataForView.heightRatio;

                return -(rectangleHeight - value);
            } else {
                return 0;
            }
        },

        getRectTwo() {
            if (this.isLandscapeFormat || this.isSquareFormat) {
                return this.dataForView.height * 0.05 + this.dataForView.height * 0.04;
            } else {
                return (
                    this.getRectOne +
                    (this.dataForView.height * 0.045 + this.dataForView.height * this.dataForView.heightRatio)
                );
            }
        },

        getRectThree() {
            const rectangleHeight = this.dataForView.height * this.dataForView.heightRatio;
            return this.getRectTwo + (rectangleHeight + this.dataForView.height * 0.045);
        },

        getRectFour() {
            const rectangleHeight = this.dataForView.height * this.dataForView.heightRatio;
            return this.getRectThree + (rectangleHeight + this.dataForView.height * 0.045);
        },

        dataForView() {
            if (this.isLandscapeFormat) {
                return {
                    width: 1920,
                    height: 1080,
                    size: 540,
                    viewBox: '0 0 1920 1080',
                    heightRatio: 0.4
                };
            }

            if (this.isSquareFormat) {
                return {
                    width: 1000,
                    height: 1000,
                    size: 500,
                    viewBox: '0 0 1000 1000',
                    heightRatio: 0.4
                };
            }

            if (this.isPortraitFormat) {
                return {
                    width: 1080,
                    height: 1920,
                    size: 960,
                    viewBox: '0 0 1080 1920',
                    heightRatio: 0.22
                };
            }
        }
    },

    watch: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            const t = gsap.timeline();
            this._timeline = t;

            t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 }, 0.001);
            t.set('#' + this.containerID + ' .sg-one', { attr: { y: this.dataForView.height } });
            t.set('#' + this.containerID + ' .sg-two', { attr: { y: this.dataForView.height } });
            t.set('#' + this.containerID + ' .sg-three', { attr: { y: this.dataForView.height } });
            t.set('#' + this.containerID + ' .sg-four', { attr: { y: this.dataForView.height } });
            t.set('#' + this.containerID + ' .sg-container', { attr: { y: -this.dataForView.height } });

            t.to(
                '#' + this.containerID + ' .sg-one',
                { duration: 0.5, attr: { y: this.getRectOne }, ease: 'power2.inOut' },
                0.5
            );
            t.to(
                '#' + this.containerID + ' .sg-two',
                { duration: 0.5, attr: { y: this.getRectTwo }, ease: 'power2.inOut' },
                0.6
            );
            t.to(
                '#' + this.containerID + ' .sg-three',
                { duration: 0.5, attr: { y: this.getRectThree }, ease: 'power2.inOut' },
                0.7
            );
            t.to(
                '#' + this.containerID + ' .sg-four',
                { duration: 0.5, attr: { y: this.getRectFour }, ease: 'power2.inOut' },
                0.8
            );

            t.to('#' + this.containerID + ' .sg-one', { fill: this.state.color.start, duration: 0 }, 0.5);
            t.to('#' + this.containerID + ' .sg-one', { fill: '#fff', duration: 0 }, 0.7);
            t.to('#' + this.containerID + ' .sg-one', { fill: '#000', duration: 0 }, 0.9);
            t.to('#' + this.containerID + ' .sg-one', { fill: this.state.color.start, duration: 0 }, 1.1);

            t.to('#' + this.containerID + ' .sg-two', { fill: '#000', duration: 0 }, 0.5);
            t.to('#' + this.containerID + ' .sg-two', { fill: this.state.color.start, duration: 0 }, 0.7);
            t.to('#' + this.containerID + ' .sg-two', { fill: '#fff', duration: 0 }, 0.9);
            t.to('#' + this.containerID + ' .sg-two', { fill: this.state.color.start, duration: 0 }, 1.1);

            t.to('#' + this.containerID + ' .sg-three', { fill: this.state.color.start, duration: 0 }, 0.5);
            t.to('#' + this.containerID + ' .sg-three', { fill: '#fff', duration: 0 }, 0.7);
            t.to('#' + this.containerID + ' .sg-three', { fill: '#000', duration: 0 }, 0.9);
            t.to('#' + this.containerID + ' .sg-three', { fill: this.state.color.start, duration: 0 }, 1.1);

            t.to(
                '#' + this.containerID + ' .sg-one',
                { duration: 0.5, scaleY: 1.2, scaleX: 1.4, ease: 'power2.inOut', transformOrigin: '50% 50%' },
                1
            );
            t.to(
                '#' + this.containerID + ' .sg-two',
                { duration: 0.5, scaleY: 1.2, scaleX: 1.4, ease: 'power2.inOut', transformOrigin: '50% 50%' },
                1
            );
            t.to(
                '#' + this.containerID + ' .sg-three',
                { duration: 0.5, scaleY: 1.2, scaleX: 1.4, ease: 'power2.inOut', transformOrigin: '50% 50%' },
                1
            );
            t.to(
                '#' + this.containerID + ' .sg-four',
                { duration: 0.5, scaleY: 1.2, scaleX: 1.4, ease: 'power2.inOut', transformOrigin: '50% 50%' },
                1
            );

            t.to(
                '#' + this.containerID + ' .sg-container',
                { attr: { y: this.dataForView.height }, duration: 0.5 },
                1.5
            );

            t.to(
                '#' + this.containerID + ' .sg-one',
                { duration: 0.5, attr: { y: this.dataForView.height * 2 }, ease: 'power2.inOut' },
                1.5
            );
            t.to(
                '#' + this.containerID + ' .sg-two',
                { duration: 0.5, attr: { y: this.dataForView.height * 2 }, ease: 'power2.inOut' },
                1.6
            );
            t.to(
                '#' + this.containerID + ' .sg-three',
                { duration: 0.5, attr: { y: this.dataForView.height * 2 }, ease: 'power2.inOut' },
                1.7
            );
            t.to(
                '#' + this.containerID + ' .sg-four',
                { duration: 0.5, attr: { y: this.dataForView.height * 2 }, ease: 'power2.inOut' },
                1.8
            );

            return t;
        }
    }
};
</script>
