<!--
  -- Component name: Number6
  -- Type: Transition
  -- Uses: color.start
  -- Tags: number
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <defs>
            <mask :id="svgMaskID">
                <rect x="0" y="0" width="100%" height="100%" fill="#fff" />
                <path
                    class="color-number"
                    d="M591.7,345.6c6.1-1.5,11.7-2.7,17.3-4.2c19.2-5.2,38.8-4.9,58.3-4.1c11.5,0.4,22.7,3.1,33.3,7.8
				c20.7,9.1,33.1,24.9,37.5,46.7c4.9,24.2,3.5,48-8.5,70.2c-9.5,17.6-24.6,28.7-43.3,35.2c-15.1,5.2-30.6,6.7-46.3,5.8
				c-21.1-1.2-40.9-6.5-57.1-21c-10.9-9.8-17.9-22.1-23-35.6c-5.6-15-9-30.5-9.9-46.4c-0.8-16.1-1.9-32.3-1.4-48.4
				c0.8-25.4,4.5-50.5,16.3-73.6c13.5-26.5,35.4-41.6,64.4-47.2c18.2-3.5,36.6-2.6,54.8-2c13.7,0.5,27.2,3.3,40.8,5
				c2.5,0.3,3.4,1.2,2.9,3.9c-1.4,8.2-2.3,16.4-3.4,24.7c-0.7,5.1-0.7,5.1-5.9,4.2c-22.2-3.9-44.4-6.9-67-5.4
				c-23.8,1.6-42.8,11.9-52.6,38.2c-4.3,11.5-6.2,23.6-6.5,35.9C592.4,338.7,592,341.9,591.7,345.6z M697.3,415.7
				c0-2.4,0.2-4.8,0-7.2c-0.8-8.4-2.6-16.4-7.5-23.5c-7.5-10.8-19-13.6-30.8-14.5c-21.7-1.6-43,1-63.7,8.3c-2.9,1-3.7,2.4-3.5,5.2
				c0.6,7,1.1,14,1.5,21.1c0.7,11,2.7,21.7,6.7,32c4.2,10.8,10.4,20.3,20.7,26.2c11,6.3,22.8,8.1,35.4,6.4
				c14.9-2,26.2-9.4,33.8-22.2C695.8,437.8,697.8,427,697.3,415.7z"
                />
            </mask>
        </defs>
        <rect x="0" y="0" width="100%" height="100%" :fill="state.color.start" :mask="svgMask" />
    </svg>
</template>

<script>
import { Dimension } from '../../constants';
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: false, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
        };
    },

    computed: {
        svgMaskID() {
            return this.containerID + '-number6-mask';
        },

        svgMask() {
            return 'url(#' + this.svgMaskID + ')';
        }
    },

    watch: {
        svgMaskRadius: {
            handler() {
                this.updateTimeline();
            }
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            this._timeline = t;
            t.fromTo(
                '#' + this.svgMaskID + ' .color-number',
                { scale: 1 },
                { scale: 1.5, transformOrigin: '50% 50%', duration: 1 },
                0
            );
            t.fromTo(
                '#' + this.svgMaskID + ' .color-number',
                { scale: 1.5 },
                { scale: 50, translateX: '-100%', transformOrigin: '50% 50%', duration: 0.2 },
                1
            );
            return t;
        }
    }
};
</script>
