<template>
    <div class="dropdown-list-item" @click="selectValue" :class="{ selected: isSelected }">
        <slot />
    </div>
</template>

<script setup lang="ts">
import { inject, computed } from 'vue';

const props = defineProps<{
    value: any;
}>();

const model = inject<{ value: any }>('model');

const isSelected = computed(() => {
    if (!model || !model.value || !props.value) return false;

    if (typeof props.value === 'object' && props.value !== null) {
        // If both have id property, compare by id
        if ('id' in props.value && model.value && typeof model.value === 'object' && 'id' in model.value) {
            return props.value.id === model.value.id;
        }
        // Otherwise try JSON comparison (not ideal but works for simple cases)
        return JSON.stringify(props.value) === JSON.stringify(model.value);
    }

    return props.value === model.value;
});

// Update the model when this item is selected
const selectValue = () => {
    if (model) {
        model.value = props.value;
    }
};
</script>
