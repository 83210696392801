<template>
    <div class="ui-caption-generator-form">
        <div class="ui-content-row">
            <label>{{ $t('captions.generator.video_language') }}</label>
            <dropdown v-model="selectedLanguageId" class="dropdown-small">
                <template #dropdown-selected-value>
                    <span v-if="selectedLanguage">{{ selectedLanguage.label }}</span>
                </template>
                <ui-scrollable>
                    <dropdown-item v-for="lang in languages" :key="lang.id" :value="lang.id">
                        <span class="dropdown-list-item-label">{{ lang.label }}</span>
                    </dropdown-item>
                </ui-scrollable>
            </dropdown>
        </div>
        <div class="ui-content-row flex-end">
            <button class="ui-simple-button ui-simple-button-select" @click="startCaptioning">
                {{ $t('Generate') }}
            </button>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import Dropdown from '@/js/components/dropdown/Dropdown.vue';
import DropdownItem from '@/js/components/dropdown/DropdownItem.vue';
import { useTranslateOptions } from '@/js/composables/useTranslateOptions';
import CaptionService from '@/js/videos/application/services/caption/CaptionService.ts';
import UiScrollable from '@/js/components/UiScrollable.vue';
import { AxiosResponse } from 'axios';
import { CaptionUnit } from '@/js/videos/types/captions';
import { useCaptions } from '@/js/videos/composables/caption/useCaptions';
import { useNotification } from '@/js/composables/useNotification';
const props = defineProps<{
    mediaId: string;
    storeModulePath: string;
    type: string;
    parentStoreModulePath: string;
}>();

const { commitModulePath, isCaptioning, resetCaptions, wordsNumber, DEFAULT_WORDS_NUMBER } = useCaptions(
    props.storeModulePath,
    props.type,
    props.parentStoreModulePath
);

const store = useStore();

const { t } = useI18n();
const { translateOptions, fetchTranslateOptions } = useTranslateOptions();
const { success, error } = useNotification('toast', false);

type lang = { id: number; label: string; db_video_language: string };

const defaultLanguageId: number = -1;
const defaultLanguage: lang = { id: defaultLanguageId, label: t('Automatic'), db_video_language: 'auto' };

const selectedLanguageId = ref<number>(defaultLanguageId);

const languages = ref<lang[]>([defaultLanguage]);

const selectedLanguage = computed<lang>(() => {
    return languages.value.find((lang) => lang.id === selectedLanguageId.value) || defaultLanguage;
});

const attempts = ref<number>(0);
const MAX_ATTEMPTS = 200;
const ATTEMPT_INTERVAL = 3000;

const setCaptioningStatus = () => {
    store.commit(commitModulePath.value + '/updateStatus', 'processing');
};

const handleError = (): void => {
    store.commit(commitModulePath.value + '/updateStatus', null);
    error(t('captions.generator.error'));
};

const handleSuccess = (result: CaptionUnit[]): void => {
    store.commit(commitModulePath.value + '/updateCaptions', [...result]);
    store.commit(commitModulePath.value + '/updateBaseCaptions', [...result]);
    store.commit(commitModulePath.value + '/updateStatus', null);
    wordsNumber.value = DEFAULT_WORDS_NUMBER;
    success(t('captions.generator.success'));
};

const checkTaskResult = (response: AxiosResponse): void => {
    if (!isCaptioning.value) {
        // If the captioning has been cancelled, we don't need to check the task result
        return;
    }

    if (!response.data.success) {
        return handleError();
    }

    if (response.data.stt) {
        return handleSuccess(response.data.stt);
    }

    // If the task is not finished, we need to wait for the next attempt
    attempts.value++;

    if (attempts.value > MAX_ATTEMPTS) {
        return handleError();
    }

    setTimeout(() => {
        new CaptionService(store).getMediaTaskResult(props.mediaId).then(checkTaskResult).catch(handleError);
    }, ATTEMPT_INTERVAL);
};

const startCaptioning = (): void => {
    attempts.value = 0;
    resetCaptions();
    setCaptioningStatus();

    new CaptionService(store)
        .createTask(props.mediaId, selectedLanguage.value.db_video_language)
        .then(checkTaskResult)
        .catch(handleError);
};

defineExpose({
    startCaptioning
});

onMounted((): void => {
    fetchTranslateOptions().then(() => {
        languages.value = languages.value.concat(translateOptions.value);
    });
});
</script>
