<template>
    <ui-media-selector
        v-if="isImageCategory"
        :id="cardElementId + '-image'"
        ref="$imageVisualSelector"
        v-model:media="visualImage"
        :type="mediaTypeImage"
        :label="$t('studio.visual_categories.image')"
        :show-label="false"
        :disabled="readOnly"
        icon="fa-regular fa-image"
        :trigger-opening="triggerSelection && isImageCategory && !libraryId"
        @[mediaSelectorShowEvent]="openMediaLibrary"
    />
    <ui-media-selector
        v-else-if="isIconCategory"
        :id="cardElementId + '-icon'"
        v-model:media="visualImage"
        :type="mediaTypeIcon"
        :label="$t('studio.visual_categories.icon')"
        :show-label="false"
        :disabled="readOnly"
        icon="fa-regular fa-badge-check"
        :trigger-opening="triggerSelection && isIconCategory && !libraryId"
        @[mediaSelectorShowEvent]="openMediaLibrary"
    />
    <ui-media-selector
        v-else-if="isVideoCategory"
        :id="cardElementId + '-video'"
        v-model:media="visualVideo"
        :type="mediaTypeVideo"
        :label="$t('studio.visual_categories.video')"
        :show-label="false"
        :disabled="readOnly"
        icon="fa-regular fa-circle-play"
        :trigger-opening="triggerSelection && isVideoCategory && !libraryId"
        @[mediaSelectorShowEvent]="openMediaLibrary"
        @[mediaSelectorChangeEvent]="handleMediaChange"
    />
    <ui-library-selector
        v-else-if="isAnimatedCategory && visualCategories.includes(visualAnimatedCategory)"
        :id="cardElementId + '-animated'"
        v-model="visualType"
        :default-value="visualDefaultTypes[visualCategory]"
        library="visuals"
        :category="elementState.animation.category"
        :preview-path="visualCategoryPreviewPath"
        :show-label="false"
        :disabled="readOnly"
        icon="fa-regular fa-note-sticky"
        :trigger-opening="
            triggerSelection && isAnimatedCategory && visualCategories.includes(visualAnimatedCategory) && !libraryId
        "
        @[librarySelectorShowEvent]="openLibrary"
        @[librarySelectorPreviewChangeEvent]="previewVisualType"
    />
    <ui-library-selector
        v-else-if="isCollageCategory"
        :id="cardElementId + '-collage'"
        v-model="visualType"
        :default-value="visualDefaultTypes[visualCategory]"
        library="visuals"
        :category="elementState.animation.category"
        :preview-path="visualCategoryPreviewPath"
        icon="fa-solid fa-sparkles"
        :show-label="false"
        :disabled="readOnly"
        :trigger-opening="triggerSelection && isCollageCategory && !libraryId"
        @[librarySelectorShowEvent]="openLibrary"
        @[librarySelectorPreviewChangeEvent]="previewVisualType"
    />
    <ui-library-selector
        v-else-if="isLottieCategory && visualCategories.includes(visualLottieCategory)"
        :id="cardElementId + '-lottie'"
        v-model="visualType"
        :default-value="visualDefaultTypes[visualCategory]"
        library="visuals"
        :category="elementState.animation.category"
        :preview-path="visualCategoryPreviewPath"
        :show-label="false"
        :disabled="readOnly"
        icon="fa-solid fa-person-running"
        :trigger-opening="
            triggerSelection && isLottieCategory && visualCategories.includes(visualLottieCategory) && !libraryId
        "
        @[librarySelectorShowEvent]="openLibrary"
        @[librarySelectorPreviewChangeEvent]="handleMediaChange"
    />
    <ui-recording-selector
        v-else-if="isRecordingCategory"
        :id="cardElementId + '-recording'"
        v-model:recording="visualRecording"
        :seqId="seqId"
        :type="RECORDING_TYPE_SCREEN"
        :show-label="false"
        :disabled="readOnly"
        :trigger-opening="triggerSelection && isRecordingCategory && !libraryId"
        @[recordingSelectorChangeEvent]="resetVisualVideoCaptions"
    />
</template>

<script setup>
import { useStore } from 'vuex';

import UiRecordingSelector, { UI_RECORDING_SELECTOR_CHANGE } from '@/js/components/UiRecordingSelector.vue';
import UiLibrarySelector, {
    UI_LIBRARY_SELECTOR_PREVIEW_CHANGE,
    UI_LIBRARY_SELECTOR_SHOW
} from '@/js/components/UiLibrarySelector.vue';
import UiMediaSelector, {
    UI_MEDIA_SELECTOR_CHANGE,
    UI_MEDIA_SELECTOR_SHOW,
    UI_MEDIA_TYPE_ICON,
    UI_MEDIA_TYPE_IMAGE,
    UI_MEDIA_TYPE_VIDEO
} from '@/js/components/UiMediaSelector.vue';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import { RECORDING_TYPE_SCREEN } from '@/js/constants/index.js';
import { useBrand } from '@/js/videos/composables/useBrand.js';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { useVisual } from '@/js/videos/composables/useVisual.js';
import { Visual } from '@/js/video-studio/constants/index.js';
import { useCaptions } from '@/js/videos/composables/caption/useCaptions.ts';

const props = defineProps({
    seqId: String,
    elementId: String,
    cardElementId: String,
    triggerSelection: Boolean
});

const mediaSelectorShowEvent = UI_MEDIA_SELECTOR_SHOW;
const mediaSelectorChangeEvent = UI_MEDIA_SELECTOR_CHANGE;
const librarySelectorShowEvent = UI_LIBRARY_SELECTOR_SHOW;
const librarySelectorPreviewChangeEvent = UI_LIBRARY_SELECTOR_PREVIEW_CHANGE;
const recordingSelectorChangeEvent = UI_RECORDING_SELECTOR_CHANGE;

const mediaTypeImage = UI_MEDIA_TYPE_IMAGE;
const mediaTypeIcon = UI_MEDIA_TYPE_ICON;
const mediaTypeVideo = UI_MEDIA_TYPE_VIDEO;
const visualAnimatedCategory = Visual.ANIMATED_CATEGORY;
const visualLottieCategory = Visual.LOTTIE_CATEGORY;

const store = useStore();
const { libraryTagOrders, brandPalette, brandEnableOtherColors } = useBrand();
const { saveHistoryStep, ignoreHistoryStep } = useHistory();
const { readOnly, openMediaLibrary, openLibrary, libraryId } = useVideo();
const {
    elementState,

    isImageCategory,
    isIconCategory,
    isVideoCategory,
    isAnimatedCategory,
    isRecordingCategory,
    isCollageCategory,
    isLottieCategory,

    visualImage,
    visualVideo,
    visualType,
    visualCategory,
    visualCategories,
    visualRecording,

    visualCategoryPreviewPath,
    visualDefaultTypes,

    previewVisualType,
    resetVisualVideoCaptions
} = useVisual(props.seqId, props.elementId);

const { resetCaptions } = useCaptions(
    `sequences/${props.seqId}/${props.elementId}/video`,
    'visual',
    `sequences/${props.seqId}/${props.elementId}`
);

const handleMediaChange = () => {
    resetVisualVideoCaptions();
    resetCaptions();
};
</script>
