<template>
    <div class="waveform-container" ref="waveformContainer">
        <div
            v-for="(canvas, index) in canvasElements"
            :key="index"
            class="canvas-segment"
            :style="{ width: `${segmentWidth}px`, left: `${index * segmentWidth}px` }"
        >
            <canvas :ref="(el) => setCanvasRef(el, index)" :width="segmentWidth" :height="canvasHeight"></canvas>
        </div>
        <div ref="waveformColor" class="waveform-color"></div>
    </div>
</template>

<script setup>
import { ref, onMounted, watch, computed, nextTick } from 'vue';
import { useWaveformDrawing } from '../composables/useWaveformDrawing';

const props = defineProps({
    audioData: {
        type: [Float32Array, Array],
        required: true
    },
    canvasWidth: {
        type: Number,
        required: true
    },
    canvasHeight: {
        type: Number,
        required: true
    },
    waveformType: {
        type: String,
        default: 'wave',
        validator: (value) => ['bar', 'wave', 'wave-middle'].includes(value)
    },
    zoomLevel: {
        type: Number,
        default: 0.1
    },
    startTime: {
        type: Number,
        default: 0
    },
    endTime: {
        type: Number,
        default: 0
    },
    duration: {
        type: Number,
        default: 0
    }
});

// Configuration des segments de canvas
const MAX_CANVAS_WIDTH = 4000; // Largeur maximale pour un canvas individuel
const waveformContainer = ref(null);
const waveformColor = ref(null);
const canvasRefs = ref([]);
const isUpdating = ref(false);
const { drawWaveform } = useWaveformDrawing();

// Calcul du nombre de segments nécessaires
const segmentCount = computed(() => {
    return Math.max(1, Math.ceil(props.canvasWidth / MAX_CANVAS_WIDTH));
});

// Largeur de chaque segment de canvas
const segmentWidth = computed(() => {
    return Math.ceil(props.canvasWidth / segmentCount.value);
});

// Tableau pour stocker les références aux éléments canvas
const canvasElements = computed(() => {
    return Array(segmentCount.value).fill(null);
});

// Fonction pour définir les références aux canvas
const setCanvasRef = (el, index) => {
    if (el) {
        canvasRefs.value[index] = el;
    }
};

const getWaveformColor = () => {
    if (!waveformColor.value) return 'rgba(0, 0, 0, 0.8)';

    const color = getComputedStyle(waveformColor.value).backgroundColor;

    if (props.waveformType === 'bar' || props.waveformType === 'wave-middle') return color;

    const rgba = color.replace(')', ', 0.8)').replace('rgb', 'rgba');
    return rgba;
};

// Diviser les données audio en segments pour chaque canvas
const getSegmentData = (index) => {
    if (!props.audioData || !props.audioData.length) return [];

    const dataLength = props.audioData.length;

    // Si nous avons des informations de durée, ajuster la segmentation en fonction du temps
    if (props.duration > 0 && props.endTime > props.startTime) {
        // Calculer le ratio de données à afficher
        const timeRatio = (props.endTime - props.startTime) / props.duration;
        const visibleDataLength = Math.floor(dataLength * timeRatio);

        // Calculer l'index de départ basé sur le startTime
        const startOffset = Math.floor(dataLength * (props.startTime / props.duration));

        // Calculer la taille de chaque segment en fonction des données visibles
        const segmentSize = Math.ceil(visibleDataLength / segmentCount.value);

        // Calculer les indices avec un léger chevauchement
        const overlap = Math.min(20, Math.floor(segmentSize * 0.05)); // 5% de chevauchement
        const startIndex = Math.max(startOffset, startOffset + index * segmentSize - (index > 0 ? overlap : 0));
        const endIndex = Math.min(
            startOffset + visibleDataLength,
            startOffset + (index + 1) * segmentSize + (index < segmentCount.value - 1 ? overlap : 0)
        );

        return Array.from(props.audioData.slice(startIndex, endIndex));
    }

    // Comportement par défaut si pas d'information temporelle
    const segmentSize = Math.ceil(dataLength / segmentCount.value);

    // Calculer les indices avec un léger chevauchement pour éviter les discontinuités
    const overlap = Math.min(20, Math.floor(segmentSize * 0.05)); // 5% de chevauchement
    const startIndex = Math.max(0, index * segmentSize - (index > 0 ? overlap : 0));
    const endIndex = Math.min(dataLength, (index + 1) * segmentSize + (index < segmentCount.value - 1 ? overlap : 0));

    return Array.from(props.audioData.slice(startIndex, endIndex));
};

// Détecter si le média est un fichier MP3
const isMP3Media = ref(false);

// Fonction pour vérifier si le média est un MP3 basé sur les propriétés ou le contexte
const checkIfMP3 = () => {
    // Si nous avons accès à une URL source, vérifier l'extension
    const mediaElement = document.querySelector('audio[src], video[src]');
    if (mediaElement) {
        const src = mediaElement.getAttribute('src');
        if (src && typeof src === 'string') {
            // Vérifier l'extension ou les paramètres d'URL qui pourraient indiquer un MP3
            if (
                src.toLowerCase().endsWith('.mp3') ||
                src.toLowerCase().includes('audio/mp3') ||
                src.toLowerCase().includes('type=audio')
            ) {
                isMP3Media.value = true;
                return;
            }
        }
    }

    // Vérifier si les données audio ont des caractéristiques typiques des MP3
    // (Moins de valeurs non-nulles, plus de variations brusques)
    if (props.audioData && props.audioData.length > 100) {
        let nonZeroCount = 0;
        let variationCount = 0;
        let zeroSequences = 0;
        let currentZeroSequence = 0;
        let maxZeroSequence = 0;
        let hasHoles = false;

        // Analyser les 1000 premiers échantillons
        for (let i = 1; i < Math.min(props.audioData.length, 1000); i++) {
            if (Math.abs(props.audioData[i]) > 0.001) {
                nonZeroCount++;
                if (currentZeroSequence > 0) {
                    zeroSequences++;
                    maxZeroSequence = Math.max(maxZeroSequence, currentZeroSequence);
                    currentZeroSequence = 0;
                }
            } else {
                currentZeroSequence++;
            }

            if (Math.abs(props.audioData[i] - props.audioData[i - 1]) > 0.05) {
                variationCount++;
            }
        }

        // Heuristique pour détecter un MP3 basée sur les caractéristiques des données
        const nonZeroRatio = nonZeroCount / Math.min(props.audioData.length, 1000);
        const variationRatio = variationCount / Math.min(props.audioData.length, 1000);

        // Compter les trous (séquences de zéros) dans les données
        let holes = 0;
        let inHole = false;
        for (let i = 0; i < props.audioData.length; i++) {
            if (Math.abs(props.audioData[i]) < 0.001) {
                if (!inHole) {
                    inHole = true;
                    holes++;
                }
            } else {
                inHole = false;
            }
        }

        // Détecter les trous significatifs dans les données
        if (holes > 0) {
            hasHoles = true;
        }

        // Vérifier si les données ont des caractéristiques de MP3 ou des problèmes qui nécessitent un traitement spécial
        isMP3Media.value = nonZeroRatio < 0.7 || variationRatio > 0.3 || hasHoles || holes > 0;

        // Si nous avons des trous mais que l'heuristique n'a pas détecté un MP3, forcer le traitement MP3
        if (holes > 0 && !isMP3Media.value) {
            isMP3Media.value = true;
        }
    }

    // Si nous n'avons pas pu détecter avec certitude, vérifier si c'est un fichier audio sans vidéo
    if (!isMP3Media.value) {
        const videoElement = document.querySelector('video');
        const audioElement = document.querySelector('audio');

        if (audioElement && !videoElement) {
            isMP3Media.value = true;
        }
    }
};

const updateWaveform = async () => {
    if (isUpdating.value || !canvasRefs.value.length) return;

    isUpdating.value = true;
    try {
        const color = await getWaveformColor();

        // Vérifier si le média est un MP3
        checkIfMP3();

        // S'assurer que tous les canvas sont correctement dimensionnés
        for (let i = 0; i < segmentCount.value; i++) {
            const canvas = canvasRefs.value[i];
            if (!canvas) {
                console.warn(`[AudioWaveform] Canvas ${i} non disponible`);
                continue;
            }

            // Vérifier et ajuster les dimensions du canvas si nécessaire
            if (canvas.width !== segmentWidth.value || canvas.height !== props.canvasHeight) {
                canvas.width = segmentWidth.value;
                canvas.height = props.canvasHeight;
            }
        }

        // Traiter chaque segment de canvas
        for (let i = 0; i < segmentCount.value; i++) {
            const canvas = canvasRefs.value[i];
            if (!canvas) {
                console.warn(`[AudioWaveform] Canvas ${i} non disponible`);
                continue;
            }

            const ctx = canvas.getContext('2d');
            ctx.clearRect(0, 0, segmentWidth.value, props.canvasHeight);

            // Obtenir les données pour ce segment
            const segmentData = getSegmentData(i);

            if (segmentData.length === 0) {
                console.warn(`[AudioWaveform] Segment ${i} sans données`);
            }

            if (segmentData.length > 0) {
                // Analyser les données du segment pour détecter les trous
                let zeroCount = 0;
                for (let j = 0; j < segmentData.length; j++) {
                    if (Math.abs(segmentData[j]) < 0.001) {
                        zeroCount++;
                    }
                }
                const zeroRatio = zeroCount / segmentData.length;

                // Calculer les dimensions réelles pour ce segment
                const segmentCanvasWidth =
                    i === segmentCount.value - 1 && props.canvasWidth % segmentWidth.value !== 0
                        ? props.canvasWidth % segmentWidth.value
                        : segmentWidth.value;

                await drawWaveform(
                    ctx,
                    segmentCanvasWidth,
                    props.canvasHeight,
                    color,
                    segmentData,
                    props.waveformType,
                    isMP3Media.value
                );
            }
        }
    } catch (error) {
        console.error("[AudioWaveform] Erreur lors de la mise à jour de la forme d'onde:", error);
    } finally {
        isUpdating.value = false;
    }
};

onMounted(() => {
    nextTick(updateWaveform);
});

watch(
    () => props.audioData,
    (newData) => {
        updateWaveform();
    }
);

watch(() => props.canvasWidth, updateWaveform);
watch(() => props.waveformType, updateWaveform);
watch(() => props.zoomLevel, updateWaveform);
watch(segmentCount, updateWaveform);
</script>

<style scoped>
.waveform-container {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 5;
    transform: translateY(-58px); /* Remonter la forme d'onde pour qu'elle se superpose aux frames vidéo */
}

.canvas-segment {
    position: absolute;
    top: 0;
    height: 100%;
}

canvas {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
}

.waveform-color {
    background-color: var(--edit-mode-color);
    display: none;
}
</style>
