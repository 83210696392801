import { CaptionConfig, Captions, CaptionUnit } from '@/js/videos/types/captions.ts';
import { Color } from '@/js/video-studio/constants';
import { ComponentPosition, ComponentSize } from '@/js/video-studio/types.ts';

const DEFAULT_FONT_SIZE = 38;

const state = (): Captions => ({
    status: null,
    manuallyUpdated: false,
    animation: 'CaptionsRetroAnimation',
    font: '',
    fontSize: DEFAULT_FONT_SIZE,
    config: {
        color: Color.WHITE,
        bold: true,
        italic: false,
        uppercase: false,
        alignment: 'center',
        interline: 1.5,
        textShadow: {
            distance: 1,
            angle: 45,
            blur: 2,
            color: {
                value: '#000',
                alpha: 1
            }
        }
    },
    palette: {
        color1: Color.NONE,
        color2: Color.NONE,
        color3: Color.NONE
    },
    wordsNumber: 1,
    captions: [],
    baseCaptions: [],
    position: {
        left: null,
        top: null
    },
    size: {
        width: null,
        height: null
    }
});

const getters = {
    font: (state, getters, rootState, rootGetters) => {
        return state.font || rootGetters['branding/defaultFont'];
    },

    palette: (state, getters, rootState) => {
        let brandPalette = rootState.branding.palette;
        return {
            color1: state.palette.color1 || brandPalette.color1,
            color2: state.palette.color2 || brandPalette.color2,
            color3: state.palette.color3 || brandPalette.color3
        };
    }
};

const mutations = {
    updateCaptions(state, captions: CaptionUnit[]): void {
        state.captions = captions.map((caption) => ({
            ...caption
        }));
    },

    updateBaseCaptions(state, captions: CaptionUnit[]): void {
        state.baseCaptions = captions.map((caption) => ({
            ...caption
        }));
    },

    removeCaptionUnit(state, index: number): void {
        state.captions.splice(index, 1);
    },

    updateCaptionUnit(state, { index, value }): void {
        state.captions[index] = { ...value };
    },

    updateAnimation(state, animation: string): void {
        state.animation = animation;
    },

    updateWordsNumber(state, wordsNumber: number): void {
        state.wordsNumber = wordsNumber;
    },

    updatePalette(state, palette): void {
        state.palette = palette;
    },

    updatePaletteColor(state, { index, color }): void {
        state.palette[`color${index}`] = color;
    },

    updateConfig(state, config: CaptionConfig): void {
        state.config = config;
    },

    updateFont(state, font: string): void {
        state.font = font;
    },

    updateFontSize(state, size: number): void {
        state.fontSize = size;
    },

    reset(state): void {
        state.status = null;
        state.captions = [];
        state.baseCaptions = [];
        state.fontSize = DEFAULT_FONT_SIZE;
        state.position = {
            left: null,
            top: null
        };
        state.size = {
            width: null,
            height: null
        };
    },

    updateStatus(state, status: string | null): void {
        state.status = status;
    },

    updatePosition(state, position: ComponentPosition): void {
        state.position = position;
    },

    updateSize(state, size: ComponentSize): void {
        state.size = size;
    },

    updateManuallyUpdated(state, manuallyUpdated: boolean): void {
        state.manuallyUpdated = manuallyUpdated;
    }
};

const actions = {
    init({ commit }, data: Captions): void {
        if (data.animation) commit('updateAnimation', data.animation);
        if (data.font) commit('updateFont', data.font);
        if (data.fontSize) commit('updateFontSize', data.fontSize);
        if (data.config) commit('updateConfig', data.config);
        if (data.palette) commit('updatePalette', data.palette);
        if (data.wordsNumber) commit('updateWordsNumber', data.wordsNumber);
        if (data.captions) commit('updateCaptions', data.captions);
        if (data.baseCaptions) commit('updateBaseCaptions', data.baseCaptions);
        if (data.position) commit('updatePosition', data.position);
        if (data.size) commit('updateSize', data.size);
        if (data.manuallyUpdated) commit('updateManuallyUpdated', data.manuallyUpdated);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};
