import MinimalFolderDTO from '@/js/dto/MinimalFolderDTO.js';

export default class MinimalBrandDTO {
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.organizationId = parseInt(data.organization_id);
        this.organizationName = data.organization_name ?? '';
        this.isMaster = data.is_master;
        this.masterName = data.master_name;
        this.logoUrl = data.logo?.media_url ?? '';
        this.foldersTotal = data.folders_total;
        this.folders = data?.folders.map((folder) => new MinimalFolderDTO(folder)) ?? [];
        this.createdAt = data.created_at;
        this.updatedAt = data.updated_at;
    }
}