import { ref } from 'vue';
import { useStore } from 'vuex';
import FetchBrandsDataService from '@/js/application/services/brands/FetchBrandsDataService.js';
import BrandDTO from '@/js/dto/BrandDTO.js';
import usePagination from '@/js/composables/usePagination.js';
import MinimalBrandDTO from '@/js/dto/MinimalBrandDTO.js';

export function useBrands() {
    const store = useStore();

    const brands = ref([]);
    const minimalBrands = ref([]);

    const totalBrandsForOrganization = ref(0);
    const maxBrandsForOrganization = ref(0);

    const { page, totalPages, isLoading, paginationRequest, resetData } = usePagination();

    const fetchBrands = async (options) => {
        if (options?.search) {
            resetData();
        }

        try {
            const paginatedResult = await paginationRequest(() =>
                new FetchBrandsDataService(store).fetchBrands({
                    ...options,
                    page: page.value
                })
            );

            if (paginatedResult) {
                brands.value =
                    page.value === 1
                        ? paginatedResult.data.map((item) => new BrandDTO(item))
                        : brands.value.concat(paginatedResult.data.map((item) => new BrandDTO(item)));
            }
        } catch (error) {
            console.error('Erreur lors de la récupération des marques :', error);
        }
    };

    const fetchBrandsForOrganization = async (organizationId, options, reset = false) => {
        try {
            if (reset) {
                resetDataForBrands(options); // Réinitialise la pagination et vide les données
            }

            const paginatedResult = await paginationRequest(() =>
                new FetchBrandsDataService(store).fetchBrandsForOrganization(organizationId, options)
            );

            if (paginatedResult) {
                addMinimalBrands(paginatedResult.data.map((item) => new MinimalBrandDTO(item)));

                if (paginatedResult.extraData) {
                    setTotalBrands(paginatedResult.extraData.organization_brands_count);
                    setMaxBrands(paginatedResult.extraData.organization_max_brands);
                }
            }
        } catch (error) {
            console.error("Erreur lors de la récupération des marques pour l'organisation :", error);
        }
    };

    const setTotalBrands = (total) => {
        totalBrandsForOrganization.value = total || 0;
    };

    const setMaxBrands = (max) => {
        maxBrandsForOrganization.value = max || 0;
    };

    const addBrands = (items) => {
        items.forEach((item) => brands.value.push(new BrandDTO(item)));
    };

    const addMinimalBrands = (items) => {
        minimalBrands.value = [...minimalBrands.value, ...items.map((item) => item)];
    };

    const resetDataForBrands = () => {
        resetData();
        brands.value = [];
        minimalBrands.value = [];
    };

    return {
        brands,
        minimalBrands,
        page,
        totalPages,
        isLoading,
        totalBrandsForOrganization,
        maxBrandsForOrganization,
        fetchBrands,
        fetchBrandsForOrganization,
        resetDataForBrands,
        addMinimalBrands,
        addBrands
    };
}