const state = () => ({
    captions: false
});

const getters = {
    captions: (state) => {
        return state.captions;
    }
};

const mutations = {
    setCaptions(state, captions) {
        state.captions = captions;
    }
};

const actions = {
    initCaptions({ commit, dispatch }, data) {
        if (data.captions) commit('setCaptions', data.captions);
    }
};

export default {
    state,
    getters,
    mutations,
    actions
};
