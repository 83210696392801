<template>
    <footer class="ui-captions-editor-footer">
        <dropdown
            v-if="cgsEnabled"
            ref="captioningDropdownRef"
            auto-close="outside"
            class="hide-chevron ui-generate-captions-button"
            :class="{ loading: isCaptioning }"
            :disabled="isCaptioning"
        >
            <template #dropdown-selected-value>
                <fa-icon class="icon ui-generate-captions-button-icon" icon="fa-regular fa-subtitles" />
                <svg-icon icon="circle-loader" class="loader" />
                <span>{{ $t('captions.editor.generate') }}</span>
            </template>
            <ui-captions-generator-form
                :media-id="editorState.mediaId"
                :store-module-path="editorState.storeModulePath"
                :type="editorState.type"
                :parent-store-module-path="editorState.parentStoreModulePath"
            />
        </dropdown>
        <dropdown
            v-if="AIenabled"
            ref="translatingDropdownRef"
            auto-close="outside"
            class="hide-chevron ui-generate-captions-button"
            :class="{ loading: isTranslating }"
            :disabled="isTranslating"
        >
            <template #dropdown-selected-value>
                <fa-icon class="icon ui-generate-captions-button-icon" icon="fa-regular fa-language" />
                <svg-icon icon="circle-loader" class="loader" />
                <span>{{ $t('captions.editor.translate') }}</span>
            </template>
            <ui-captions-translation-form
                :media-id="editorState.mediaId"
                :store-module-path="editorState.storeModulePath"
                :type="editorState.type"
                :parent-store-module-path="editorState.parentStoreModulePath"
            />
        </dropdown>

        <button class="ui-simple-button ui-captions-reset-button" @click="resetCaptions">
            <fa-icon class="icon" icon="fa-regular fa-trash-can" />
            <span>{{ $t('captions.editor.erase') }}</span>
        </button>
    </footer>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { useCaptionsEditor } from '@/js/videos/composables/caption/useCaptionsEditor.ts';
import { useCaptions } from '@/js/videos/composables/caption/useCaptions.ts';
import UiCaptionsGeneratorForm from '@/js/videos/components/ui/body/selectors/caption/UiCaptionsGeneratorForm.vue';
import UiCaptionsTranslationForm from '@/js/videos/components/ui/body/selectors/caption/UiCaptionsTranslationForm.vue';
import Dropdown from '@/js/components/dropdown/Dropdown.vue';
import { useStore } from 'vuex';

const store = useStore();
const { editorState } = useCaptionsEditor();
const { isCaptioning, isTranslating, resetCaptions } = useCaptions(
    editorState.value.storeModulePath,
    editorState.value.type,
    editorState.value.parentStoreModulePath
);

const captioningDropdownRef = ref<typeof Dropdown | null>(null);
const translatingDropdownRef = ref<typeof Dropdown | null>(null);

const cgsEnabled = computed(() => {
    return store.state.ui.restrictions.enableTTS; // STT has actually the same CGS as TTS
});

const AIenabled = computed(() => {
    return store.state.auth.user.client.videoCompanionEnabled;
});

watch(isCaptioning, (newValue) => {
    if (newValue) {
        captioningDropdownRef.value?.close();
    }
});

watch(isTranslating, (newValue) => {
    if (newValue) {
        translatingDropdownRef.value?.close();
    }
});
</script>
