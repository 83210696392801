<!--
  -- Component name: DivideFromCenter
  -- Type: Transition
  -- Uses: 
  -- Tags: translation
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <defs>
            <clipPath :id="svgMaskID" clipPathUnits="objectBoundingBox">
                <rect class="mask-vertical-border-left" x="-0.5" y="0" width="1" height="1" />
                <rect class="mask-vertical-border-right" x="0.5" y="0" width="1" height="1" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            needsRefresh: true, // The transition needs Chrome and Safari to force a re-rendering during the animation
            // isAbove: false, // The transition component is placed above the in and out elements
            swapElements: true, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
            keepActive: true
        };
    },

    computed: {
        svgMaskID() {
            return this.containerID + '-divide-from-center-mask';
        },

        svgMask() {
            return 'url(#' + this.svgMaskID + ')';
        }
    },

    watch: {
        svgMaskRadius: {
            handler() {
                this.updateTimeline();
            }
        }
    },

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            this._timeline = t;
            t.set(this, { outClipPathProxy: this.svgMask }, 0.0001);
            t.fromTo(
                '#' + this.svgMaskID + ' .mask-vertical-border-left',
                { attr: { x: '-0.5' } },
                { attr: { x: '-1' }, duration: 0.7, ease: 'power2.out' },
                0
            );
            t.fromTo(
                '#' + this.svgMaskID + ' .mask-vertical-border-right',
                { attr: { x: '0.5' } },
                { attr: { x: '1' }, duration: 0.7, ease: 'power2.out' },
                0
            );
            return t;
        }
    }
};
</script>
