/**
 * Video Studio
 * Sequence data Vuex store module
 */

import gsap from 'gsap';
import _merge from 'lodash/merge';
import _mergeWith from 'lodash/mergeWith';
import { v4 as uuidv4 } from 'uuid';
import { Timeline } from '@/js/video-studio/constants';
import { randomID, types } from '@/js/video-studio/utils';
import { getPrefixedUuid } from '@/js/utils.js';
import { filterState, formatSequenceStateForSave, getMapZoomUrl } from '@/js/videos/utils';
import { Background, Message, Transition, Visual } from 'cte-video-studio';
import i18n from '@/libs/i18n';
import {
    AUDIO_ELEMENT_ID,
    CARD_CLIPBOARD_TYPE,
    FOOTER_ELEMENT_ID,
    PANEL_ELEMENT_ID,
    SEQUENCE_CLIPBOARD_TYPE,
    SEQUENCE_ELEMENT_ID,
    TRANSITION_ELEMENT_ID,
    TTS_ELEMENT_ID
} from '@/js/constants';
import orderedList from '../orderedList';
import timeline from '../timeline';
import transition, { resolveTransitionCardData } from './transition';
import background, { resolveBackgroundCardData } from './background';
import panel from './panel';
import visual, { resolveVisualCardData } from './visual';
import message, { resolveMessageCardData } from './message';
import audio from './audio';
import options from './options';
import tts from '../commons/tts';
import newCaptions from '@/js/store/modules/commons/newCaptions';

const resolveSequenceData = (data, findLibraryItem) => {
    let resolvedData = resolveSequenceCardData(data, findLibraryItem);
    resolvedData.transition = resolveTransitionCardData(resolvedData.transition, findLibraryItem);
    resolvedData.panel = resolveTransitionCardData(resolvedData.panel, findLibraryItem);
    resolvedData.messages = resolvedData.messages.map((message) => resolveMessageCardData(message, findLibraryItem));
    resolvedData.visuals = resolvedData.visuals.map((visual) => resolveVisualCardData(visual, findLibraryItem));

    return resolvedData;
};

const resolveSequenceCardData = (data, findLibraryItem) => {
    let resolvedData = _merge({}, data);
    resolvedData.background = resolveBackgroundCardData(resolvedData.background, findLibraryItem);

    return resolvedData;
};

const getMatchingVisualIds = (templateVisuals, visuals) => {
    let matchingVisualIds = templateVisuals.reduce((matching, templateVisual, index) => {
        matching[index] = visuals.find(
            (visual) =>
                visual.animation.category == templateVisual.animation.category && matching.indexOf(visual.id) == -1
        )?.id;
        return matching;
    }, []);

    return matchingVisualIds.reduce((matching, visualId, index) => {
        matching[index] = visualId || visuals.find((visual) => matching.indexOf(visual.id) == -1)?.id;
        return matching;
    }, matchingVisualIds);
};

// Initial state
const state = () => ({
    ...orderedList.state(),
    ...timeline.state(),

    name: '',
    el: null,
    convertDistributedAlign: false,
    timeline: () => gsap.timeline({ id: Timeline.SEQUENCE_TIMELINE_ID })
});

// Getters
const getters = {
    ...orderedList.getters,

    index: (state, getters, rootState, rootGetters) => rootGetters['sequences/indexOf'](state.id),

    isFirst: (state, getters) => getters.index == 0,

    isFirstVisible: (state, getters) => !state.options.hidden && !getters.previousVisible,

    isLast: (state, getters, rootState, rootGetters) => getters.index == rootGetters['sequences/total'] - 1,

    isLastVisible: (state, getters) => !state.options.hidden && !getters.nextVisible,

    previous: (state, getters, rootState, rootGetters) =>
        !getters.isFirst ? rootGetters['sequences/childAt'](getters.index - 1) : null,

    previousVisible: (state, getters, rootState, rootGetters) => {
        if (!getters.previous) return null;
        if (!getters.previous.options.hidden) return getters.previous;
        return rootGetters['sequences/' + getters.previous.id + '/previousVisible'];
    },

    previousWithBackground: (state, getters, rootState, rootGetters) => {
        if (!getters.previousVisible) return null;
        if (!rootGetters['sequences/' + getters.previousVisible.id + '/hasPreviousBackground'])
            return getters.previousVisible;
        return rootGetters['sequences/' + getters.previousVisible.id + '/previousWithBackground'];
    },

    next: (state, getters, rootState, rootGetters) =>
        !getters.isLast ? rootGetters['sequences/childAt'](getters.index + 1) : null,

    nextVisible: (state, getters, rootState, rootGetters) => {
        if (!getters.next) return null;
        if (!getters.next.options.hidden) return getters.next;
        return rootGetters['sequences/' + getters.next.id + '/nextVisible'];
    },

    backgroundGroup: (state, getters, rootState, rootGetters) => {
        let group = [],
            current = (getters.hasPreviousBackground && getters.previousWithBackground) || state;

        do {
            group.push(current.id);
            current = rootGetters['sequences/' + current.id + '/nextVisible'];
        } while (current && rootGetters['sequences/' + current.id + '/hasPreviousBackground']);

        return group;
    },

    visuals: (state, getters) => getters.all.filter((item) => Object.hasOwn(item, 'video')),

    visualIds: (state, getters) => getters.visuals.map((visual) => visual.id),

    messages: (state, getters) => getters.all.filter((item) => Object.hasOwn(item, 'text')),

    hasDistributedAsset: (state, getters) =>
        !!getters.all.filter(
            (item) => types.isDistributedAlign(item.position.alignH) || types.isDistributedAlign(item.position.alignV)
        ).length,

    hasHorDistributedAsset: (state, getters) =>
        !!getters.all.filter((item) => types.isDistributedAlign(item.position.alignH)).length,

    hasVerDistributedAsset: (state, getters) =>
        !!getters.all.filter((item) => types.isDistributedAlign(item.position.alignV)).length,

    resolvedId: (state, getters) => {
        return !getters.hasPreviousBackground || !getters.previousWithBackground
            ? state.id
            : getters.previousWithBackground.id;
    },

    previewSrc: (state, getters, rootState, rootGetters) => {
        switch (state.background.type) {
            case Background.IMAGE_TYPE:
            case Background.CARTOON_TYPE:
                return rootGetters['ui/mediaThumbnailUrl'](state.background.image.src__id);
            case Background.COLLAGE_TYPE:
                return rootGetters['ui/mediaThumbnailUrl'](
                    state.background.collage.images.image1__id ||
                        state.background.collage.images.image2__id ||
                        state.background.collage.images.image3__id ||
                        state.background.collage.images.image4__id
                );
            case Background.VIDEO_TYPE:
            case Background.ANIMATED_TYPE:
                return rootGetters['ui/mediaThumbnailUrl'](state.background.video.src__id);
            case Background.MAP_ZOOM_TYPE:
                return !!state.background.mapZoom.coords && getMapZoomUrl(state.background.mapZoom.coords, 3, false);
            case Background.PREVIOUS_TYPE:
                return (
                    !!getters.previousWithBackground &&
                    rootGetters['sequences/' + getters.previousWithBackground.id + '/previewSrc']
                );
        }

        return false;
    },

    hasMessage: (state, getters) => !!getters.messages.length,

    hasVisual: (state, getters) => !!getters.visuals.length,

    hasPanel: (state) => state.panel.enabled,

    hasAudio: (state, getters) => getters.hasAudioDirtyState || state.audio.cardShown,

    hasTransition: (state) => state.transition.type != Transition.DEFAULT || state.transition.cardShown,

    hasFooter: (state) => state.options.footer.text !== '' || state.options.footer.cardShown,

    hasTts: (state) =>
        state.tts.cardShown || !!state.tts.voice.text || !!state.tts.audio.src || !!state.tts.recording.src
};

// Mutations
const mutations = {
    ...orderedList.mutations,

    ...timeline.mutations,

    setName(state, name) {
        state.name = name;
    },

    setEl(state, el) {
        state.el = el;
    },

    disableDistributedAlign(state, value) {
        state.convertDistributedAlign = value;
    },

    resetPanelState(state) {
        state.panel = panel.state();
    },

    resetAudioState(state) {
        state.audio = audio.state();
        state.audio.newCaptions = audio.modules.newCaptions.state();
    },

    resetTransitionState(state) {
        state.transition = transition.state();
    },

    resetFooterState(state) {
        state.options.footer = options.state().footer;
    },

    resetTtsState(state) {
        state.tts = tts.state();
        state.tts.voice = tts.modules.voice.state();
        state.tts.audio = tts.modules.audio.state();
        state.tts.recording = tts.modules.recording.state();
        state.tts.voice.newCaptions = newCaptions.state();
        state.tts.audio.newCaptions = newCaptions.state();
        state.tts.recording.newCaptions = newCaptions.state();
    }
};

// Actions
const actions = {
    ...orderedList.actions,

    init({ commit, dispatch }, data) {
        commit('setName', data.name);
        dispatch('initTransition', data.transition);
        dispatch('initBackground', data.background);
        dispatch('initPanel', data.panel);
        dispatch('initAudio', data.audio);
        if (data.tts) dispatch('tts/init', data.tts);
        dispatch('initOptions', data.options);
        data.visuals.forEach((visual) => {
            dispatch('addVisual', { item: visual });
        });
        data.messages.forEach((message) => {
            dispatch('addMessage', { item: message });
        });
    },

    addEmptyVisual({ dispatch, getters }, data) {
        // data: { id, index }
        let v = visual,
            index =
                (data && data.index) ||
                (!!getters.messages.length ? getters.indexOf(getters.messages[0].id) : getters.total);
        dispatch('addItem', {
            module: true,
            id: (data && data.id) || randomID(Visual.PREFIX_ID),
            item: v,
            index
        });
    },

    addVisual({ dispatch, getters }, data) {
        // data: { id, item, index }
        let id = data.id || randomID(Visual.PREFIX_ID),
            index = data.index || getters.total;
        dispatch('addEmptyVisual', { id, index });
        dispatch(id + '/init', data.item);
    },

    addMessage({ dispatch, getters }, data) {
        // data: { id, item, index }
        let id = data.id || randomID(Message.PREFIX_ID),
            index = data.index || getters.total;
        dispatch('addEmptyMessage', { id, index });
        dispatch(id + '/init', data.item);
    },

    removeElement({ commit, state }, elementId) {
        let element = state[elementId];
        if (element) {
            commit('remove', {
                module: true,
                path: element.path
            });
        }
    },

    moveElement({ commit }, data) {
        // data: { id, index }
        commit('move', data);
    },

    removeSelf({ commit, state }) {
        commit(
            'sequences/remove',
            {
                module: true,
                path: state.path
            },
            { root: true }
        );
    },

    moveSelf({ commit, state }, index) {
        // data: { id, index }
        commit('sequences/move', { id: state.id, index }, { root: true });
    },

    initDefaults({ dispatch }) {
        dispatch('initTransitionDefaults');
    },

    duplicateSelf({ dispatch, state, rootState, getters }) {
        let seqData = _merge({}, state, {
            id: getPrefixedUuid(rootState.ui.prefixes.sequenceId),
            visuals: [...getters.visuals],
            messages: [...getters.messages]
        });

        dispatch('sequences/addSequence', { item: seqData, index: getters.index + 1 }, { root: true });
    },

    applyTemplate({ dispatch, state, getters, rootState }, { templateData, originalState }) {
        state.order.forEach((elementId) => {
            if (!originalState[elementId]) {
                this.unregisterModule(state[elementId].path);
            }
        });
        rootState.sequences[state.id] = _mergeWith(
            _merge({}, state),
            filterState(originalState),
            (objValue, srcValue, key, obj, src) => {
                if (key == 'el') return objValue;
                if (key == 'captions') return srcValue;
                if (key == 'order') return srcValue;
                if (RegExp('^(?:' + Visual.PREFIX_ID + '|' + Message.PREFIX_ID + ')').test(key)) {
                    if (!src[key]) return null;
                }
            }
        );

        if (!!templateData) {
            dispatch('applyTemplateTransition', templateData.transition);
            dispatch('applyTemplateBackground', templateData.background);
            dispatch('applyTemplatePanel', templateData.panel);
            dispatch('applyTemplateOptions', templateData.options);

            // Find matching visual types between the template and the original state
            getMatchingVisualIds(templateData.visuals, getters.visuals).forEach((visualId, index) => {
                if (!visualId) {
                    // If no match has been made for a template visual, we create a new one
                    let id = Visual.PREFIX_ID + uuidv4();
                    dispatch('addEmptyVisual', { id });
                    dispatch(id + '/init', templateData.visuals[index]);
                } else {
                    // If there is a matching visual, we apply the template to it
                    dispatch(visualId + '/applyTemplateVisual', templateData.visuals[index]);
                }
            });

            templateData.messages.forEach((message, index) => {
                if (getters.messages.length <= index) {
                    // If the original state doesn't have as much messages as the template, we create it
                    let id = Message.PREFIX_ID + uuidv4();
                    dispatch('addEmptyMessage', { id });
                    dispatch(id + '/init', message);
                } else {
                    // If there is a matching message, we apply the template to it
                    dispatch(getters.messages[index].id + '/applyTemplateMessage', message);
                }
            });
        }
    },

    copyToClipboard({ dispatch, state }) {
        dispatch(
            'ui/clipboard/copy',
            {
                type: SEQUENCE_CLIPBOARD_TYPE,
                data: formatSequenceStateForSave(filterState(state))
            },
            { root: true }
        );
    },

    pasteAfter({ dispatch, rootState, getters, rootGetters }) {
        if (rootGetters['ui/clipboard/hasPastableDataOfType'](SEQUENCE_CLIPBOARD_TYPE)) {
            let data =
                rootState.ui.clipboard.brandId == rootState.branding.id
                    ? rootState.ui.clipboard.data
                    : resolveSequenceData(
                          rootState.ui.clipboard.data,
                          rootGetters['branding/libraries/findLibraryItem']
                      );

            dispatch(
                'sequences/addSequence',
                {
                    id: getPrefixedUuid(rootState.ui.prefixes.sequenceId),
                    item: data,
                    index: getters.index + 1
                },
                { root: true }
            );
        }
    },

    copyCardToClipboard({ dispatch, state }, cardId) {
        let includeKeys = [],
            excludeKeys = [];

        switch (cardId) {
            case SEQUENCE_ELEMENT_ID:
                includeKeys = [
                    'background',
                    'options',
                    'options.duration',
                    'options.logo',
                    'audio',
                    'audio.volume',
                    'audio.fade'
                ];
                excludeKeys = ['background.video.captioning'];
                break;
            case PANEL_ELEMENT_ID:
                includeKeys = ['panel'];
                break;
            case AUDIO_ELEMENT_ID:
                includeKeys = ['audio', 'audio.track'];
                excludeKeys = ['audio.track.captioning'];
                break;
            case TRANSITION_ELEMENT_ID:
                includeKeys = ['transition'];
                break;
            case FOOTER_ELEMENT_ID:
                includeKeys = ['options', 'options.footer'];
                break;
            case TTS_ELEMENT_ID:
                includeKeys = ['tts'];
                break;
        }

        dispatch(
            'ui/clipboard/copy',
            {
                type: CARD_CLIPBOARD_TYPE + cardId,
                data: filterState(state, includeKeys, excludeKeys)
            },
            { root: true }
        );
    },

    pasteCard({ commit, dispatch, rootState, rootGetters }, cardId) {
        if (rootGetters['ui/clipboard/hasPastableDataOfType'](CARD_CLIPBOARD_TYPE + cardId)) {
            let data = rootState.ui.clipboard.data;

            switch (cardId) {
                case SEQUENCE_ELEMENT_ID:
                    if (rootState.ui.clipboard.brandId != rootState.branding.id) {
                        data = resolveSequenceCardData(data, rootGetters['branding/libraries/findLibraryItem']);
                    }

                    dispatch('initBackground', data.background);
                    commit('setDuration', data.options.duration);
                    commit('enableLogo', data.options.logo.enabled);
                    commit('setMainVolume', data.audio.volume);
                    commit('setMainVolumeFade', data.audio.fade);
                    break;
                case PANEL_ELEMENT_ID:
                    dispatch('pastePanelCard', data.panel);
                    break;
                case AUDIO_ELEMENT_ID:
                    dispatch('pasteAudioCard', data.audio.track);
                    break;
                case TRANSITION_ELEMENT_ID:
                    dispatch('pasteTransitionCard', data.transition);
                    break;
                case FOOTER_ELEMENT_ID:
                    dispatch('pasteFooterCard', data.options.footer);
                    break;
                case TTS_ELEMENT_ID:
                    dispatch('tts/pasteCard', data.tts);
                    break;
            }
        }
    },

    addEmptyMessage({ getters, dispatch }, data) {
        let m = message;
        dispatch('addItem', {
            module: true,
            id: (data && data.id) || randomID(Message.PREFIX_ID),
            item: m,
            index: data && data.index
        });

        let index = (data && data.index) || getters.total - 1;
        dispatch(getters.childAt(index).id + '/initDefaults');
    },

    addNewMessage({ state, commit, dispatch, getters }, data) {
        data = data ?? {};
        data.index = data.index || getters.total;
        dispatch('addEmptyMessage', data);
        commit(getters.childAt(data.index).id + '/setText', i18n.global.t('New message'));
    },

    duplicateMessage({ dispatch, rootState, state }, data) {
        // data: { newId, modelId }
        // update message ID and media references if necessary
        const updatedData = { id: data.newId, images: {} };

        for (const imageIndex of [1, 2, 3, 4, 5]) {
            const keyName = `image${imageIndex}__ref`;
            if (state[data.modelId].images[keyName])
                updatedData.images[keyName] = getPrefixedUuid(rootState.ui.prefixes.mediaReference);
        }

        const messageData = _merge({}, state[data.modelId], updatedData);

        dispatch('addNewMessage', { id: data.newId });
        dispatch(messageData.id + '/init', messageData);
    },

    duplicateVisual({ dispatch, rootState, state }, data) {
        // data: { newId, modelId }
        // update visual ID and media references if necessary
        const updatedData = { id: data.newId, collage: { images: {} } };
        if (state[data.modelId].image?.src__ref)
            updatedData.image = { src__ref: getPrefixedUuid(rootState.ui.prefixes.mediaReference) };
        if (state[data.modelId].video?.src__ref)
            updatedData.video = { src__ref: getPrefixedUuid(rootState.ui.prefixes.mediaReference) };

        for (const imageIndex of [1, 2, 3, 4]) {
            const keyName = `image${imageIndex}__ref`;
            if (state[data.modelId].collage?.images[keyName])
                updatedData.collage.images[keyName] = getPrefixedUuid(rootState.ui.prefixes.mediaReference);
        }

        const visualData = _merge({}, state[data.modelId], updatedData);

        dispatch('addEmptyVisual', { id: visualData.id });
        dispatch(visualData.id + '/init', visualData);
    }
};

export { visual as visualStoreConfig };
export { message as messageStoreConfig };

export default {
    namespaced: true,

    modules: {
        transition: transition,
        background: background,
        panel: panel,
        audio: audio,
        tts: tts,
        options: options
    },

    state,
    getters: getters,
    mutations: mutations,
    actions: actions
};
