<template>
    <div class="captions-text-container" :style="textStyle">
        <font ref="fontRef" v-if="font" :font-name="font" />
        <slot />
    </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue';
import { Captions } from '@/js/videos/types/captions.ts';
import { Palette } from '@/js/videos/types/palette.ts';
import Font from '@/js/video-studio/components/Font.vue';
import { Color } from '@/js/video-studio/constants';

const props = defineProps<{
    captions: Captions;
    palette: Palette;
    font: string;
}>();

const fontRef = ref<Font | null>(null);

const textStyle = computed(() => {
    return {
        lineHeight: props.captions.config.interline,
        textAlign: props.captions.config.alignment,
        fontWeight: props.captions.config.bold ? 'bold' : 'normal',
        fontStyle: props.captions.config.italic ? 'italic' : 'normal',
        textTransform: props.captions.config.uppercase ? 'uppercase' : 'none',
        fontSize: (props.captions.fontSize * 100) / 48 + '%',
        fontFamily: fontRef.value?.fontFamily,
        color: props.captions.config.color,
        textShadow: textShadow.value,
        '--animation-color1': props.palette.color1 || Color.WHITE,
        '--animation-color2': props.palette.color2 || Color.WHITE,
        '--animation-color3': props.palette.color3 || Color.WHITE
    };
});

const rgbaShadowColor = computed(() => {
    let color = props.captions.config.textShadow.color.value;
    let opacity = props.captions.config.textShadow.color.alpha;

    let regex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
    let rgb = regex
        ? {
              r: parseInt(regex[1], 16),
              g: parseInt(regex[2], 16),
              b: parseInt(regex[3], 16)
          }
        : {
              r: 0,
              g: 0,
              b: 0
          };

    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${opacity})`;
});

const textShadow = computed(() => {
    const distance = props.captions.config.textShadow.distance / 30;
    const radians = (Math.PI / 180) * props.captions.config.textShadow.angle;
    const x = distance * Math.cos(radians);
    const y = distance * Math.sin(radians);

    let value = `${x}em ${y}em ${props.captions.config.textShadow.blur}px ${rgbaShadowColor.value}`;

    return `${value}, ${value}`;
});
</script>
