// Utilities

import _isArray from 'lodash/isArray';
import _isPlainObject from 'lodash/isPlainObject';
import _merge from 'lodash/merge';
import _omitBy from 'lodash/omitBy';
import _transform from 'lodash/transform';
import { Background, Message, Visual } from 'cte-video-studio';

const getMapZoomUrl = (coords, zoomLevel, roadmap) => {
    let url = Background.MAP_ZOOM_URL;
    url = url.replace(Background.MAP_ZOOM_COORDS_PLACEHOLDER, coords);
    url = url.replace(Background.MAP_ZOOM_LEVEL_PLACEHOLDER, Background.MAP_ZOOM_LEVELS[zoomLevel || 0]);
    url = url.replace(
        Background.MAP_ZOOM_TYPE_PLACEHOLDER,
        (roadmap && Background.MAP_ZOOM_ROADMAP_TYPE) || Background.MAP_ZOOM_SATELLITE_TYPE
    );
    url = url.replace(Background.MAP_ZOOM_API_KEY_PLACEHOLDER, window.STUDIO_MAP_API_KEY || '');
    return url;
};

const filterState = (state, onlyKeys, excludeKeys) => {
    onlyKeys = _isArray(onlyKeys) ? onlyKeys : [];
    excludeKeys = (_isArray(excludeKeys) ? excludeKeys : []).concat(['timeline', 'timelineOut', 'needsRefresh', 'el']);

    return _transform(_merge({}, state), (filtered, value, key, object) => {
        if ((!onlyKeys.length || onlyKeys.indexOf(key) != -1) && excludeKeys.indexOf(key) == -1) {
            let only = (key == 'display' && ['format']) || (key == 'ui' && ['currentEditedItemId', 'files']) || [];
            only = only.concat(
                onlyKeys.reduce((filteredOnly, onlyKey) => {
                    let matches = onlyKey.match(/^([^\.]+)\.(.+)$/);
                    if (matches && (matches[1] == key || matches[1] == '*')) filteredOnly.push(matches[2]);
                    return filteredOnly;
                }, [])
            );

            let exclude = (key == 'settings' && ['ttsService']) || [];
            exclude = exclude.concat(
                excludeKeys.reduce((filteredExclude, excludeKey) => {
                    let matches = excludeKey.match(/^([^\.]+)\.(.+)$/);
                    if (matches && (matches[1] == key || matches[1] == '*')) filteredExclude.push(matches[2]);
                    return filteredExclude;
                }, [])
            );

            filtered[key] = _isPlainObject(value) ? filterState(value, only, exclude) : value;
        }
    });
};

const filterSequenceStateForCache = (state) => {
    let onlyKeys = [
        'timeline',
        'timelineOut',
        'needsRefresh',
        'el',
        'audio',
        'track',
        'background',
        'video',
        'panel',
        'transition'
    ];

    return _transform(_merge({}, state), (filtered, value, key, object) => {
        if (
            onlyKeys.indexOf(key) != -1 ||
            RegExp('^(?:' + Visual.PREFIX_ID + '|' + Message.PREFIX_ID + ')').test(key)
        ) {
            filtered[key] = _isPlainObject(value) ? filterSequenceStateForCache(value) : value;
        }
    });
};

const formatStateForSave = (state) => {
    let sequences = state.sequences.order.map((seqId) => formatSequenceStateForSave(state.sequences[seqId]));

    return _merge({}, state, { sequences });
};

const formatSequenceStateForSave = (seqState) => {
    let sequence = _omitBy(
        seqState,
        (value, key) =>
            RegExp('^(?:' + Visual.PREFIX_ID + '|' + Message.PREFIX_ID + ')').test(key) ||
            ['path', 'order', 'convertDistributedAlign'].indexOf(key) != -1
    );

    let visuals = seqState.order
        .filter((value) => RegExp('^' + Visual.PREFIX_ID).test(value))
        .map((visualId) => _omitBy(seqState[visualId], (value, key) => ['id', 'path'].indexOf(key) != -1));

    let messages = seqState.order
        .filter((value) => RegExp('^' + Message.PREFIX_ID).test(value))
        .map((messageId) => _omitBy(seqState[messageId], (value, key) => ['id', 'path'].indexOf(key) != -1));

    return _merge(sequence, { visuals, messages });
};

export { getMapZoomUrl, filterState, filterSequenceStateForCache, formatStateForSave, formatSequenceStateForSave };
