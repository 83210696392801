<!--
  -- Component name: Folding10
  -- Type: Transition
  -- Uses: 
  -- Tags: folding
  -->
<template>
    <div
        class="studio-sequence-transition studio-container transition-folding10"
        :class="classes"
        :id="this.containerID"
    >
        <AssetSpritesheet
            ref="$transitionSpritesheet"
            name="$transitionSpritesheet"
            :src="spritesheetURL"
            width="100%"
            height="100%"
            :cover="true"
            grid="4x6"
            :frames="21"
            :fps="20"
            @update="update($event)"
        />
    </div>
</template>

<style>
.transition-folding10 canvas.sprite {
    object-position: bottom;
}
</style>

<script>
import TransitionMixin from '../../mixins/Transition';
import AssetSpritesheet from '../assets/AssetSpritesheet.vue';
import folding10 from '@resources/assets/transitions/Folding10.png';
import folding10916 from '@resources/assets/transitions/Folding10-916.png';
import gsap from 'gsap';

export default {
    components: {
        AssetSpritesheet
    },

    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            needsRefresh: true, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true, // The transition component is placed above the in and out elements
            // swapElements: false, // The in and out parent elements are swapped to have the out elements above the in elements
            inElementsAutoAppear: false, // The in elements are automatically added to the timeline and appear from the start
            spritesheetURL: folding10
        };
    },

    computed: {},

    watch: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            let t = gsap.timeline();
            this._timeline = t;
            if (this.$refs.$transitionSpritesheet.$el.clientHeight >= this.$refs.$transitionSpritesheet.$el.clientWidth)
                this.spritesheetURL = folding10916;
            t.add(this.$refs.$transitionSpritesheet.timeline());
            t.set([this.backgroundInElement, this.sequenceInElement], { autoAlpha: 1 }, 0.5);
            t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 }, 0.5);
            return t;
        }
    }
};
</script>
