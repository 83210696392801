<template>
    <ui-video-card :id="cardElementId" :card-name="$t('Audio file')" classes="ui-video-card-audio" :selectable="false">
        <template #selector>
            <ui-media-selector
                ref="mediaSelector"
                :id="cardElementId + '-track'"
                key="media-selector-sequence-track"
                v-model:media="track"
                :type="mediaTypeAudio"
                :disabled="readOnly"
                icon="fa-regular fa-volume"
                :show-label="false"
                @[mediaSelectorShowEvent]="openMediaLibrary"
                @[mediaSelectorChangeEvent]="resetCaptions"
            />
        </template>

        <template #content>&nbsp;</template>

        <template #actions-menu-content>
            <ui-card-sequence-menu-content :id="id" :seq-id="seqId" :remove-actions="removeCard" />
        </template>

        <template #actions-tools>
            <ui-audio-tools :seq-id="seqId" :card-element-id="cardElementId" />
        </template>
    </ui-video-card>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';

import UiVideoCard from '@/js/videos/components/ui/body/UiVideoCard.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import UiMediaSelector, {
    UI_MEDIA_SELECTOR_CHANGE,
    UI_MEDIA_SELECTOR_SHOW,
    UI_MEDIA_TYPE_AUDIO
} from '@/js/components/UiMediaSelector.vue';
import { getPrefixedUuid } from '@/js/utils.js';
import { useSequence } from '@/js/videos/composables/useSequence.js';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import { AUDIO_ELEMENT_ID } from '@/js/constants/index.js';
import UiCardSequenceMenuContent from '@/js/videos/components/ui/body/cards/menus/UiCardSequenceMenuContent.vue';
import UiAudioTools from '@/js/videos/components/ui/body/cards/tools/UiAudioTools.vue';
import { useCaptions } from '@/js/videos/composables/caption/useCaptions.ts';

const props = defineProps({
    id: {
        type: String,
        default: AUDIO_ELEMENT_ID
    },
    seqId: String,
    addedCardId: {
        type: String,
        default: ''
    }
});

const store = useStore();
const { saveHistoryStep } = useHistory();
const { readOnly, openMediaLibrary } = useVideo();
const { sequenceState, sequenceStoreModulePath } = useSequence(props.seqId);
const { resetCaptions } = useCaptions(`sequences/${props.seqId}/audio`, 'audio', `sequences/${props.seqId}`);

const mediaTypeAudio = UI_MEDIA_TYPE_AUDIO;
const mediaSelectorShowEvent = UI_MEDIA_SELECTOR_SHOW;
const mediaSelectorChangeEvent = UI_MEDIA_SELECTOR_CHANGE;

const cardElementId = computed(() => `${props.seqId}-${props.id}`);
const prefixes = computed(() => store.state.ui.prefixes);
const mediaSrc = computed(() => store.getters['ui/quickcut/getMediaSrc']);

const track = computed({
    get: () => ({ src: sequenceState.value.audio.track.src, id: sequenceState.value.audio.track.src__id }),
    set: ({ src, id }) => {
        // reset timerange segments and timerange if the video source is changed for UiQuickCut
        if (mediaSrc.value !== src) {
            store.commit(`${sequenceStoreModulePath.value}/setTrackTimerangeSegments`, []);
            store.commit(`${sequenceStoreModulePath.value}/setTrackTimerange`, { start: 0, end: 0 });
        }

        saveHistoryStep(() => {
            if (!sequenceState.value.audio.track.src__ref) {
                store.commit(
                    `${sequenceStoreModulePath.value}/setTrackReference`,
                    getPrefixedUuid(prefixes.value.mediaReference)
                );
            }
            store.dispatch(`${sequenceStoreModulePath.value}/updateTrack`, { src, src__id: id, improved: false });
        });
    }
});

const removeCard = () => store.dispatch(`${sequenceStoreModulePath.value}/showAudioCard`, false);

const mediaSelector = ref(null);
watch(
    () => props.addedCardId,
    (newValue) => {
        if (newValue === cardElementId.value) openMediaLibrary(mediaSelector.value);
    }
);
onMounted(() => {
    if (cardElementId.value === props.addedCardId) openMediaLibrary(mediaSelector.value);
});
</script>
