<template>
    <div :class="templateListClass" ref="listContainer">
        <template v-if="showLoader">
            <SequenceTemplatesListItem
                v-for="i in 1"
                :key="i"
                :loading="loading"
                :sequence-template="placeholderTemplate"
                class="home-view-template-list-item"
                type="template"
                :show-meta="true"
                :selected-format="selectedTemplateFormat"
            />
        </template>
        <SequenceTemplatesListItem
            v-else
            v-for="sequenceTemplate in sequenceTemplates"
            :key="sequenceTemplate.id"
            :sequence-template="sequenceTemplate"
            class="home-view-template-list-item"
            :class="{
                usable: true
            }"
            type="template"
            :show-meta="true"
            :selected-format="selectedTemplateFormat"
            @click="selectTemplate(sequenceTemplate)"
        />
    </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { gsap } from 'gsap';
import SequenceTemplatesListItem from './SequenceTemplatesListItem.vue?inline';
import { SequenceTemplate } from '@/js/types/SequenceTemplate';
import { PREDEFINED_FORMATS } from '@/js/types/Format';

const props = defineProps<{
    loading: boolean;
    sequenceTemplates: SequenceTemplate[];
    selectedTemplateFormat: keyof typeof PREDEFINED_FORMATS;
}>();

const emit = defineEmits<{
    (e: 'select-sequence-template', template: SequenceTemplate): void;
}>();

const listContainer = ref<HTMLElement | null>(null);

const showLoader = computed(() => props.loading && !props.sequenceTemplates.length);

const templateListClass = computed(() => `home-view-templates-list ${props.selectedTemplateFormat}`);

const placeHolderFormat = computed(() => {
    const format = props.selectedTemplateFormat.toLowerCase();
    switch (format) {
        case 'landscape':
            return { width: 16, height: 9 };
        case 'portrait':
            return { width: 9, height: 16 };
        case 'square':
            return { width: 1, height: 1 };
        default:
            return { width: 16, height: 9 };
    }
});

const placeholderTemplate = computed(() => {
    return {
        id: 'placeholder',
        name: '',
        themes: [],
        format: placeHolderFormat.value,
        thumbnails: {
            static_src: '',
            video_src: ''
        },
        compatible_formats: [],
        created_at: '',
        updated_at: '',
        is_produced: false
    } as unknown as SequenceTemplate;
});

// Keep track of the last animation timestamp to prevent too frequent animations
let lastAnimationTime = 0;
const ANIMATION_THROTTLE = 800; // ms

const animateItems = (items: SequenceTemplate[]) => {
    if (!items.length || !listContainer.value) return;

    const now = Date.now();
    if (now - lastAnimationTime < ANIMATION_THROTTLE) return;

    lastAnimationTime = now;

    nextTick(() => {
        const container = listContainer.value;
        if (!container) return;

        const itemElements = container.querySelectorAll('.home-view-template-list-item:not(.skeleton)');
        const elementsToAnimate = Array.from(itemElements).slice(-items.length);

        gsap.set(elementsToAnimate, { opacity: 0 });
        elementsToAnimate.forEach((item, index) => {
            gsap.to(item as HTMLElement, {
                opacity: 1,
                duration: 0.2,
                ease: 'power1.out',
                delay: index * 0.05 // Reduced delay to speed up animation
            });
        });
    });
};

let previousTemplates: SequenceTemplate[] = [];
let isInitialLoad = true;

watch(
    () => props.sequenceTemplates,
    (newTemplates) => {
        if (props.loading) return;

        if (isInitialLoad && newTemplates.length > 0) {
            // Initial load - animate all items
            isInitialLoad = false;
            animateItems(newTemplates);
        } else if (previousTemplates.length === 0 && newTemplates.length > 0) {
            // Switching tabs or filters - animate all items
            animateItems(newTemplates);
        } else if (newTemplates.length > previousTemplates.length) {
            // New items added (pagination) - animate only the new items
            const newItemsCount = newTemplates.length - previousTemplates.length;
            animateItems(newTemplates.slice(-newItemsCount));
        }

        previousTemplates = [...newTemplates];
    },
    { deep: true }
);

watch(
    () => props.loading,
    (isLoading) => {
        // Reset animation state when loading starts
        if (isLoading) {
            lastAnimationTime = 0;
        }
    }
);

const selectTemplate = (template: SequenceTemplate) => {
    if (!template.is_produced) {
        return;
    }

    emit('select-sequence-template', template);
};

onMounted(() => {
    if (props.sequenceTemplates.length > 0 && !props.loading) {
        nextTick(() => {
            animateItems(props.sequenceTemplates);
        });
    }
});
</script>

<style lang="scss" scoped>
.home-view-templates-list {
    display: grid;
    gap: 20px;
}

.home-view-template-list-item {
    transform-origin: center center;
    width: 100%;
    height: 100%;
}
</style>
