<template>
    <div class="segment-editor-container">
        <transition name="slide-up" mode="out-in">
            <div class="segment-editor" v-if="selectedSegment" :key="selectedSegmentIndex">
                <div class="time-code-input-container">
                    <QuickCutTimeCodeInput
                        v-model="startTime"
                        :rate="rate"
                        :label="$t('start-of-clip')"
                        :min="0"
                        :max="endTime - 0.01"
                        :step="0.1"
                        @update:modelValue="updateSegment('start')"
                    />
                    <QuickCutTimeCodeInput
                        v-model="endTime"
                        :rate="rate"
                        :label="$t('end-of-clip')"
                        :min="startTime + 0.01"
                        :max="props.mediaMaxDuration"
                        :step="0.1"
                        @update:modelValue="updateSegment('end')"
                    />
                    <div class="remove-container">
                        <button @click="removeSegment" :disabled="!canRemoveSegment" class="remove-item">
                            <fa-icon class="icon" icon="fa-regular fa-trash-can" />
                        </button>
                    </div>
                </div>
                <div class="time-media-container">
                    <fa-icon class="icon" :icon="['fas', 'circle-info']" />
                    <span>{{ $t('Total duration') }} : </span>
                    <span>{{ formatMediaDuration }}</span>
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup lang="ts">
import { computed, watch } from 'vue';
import QuickCutTimeCodeInput from './QuickCutTimeCodeInput.vue?inline';
// @ts-ignore
import { Duration } from '@/js/video-studio/constants';
import { formatTime } from '@/js/videos/utils/time';
import type { Segment } from '@/js/videos/types/segments';

interface Props {
    segments: Segment[];
    selectedSegmentIndex: number;
    rate: number;
    mediaDuration: number;
    mediaMaxDuration: number;
    playbackRate?: number;
}

const props = withDefaults(defineProps<Props>(), {
    playbackRate: Duration.PLAY_BACK_RATE_DEFAULT
});

// function to convert time to frames
function timeToFrames(time: number): number {
    return Math.round(time * props.rate);
}

// function to convert frames to time
function framesToTime(frames: number): number {
    return frames / props.rate;
}

// function to normalize time values in function of the framerate
function normalizeTimeValue(time: number): number {
    const frames = timeToFrames(time);
    return framesToTime(frames);
}

const emit = defineEmits<{
    (event: 'update-segment', index: number, segment: Segment): void;
    (event: 'remove-segment', index: number): void;
}>();

const selectedSegment = computed(() =>
    props.selectedSegmentIndex !== -1 ? props.segments[props.selectedSegmentIndex] : null
);

const startTime = computed({
    get: () => (selectedSegment.value ? normalizeTimeValue(selectedSegment.value.start) : 0),
    set: (value) => {
        if (selectedSegment.value) {
            const normalizedValue = normalizeTimeValue(value);
            if (Math.abs(normalizedValue - selectedSegment.value.start) >= 1 / props.rate) {
                updateSegment('start', normalizedValue);
            }
        }
    }
});

const endTime = computed({
    get: () => (selectedSegment.value ? normalizeTimeValue(selectedSegment.value.end) : 0),
    set: (value) => {
        if (selectedSegment.value) {
            const normalizedValue = normalizeTimeValue(value);
            if (Math.abs(normalizedValue - selectedSegment.value.end) >= 1 / props.rate) {
                updateSegment('end', normalizedValue);
            }
        }
    }
});

const canRemoveSegment = computed(() => {
    if (!props.segments.length) return false;
    const firstSegment = props.segments[0];
    return props.segments.length > 1 || firstSegment.start !== 0 || firstSegment.end !== props.mediaMaxDuration;
});

const formatMediaDuration = computed(() => {
    const time = props.mediaDuration * props.playbackRate;
    const frames = Math.round((time % 1) * props.rate);
    return formatTime(time, props.rate, frames);
});

watch(
    selectedSegment,
    (newSegment) => {
        if (newSegment) {
            startTime.value = newSegment.start;
            endTime.value = newSegment.end;
        }
    },
    { immediate: true, deep: true }
);

const updateSegment = (type: 'start' | 'end', value?: number) => {
    if (selectedSegment.value === null || props.selectedSegmentIndex === -1) return;

    const updatedSegment: Segment = { ...selectedSegment.value };
    const frameTime = 1 / props.rate;

    if (type === 'start') {
        const newStart = value !== undefined ? value : startTime.value;
        const normalizedStart = normalizeTimeValue(newStart);
        const normalizedEnd = normalizeTimeValue(endTime.value);

        updatedSegment.start = normalizedStart;
        updatedSegment.end = Math.max(normalizedEnd, normalizedStart + frameTime);
    } else {
        const newEnd = value !== undefined ? value : endTime.value;

        const maxFrames = Math.floor(props.mediaMaxDuration * props.rate);
        const endFramesExact = newEnd * props.rate;

        const endFrames = Math.floor(endFramesExact);

        const limitedFrames = Math.min(endFrames, maxFrames);

        const normalizedEnd = limitedFrames / props.rate;

        const normalizedStart = normalizeTimeValue(startTime.value);

        updatedSegment.end = normalizedEnd;
        updatedSegment.start = Math.min(normalizedStart, normalizedEnd - frameTime);
    }

    emit('update-segment', props.selectedSegmentIndex, updatedSegment);
};

const removeSegment = () => {
    if (selectedSegment.value) {
        emit('remove-segment', props.selectedSegmentIndex);
    }
};
</script>

<style scoped>
span {
    color: white;
}

.remove-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

button {
    display: flex;
    padding: 8px 9px;
    align-items: center;
    gap: 5px;
    font-size: rfs-value(14px);
    /*cte-light-dark*/
    background-color: #3a4452;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition:
        opacity 0.3s ease,
        background-color 0.3s ease;
    height: 34px;
}

button:hover {
    opacity: 0.8;
}

button:disabled {
    background-color: #2a3240;
    color: #ffffff;
    cursor: not-allowed;
    opacity: 0.6;
}

.time-code-input-container {
    display: flex;
    gap: 16px;
    align-items: center;
}

.time-media-container {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    justify-content: center;
    text-align: center;
}
.icon {
    width: 16px;
    height: 16px;
    color: white;
}
.segment-editor-container {
    display: flex;
    justify-content: center;
    height: 40px;
}

.segment-editor {
    display: flex;
    gap: 42px;
    margin-top: 10px;
    align-items: center;
}

.ui-content-row {
    width: 100%;
}

.slide-up-enter-active,
.slide-up-leave-active {
    transition: all 0.3s ease-out;
}

.slide-up-enter-from {
    opacity: 0;
    transform: translateY(20px);
}

.slide-up-leave-to {
    opacity: 0;
    transform: translateY(-20px);
}

.remove-item {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
}
</style>
