<!--
  -- Component name: SlideTopBottom
  -- Type: Transition
  -- Uses: color.start
  -- Tags: savoie
  -->
<template>
    <div class="studio-sequence-transition studio-container" :class="classes" :id="this.containerID">
        <svg class="studio-hidden-svg">
            <filter :id="assetVideoColorFilterID">
                <feFlood :flood-color="state.color.start" />
                <feComposite operator="in" in2="SourceAlpha" />
            </filter>
        </svg>
        <AssetVideo
            width="100%"
            height="100%"
            :cover="true"
            :volume="0"
            :src="assetSrc"
            ref="$transitionVideo"
            @update="update($event)"
            :seqId="this.id"
            :style="videoStyles"
        />
    </div>
</template>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';
import AssetVideo from '../assets/AssetVideo.vue';

export default {
    components: { AssetVideo },

    mixins: [TransitionMixin],

    data() {
        return {
            isAbove: true, // The transition component is placed above the in and out elements
            inElementsAutoAppear: false, // The in elements are automatically added to the timeline and appear from the start
        };
    },

    computed: {
        assetSrc() {
            const assetFilename =
                this.formatRatio > 1 ? 'TransitionSlideTopBottomLandscape' : this.formatRatio < 1 ? 'TransitionSlideTopBottomPortrait' : 'TransitionSlideTopBottomSquare';
            return new URL(`../../../../assets/transitions/${assetFilename}.webm`, import.meta.url).href;
        },
        videoStyles() {
            return {
                filter: `url(#${ this.assetVideoColorFilterID })`,
            };
        },
        assetVideoColorFilterID() {
            return this.id + '-colorFilter';
        },


    },

    methods: {
        getTimeline() {
            const t = gsap.timeline();
            this._timeline = t;
            t.add(this.$refs.$transitionVideo.timeline().play());
            t.set([this.backgroundInElement, this.sequenceInElement], { autoAlpha: 1 }, 0.6);
            return t;
        }
    }
};
</script>
