import { max } from 'lodash';
import { ref, computed } from 'vue';

export function useZoom(FRAME_WIDTH, videoEndTime, sliderContainer, canvasWidth, segments) {
    const MAX_CANVAS_WIDTH = 600000;
    const zoomLevelAdjusted = ref(false);
    const zoomLevel = ref(1);
    const minZoomLevel = ref(1);
    const maxZoomLevel = ref(1);
    const containerWidth = ref(0);
    const desiredSegmentWidthRatio = 0.5;

    const adjustZoomLevel = () => {
        if (!sliderContainer.value) return;

        containerWidth.value =
            (sliderContainer.value.clientWidth ||
                sliderContainer.value.offsetWidth ||
                (sliderContainer.value.parentNode && sliderContainer.value.parentNode.offsetWidth) ||
                0) - 32;

        const videoDuration = videoEndTime.value || 1;
        zoomLevel.value = containerWidth.value / (videoDuration * FRAME_WIDTH);
        minZoomLevel.value = zoomLevel.value;

        const MAX_ZOOM_LEVEL = MAX_CANVAS_WIDTH / (FRAME_WIDTH * videoEndTime.value);
        maxZoomLevel.value = Math.min(200 / FRAME_WIDTH, MAX_ZOOM_LEVEL);

        zoomLevelAdjusted.value = true;

        if (minZoomLevel.value < 1) {
            autoZoom();
        }
    };

    // Rest of the code remains the same
    const autoZoom = () => {
        if (isSingleSegmentCoveringWholeVideo()) return;

        const smallestSegmentDuration = getSegmentDuration();
        const finalZoom = calculateFinalZoom(smallestSegmentDuration);

        zoomLevel.value = finalZoom;
    };

    const isSingleSegmentCoveringWholeVideo = () =>
        segments.value.length === 1 && segments.value[0].end == videoEndTime.value;

    const getSegmentDuration = () => {
        const firstSegment = segments.value[0];
        const lastSegment = segments.value[segments.value.length - 1];
        return lastSegment.end - firstSegment.start;
    };

    const calculateFinalZoom = (smallestSegmentDuration) => {
        const desiredWidth = containerWidth.value * desiredSegmentWidthRatio;
        const requiredZoom = desiredWidth / (smallestSegmentDuration * FRAME_WIDTH);
        return Math.min(Math.max(requiredZoom, minZoomLevel.value), maxZoomLevel.value);
    };

    const handleZoom = (zoomFactor) => {
        const newZoomLevel = zoomLevel.value * zoomFactor;
        zoomLevel.value = Math.min(Math.max(newZoomLevel, minZoomLevel.value), maxZoomLevel.value);
    };

    const canZoomIn = computed(() => zoomLevel.value < maxZoomLevel.value);
    const canZoomOut = computed(() => zoomLevel.value > minZoomLevel.value);

    return {
        zoomLevel,
        canvasWidth,
        adjustZoomLevel,
        handleZoom,
        autoZoom,
        canZoomIn,
        canZoomOut
    };
}
