/**
 * Video Studio
 * Align constants
 */

const TEXT_LEFT = 'left';
const TEXT_CENTER = 'center';
const TEXT_RIGHT = 'right';
const TEXT_JUSTIFY = 'justify';

const Align = {
    TOP: 'T',
    RIGHT: 'R',
    BOTTOM: 'B',
    LEFT: 'L',
    CENTER: 'C',

    TOP_LEFT: 'TL',
    TOP_RIGHT: 'TR',
    BOTTOM_LEFT: 'BL',
    BOTTOM_RIGHT: 'BR',

    HALF_TOP: 'HT',
    HALF_RIGHT: 'HR',
    HALF_BOTTOM: 'HB',
    HALF_LEFT: 'HL',
    HALF_TOP_LEFT: 'HTL',
    HALF_TOP_RIGHT: 'HTR',
    HALF_BOTTOM_LEFT: 'HBL',
    HALF_BOTTOM_RIGHT: 'HBR',

    DISTRIBUTED_DEFAULT: 'DD',
    DISTRIBUTED_TOP: 'DT',
    DISTRIBUTED_RIGHT: 'DR',
    DISTRIBUTED_BOTTOM: 'DB',
    DISTRIBUTED_LEFT: 'DL',

    CUSTOM: 'CUSTOM',

    TEXT_LEFT: TEXT_LEFT,
    TEXT_CENTER: TEXT_CENTER,
    TEXT_RIGHT: TEXT_RIGHT,
    TEXT_JUSTIFY: TEXT_JUSTIFY,

    TEXT_ALIGNMENTS: [TEXT_LEFT, TEXT_CENTER, TEXT_RIGHT, TEXT_JUSTIFY],
    TEXT_ALIGNMENTS_WITH_JUSTIFY: [TEXT_LEFT, TEXT_CENTER, TEXT_RIGHT]
};

export default Align;
