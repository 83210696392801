<template>
    <ui-scrollable class="ui-body" ref="$scrollable" :margin-top="15" :margin-bottom="15">
        <ui-settings-panel v-if="isSettingsPanel" />
        <ui-sequence-panel
            v-else
            ref="$sequencePanel"
            :seq-id="currentEditedItemId"
            :edited-element="currentEditedElement"
            @[addedCardEvent]="scrollToAddedCard"
        />
    </ui-scrollable>
</template>

<script>
import { mapState } from 'vuex';
import { SETTINGS_ITEM_ID } from '../../../../constants';
import { ProvidesIcons } from '../../../../mixins';
import { UiScrollable } from '../../../../components';
import UiSettingsPanel from './UiSettingsPanel.vue';
import UiSequencePanel, { UI_SEQUENCE_ADDED_CARD } from './sequence-panel/UiSequencePanel.vue';
import { Visual } from '@/js/video-studio/constants/index.js';

export default {
    mixins: [ProvidesIcons],
    inject: ['$videoStudio'],

    components: {
        UiScrollable,
        UiSettingsPanel,
        UiSequencePanel
    },

    props: {
        //
    },

    data() {
        return {
            addedCardEvent: UI_SEQUENCE_ADDED_CARD
        };
    },

    computed: {
        ...mapState({
            currentEditedItemId: (state) => state.ui.currentEditedItemId,
            currentEditedElement: (state) => state.edition.editingElement
        }),

        isSettingsPanel() {
            return this.currentEditedItemId == SETTINGS_ITEM_ID;
        }
    },

    watch: {
        currentEditedItemId() {
            this.$refs.$scrollable.refresh(true);
            if (!!this.currentEditedElement) {
                this.$nextTick(this.scrollToEditedElementCard);
            }
        },

        currentEditedElement() {
            if (
                !!this.currentEditedElement &&
                (this.currentEditedElement.seqId == this.currentEditedItemId ||
                    this.currentEditedElement.id == this.currentEditedItemId)
            ) {
                this.scrollToEditedElementCard();
            }
        }
    },

    methods: {
        scrollToAddedCard(cardId, seqId, cardSelector) {
            this.$refs.$scrollable.scrollTo(cardSelector, true);
            /*this.$nextTick(() =>
                this.$videoStudio.studio.$stage.getSequenceElement(seqId).startEditingElement(null, cardId)
            );*/
        },

        scrollToEditedElementCard() {
            // panel has no ID so we must adapt the selector
            const isPanel = this.currentEditedElement.shapeId !== undefined;
            const isSequence = this.currentEditedElement.containerID !== undefined;

            let selector = '';
            if (isPanel) selector = `#${this.currentEditedElement.id}`;
            else if (isSequence) selector = `#${this.currentEditedElement.id}-sequence`;
            else selector = `#${this.currentEditedItemId}-${this.currentEditedElement.id}`;

            this.$refs.$scrollable.scrollTo(selector, true);
        },

        openCardAnimationLibrary() {
            const isVisual = this.currentEditedElement.id.startsWith(Visual.PREFIX_ID);
            console.log(isVisual, this.currentEditedElement.id);

            const card = isVisual
                ? this.$refs.$sequencePanel.$refs.cardSequenceVisual.find(
                      (ref) => ref.elementId === this.currentEditedElement.id
                  )
                : this.$refs.$sequencePanel.$refs.cardSequenceBackground;

            card?.openAnimationLibrary();
        }
    },

    mounted() {
        //
    }
};
</script>
