<!--
  -- Component name: SG2
  -- Type: Transition
  -- Uses: color.start,color.second
  -- Tags: sg
  -->
<template>
    <svg
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1280 720"
        preserveAspectRatio="xMidYMid slice"
    >
        <path id="first-color" class="color-move-1" :fill="state.color.start" d="M0 0h1280v720H0z" />
        <path id="second-color" class="color-move-2" :fill="state.color.second" d="M0 0h1280v720H0z" />

        <use xlink:href="#first-color" />
    </svg>
</template>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';

export default {
    mixins: [TransitionMixin],

    data() {
        return {
            isAbove: true
        };
    },

    computed: {},

    watch: {},

    methods: {
        getTimeline() {
            // Animation of the transition. Use backgroundInElement, sequenceInElement, backgroundOutElement, sequenceOutElement computed values to animate the in and out sequence elements
            const t = gsap.timeline();
            this._timeline = t;

            t.set([this.backgroundOutElement, this.sequenceOutElement], { autoAlpha: 0 }, 0.001);

            t.fromTo(
                '#' + this.containerID + ' .color-move-1',
                { yPercent: -100 },
                { yPercent: 100, duration: 0.8 },
                0.2
            );
            t.fromTo(
                '#' + this.containerID + ' .color-move-2',
                { yPercent: -100 },
                { yPercent: 100, duration: 0.8 },
                0.4
            );

            return t;
        }
    }
};
</script>
