<!--
  -- Component name: EDF1
  -- Type: Transition
  -- Uses: color.start,color.second,image,image.color
  -- Tags: edf
  -->
<template>
    <div
        v-if="landscapeFormat"
        class="studio-sequence-transition studio-container"
        :class="classes"
        :id="this.containerID"
    >
        <svg class="studio-container" viewBox="0 0 1920 1080" preserveAspectRatio="xMinYMin slice">
            <rect class="edf-rect1" x="0" y="0" width="100%" height="50%" :fill="state.color.second" />
            <rect class="edf-rect2" x="0" y="50%" width="100%" height="50%" :fill="state.color.second" />
        </svg>
        <div class="edf-image-rect edf-landscape-mode" :style="{ backgroundColor: state.color.start }">
            <AssetImage :src="state.image.src" :color="{ start: state.image.color }" width="75%" height="75%" />
        </div>
    </div>
    <div v-else class="studio-sequence-transition studio-container" :class="classes" :id="this.containerID">
        <svg class="studio-container">
            <rect class="edf-rect2" x="0%" y="71.875%" width="100%" height="28.125%" :fill="state.color.second" />
            <rect class="edf-rect1" x="0%" y="0" width="100%" height="71.95%" :fill="state.color.second" />
        </svg>
        <div
            class="edf-image-rect edf-portrait-mode"
            :class="{ 'edf-square-mode': squareFormat }"
            :style="{ backgroundColor: state.color.start }"
        >
            <AssetImage :src="state.image.src" :color="{ start: state.image.color }" width="75%" height="75%" />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.edf-image-rect {
    position: absolute;

    &.edf-landscape-mode {
        width: 30%;
        height: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &.edf-portrait-mode {
        width: 50%;
        padding-top: 50%;
        right: 0;
        bottom: 0;

        .image-container {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }

        &.edf-square-mode {
            width: 25%;
            padding-top: 28.125%;
        }
    }
}
</style>

<script>
import TransitionMixin from '../../mixins/Transition';
import gsap from 'gsap';
import AssetImage from '../assets/AssetImage.vue';

export default {
    components: { AssetImage },

    mixins: [TransitionMixin],

    data() {
        return {
            // Default values
            // needsRefresh: true, // The transition needs Chrome and Safari to force a re-rendering during the animation
            isAbove: true // The transition component is placed above the in and out elements
            // swapElements: true, // The in and out parent elements are swapped to have the out elements above the in elements
            // inElementsAutoAppear: true, // The in elements are automatically added to the timeline and appear from the start
        };
    },

    computed: {
        landscapeFormat() {
            return this.format.width > this.format.height;
        },

        squareFormat() {
            return this.format.width === this.format.height;
        }
    },

    watch: {},

    methods: {
        getTimeline() {
            let t = gsap.timeline();
            this._timeline = t;

            t.fromTo(
                '#' + this.containerID + ' .edf-image-rect',
                { scale: 1 },
                { scale: 0.9, transformOrigin: 'center', duration: 0.15 },
                0.2
            );
            t.to('#' + this.containerID + ' .edf-image-rect', { scale: 1, transformOrigin: 'center', duration: 0.15 });

            if (this.landscapeFormat) {
                t.fromTo(
                    '#' + this.containerID + ' .edf-image-rect .image-container',
                    { opacity: 1 },
                    { opacity: 0, duration: 0.5 },
                    0.5
                );
                t.fromTo(
                    '#' + this.containerID + ' .edf-image-rect',
                    { width: '30%', paddingLeft: '0%', left: '0%' },
                    { width: '60%', paddingLeft: '30%', left: '40%', duration: 0.5, ease: 'power1.in' },
                    0.5
                );
                t.to(
                    '#' + this.containerID + ' .edf-image-rect',
                    { width: '30%', left: '100%', paddingLeft: '0%', duration: 0.5, ease: 'power1.out' },
                    1
                );
            } else {
                t.fromTo(
                    '#' + this.containerID + ' .edf-image-rect',
                    { xPercent: 0 },
                    { xPercent: 100, duration: 0.6, ease: 'power1.inOut' },
                    0.7
                );
                t.fromTo(
                    '#' + this.containerID + ' .edf-image-rect .image-container',
                    { opacity: 1 },
                    { opacity: 0, duration: 0.4 },
                    0.7
                );
            }

            t.fromTo(
                '#' + this.containerID + ' .edf-rect1',
                { attr: { x: 0 } },
                { attr: { x: '50%' }, duration: 0.4, ease: 'power1.in' },
                0.7
            );
            t.to(
                '#' + this.containerID + ' .edf-rect1',
                { attr: { x: '100%' }, duration: 0.5, ease: 'power2.out' },
                1.1
            );

            t.fromTo(
                '#' + this.containerID + ' .edf-rect2',
                { attr: { x: 0 } },
                { attr: { x: '50%' }, duration: 0.4, ease: 'power1.in' },
                0.8
            );
            t.to(
                '#' + this.containerID + ' .edf-rect2',
                { attr: { x: '100%' }, duration: 0.5, ease: 'power2.out' },
                1.2
            );

            return t;
        }
    }
};
</script>
