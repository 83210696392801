<template>
    <ui-dropdown
        :id="cardElementId + '-track-volume-menu'"
        class="centered"
        :menu="false"
        :caret="false"
        :scrollable="false"
        :disabled="readOnly || isMediaConverting || !track.src"
        :tooltip="isMediaConverting ? $t('media.preparation.warning') : $t('Volume')"
        menu-placement="bottom"
        :icon-only="true"
        :toggle-style="toggleAudioVolumeStyle"
    >
        <template #dropdown-toggle>
            <svg-icon icon="volume-ai" />
        </template>

        <ui-range-input
            :id="cardElementId + '-track-volume'"
            v-model="trackVolume"
            :horizontal="false"
            :label="$t('Volume')"
            :max="200"
            :disabled="readOnly"
        />

        <div class="ui-informative-block border-rounded">
            <div class="ui-informative-block-row">
                <strong>{{ $t('audio-isolation.improve-label') }}</strong>

                <fa-icon v-if="isImproving" class="icon" icon="fa-solid fa-spinner-third" spin />

                <ui-switch-button
                    :id="cardElementId + '-track-improved'"
                    v-model="trackImproved"
                    :tooltip="!user.client.enableTts && $t('studio.features.recording-disabled')"
                    :show-label="false"
                    :disabled="readOnly || !user.client.enableTts || isImproving"
                />
            </div>

            <ui-voice-isolator-informative-note />
        </div>
    </ui-dropdown>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import { printf as sprintf } from 'fast-printf';

import UiDropdown from '@/js/components/UiDropdown.vue';
import { useVideo } from '@/js/videos/composables/useVideo.js';
import { useHistory } from '@/js/videos/composables/useHistory.js';
import { useSequence } from '@/js/videos/composables/useSequence.js';
import UiRangeInput from '@/js/components/UiRangeInput.vue';
import UiSwitchButton from '@/js/components/UiSwitchButton.vue';
import AudioIsolationService from '@/js/videos/application/services/AudioIsolationService.js';
import { useNotification } from '@/js/composables/useNotification.js';
import UsesTooltip from '@/js/mixins/UsesTooltip.js';
import UiVoiceIsolatorInformativeNote from '@/js/videos/components/ui/body/cards/menus/UiVoiceIsolatorInformativeNote.vue';
import { useAuth } from '@/js/composables/useAuth.js';

const vTooltip = UsesTooltip.directives.tooltip;

const props = defineProps({
    seqId: String,
    cardElementId: String
});

const store = useStore();
const { user } = useAuth();
const { readOnly } = useVideo();
const { t } = useI18n();
const { saveHistoryStep, ignoreHistoryStep } = useHistory();
const { success, error } = useNotification('toast', false);
const { sequenceState, sequenceStoreModulePath } = useSequence(props.seqId);

const isMediaConverting = computed(() => store.getters['loading/isConverting'](track.value.src));
const track = computed(() => ({
    src: sequenceState.value.audio.track.src,
    id: sequenceState.value.audio.track.src__id
}));

const trackVolume = computed({
    get: () => Math.round(100 * store.getters[sequenceStoreModulePath.value + '/trackVolume']),
    set: (value) => {
        saveHistoryStep(() => {
            store.commit(`${sequenceStoreModulePath.value}/setTrackVolume`, {
                value: (value / 100).toFixed(2),
                custom: null
            });
        });
    }
});

const audioIsolationService = new AudioIsolationService();
const isImproving = ref(false);

const trackImproved = computed({
    get: () => sequenceState.value.audio.track.improved,
    set: (value) => {
        const oldValue = sequenceState.value.audio.track.improved;
        ignoreHistoryStep(() => store.commit(`${sequenceStoreModulePath.value}/setTrackImproved`, value));

        isImproving.value = true;

        (async () => {
            const variationUuid = sequenceState.value.audio.track.src__id;

            const response = value
                ? await audioIsolationService.isolate(variationUuid)
                : await audioIsolationService.cancel(variationUuid);

            if (response.hasOwnProperty('error')) {
                error(sprintf(t('audio-isolation.sequence-audio-error'), response.error || t('Unknown error')));
                ignoreHistoryStep(() => store.commit(`${sequenceStoreModulePath.value}/setTrackImproved`, oldValue));
            } else {
                saveHistoryStep(() => {
                    store.dispatch(`${sequenceStoreModulePath.value}/updateTrack`, {
                        src__id: response.variation_uuid || '',
                        src: response.variation_uuid
                            ? store.getters['ui/mediaPreviewUrl'](response.variation_uuid)
                            : '',
                        improved: value
                    });
                });

                if (value) success(t('audio-isolation.sequence-audio-success'));
            }

            isImproving.value = false;
        })();
    }
});

const toggleAudioVolumeStyle = computed(() =>
    trackVolume.value !== 100 || sequenceState.value.audio.track.improved ? { color: 'var(--edit-mode-color)' } : {}
);
</script>
