import { ref, onMounted, onBeforeUnmount } from 'vue';
import { debounce } from 'lodash';

export function useQuickCutLayout(canvasWidth, adjustZoomLevel, extractFramesHandler) {
    const sliderWrapperStyle = ref('max-width: 100%;');
    const quickCutContainer = ref(null);

    const handleResize = () => {
        canvasWidth.value = 0;
        sliderWrapperStyle.value = 'max-width:100%;';
        debouncedResize();
    };

    const debouncedResize = debounce(() => {
        adjustZoomLevel();
        extractFramesHandler();
        if (quickCutContainer.value) {
            sliderWrapperStyle.value = `max-width: ${quickCutContainer.value.offsetWidth}px;`;
        }
    }, 550);

    onMounted(() => {
        quickCutContainer.value = document.getElementById('quick-cut-container');
        window.addEventListener('resize', handleResize);

        // Appeler extractFramesHandler après un court délai pour s'assurer que tout est initialisé
        setTimeout(() => {
            if (canvasWidth.value > 0) {
                extractFramesHandler();
            }
        }, 100);
    });

    onBeforeUnmount(() => {
        window.removeEventListener('resize', handleResize);
    });

    return {
        sliderWrapperStyle,
        quickCutContainer,
        handleResize
    };
}
